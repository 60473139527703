import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';

import "core-js/stable";
import "regenerator-runtime/runtime";

import App from './App';
import {HashRouter} from 'react-router-dom'

import {login} from './reducers/login';
import {stateManagement} from './reducers/stateManagement';
import {patients} from './reducers/patients';
import {findReferrer} from './reducers/findReferrers';
import {users} from './reducers/users';
import {dropDowns} from './reducers/dropDowns';
import {housekeeping} from './reducers/housekeeping';
import {websockets} from './reducers/websockets';
import {chartResources} from './reducers/chartReources';
import {diary} from './reducers/diary';
import {tasks} from './reducers/tasks';
import {tablet} from './reducers/tablet';
import {referrers} from './reducers/referrers';
import {treatmentPlanning} from './reducers/treatmentPlanning';
import {nhsManagement} from './reducers/nhsManagement';
import {paymentPlanning} from './reducers/paymentPlanning';
import {preferences} from './reducers/preferences';
import AxiosFetchClient from './actions/fetchClient'

const rootReducer = combineReducers({
    login,
    patients,
    users,
    findReferrer,
    dropDowns,
    housekeeping,
    websockets,
    chartResources,
    diary,
    tasks,
    tablet,
    treatmentPlanning,
    referrers,
    nhsManagement,
    paymentPlanning,
    preferences,
    stateManagement,
});

export const ac = new AxiosFetchClient();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <App/>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);
