import React from 'react';
import {Panel} from "primereact/components/panel/Panel";
import {SE_NONE, SM_CLIENT_PRESCRIPTION_HISTORY} from "./Constants";
import {Button} from "primereact/components/button/Button";
import {ClientComponent} from "./ClientComponent";
import {suppressBrowserCM} from "../Utils";

export class ClientPrescriptionHistory extends ClientComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {

        suppressBrowserCM();

        const header = <div><label id='panel-header'>{SM_CLIENT_PRESCRIPTION_HISTORY.detail}</label>
            <div className="p-toolbar-group-right">
                <Button label={SM_CLIENT_PRESCRIPTION_HISTORY.exitLabel} icon={SM_CLIENT_PRESCRIPTION_HISTORY.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSave(SE_NONE)}
                />
            </div>
        </div>;

        return (
            <div className="p-col-12 p-lg-12">
                <Panel header={header} className="no-pad">
                </Panel>
            </div>
        )
    }
}

