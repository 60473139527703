import * as Actions from "./index";
import {ac} from '../index'
import _ from "lodash";
import {ETH_GROUP} from "../components/Client/Sections/NHSDetails";
import {ICON_CAMERA} from "../Constants";

export const baseURL = '/Patients';

export const RES_PATIENT_REGISTRATION = {
    CREATE: {action: Actions.PATIENT_REGISTER, url: `${baseURL}/register/create`},
    SEND: {action: Actions.PATIENT_SUBMIT, url: `${baseURL}/register/send`},
    CONFIRM: {action: Actions.PATIENT_CONFIRM, url: `${baseURL}/register/confirm`},
};

export const RES_FIND_PATIENTS = {
    CLEAR: {action: Actions.CLEAR_PATIENT_SEARCH}
};

export const RES_PATIENT_DETAILS = {
    ADD: {request: Actions.REQUEST_PATIENT_ADD, receive: Actions.RECEIVE_PATIENT_ADD, url: `${baseURL}/addPatient`},
    ERROR: {action: Actions.PATIENT_ADD_ERROR},
    GET: {
        request: Actions.REQUEST_PATIENT_DETAILS,
        receive: Actions.RECEIVE_PATIENT_DETAILS,
        url: `${baseURL}/patientDetails`
    },
    GET_PORTAL_DATA: {
        request: Actions.REQUEST_PATIENT_PORTAL_DATA,
        receive: Actions.RECEIVE_PATIENT_PORTAL_DATA,
        url: `/Tablet/PatientPortalData`
    },
    SAVE: {action: Actions.SAVE_PATIENT_DETAILS, url: `${baseURL}/patientDetails`},
    SAVE_PORTAL: {action: Actions.SAVE_PATIENT_PORTAL_DETAILS, url: `${baseURL}/patientDetails`},
    CLEAR: {action: Actions.CLEAR_PATIENT_DETAILS},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_PATIENT_DETAILS},
    ADD_ONLINE: {
        request: Actions.REQUEST_PATIENT_ADD_ONLINE,
        receive: Actions.PATIENT_REGISTER,
        url: `${baseURL}/register/register`
    },
    WRITE_OFF: {action: Actions.PATIENT_WRITE_OFF, url: `${baseURL}/writeOff`},
    REACTIVATE: {
        request: Actions.REQUEST_PATIENT_REACTIVATE,
        receive: Actions.RECEIVE_PATIENT_REACTIVATE,
        url: `${baseURL}/reactivate`
    },
    GET_NOK: {
        request: Actions.REQUEST_PATIENT_NOK,
        receive: Actions.RECEIVE_PATIENT_NOK,
        url: `${baseURL}/patientNOKDetails`
    },
    SAVE_NOK: {action: Actions.SAVE_PATIENT_NOK_DETAILS, url: `${baseURL}/patientNOKDetails`},
    CLEAR_NOK: {action: Actions.CLEAR_PATIENT_NOK_DETAILS},
    SAVE_CLEAR_NOK: {action: Actions.SAVE_CLEAR_PATIENT_NOK_DETAILS},
    MED_COND: {action: Actions.SAVE_PATIENT_MED_COND, url: `${baseURL}/patientMedCond`},
};

export const RES_PATIENT_PASS = {
    GET: {request: Actions.REQUEST_PATIENT_PASS, receive: Actions.RECEIVE_PATIENT_PASS, url: `${baseURL}/patientPASS`},
};

export const RES_PATIENT_MHFS = {
    GET: {
        request: Actions.REQUEST_PATIENT_MHFS,
        receive: Actions.RECEIVE_PATIENT_MHFS,
        url: `${baseURL}/medicalHistoryForms`
    },
    GET_TABLET: {
        request: Actions.REQUEST_PATIENT_MHF,
        receive: Actions.RECEIVE_PATIENT_MHF,
        url: `Tablet/medicalHistoryFormForTablet`
    },
    SAVE: {
        action: Actions.SAVE_PATIENT_MHF,
        url: `/Tablet/medicalHistoryForm`
    },
    SAVE_TABLET: {
        action: Actions.SAVE_PATIENT_MHF,
        url: `/Tablet/medicalHistoryForm`
    },
    CLEAR: {action: Actions.CLEAR_PATIENT_MHF},
};

export const RES_PATIENT_QUESTIONNAIRE = {
    QUERY: {
        request: Actions.REQUEST_QUERY_PATIENT_QUESTIONNAIRE,
        receive: Actions.RECEIVE_QUERY_PATIENT_QUESTIONNAIRE,
        url: `/Tablet/queryQuestionnaire`
    },
    GET: {
        request: Actions.REQUEST_PATIENT_QUESTIONNAIRE,
        receive: Actions.RECEIVE_PATIENT_QUESTIONNAIRE,
        url: `Tablet/questionnaire`
    },
    GET_BY_ID: {
        request: Actions.REQUEST_PATIENT_QUESTIONNAIRE_BY_ID,
        receive: Actions.RECEIVE_PATIENT_QUESTIONNAIRE_BY_ID,
        url: `/Tablet/questionnaireById`
    },
    SAVE: {
        action: Actions.SAVE_PATIENT_QUESTIONNAIRE,
        url: `Tablet/questionnaire`
    },
    CLEAR: {
        action: Actions.CLEAR_PATIENT_QUESTIONNAIRE,
        url: `${baseURL}/questionnaire`
    },
    COMPLETE_UNCHANGED: {
        request: Actions.REQUEST_COMPLETE_UNCHANGED,
        receive: Actions.RECEIVE_COMPLETE_UNCHANGED,
        url: `${baseURL}/completeQuestionnaireUnchanged`
    },
    PRECOMPLETIONS: {
        request: Actions.REQUEST_PRECOMPLETED_QUESTIONNAIRES,
        receive: Actions.RECEIVE_PRECOMPLETED_QUESTIONNAIRES,
        url: `${baseURL}/completedQuestionnaires`
    },
    COMPLETIONS: {
        request: Actions.REQUEST_COMPLETED_QUESTIONNAIRES,
        receive: Actions.RECEIVE_COMPLETED_QUESTIONNAIRES,
        url: `${baseURL}/completedQuestionnaires`
    },
};

export const RES_PATIENT_MCONS = {
    GET: {
        request: Actions.REQUEST_PATIENT_MCONS,
        receive: Actions.RECEIVE_PATIENT_MCONS,
        url: `${baseURL}/medicalConditions`
    },
};

export const RES_PATIENT_DETAILS_SHORT = {
    GET: {
        request: Actions.REQUEST_PATIENT_DETAILS_SHORT,
        receive: Actions.RECEIVE_PATIENT_DETAILS_SHORT,
        url: `${baseURL}/patientDetailsShort`
    },
    REF: {
        request: Actions.REQUEST_PATIENT_DETAILS_SHORT,
        receive: Actions.RECEIVE_PATIENT_DETAILS_SHORT,
        url: `${baseURL}/patientRefDetails`
    },
};

export const RES_PATIENT_APPOINTMENT_SUMMARY = {
    GET: {
        request: Actions.REQUEST_PATIENT_APPOINTMENT_SUMMARY,
        receive: Actions.RECEIVE_PATIENT_APPOINTMENT_SUMMARY,
        url: `${baseURL}/appointmentSummary`
    },
    CLEAR: {action: Actions.CLEAR_PATIENT_APPOINTMENT_SUMMARY}
};

export const RES_PATIENT_TREATMENT_HISTORY = {
    GET: {
        request: Actions.REQUEST_PATIENT_TREATMENT_HISTORY,
        receive: Actions.RECEIVE_PATIENT_TREATMENT_HISTORY,
        url: `${baseURL}/treatmentHistory`
    },
    SAVE: {action: Actions.SAVE_PATIENT_TREATMENT_HISTORY, url: `${baseURL}/treatmentHistory`},
    CLEAR: {action: Actions.CLEAR_PATIENT_TREATMENT_HISTORY}
};

export const RES_PATIENT_ACCOUNT_HISTORY = {
    GET_OVERVIEW: {
        request: Actions.REQUEST_PATIENT_ACCOUNT_HISTORY_OVERVIEW,
        receive: Actions.RECEIVE_PATIENT_ACCOUNT_HISTORY_OVERVIEW,
        url: `${baseURL}/accountHistoryOverview`
    },
    GET: {
        request: Actions.REQUEST_PATIENT_ACCOUNT_HISTORY,
        receive: Actions.RECEIVE_PATIENT_ACCOUNT_HISTORY,
        url: `${baseURL}/accountHistory`
    },
    PDF: {
        request: Actions.REQUEST_PATIENT_ACCOUNT_HISTORY_PDF,
        receive: Actions.RECEIVE_PATIENT_ACCOUNT_HISTORY_PDF,
        url: `/Patient/PDFs/patientAccount`,
    },
    CLEAR: {action: Actions.CLEAR_PATIENT_ACCOUNT_HISTORY_PDF}
};

export const RES_PATIENT_PERIO_HISTORY = {
    GET: {
        request: Actions.REQUEST_PATIENT_PERIO_HISTORY,
        receive: Actions.RECEIVE_PATIENT_PERIO_HISTORY,
        url: `${baseURL}/perioHistory`
    },
    SAVE: {action: Actions.SAVE_PATIENT_PERIO_CHART, url: `${baseURL}/perioChart`},
    CLEAR: {action: Actions.CLEAR_PATIENT_PERIO_HISTORY},
    DELETE: {action: Actions.DELETE_PATIENT_PERIO_CHART, url: `${baseURL}/perioChart`}
};

export const RES_PATIENT_APPOINTMENT_TREATMENT_HISTORY = {
    GET: {
        request: Actions.REQUEST_PATIENT_APPOINTMENT_TREATMENT_HISTORY,
        receive: Actions.RECEIVE_PATIENT_APPOINTMENT_TREATMENT_HISTORY,
        url: `${baseURL}/treatmentHistory`
    }
};

export const HM_PATIENT_IMAGE = {
    id: 'HM_PATIENT_IMAGE',
    label: 'Use as Patient Photograph',
    header: 'Patient Photograph',
    message: 'Are you sure you want to use this image as the patient photograph?',
    icon: ICON_CAMERA,
};

export const RES_PAYMENT_PLAN_HISTORY = {
    GET: {
        request: Actions.REQUEST_PAYMENT_PLAN_HISTORY,
        receive: Actions.RECEIVE_PAYMENT_PLAN_HISTORY,
        url: `${baseURL}/paymentPlanHistory`
    },
    CLEAR: {action: Actions.CLEAR_PP_HISTORY},
};

export const RES_PATIENT_REMINDERS = {
    GET: {
        request: Actions.REQUEST_PATIENT_REMINDERS,
        receive: Actions.RECEIVE_PATIENT_REMINDERS,
        url: `${baseURL}/reminders`
    },
};

export const RES_PATIENT_RECALLS = {
    GET: {
        request: Actions.REQUEST_PATIENT_RECALLS,
        receive: Actions.RECEIVE_PATIENT_RECALLS,
        url: `${baseURL}/recalls`
    },
};

export const RES_PATIENT_NOTES = {
    GET: {
        request: Actions.REQUEST_PATIENT_NOTES,
        receive: Actions.RECEIVE_PATIENT_NOTES,
        url: `${baseURL}/patientNotes`
    },
    PDF_ALL: {
        request: Actions.REQUEST_PATIENT_NOTE_HISTORY_PDF,
        receive: Actions.RECEIVE_PATIENT_NOTE_HISTORY_PDF,
        url: `${baseURL}/noteHistory.pdf`
    },
    SAVE: {action: Actions.SAVE_PATIENT_NOTES, url: `${baseURL}/patientNotes`},
    CLEAR: {action: Actions.CLEAR_PATIENT_NOTES},
    DELETE: {action: Actions.DELETE_PATIENT_NOTE, url: `${baseURL}/patientNote`},
};

export const RES_PATIENT_APPOINTMENT_NOTES = {
    GET: {
        request: Actions.REQUEST_PATIENT_APPOINTMENT_NOTES,
        receive: Actions.RECEIVE_PATIENT_APPOINTMENT_NOTES,
        url: `${baseURL}/patientNotes`
    },
    SAVE: {action: Actions.SAVE_PATIENT_APPOINTMENT_NOTES, url: `${baseURL}/patientNotes`},
    CLEAR: {action: Actions.CLEAR_PATIENT_APPOINTMENT_NOTES},
    DELETE: {action: Actions.DELETE_PATIENT_APPOINTMENT_NOTE, url: `${baseURL}/patientNote`},
};

export const RES_PATIENT_XRAY_RECORDS = {
    GET: {
        request: Actions.REQUEST_PATIENT_XRAY_RECORDS,
        receive: Actions.RECEIVE_PATIENT_XRAY_RECORDS,
        url: `${baseURL}/xrayRecords`
    },
    SAVE: {action: Actions.SAVE_PATIENT_XRAY_RECORDS, url: `${baseURL}/xrayRecords`},
    CLEAR: {action: Actions.CLEAR_PATIENT_XRAY_RECORDS},
    DELETE: {action: Actions.DELETE_PATIENT_XRAY_RECORD, url: `${baseURL}/xrayRecord`},
};

export const RES_PATIENT_DOCUMENTS = {
    GET: {
        request: Actions.REQUEST_PATIENT_DOCUMENTS,
        receive: Actions.RECEIVE_PATIENT_DOCUMENTS,
        url: `${baseURL}/patientDocuments`
    },
    CREATE: {action: Actions.CREATE_PATIENT_DOCUMENTS, url: `${baseURL}/createDocument`},
    SAVE: {action: Actions.SAVE_PATIENT_DOCUMENTS, url: `${baseURL}/updateDocument`},
    OPEN: {action: Actions.OPEN_PATIENT_DOCUMENTS, url: `${baseURL}/openPatientDocument`},
    DELETE: {action: Actions.DELETE_PATIENT_DOCUMENTS, url: `${baseURL}/deleteDocument`},
    TRANSFER: {action: Actions.DELETE_PATIENT_DOCUMENTS, url: `${baseURL}/transferDocument`},
    USE_AS_PHOTO: {action: Actions.USE_AS_PHOTO, url: `${baseURL}/useAsPhoto`},
    PDF: {
        request: Actions.REQUEST_PATIENT_DOCUMENT_PDF,
        receive: Actions.RECEIVE_PATIENT_DOCUMENT_PDF,
        url: `${baseURL}/openPatientDocument`
    },
};

export const RES_PATIENT_BILLING_GROUP = {
    GET: {
        request: Actions.REQUEST_PATIENT_BILLING_GROUP,
        receive: Actions.RECEIVE_PATIENT_BILLING_GROUP,
        url: `${baseURL}/billingGroup`
    },
    ADD: {action: Actions.ADD_PATIENT_BILLING_GROUP, url: `${baseURL}/addBillingGroup`},
    UPDATE: {action: Actions.UPDATE_PATIENT_BILLING_GROUP, url: `${baseURL}/updateBillingGroup`},
    REMOVE: {action: Actions.REMOVE_PATIENT_BILLING_GROUP, url: `${baseURL}/updateBillingGroup`},
    DELETE: {action: Actions.DELETE_PATIENT_BILLING_GROUP, url: `${baseURL}/deleteBillingGroup`},
    GET_IN_FIND: {
        request: Actions.REQUEST_PATIENT_BG_IN_FIND,
        receive: Actions.RECEIVE_PATIENT_BG_IN_FIND,
        url: `${baseURL}/billingGroup`
    },
};

export const RES_PATIENT_ASSESSMENTS = {
    GET: {
        request: Actions.REQUEST_PATIENT_ASSESSMENTS,
        receive: Actions.RECEIVE_PATIENT_ASSESSMENTS,
        url: `${baseURL}/getPatAssessments`
    },
    SAVE: {action: Actions.SAVE_PAT_ASSESSMENT, url: `${baseURL}/patientAssessment`},
    CLEAR: {action: Actions.CLEAR_PAT_ASSESSMENT}
};

export const RES_PATIENT_ASSESSMENTS_APPOINTMENT = {
    GET: {
        request: Actions.REQUEST_PATIENT_ASSESSMENTS_APPOINTMENT,
        receive: Actions.RECEIVE_PATIENT_ASSESSMENTS_APPOINTMENT,
        url: `${baseURL}/getPatAssessments`
    },
    SAVE: {action: Actions.SAVE_PAT_ASSESSMENT_APPOINTMENT, url: `${baseURL}/patientAssessment`},
    CLEAR: {action: Actions.CLEAR_PAT_ASSESSMENT_APPOINTMENT}
};

export const RES_ORTHO_ASSESSMENTS = {
    GET: {
        request: Actions.REQUEST_ORTHO_ASSESSMENTS,
        receive: Actions.RECEIVE_ORTHO_ASSESSMENTS,
        url: `${baseURL}/getOrthoAssessments`
    },
    SAVE: {action: Actions.SAVE_ORTHO_ASSESSMENT, url: `${baseURL}/orthoAssessment`},
    DELETE: {action: Actions.SAVE_ORTHO_ASSESSMENT, url: `${baseURL}/orthoAssessment`},
    CLEAR: {action: Actions.CLEAR_ORTHO_ASSESSMENT}
};

export const RES_ORTHO_ASSESSMENTS_APPOINTMENT = {
    GET: {
        request: Actions.REQUEST_ORTHO_ASSESSMENTS_APPOINTMENT,
        receive: Actions.RECEIVE_ORTHO_ASSESSMENTS_APPOINTMENT,
        url: `${baseURL}/getOrthoAssessments`
    },
    SAVE: {action: Actions.SAVE_ORTHO_ASSESSMENT_APPOINTMENT, url: `${baseURL}/orthoAssessment`},
    CLEAR: {action: Actions.CLEAR_ORTHO_ASSESSMENT_APPOINTMENT}
};

export const RES_PATIENT_ACCOUNT = {
    ISSUE_INVOICE: {
        request: Actions.REQUEST_PATIENT_ISSUE_INVOICE,
        receive: Actions.RECEIVE_PATIENT_ISSUE_INVOICE,
        url: `${baseURL}/issueInvoice`
    },
    ISSUE_INVOICE2: {
        request: Actions.REQUEST_PATIENT_ISSUE_INVOICE,
        receive: Actions.RECEIVE_PATIENT_ISSUE_INVOICE,
        url: `${baseURL}/issueInvoice2`
    },
    ISSUE_RECEIPT: {
        request: Actions.REQUEST_PATIENT_ISSUE_RECEIPT,
        receive: Actions.RECEIVE_PATIENT_ISSUE_RECEIPT,
        url: `${baseURL}/issueReceipt`
    },
    ISSUE_RECEIPT2: {
        request: Actions.REQUEST_PATIENT_ISSUE_RECEIPT,
        receive: Actions.RECEIVE_PATIENT_ISSUE_RECEIPT,
        url: `${baseURL}/issueReceipt2`
    },
    MAKE_PAYMENT: {
        request: Actions.REQUEST_PATIENT_MAKE_PAYMENT,
        receive: Actions.RECEIVE_PATIENT_MAKE_PAYMENT,
        url: `${baseURL}/makePayment`
    },
    MAKE_REFUND: {
        request: Actions.REQUEST_PATIENT_MAKE_REFUND,
        receive: Actions.RECEIVE_PATIENT_MAKE_REFUND,
        url: `${baseURL}/makeRefund`
    },
    ISSUE_CREDIT: {
        request: Actions.REQUEST_PATIENT_ISSUE_CREDIT,
        receive: Actions.RECEIVE_PATIENT_ISSUE_CREDIT,
        url: `${baseURL}/makeRefund`
    },
    RECALC: {
        request: Actions.REQUEST_PATIENT_ACCOUNT_RECALC,
        receive: Actions.RECEIVE_PATIENT_ACCOUNT_RECALC,
        url: `${baseURL}/recalcAccount`
    },
};

export const RES_PATIENT_ACCESS = {
    ADD: {action: Actions.PATIENT_ACCESS_ADD, url: `${baseURL}/accessHistory`},
    GET: {
        request: Actions.REQUEST_PATIENT_ACCESS,
        receive: Actions.RECEIVE_PATIENT_ACCESS,
        url: `${baseURL}/accessHistory`
    },
    CLEAR: {action: Actions.CLEAR_PATIENT_ACCESS}
};

export const RES_PATIENT_PASSWORD = {
    RESET: {action: Actions.RESET_PORTAL_PASSWORD, url: `${baseURL}/resetPatientPassword`},
};

export const RES_CHART = {
    GET: {url: `${baseURL}/chartByAppointmentId`, request: Actions.REQUEST_CHART, receive: Actions.RECEIVE_CHART},
    GET_BY_PATIENT_ID: {
        url: `${baseURL}/chartByPatientId`,
        request: Actions.REQUEST_CHART,
        receive: Actions.RECEIVE_CHART
    },
    SAVE: {url: `${baseURL}/update`, action: Actions.SAVE_CHART},
    CLEAR: {action: Actions.CLEAR_CHART},
    SAVE_CLEAR: {action: Actions.SAVE_CLEAR_CHART}
};

export const RES_REPORT_ACC_ITEMS = {
    GET: {
        request: Actions.REQUEST_REPORT_ACC_ITEM,
        receive: Actions.RECEIVE_REPORT_ACC_ITEM,
        url: `${baseURL}/accountingItem.pdf`
    },
    INV: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.invoice.pdf`
    },
    RCT: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.pdf`
    },
    REF: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.pdf`
    },
    PAY: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.payment.pdf`
    },
    CRN: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.cnpa.pdf`
    },
    ADJ: {
        request: Actions.REQUEST_ACCOUNT_ITEM_PDF,
        receive: Actions.RECEIVE_ACCOUNT_ITEM_PDF,
        url: `${baseURL}/accountingItem.cnpa.pdf`
    },
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {
                if (res !== undefined) {
                    dispatch({type: receive, payload: res.data, ...params});
                }
            });
        dispatch({type: request, payload: false, ...params,});
    }
};

// this is required as there is a sharing between patient and appointment request that need a requesterRef
export const getResource2 = ({url, request, receive}, requesterLoadedRef, requesterRef, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({
                    type: receive,
                    payload: res.data,
                    smLoadedRef: requesterLoadedRef,
                    smRef: requesterRef, ...params
                })
            });
        dispatch({type: request, payload: false, ...params,});
    }
};

export const savePatientDataWithMcId = ({url, request, receive}, mcId, patient, _NHSRegistration) => {

    return (dispatch) => {

        let NHSRegistration = {..._NHSRegistration};
        NHSRegistration.ethnicGroup = _.find(ETH_GROUP, group => group.code === NHSRegistration.ethnicGroup.code).value;

        if (NHSRegistration.nhsNumber.trim() !== '') {
            patient.NHSPatient = true;
        } else {
            NHSRegistration = null;
            patient.NHSPatient = false;
        }

        const data = {patient, NHSRegistration};

        ac.fetchClient().post(`${url}/?mcId=${mcId}`, data)
            .then((res) => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const savePatientNHSData = ({url, request, receive}, data) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, data)
            .then((res) => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const issueReceipt = ({url, request, receive}, receipt, salesInvoice, receiptChargesToSend, removeCharges) => {

    return (dispatch) => {

        const receiptCharges = receiptChargesToSend.constructor === Array ? receiptChargesToSend : [{...receiptChargesToSend}];

        receiptCharges.forEach(charge => {
            console.log(`Receipt Charge : ${charge.id} :: ${charge.status}`);
        });

        const params = {params: {receipt, salesInvoice, receiptCharges, removeCharges}};

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, params)
            .then((res) => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const makePayment = ({url, request, receive}, _payment) => {

    const payment = {..._payment};

    payment.takenBy = {id: _payment.takenBy.id};

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, payment)
            .then((res) => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const recalculateAccount = ({url, request, receive}, details) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}/?mcId=${ac.getMcId()}`, {params: {...details}})
            .then((res) => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const openDocument = ({url, action}, document) => {

    const UUID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    return (dispatch) => {

        ac.fetchClient()({
            url: `${url}/${ac.getMcId()}/${document.filename}/${document.type}/${UUID}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then((res) => {

                dispatch({type: action, payload: res.data, filename: document.filename})
            });
    }
};

export const setPhotoImage = ({url, action}, docId, appointmentId) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}/?mcId=${ac.getMcId()}&docId=${docId}&appointmentId=${appointmentId}`)
            .then((res) => {

                dispatch({type: action, payload: res.data})
            });
    }
};

export const addPatientAccess = ({url, action}, params) => {

    return () => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, params).then();
    }
};

export const getMedicalHistory = ({url, request, receive}, patientId) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {patientId}})
            .then((res) => {
                dispatch({type: receive, payload: res.data, patientId});
            });
        dispatch({type: request, payload: false});
    }
};

export const saveMedicalHistory = ({url, action}, medicalHistoryForm) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, medicalHistoryForm)
            .then((res) => {
                dispatch({type: action, payload: res.data});
            });
    }
};

export const savePatientNOK = ({url, action}, patientNOKData) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, patientNOKData)
            .then((res) => {
                dispatch({type: action, payload: res.data});
            });
    }
};

export const getQuestionnaire = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then((res) => {
                dispatch({type: receive, payload: res.data, ...params});
            });
        dispatch({type: request, payload: false});
    }
};

export const saveQuestionnaire = ({url, action}, data) => {

    const _data = {...data};
    _data.questionnaire.patient = {id: _data.questionnaire.patient.id};

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, _data)
            .then((res) => {
                dispatch({type: action, payload: res.data});
            });
    }
};

export const sendSMS = ({url, action}, details) => {

    const params = {params: {...details}};

    return (dispatch) => {
        ac.fetchClient().get(`${url}/?mcId=${ac.getMcId()}`, params)
            .then(() => {
                dispatch({type: action, payload: params})
            });
    }
};

export const resendPortalReference = ({url, action}, patientId) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}/?mcId=${ac.getMcId()}`, {params: {patientId}})
            .then(() => {
                dispatch({type: action, payload: true})
            });
    }
};

export const previewPatientDocument = ({url, action}, document, previewToken) => {

    return () => {

        const params = {
            document,
            previewToken,
        };

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}}).then();
    }
};

export const previewAccountItem = ({url, action}, details, previewToken) => {

    return () => {

        const params = {
            details,
            previewToken,
        };

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}}).then();
    }
};

export const sendPatientDocument = ({url, action}, document, message, idToken) => {

    return () => {

        const params = {
            document,
            message,
            idToken
        };

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}}).then();
    }
};

export const patientRegister = ({url, action}, mcId, inputForm) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${mcId}`, inputForm)
            .then(res => {

                dispatch({type: action, payload: res.data})
            });
    }
};

export const resetPassword = ({url, action}, portalReference) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {portalReference}})
            .then(res => {

                dispatch({type: action, payload: res.data})
            });
    }
};

export const getPDFDocument = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}/${ac.getMcId()}/${params.filename}/${params.type}/${params.key}`, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            },
        })
            .then(res => {
                dispatch({type: receive, payload: res.data, docId: params.docId})
            });
        dispatch({type: request, payload: {}, docId: params.docId});
    }
};
