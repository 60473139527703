import {ac} from '../index'

export const RES_PREFERENCES_PDS = {
    GET: {request: 'REQUEST_PREFERENCES_PDS', receive: 'RECEIVE_PREFERENCES_PDS', url: '/Preferences/practiceDetails'},
    SAVE: {action: 'SAVE_PREFERENCES_PDS', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_ACS = {
    GET: {request: 'REQUEST_PREFERENCES_ACS', receive: 'RECEIVE_PREFERENCES_ACS', url: '/Preferences/practiceDetails'},
    SAVE: {action: 'SAVE_PREFERENCES_ACS', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_EMAIL = {
    GET: {
        request: 'REQUEST_PREFERENCES_EMAIL',
        receive: 'RECEIVE_PREFERENCES_EMAIL',
        url: '/Preferences/practiceDetails'
    },
    SAVE: {action: 'SAVE_PREFERENCES_EMAIL', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_HOURS = {
    GET: {
        request: 'REQUEST_PREFERENCES_HOURS',
        receive: 'RECEIVE_PREFERENCES_HOURS',
        url: '/Preferences/practiceDetails'
    },
    SAVE: {action: 'SAVE_PREFERENCES_HOURS', url: '/Preferences/practiceDetails'},
};

export const RES_PREFERENCES_NHS = {
    GET: {request: 'REQUEST_PREFERENCES_NHS', receive: 'RECEIVE_PREFERENCES_NHS', url: '/Preferences/nhsContracts'},
    SAVE: {action: 'SAVE_PREFERENCES_NHS', url: '/Preferences/nhsContracts'},
    DELETE: {action: 'DELETE_PREFERENCES_NHS', url: '/Preferences/deleteContract'}
};

export const RES_PREFERENCES_RAR = {
    GET: {request: 'REQUEST_PREFERENCES_RAR', receive: 'RECEIVE_PREFERENCES_RAR', url: '/Preferences/practiceDetails'},
    SAVE: {action: 'SAVE_PREFERENCES_RAR', url: '/Preferences/practiceDetails'},
};

export const getResource = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...params}})
            .then(res => {

                dispatch({type: receive, payload: res.data, params})
            });
        dispatch({type: request, payload: false})
    }
};
