import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {dropDown, inputText} from './OnChangeUtils';

export const Address = (props) => {

    let key = Math.floor(Math.random() * 1000);;
    const postCodeMandatory = props.postCodeMandatory === undefined ? false : props.postCodeMandatory;

    return (
        <Panel header={props.header}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-2">
                    <label htmlFor='line1'>Line 1</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'addressLine1', 'Line 1', false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='line2'>Line 2</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'addressLine2', 'Line 2', false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='city'>City</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'addressLine3', 'City', false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='county'>County</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'county', 'County', false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='postcode'>Postcode</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'postcode', 'Postcode', false, postCodeMandatory)}
                </div>
                <div className="p-col-2">
                    <label htmlFor="mainCountry">Country</label>
                </div>
                <div className="p-col-10">
                    {dropDown(key+=1, props, 'countries', 'country', 'countryName', true, false)}
                </div>
            </div>
        </Panel>
    );
};
