import * as Actions from './index';
import {RES_HOUSEKEEPING} from "./housekeeping";
import _ from 'lodash'
import {ac} from '../index'
import {RES_CLINICIAN_DIARY, RES_PRACTICE_DIARY} from "./diary";
import {RES_TREATMENT_PLAN_BY_APP_ID, RES_TREATMENT_PLAN_BY_ID, RES_TREATMENT_PLANNING} from "./treatmentPlanning";
import {RES_FIND_PATIENTS, RES_PATIENT_DETAILS, RES_PATIENT_MHFS, RES_PATIENT_QUESTIONNAIRE} from "./personal";
import {RES_FIND_USERS, RES_getUsersAndCapabilities} from "./users";
import {savePlan, savePlan2} from "./Utils/treatmentPlanning";
import {savePaymentPlan} from "./Utils/paymentPlanning";
import {savePatient, savePatientMHF, savePatientPortal, savePatientQuestionnaire} from "./Utils/personal";
import {saveHouskeeping} from "./Utils/housekeeping";
import {TAB_PatientConsentForms} from "../components/Client/Constants";
import {saveRoleCapabilities, saveUserCapabilities} from "./Utils/users";
import {RES_PAYMENT_PLAN_BY_ID, RES_PAYMENT_PLANNING} from "./paymentPlanning";
import {ICON_PREFS} from "../Constants";

// help urls

export const CLIENT_KNOWLEDGE_BASE = `http://chiraldocs.hopto.org/knowledge-base/client`;

export const KNOWLEDGE_BASE = `http://chiraldocs.hopto.org/knowledge-base/`;

export const SM_404_PAGE_ERROR = {
    route: '/notFound',
}

export const SM_404_CLOUD_PAGE_ERROR = {
    route: '/cloudNotFound',
}

export const SM_500_CLOUD_PAGE_ERROR = {
    route: '/cloudInactive',
}

export const SM_404_CLOUD_LAB_PAGE_ERROR = {
    route: '/cloudLabNotFound',
}

export const SM_PRACTICE_DIARY = {
    loaded: 'practiceDiaryLoaded',
    id: 'practiceDiaryNotes',
    label: 'Practice Diary',
    bigIcon: 'far fa-calendar-alt',
    icon: 'far fa-calendar-alt',
    tabIcon: 'far fa-calendar-alt'
};

export const SM_CLINICIAN_DIARY = {
    loaded: 'SM_CLINICIAN_DIARYLoaded',
    id: 'SM_CLINICIAN_DIARY',
    label: 'Clinician Diary',
    bigIcon: 'far fa-calendar-alt',
    icon: 'far fa-calendar-alt',
    tabIcon: 'far fa-calendar-alt'
};

export const SM_templateNotes = {
    loaded: 'templatesNotesLoaded',
    id: 'templatesNotes',
    label: '',
    icon: '',
    tabIcon: ''
};

// Payment plan related

export const SM_PaymentPlaning = {
    loaded: 'SM_PaymentPlaningLoaded',
    id: 'SM_PaymentPlaning',
    label: 'Payment Planing',
    icon: 'fas fa-list-ol',
    tabIcon: 'fas fa-list-ol'
};

// treatment plan related

export const SM_TreatmentPlaning = {
    loaded: 'SM_TreatmentPlaningLoaded',
    id: 'SM_TreatmentPlaning',
    label: '',
    icon: 'fas fa-clipboard-list',
    tabIcon: 'fas fa-clipboard-list',
};

export const SM_TreatmentPlanHistory = {
    loaded: 'SM_TreatmentPlanHistoryLoaded',
    id: 'SM_TreatmentPlanHistory',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_TreatmentPlan = {
    loaded: 'SM_TreatmentPlanLoaded',
    id: 'SM_TreatmentPlan',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_APPTreatmentPlan = {
    loaded: 'SM_APPTreatmentPlanLoaded',
    id: 'SM_APPTreatmentPlan',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PAYMENT_PLAN = {
    loaded: 'SM_PAYMENT_PLAN_LOADED',
    id: 'SM_PAYMENT_PLAN',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PAYMENT_PLAN_INSTALLMENTS = {
    loaded: 'SM_PAYMENT_PLAN_INSTALLMENTS_LOADED',
    id: 'SM_PAYMENT_PLAN_INSTALLMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

// user related

export const SM_USER_SEARCH = {
    loaded: 'SM_USER_SEARCH_LOADED',
    id: 'SM_USER_SEARCH',
    label: 'Find Users',
    bigIcon: 'fas fa-search',
    icon: 'fas fa-search',
    tabIcon: 'fas fa-search'
};

export const SM_USER_DATA = {
    loaded: 'SM_USER_DATA_LOADED',
    id: 'SM_USER_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_USER_CAPABILITIES = {
    loaded: 'SM_USER_CAPABILITIES_LOADED',
    id: 'SM_USER_CAPABILITIES',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

// referrer related

export const SM_REFERRER_DATA = {
    loaded: 'SM_REFERRER_SEARCH_DATA',
    id: 'SM_REFERRER_DATA',
    label: 'Open Patient Summary',
    icon: 'fas fa-people-arrows',
    tabIcon: 'fas fa-people-arrows',
};

export const SM_REFERRER_PATIENT_DATA = {
    loaded: 'SM_REFERRER_PATIENT_DATA_LOADED',
    id: 'SM_REFERRER_PATIENT_DATA',
    label: 'Open Patient Summary',
    icon: 'fas fa-people-arrows',
    tabIcon: 'fas fa-people-arrows',
};

// patient related

export const SM_EMAIL_MAIL_SHOTS = {
    loaded: 'SM_EMAIL_MAIL_SHOTS_Loaded',
    id: 'SM_EMAIL_MAIL_SHOTS',
    label: 'Email Mail Shots',
    icon: 'far fa-email',
    tabIcon: 'far fa-email'
};

export const SM_SMS_MAIL_SHOTS = {
    loaded: 'SM_SMS_MAIL_SHOTS_Loaded',
    id: 'SM_SMS_MAIL_SHOTS',
    label: 'SMS Mail Shots',
    icon: 'far fa-sms',
    tabIcon: 'far fa-sms'
};

export const SM_PATIENT_SEARCH = {
    loaded: 'SM_PATIENT_SEARCH_LOADED',
    id: 'SM_PATIENT_SEARCH',
    label: 'Find Patients',
    bigIcon: 'fas fa-search',
    icon: 'fas fa-search',
    tabIcon: 'fas fa-search'
};

export const SM_PATIENT_DATA = {
    loaded: 'SM_PATIENT_DATA_LOADED',
    id: 'SM_PATIENT_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_NOK_DATA = {
    loaded: 'SM_PATIENT_NOK_DATA_LOADED',
    id: 'SM_PATIENT_NOK_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_XRAY_RECORDS = {
    loaded: 'SM_PATIENT_XRAY_RECORDS_LOADED',
    id: 'SM_PATIENT_XRAY_RECORDS',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_PATIENT_PORTAL_DATA = {
    loaded: 'SM_PATIENT_PORTAL_DATA_LOADED',
    id: 'SM_PATIENT_PORTAL_DATA',
    label: '',
    maleIcon: '',
    femaleIcon: '',
    maleTabIcon: 'fas fa-male',
    femaleTabIcon: 'fas fa-female'
};

export const SM_patientDataShort = {
    loaded: 'SM_patientDataShortLoaded',
    id: 'SM_patientDataShort',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_NOTES = {
    loaded: 'SM_PATIENT_APPOINTMENT_NOTES_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_NOTES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_NOTES = {
    loaded: 'SM_PATIENT_NOTES_LOADED',
    id: 'SM_PATIENT_NOTES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_SUMMARY = {
    loaded: 'SM_PATIENT_APPOINTMENT_SUMMARY_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_SUMMARY',
    label: 'Appointment Summary',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_HISTORY_PDF = {
    loaded: 'SM_PATIENT_APPOINTMENT_HISTORY_PDF_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_HISTORY_PDF',
    label: 'Appointment History PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AppointmentHistory',
};

export const SM_PATIENT_NOTE_HISTORY_PDF = {
    loaded: 'SM_PATIENT_NOTE_HISTORY_PDF_LOADED',
    id: 'SM_PATIENT_NOTE_HISTORY_PDF',
    label: 'Note History PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'NoteHistory',
};

export const SM_PATIENT_TREATMENT_HISTORY = {
    loaded: 'SM_PATIENT_TREATMENT_HISTORY_LOADED',
    id: 'SM_TREATMENT_HISTORY_SUMMARY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY = {
    loaded: 'SM_PATIENT_APPOINTMENT_TREATMENT_HISTORY_LOADED',
    id: 'SM_TREATMENT_APPOINTMENT_HISTORY_SUMMARY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW = {
    loaded: 'SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW_LOADED',
    id: 'SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW',
    label: 'Account History Overview',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCOUNT_HISTORY = {
    loaded: 'SM_PATIENT_ACCOUNT_HISTORY_LOADED',
    id: 'SM_PATIENT_ACCOUNT_HISTORY',
    label: 'Account History',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCOUNT_HISTORY_PDF = {
    loaded: 'SM_PATIENT_ACCOUNT_HISTORY_PDF_LOADED',
    id: 'SM_PATIENT_ACCOUNT_HISTORY_PDF',
    label: 'Account History PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AccountHistory'
};

export const SM_PATIENT_ACCOUNT_ITEM_PDF = {
    loaded: 'SM_PATIENT_ACCOUNT_ITEM_PDF_LOADED',
    id: 'SM_PATIENT_ACCOUNT_ITEM_PDF',
    label: 'Account Item PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AccountItem'
};

export const SM_PAYMENT_PLAN_HISTORY = {
    loaded: 'SM_PAYMENT_PLAN_HISTORY_LOADED',
    id: 'SM_PAYMENT_PLAN_HISTORY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_DOCUMENTS = {
    loaded: 'SM_PATIENT_DOCUMENTS_LOADED',
    id: 'SM_PATIENT_DOCUMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_DOCUMENT_PDF = {
    loaded: 'SM_PATIENT_DOCUMENT_PDF_LOADED',
    id: 'SM_PATIENT_DOCUMENT_PDF',
    label: 'Patient PDF Document',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'PatientDocument',
};

export const SM_PATIENT_IMAGES = {
    loaded: 'SM_PATIENT_IMAGES_LOADED',
    id: 'SM_PATIENT_IMAGES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_BILLING_GROUP = {
    loaded: 'SM_PATIENT_BILLING_GROUP_LOADED',
    id: 'SM_PATIENT_BILLING_GROUP',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_RECALLS = {
    loaded: 'SM_PATIENT_RECALLS_LOADED',
    id: 'SM_PATIENT_RECALLS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_PRSS = {
    loaded: 'SM_PATIENT_PRSS_LOADED',
    id: 'SM_PATIENT_PRSS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_PASS = {
    loaded: 'SM_PATIENT_PASS_LOADED',
    id: 'SM_PATIENT_PASS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_MHFS = {
    loaded: 'SM_PATIENT_MHFS_LOADED',
    id: 'SM_PATIENT_MHFS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_MHF = {
    loaded: 'SM_PATIENT_MHF_LOADED',
    id: 'SM_PATIENT_MHF',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_QUES = {
    loaded: 'SM_PATIENT_QUES_LOADED',
    id: 'SM_PATIENT_QUES',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_QUES_QUERY = {
    loaded: 'SM_PATIENT_QUES_QUERY_LOADED',
    id: 'SM_PATIENT_QUES_QUERY',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_MCONS = {
    loaded: 'SM_PATIENT_MCONS_LOADED',
    id: 'SM_PATIENT_MCONS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_REMINDERS = {
    loaded: 'SM_PATIENT_REMINDERS_LOADED',
    id: 'SM_PATIENT_REMINDERS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ASSESSMENTS = {
    loaded: 'SM_PATIENT_ASSESSMENTS_LOADED',
    id: 'SM_PATIENT_ASSESSMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ASSESSMENTS_ORTHO = {
    loaded: 'SM_PATIENT_ASSESSMENTS_ORTHO_LOADED',
    id: 'SM_PATIENT_ASSESSMENTS_ORTHO',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_ASSESSMENTS = {
    loaded: 'SM_PATIENT_ASSESSMENTS_APPOINTMENT_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_ASSESSMENTS',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO = {
    loaded: 'SM_PATIENT_ASSESSMENTS_APPOINTMENT_ORTHO_LOADED',
    id: 'SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_patientChart = {
    loaded: 'SM_patientChartLoaded',
    id: 'SM_patientChart',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PERIO_CHART = {
    loaded: 'SM_PERIO_CHART_Loaded',
    id: 'SM_PERIO_CHART',
    label: 'Perio CHart',
    icon: '',
    tabIcon: ''
};

export const SM_APPOINTMENT_PERIO_CHART = {
    loaded: 'SM_APPOINTMENT_PERIO_CHART_Loaded',
    id: 'SM_APPOINTMENT_PERIO_CHART',
    label: 'Perio CHart',
    icon: '',
    tabIcon: ''
};

export const SM_NHSRegistration = {
    loaded: 'SM_NHSRegistrationLoaded',
    id: 'SM_NHSRegistration',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_NHSClaimHistory = {
    loaded: 'SM_NHSClaimHistoryLoaded',
    id: 'SM_NHSClaimHistory',
    label: '',
    icon: '',
    tabIcon: ''
};

export const SM_PATIENT_ACCESS = {
    loaded: 'SM_PATIENT_ACCESS_Loaded',
    id: 'SM_PATIENT_ACCESS',
    label: 'Patient Access',
    icon: 'fas fa-user-check',
    tabIcon: 'fas fa-user-check'
};

export const SM_CLOUD_LOGGED_IN = {
    loaded: 'SM_CLOUD_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_LOGGED_IN',
    route: "/main/practiceControlCenter",
    label: 'Cloud Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_FORCE_LOGOUT = {
    id: 'SM_FORCE_LOGOUT',
};

export const SM_CLOUD_ADMIN_LOGGED_IN = {
    loaded: 'SM_CLOUD_ADMIN_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_ADMIN_LOGGED_IN',
    route: "/main/adminControlCenter",
    label: 'Cloud Admin Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_CLOUD_PORTAL_LOGIN = {
    id: 'SM_CLOUD_PORTAL_LOGIN',
    route: `/Client/:portalReference?`,
};

export const SM_CLOUD_PORTAL_LOGGED_IN = {
    loaded: 'SM_CLOUD_PORTAL_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_PORTAL_LOGGED_IN',
    route: "/main/clientControlCenter",
    label: 'Portal Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_CLOUD_LAB_LOGGED_IN = {
    loaded: 'SM_CLOUD_LAB_LOGGED_IN_Loaded',
    id: 'SM_CLOUD_LAB_LOGGED_IN',
    route: "/main/labControlCenter",
    label: 'Laboratory Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

// tablet related

export const SM_CLIENT_PW_FORGOT = {
    loaded: 'SM_CLIENT_PW_FORGOT_Loaded',
    id: 'SM_CLIENT_PW_FORGOT',
    route: "/forgotClient",
    label: 'Client Forgotten Password',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_TABLET_LOGGED_IN = {
    loaded: 'SM_TABLET_LOGGED_IN_Loaded',
    id: 'SM_TABLET_LOGGED_IN',
    route: '/main/tablet',
    label: 'Tablet Logged In',
    icon: 'tablet24',
    tabIcon: 'tablet16'
};

export const SM_TAB_PATIENT_CONSENT = {
    loaded: 'SM_TAB_PATIENT_CONSENT_LOADED',
    id: 'SM_TAB_PATIENT_CONSENT',
    route: '/main/patientConsent',
    exitRoute: '/main/patientConsentExit',
    label: 'Consent Forms',
    type: TAB_PatientConsentForms,
    detail: 'Added to consent form list',
    icon: 'fas fa-tablet-alt',
};

export const SM_TAB_PATIENT_CONSENT_SIG = {
    label: 'Patient Signature',
};

export const SM_TAB_GDPR_CONSENT_SIG = {
    label: 'Patient Signature',
};

export const SM_TAB_GDPR_CONSENT = {
    loaded: 'SM_TAB_GDPR_CONSENT_LOADED',
    id: 'SM_TAB_GDPR_CONSENT',
    label: 'GDPR Consent Form',
};

export const SM_PLAN_PROVIDERS = {
    loaded: 'SM_PLAN_PROVIDERS_Loaded',
    id: 'SM_PLAN_PROVIDERS',
    label: '',
    icon: '',
    tabIcon: ''
};

// Preferences

export const SM_PREFERENCES = {
    loaded: 'SM_PREFERENCESLoaded',
    id: 'SM_PREFERENCES',
    label: 'Preferences',
    icon: ICON_PREFS,
    tabIcon: ICON_PREFS
};

// House keeping

export const SM_HOUSEKEEPING = {
    loaded: 'SM_HOUSEKEEPINGLoaded',
    id: 'SM_HOUSEKEEPING',
    label: 'House Keeping',
    bigIcon: 'fas fa-tools',
    icon: 'fas fa-tools',
    tabIcon: 'fas fa-tools'
};

export const SM_HOUSEKEEPING_MCS = {
    loaded: 'SM_HOUSEKEEPING_MCSLoaded',
    id: 'SM_HOUSEKEEPING_MCS',
    label: 'Medical Conditions',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-medical-conditions/`
};

export const SM_HOUSEKEEPING_QUES = {
    loaded: 'SM_HOUSEKEEPING_QUESLoaded',
    id: 'SM_HOUSEKEEPING_QUES',
    label: 'Questionnaires',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-questionnaires/`
};

export const SM_HOUSEKEEPING_RECTYPES = {
    loaded: 'SM_HOUSEKEEPING_RECTYPESLoaded',
    id: 'SM_HOUSEKEEPING_RECTYPES',
    label: 'Recall Types',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-recall-types/`
};

export const SM_HOUSEKEEPING_CRS = {
    loaded: 'SM_HOUSEKEEPING_CRSLoaded',
    id: 'SM_HOUSEKEEPING_CRS',
    label: 'Cancellation Reasons',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-cancellation-reasons/`
};

export const SM_HOUSEKEEPING_JSTAGES = {
    loaded: 'SM_HOUSEKEEPING_JSTAGESLoaded',
    id: 'SM_HOUSEKEEPING_JSTAGES',
    label: 'Patient Journey Stages',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-patient-journey-stages/`
};

export const SM_HOUSEKEEPING_OCCS = {
    loaded: 'SM_HOUSEKEEPING_OCCSLoaded',
    id: 'SM_HOUSEKEEPING_OCCS',
    label: 'Occupations',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-occupations/`
};

export const SM_HOUSEKEEPING_ASSCATS = {
    loaded: 'SM_HOUSEKEEPING_ASSCATSLoaded',
    id: 'SM_HOUSEKEEPING_ASSCATS',
    label: 'Assessment Category Forms',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-assessment-category-forms/`
};

export const SM_HOUSEKEEPING_CCS = {
    loaded: 'SM_HOUSEKEEPING_CCSLoaded',
    id: 'SM_HOUSEKEEPING_CCS',
    label: 'Cost Centers',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-cost-centers/`
};

export const SM_HOUSEKEEPING_TCODES = {
    loaded: 'SM_HOUSEKEEPING_TCODESLoaded',
    id: 'SM_HOUSEKEEPING_TCODES',
    label: 'Treatment Codes',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-treatment-codes/`
};

export const SM_HOUSEKEEPING_ADETS = {
    loaded: 'SM_HOUSEKEEPING_ADETSLoaded',
    id: 'SM_HOUSEKEEPING_ADETS',
    label: 'Diary Types',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-diary-types/`
};

export const SM_HOUSEKEEPING_FAVS = {
    loaded: 'SM_HOUSEKEEPING_FAVSLoaded',
    id: 'SM_HOUSEKEEPING_FAVS',
    label: 'Treatment Favourites',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-treatment-favorites/`
};

export const SM_HOUSEKEEPING_TITLES = {
    loaded: 'SM_HOUSEKEEPING_TITLESLoaded',
    id: 'SM_HOUSEKEEPING_TITLES',
    label: 'Titles',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-titles/`
};

export const SM_HOUSEKEEPING_PAT_CONSENT_FORMS = {
    id: 'SM_HOUSEKEEPING_PAT_CONSENT_FORMS',
    label: 'Patient Consent Forms',
    icon: '',
    tabIcon: '',
    helpUrl: `${KNOWLEDGE_BASE}/housekeeping-patient-consent-forms/`
};

// reports related 

export const SM_REPORTS_OUTSTANDING_ACCOUNTS_PDF = {
    loaded: 'SM_REPORTS_OUTSTANDING_ACCOUNTS_PDFLoaded',
    id: 'SM_REPORTS_OUTSTANDING_ACCOUNTS_PDF',
    label: 'Outstanding Accounts PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'OutstandingAccounts',
};

export const SM_REPORTS_DAILY_CASH_PDF = {
    loaded: 'SM_REPORTS_DAILY_CASH_PDFLoaded',
    id: 'SM_REPORTS_DAILY_CASH_PDF',
    label: 'Daily Cash Book PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyCashBook',
};

export const SM_REPORTS_DAILY_CANCELS_PDF = {
    loaded: 'SM_REPORTS_DAILY_CANCELS_PDFLoaded',
    id: 'SM_REPORTS_DAILY_CANCELS_PDF',
    label: 'Daily Cancellations PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyCancels',
};

export const SM_REPORTS_DAILY_DELETES_PDF = {
    loaded: 'SM_REPORTS_DAILY_DELETES_PDFLoaded',
    id: 'SM_REPORTS_DAILY_DELETES_PDF',
    label: 'Daily Deletions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyDeletions',
};

export const SM_REPORTS_WEEKLY_CANCELS_PDF = {
    loaded: 'SM_REPORTS_WEEKLY_CANCELS_PDFLoaded',
    id: 'SM_REPORTS_WEEKLY_CANCELS_PDF',
    label: 'Weeks Cancellations PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WeeksCancels',
};

export const SM_REPORTS_WEEKLY_FAILS_PDF = {
    loaded: 'SM_REPORTS_WEEKLY_FAILS_PDFLoaded',
    id: 'SM_REPORTS_WEEKLY_FAILS_PDF',
    label: 'Weeks Failed to Attends PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf'
};

export const SM_REPORTS_WEEKLY_DELETES_PDF = {
    loaded: 'SM_REPORTS_WEEKLY_DELETES_PDFLoaded',
    id: 'SM_REPORTS_WEEKLY_DELETES_PDF',
    label: 'Weeks Deletions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WeeksDeletions',
};

export const SM_REPORTS_MONTHS_CASH_PDF = {
    loaded: 'SM_REPORTS_MONTHS_CASH_PDFLoaded',
    id: 'SM_REPORTS_MONTHS_CASH_PDF',
    label: 'Months Cash Book PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'MonthsCashBook'
};

export const SM_REPORTS_DAILY_TRANSACTIONS_PDF = {
    id: 'SM_REPORTS_DAILY_TRANSACTIONS_PDF',
    label: 'Daily Transactions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'DailyTransactions',
};

export const SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF = {
    loaded: 'SM_REPORTS_ACCOUNTS_IN_CREDIT_PDFLoaded',
    id: 'SM_REPORTS_ACCOUNTS_IN_CREDIT_PDF',
    label: 'Accounts In Credit PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'AccountsInCredit'
};

export const SM_REPORTS_UNINVOICED_CHARGES_PDF = {
    loaded: 'SM_REPORTS_UNINVOICED_CHARGES_PDFLoaded',
    id: 'SM_REPORTS_UNINVOICED_CHARGES_PDF',
    label: 'Uninvoiced Charges PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'UninvCharges',
};

export const SM_REPORTS_VOIDED_TRANSACTIONS_PDF = {
    loaded: 'SM_REPORTS_VOIDED_TRANSACTIONS_PDFLoaded',
    id: 'SM_REPORTS_VOIDED_TRANSACTIONS_PDF',
    label: 'Voided Transactions PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'Voids'
};

export const SM_REPORTS_WRITTEN_OFFS_PDF = {
    loaded: 'SM_REPORTS_WRITTEN_OFFSLoaded_PDF',
    id: 'SM_REPORTS_WRITTEN_OFFS_PDF',
    label: 'Write Offs PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'WriteOffs'
};

export const SM_REPORTS_INCOMPLETE_CHARGES_PDF = {
    loaded: 'SM_REPORTS_INCOMPLETE_CHARGES_PDFLoaded',
    id: 'SM_REPORTS_INCOMPLETE_CHARGES_PDF',
    label: 'Incomplete Charges PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'IncompleteCharges'
};

export const SM_REPORTS_PATIENT_JOURNEY_PDF = {
    loaded: 'SM_REPORTS_PATIENT_JOURNEY_PDFLoaded',
    id: 'SM_REPORTS_PATIENT_JOURNEY_PDF',
    label: 'Patient Journey PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'PatJourneys',
};

export const SM_REPORTS_PATIENT_JOURNEY_SS_PDF = {
    loaded: 'SM_REPORTS_PATIENT_JOURNEY_PDF_SSLoaded',
    id: 'SM_REPORTS_PATIENT_JOURNEY_PDF_SS',
    label: 'Patient Journey Snap Shot PDF',
    icon: 'fas fa-file-pdf',
    tabIcon: 'fas fa-file-pdf',
    filename: 'PatJourneysSS',
};

//

export const stateRequest = (source, ...params) => {

    return (dispatch, getState) => {

        const sm = getState().stateManagement;

        switch (source.action) {

            case RES_HOUSEKEEPING.SAVE.action:
                saveHouskeeping(dispatch, sm);
                break;

            case RES_HOUSEKEEPING.SAVE_CLEAR.action:
                saveHouskeeping(dispatch, sm);
                dispatch({type: RES_HOUSEKEEPING.CLEAR.action});
                break;

            case RES_HOUSEKEEPING.CLEAR.action:

                dispatch({type: RES_HOUSEKEEPING.CLEAR.action});
                break;

            case RES_TREATMENT_PLAN_BY_ID.CLEAR_PDF.action:

                dispatch({type: RES_TREATMENT_PLAN_BY_ID.CLEAR_PDF.action, payload: source});
                break;

            case RES_PRACTICE_DIARY.CLEAR.action:

                dispatch({type: RES_PRACTICE_DIARY.CLEAR.action});
                break;

            case RES_CLINICIAN_DIARY.CLEAR.action:

                dispatch({type: RES_CLINICIAN_DIARY.CLEAR.action, payload: source.clinicianId});
                break;

            case RES_TREATMENT_PLAN_BY_ID.ADD.action:

                ac.fetchClient().post(`${RES_TREATMENT_PLAN_BY_ID.ADD.url}/?mcId=${ac.getMcId()}`, params[0])
                    .then(res => {

                        dispatch({type: RES_TREATMENT_PLAN_BY_ID.ADD.action, payload: res.data, id: res.data.id})
                    });
                break;

            /////////////////////////////////////////////////// Appointment

            case RES_TREATMENT_PLAN_BY_APP_ID.SAVE.action: {

                const treatmentPlan = sm[source.smRef].data.treatmentPlan;
                savePlan(dispatch, sm, source, treatmentPlan, true, false);
                break;
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.SAVE_CLEAR.action: {

                const treatmentPlan = sm[source.smRef].data.treatmentPlan;
                savePlan(dispatch, sm, source, treatmentPlan, true, true);
                break;
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action:

                dispatch({type: RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action, id: source.id, smRef: source.smRef});
                break;

            /////////////////////////////////////////////////// Treatment Planning

            case RES_TREATMENT_PLANNING.SAVE.action: {

                let plans = _.get(sm, `${SM_TreatmentPlanHistory.id}_${source.smRef}.data.treatmentPlanHistory.plans`);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const treatmentPlanData = _.get(sm, `${SM_TreatmentPlan.id}_${plan.id}.data`);

                    if (treatmentPlanData !== undefined) {

                        const {treatmentPlan} = treatmentPlanData;

                        if (Boolean(treatmentPlan.edited)) {
                            savePlan2(dispatch, sm, source, treatmentPlan, false);
                        }
                    }
                });
                break;
            }

            case RES_TREATMENT_PLANNING.SAVE_CLEAR.action: {

                let plans = _.get(sm, `${SM_TreatmentPlanHistory.id}_${source.id}.data.treatmentPlanHistory.plans`);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const treatmentPlanData = _.get(sm, `${SM_TreatmentPlan.id}_${plan.id}.data`);

                    if (treatmentPlanData !== undefined) {

                        const {treatmentPlan} = treatmentPlanData;

                        if (Boolean(treatmentPlan.edited)) {
                            savePlan2(dispatch, sm, source, treatmentPlan, true);
                        }
                    }
                });
                break;
            }

            case RES_TREATMENT_PLANNING.CLEAR.action:

                dispatch({type: RES_TREATMENT_PLANNING.CLEAR.action, id: source.id, smRef: source.smRef});
                break;

            case RES_PAYMENT_PLANNING.SAVE.action: {

                const selectedPPs = `${SM_PaymentPlaning.id}_${source.smRef}.data.selectedPPs`;
                let plans = _.get(sm, selectedPPs);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const paymentPlanData = _.get(sm, `${SM_PAYMENT_PLAN.id}_${plan.id}.data`);

                    if (paymentPlanData !== undefined) {

                        const {paymentPlan} = paymentPlanData;

                        if (Boolean(paymentPlan.edited)) {
                            const installments = [];
                            savePaymentPlan(dispatch, sm, source, paymentPlan, installments, false);
                        }
                    }
                });
                break;
            }

            case RES_PAYMENT_PLANNING.SAVE_CLEAR.action: {

                const selectedPPs = `${SM_PaymentPlaning.id}_${source.smRef}.data.selectedPPs`;
                let plans = _.get(sm, selectedPPs);

                plans = plans === undefined ? [] : plans;

                plans.forEach(plan => {

                    const paymentPlanData = _.get(sm, `${SM_PAYMENT_PLAN.id}_${plan.id}.data`);

                    if (paymentPlanData !== undefined) {

                        const {paymentPlan} = paymentPlanData;

                        if (Boolean(paymentPlan.edited)) {
                            const installments = [];
                            savePaymentPlan(dispatch, sm, source, paymentPlan, installments, true);
                        }
                    }
                });
                break;
            }

            case RES_PAYMENT_PLANNING.CLEAR.action:

                dispatch({type: RES_PAYMENT_PLANNING.CLEAR.action, id: source.id, smRef: source.smRef});

                break;

            case RES_PAYMENT_PLAN_BY_ID.ADD.action:

                ac.fetchClient().post(`${RES_PAYMENT_PLAN_BY_ID.ADD.url}/?mcId=${ac.getMcId()}`, params[0])
                    .then(res => {

                        dispatch({type: RES_PAYMENT_PLAN_BY_ID.ADD.action, payload: res.data, id: res.data.id})
                    });
                break;

            ///////////////////////////////////////////////////

            case RES_getUsersAndCapabilities.SAVE_USERS.action:
                saveUserCapabilities(dispatch, sm, false);
                break;

            case RES_getUsersAndCapabilities.SAVE_ROLES.action:
                saveRoleCapabilities(dispatch, sm, false);
                break;

            case RES_FIND_USERS.CLEAR.action:

                dispatch({type: RES_FIND_USERS.CLEAR.action});
                break;

            case RES_FIND_PATIENTS.CLEAR.action:

                dispatch({type: RES_FIND_PATIENTS.CLEAR.action});
                break;

            case Actions.CLEAR_REFERRER_ADD:

                dispatch({type: Actions.CLEAR_REFERRER_ADD, payload: source.id});
                break;

            case Actions.CLEAR_PATIENT_ADD:

                dispatch({type: Actions.CLEAR_PATIENT_ADD, payload: source.id});
                break;

            case Actions.CLEAR_USER_ADD:

                dispatch({type: Actions.CLEAR_USER_ADD, payload: source.id});
                break;

            case RES_PATIENT_DETAILS.CLEAR.action:
                dispatch({type: RES_PATIENT_DETAILS.CLEAR.action, smRef: source.smRef});
                break;

            case RES_PATIENT_DETAILS.SAVE_CLEAR.action: {
                savePatient(dispatch, sm, source, true);
                break;
            }

            case RES_PATIENT_DETAILS.SAVE.action: {
                savePatient(dispatch, sm, source, false);
                break;
            }

            case RES_PATIENT_DETAILS.SAVE_PORTAL.action: {
                savePatientPortal(dispatch, sm, source);
                break;
            }

            case Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS:

                dispatch({type: Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS});
                break;

            case RES_PATIENT_MHFS.SAVE.action:
                savePatientMHF(dispatch, sm, source);
                break;

            case RES_PATIENT_QUESTIONNAIRE.SAVE.action:
                savePatientQuestionnaire(dispatch, sm, source);
                break;

            default:
                break;
        }
    }
};

export const setState = (id, data, optionalParent) => {

    return (dispatch) => {

        dispatch({type: Actions.SET_STATE, payload: {id, data, optionalParent}})
    }
};
