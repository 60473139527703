import {Panel} from "primereact/panel";
import QRCode from "qrcode.react";
import React from "react";
import {ac} from "../../../index";
import {HM_EMAIL_IMAGE_QR} from "../../../Constants";

export const QRIdentity = (props) => {

    return (
        <Panel header={HM_EMAIL_IMAGE_QR.header}>
            <QRCode style={{display: "block", marginLeft: "auto", marginRight: "auto", }} height='150px' value={`mailto:imaging@chiralcloud.com?subject=${ac.getGroupId()}::${ac.getMcId()}::${props.patient.id}`}/>
        </Panel>
    )
}