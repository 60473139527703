import _ from 'lodash';

import {
    SM_NHSRegistration,
    SM_PATIENT_ASSESSMENTS,
    SM_PATIENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_DATA,
    SM_PATIENT_DOCUMENTS,
    SM_PATIENT_MHF,
    SM_PATIENT_NOTES,
    SM_PATIENT_QUES,
    SM_PATIENT_TREATMENT_HISTORY,
    SM_PERIO_CHART
} from "../stateManagement";
import {
    RES_ORTHO_ASSESSMENTS,
    RES_PATIENT_ASSESSMENTS,
    RES_PATIENT_DETAILS,
    RES_PATIENT_DOCUMENTS,
    RES_PATIENT_MHFS,
    RES_PATIENT_NOTES,
    RES_PATIENT_PERIO_HISTORY,
    RES_PATIENT_QUESTIONNAIRE,
    RES_PATIENT_TREATMENT_HISTORY
} from "../personal";
import {ac} from "../../index";
import {RES_NHS_REGISTRATION} from "../nhsManagement";
import {ETH_GROUP} from "../../components/Client/Sections/NHSDetails";
import * as Actions from "../index";
import {QUESTIONNAIRE_CURRENT} from "../../components/Client/Constants";

export const savePatient = (dispatch, sm, source, clearAfterSavePD) => {

    if (sm[`${SM_PATIENT_DATA.id}_${source.smRef}`] !== undefined && sm[`${SM_PATIENT_DATA.id}_${source.smRef}`] !== null) {

        const patientData = {...sm[`${SM_PATIENT_DATA.id}_${source.smRef}`].data.patientData};
        patientData.patientJourney.stage = {id: patientData.patientJourney.stage.id};
        patientData.provider = {id: patientData.provider.id};

        ac.fetchClient().post(`${RES_PATIENT_DETAILS.SAVE.url}/?mcId=${ac.getMcId()}`, patientData)
            .then((res) => {

                if (Boolean(clearAfterSavePD)) {
                    dispatch({
                        type: RES_PATIENT_DETAILS.CLEAR.action, payload: source.id, smRef: source.smRef
                    });
                } else {
                    dispatch({type: RES_PATIENT_DETAILS.SAVE.action, payload: res.data})
                }
            });

        const patientNOKData = {...sm[`${SM_PATIENT_DATA.id}_${source.smRef}`].data.patientNOKData};
        patientNOKData.patient = {id: patientData.id};

        ac.fetchClient().post(`${RES_PATIENT_DETAILS.SAVE_NOK.url}/?mcId=${ac.getMcId()}`, patientNOKData)
            .then((res) => {

                if (Boolean(clearAfterSavePD)) {
                    dispatch({
                        type: RES_PATIENT_DETAILS.CLEAR_NOK.action, payload: source.id, smRef: source.smRef
                    });
                } else {
                    dispatch({type: RES_PATIENT_DETAILS.SAVE_NOK.action, payload: res.data})
                }
            });
    }

    // if the patient notes for an appointment are defined and need saving do so ....
    if (sm[`${SM_PATIENT_NOTES.id}_${source.smRef}`] !== undefined) {

        const noteData = sm[`${SM_PATIENT_NOTES.id}_${source.smRef}`].data;
        const noteHistory = noteData.patientNotes;

        const staticNotes = _.filter(noteHistory, note => !(note.id < 0 || note.edited));
        const updatedNewNotes = _.filter(noteHistory, note => note.id < 0 || note.edited);
        const returns = [];

        updatedNewNotes.forEach(note => {

            if (note.id < 0 || note.edited) {

                note.type = note.type.value === undefined ? note.type : note.type.value;

                ac.fetchClient().post(`${RES_PATIENT_NOTES.SAVE.url}/?mcId=${ac.getMcId()}`, note)
                    .then(res => {

                        returns.push(res.data);

                        if (returns.length === updatedNewNotes.length) {

                            if (Boolean(clearAfterSavePD)) {
                                dispatch({type: RES_PATIENT_NOTES.CLEAR, smRef: source.smRef})
                            } else {
                                const newHistory = [...staticNotes, ...returns];
                                dispatch({
                                    type: RES_PATIENT_NOTES.GET.receive,
                                    payload: newHistory,
                                    smLoadedRef: noteData.requesterLoadedRef,
                                    smRef: noteData.requesterRef,
                                })
                            }
                        }
                    });
            }
        })
    }

    // if the patient assessments for an appointment are defined and need saving do so ....
    if (sm[`${SM_PATIENT_ASSESSMENTS.id}_${source.smRef}`] !== undefined) {

        const assessments = sm[`${SM_PATIENT_ASSESSMENTS.id}_${source.smRef}`].data;
        const assessmentHistory = assessments.assessmentHistory;

        const staticAssessments = _.filter(assessmentHistory, assessment => !(assessment.id < 0 || assessment.edited));
        const updatedNewAssessments = _.filter(assessmentHistory, assessment => assessment.id < 0 || assessment.edited);
        const returns = [];

        updatedNewAssessments.forEach(assessment => {

            assessment.id = assessment.id < 0 ? null : assessment.id;
            assessment.assessedBy = {
                id: assessment.assessedBy.id,
                title: assessment.assessedBy.title,
                firstName: assessment.assessedBy.firstName,
                lastName: assessment.assessedBy.lastName,
                username: assessment.assessedBy.username
            };

            ac.fetchClient().post(`${RES_PATIENT_ASSESSMENTS.SAVE.url}/?mcId=${ac.getMcId()}`, assessment)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewAssessments.length) {
                        if (Boolean(clearAfterSavePD)) {
                            dispatch({type: RES_PATIENT_ASSESSMENTS.CLEAR, payload: res.data})
                        } else {
                            const newHistory = [...staticAssessments, ...returns];
                            dispatch({
                                type: RES_PATIENT_ASSESSMENTS.SAVE.action,
                                payload: newHistory,
                                smLoadedRef: assessments.requesterLoadedRef,
                                smRef: assessments.requesterRef
                            })
                        }
                    }
                });
        })
    }

    // if the patient ortho assessments for an appointment are defined and need saving do so ....
    if (sm[`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${source.smRef}`] !== undefined) {

        const assessments = sm[`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${source.smRef}`].data;
        const assessmentHistory = assessments.assessmentHistory;

        const staticAssessments = _.filter(assessmentHistory, assessment => !(assessment.id < 0 || assessment.edited));
        const updatedNewAssessments = _.filter(assessmentHistory, assessment => assessment.id < 0 || assessment.edited);
        const returns = [];

        updatedNewAssessments.forEach(assessment => {

            assessment.id = assessment.id < 0 ? null : assessment.id;
            assessment.assessedBy = {
                id: assessment.assessedBy.id,
                title: assessment.assessedBy.title,
                firstName: assessment.assessedBy.firstName,
                lastName: assessment.assessedBy.lastName,
                username: assessment.assessedBy.username
            };

            ac.fetchClient().post(`${RES_ORTHO_ASSESSMENTS.SAVE.url}/?mcId=${ac.getMcId()}`, assessment)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewAssessments.length) {
                        if (Boolean(clearAfterSavePD)) {
                            dispatch({type: RES_ORTHO_ASSESSMENTS.CLEAR, payload: res.data})
                        } else {
                            const newHistory = [...staticAssessments, ...returns];
                            dispatch({
                                type: RES_ORTHO_ASSESSMENTS.SAVE.action,
                                payload: newHistory,
                                smLoadedRef: assessments.requesterLoadedRef,
                                smRef: assessments.requesterRef
                            })
                        }
                    }
                });
        })
    }

    // if the patient nhs details are defined and need saving do so ....
    if (sm[`${SM_NHSRegistration.id}_${source.smRef}`] !== undefined) {

        const NHSDetails = {...sm[`${SM_NHSRegistration.id}_${source.smRef}`].data};

        NHSDetails.patient = {id: NHSDetails.patient.id};
        NHSDetails.ethnicGroup = _.find(ETH_GROUP, group => group.code === NHSDetails.ethnicGroup.code).value;

        if (Boolean(NHSDetails.edited)) {

            ac.fetchClient().post(`${RES_NHS_REGISTRATION.SAVE.url}/?mcId=${ac.getMcId()}`, NHSDetails)
                .then((res) => {

                    if (Boolean(clearAfterSavePD)) {
                        dispatch({
                            type: RES_NHS_REGISTRATION.CLEAR.action, payload: source.id, smRef: source.smRef
                        });
                    } else {
                        dispatch({type: RES_NHS_REGISTRATION.SAVE.action, payload: res.data})
                    }
                });
        }
    }

    // if the patient treatment details are defined and need saving do so ....
    if (sm[`${SM_PATIENT_TREATMENT_HISTORY.id}_${source.smRef}`] !== undefined) {

        const treatments = sm[`${SM_PATIENT_TREATMENT_HISTORY.id}_${source.smRef}`].data;

        treatments.forEach(treatment => {

            if (treatment.edited) {

                ac.fetchClient().post(`${RES_PATIENT_TREATMENT_HISTORY.SAVE.url}/?mcId=${ac.getMcId()}`, treatment)
                    .then(res => {

                        if (Boolean(clearAfterSavePD)) {
                            dispatch({type: RES_PATIENT_TREATMENT_HISTORY.CLEAR, smRef: source.smRef})
                        } else {
                            dispatch({type: RES_PATIENT_TREATMENT_HISTORY.SAVE.action, payload: res.data})
                        }
                    });
            }
        });
    }

    // if the patient document details are defined and need saving do so ....
    if (sm[`${SM_PATIENT_DOCUMENTS.id}_${source.smRef}`] !== undefined) {

        const ref = `${SM_PATIENT_DOCUMENTS.id}_${source.smRef}`;

        let editedDocuments = [];

        editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.xrayDocuments, document => document.edited));
        editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.patientDocuments, document => document.edited));
        editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.presDocuments, document => document.edited));
        editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.refDocuments, document => document.edited));
        editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.tpDocuments, document => document.edited));
        editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.smsEmailDocuments, document => document.edited));
        editedDocuments = _.concat(editedDocuments, _.filter(sm[ref].data.consentFormDocuments, document => document.edited));

        editedDocuments.forEach(document => {

            ac.fetchClient().post(`${RES_PATIENT_DOCUMENTS.SAVE.url}/?mcId=${ac.getMcId()}`, document)
                .then(res => {

                    if (Boolean(clearAfterSavePD)) {
                        dispatch({type: RES_PATIENT_DOCUMENTS.CLEAR, smRef: source.smRef})
                    } else {
                        dispatch({type: RES_PATIENT_DOCUMENTS.SAVE.action, payload: res.data})
                    }
                });
        });
    }

    // if the patient perio charts are defined and need saving do so ....
    if (sm[`${SM_PERIO_CHART.id}_${source.smRef}`] !== undefined) {

        const charts = sm[`${SM_PERIO_CHART.id}_${source.smRef}`].data;
        const chartHistory = charts.chartHistory;

        const staticCharts = _.filter(chartHistory, chart => !(chart.id < 0 || chart.edited));
        const updatedNewCharts = _.filter(chartHistory, chart => chart.id < 0 || chart.edited);
        const returns = [];

        updatedNewCharts.forEach(chart => {

            chart.chartedBy = {
                id: chart.chartedBy.id,
                title: chart.chartedBy.title,
                firstName: chart.chartedBy.firstName,
                lastName: chart.chartedBy.lastName,
                username: chart.chartedBy.username
            };

            ac.fetchClient().post(`${RES_PATIENT_PERIO_HISTORY.SAVE.url}/?mcId=${ac.getMcId()}`, chart)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewCharts.length) {
                        if (Boolean(clearAfterSavePD)) {
                            dispatch({type: RES_PATIENT_PERIO_HISTORY.CLEAR, smRef: source.smRef})
                        } else {
                            const newHistory = [...staticCharts, ...returns];
                            dispatch({
                                type: RES_PATIENT_PERIO_HISTORY.SAVE.action,
                                payload: newHistory,
                                smRef: source.smRef,
                            })
                        }
                    }
                });
        });
    }
};

export const savePatientPortal = (dispatch, sm, source) => {

    if (sm[`${SM_PATIENT_DATA.id}_${source.smRef}`] !== undefined && sm[`${SM_PATIENT_DATA.id}_${source.smRef}`] !== null) {

        const patientData = {...sm[`${SM_PATIENT_DATA.id}_${source.smRef}`].data.patientData};
        patientData.patientJourney.stage = {id: patientData.patientJourney.stage.id};
        patientData.provider = {id: patientData.provider.id};

        ac.fetchClient().post(`${RES_PATIENT_DETAILS.SAVE.url}/?mcId=${ac.getMcId()}`, patientData)
            .then((res) => {

                dispatch({type: RES_PATIENT_DETAILS.SAVE.action, payload: res.data})
            });

        const patientNOKData = {...sm[`${SM_PATIENT_DATA.id}_${source.smRef}`].data.patientNOKData};
        patientNOKData.patient = {id: patientData.id};

        ac.fetchClient().post(`${RES_PATIENT_DETAILS.SAVE_NOK.url}/?mcId=${ac.getMcId()}`, patientNOKData)
            .then((res) => {

                dispatch({type: RES_PATIENT_DETAILS.SAVE_NOK.action, payload: res.data})
            });
    }
}

export const savePatientMHF = (dispatch, sm, source) => {

    if (sm[`${SM_PATIENT_MHF.id}_${source.smRef}`]) {

        const mhfData = {...sm[`${SM_PATIENT_MHF.id}_${source.smRef}`].data};
        const medicalHistoryForm = mhfData.medicalHistoryForm;

        const postData = {form: medicalHistoryForm, image: source.image, memberId: null};

        ac.fetchClient().post(`${RES_PATIENT_MHFS.SAVE.url}/?mcId=${ac.getMcId()}`, postData)
            .then((res) => {
                dispatch({type: Actions.CLEAR_PATIENT_MHF, smRef: source.smRef})
            });
    }
}

export const savePatientQuestionnaire = (dispatch, sm, source) => {

    if (sm[`${SM_PATIENT_QUES.id}_${source.smRef}`]) {

        const {data} = {...sm[`${SM_PATIENT_QUES.id}_${source.smRef}`]};
        const questionnaire = data.questionnaire;
        questionnaire.patient = {id: questionnaire.patient.id};
        questionnaire.uponArrivalDate = new Date();
        questionnaire.state = QUESTIONNAIRE_CURRENT;

        const postData = {questionnaire, memberId: data.memberId, image: null};

        ac.fetchClient().post(`${RES_PATIENT_QUESTIONNAIRE.SAVE.url}/?mcId=${ac.getMcId()}`, postData)
            .then((res) => {
                dispatch({type: Actions.CLEAR_PATIENT_QUESTIONNAIRE, smRef: source.smRef})
            });
    }
}
