import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';

import {checkBox, dropDown, inputText, inputTextEmail} from './OnChangeUtils';

const optionalContactComment = (props, member) => {

    if (props.portalView || props.onTablet) {
        return (
            <div key={`${member}_4`} className="p-col-6"/>
        )
    } else {
        return (
            <div key={`${member}_4`} className="p-col-6">
                {inputText(props, member, 'Comment', false, false)}
            </div>
        )
    }
}

const optionalWorkPhone = (props) => {

    const workPhoneMandatory = props.workPhoneMandatory === undefined ? false : props.workPhoneMandatory;

    if (props.includeWorkPhone) {
        return (
            <>
                <div key='1' className="p-col-2">
                    <label htmlFor="workTelephone">Work Phone</label>
                </div>
                <div key='2' className="p-col-4">
                    {inputText(props, 'workTelephone', 'Work Phone', false, workPhoneMandatory)}
                </div>
                {optionalContactComment(props, 'workTelephoneComment')}
            </>
        )
    }
};

const optionalOtherPhone = (props) => {

    if (props.includeOtherPhone) {
        return (
            <>
                <div key='5' className="p-col-2">
                    <label htmlFor="otherTelephone">Other Phone</label>
                </div>
                <div key='6' className="p-col-4">
                    {inputText(props, 'otherTelephone', 'Other Phone', false, false)}
                </div>
                {optionalContactComment(props, 'otherTelephoneComment')}
            </>
        )
    }
};

const optionalInfo = (props) => {

    if (props.includeOptionalInfo) {
        return [
            <div key='1' className="p-col-2">
                <label htmlFor="useAlternativeAddress">Use Alternate Address</label>
            </div>,
            <div key='2' className="p-col-10">
                {checkBox(props, 'useAlternativeAddress')}
            </div>
        ]
    }
};

const optionalOccupation = (props) => {

    if (props.includeOccupation) {
        return [
            <div className="p-col-4">
                <label htmlFor="occupation">Occupation</label>
            </div>,
            <div className="p-col-8">
                {dropDown(5, props, 'occupations', 'occupation', 'description')}
            </div>
        ]
    }
};

export const Contact = (props) => {

    const panelStyle = props.addMargin ? {margin: '15px 0px 0px 0px'} : {};

    if (props.portalView || props.onTablet) {
        return (
            <Panel header='Contact' style={panelStyle}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-2">
                        <label htmlFor="homeTelephone">Home Phone</label>
                    </div>
                    <div className="p-col-4">
                        {inputText(props, 'homeTelephone', 'Home Phone', false, props.mandatory)}
                    </div>
                    <div className="p-col-6">
                    </div>

                    {optionalWorkPhone(props)}
                    {optionalOtherPhone(props)}

                    <div className="p-col-2">
                        <label htmlFor="mobile">Mobile</label>
                    </div>
                    <div className="p-col-4">
                        {inputText(props, 'mobile', 'Mobile', false, props.mandatory)}
                    </div>
                    <div className="p-col-6">
                    </div>

                    <div className="p-col-2">
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="p-col-4">
                        {inputTextEmail(props, 'email', 'Email', false, props.mandatory)}
                    </div>
                    <div className="p-col-6">
                    </div>

                    {optionalInfo(props)}
                    {optionalOccupation(props)}
                </div>
            </Panel>
        )
    } else {
        return (
            <Panel header='Contact' style={panelStyle}>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-2">
                        <label htmlFor="homeTelephone">Home Phone</label>
                    </div>
                    <div className="p-col-4">
                        {inputText(props, 'homeTelephone', 'Home Phone', false, props.mandatory)}
                    </div>
                    {optionalContactComment(props, 'homeTelephoneComment')}

                    {optionalWorkPhone(props)}
                    {optionalOtherPhone(props)}

                    <div className="p-col-2">
                        <label htmlFor="mobile">Mobile</label>
                    </div>
                    <div className="p-col-4">
                        {inputText(props, 'mobile', 'Mobile', false, props.mandatory)}
                    </div>
                    {optionalContactComment(props, 'mobileComment')}

                    <div className="p-col-2">
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="p-col-4">
                        {inputTextEmail(props, 'email', 'Email', false, props.mandatory)}
                    </div>
                    {optionalContactComment(props, 'emailComment')}

                    {optionalInfo(props)}
                    {optionalOccupation(props)}
                </div>
            </Panel>
        )
    }
};
