import * as Actions from "./index";
import {REQUEST_PROVIDER_PERFORMERS} from "./index";
import {ac} from "../index";

const baseURL = '/NHSManagement';

export const RES_getProviderPerformers = {
    GET: {
        request: REQUEST_PROVIDER_PERFORMERS,
        receive: Actions.RECEIVE_PROVIDER_PERFORMERS,
        url: `${baseURL}/getProviderPerformers`
    },
};

export const RES_NHS_MANAGEMENT_STACKED = {
    GET: {
        request: Actions.REQUEST_NHS_MANAGEMENT_STACKED,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_STACKED,
        url: `${baseURL}/stacked`
    },
};

export const RES_NHS_MANAGEMENT_SUBMITTED = {
    GET: {
        request: Actions.REQUEST_NHS_MANAGEMENT_SUBMITTED,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_SUBMITTED,
        url: `${baseURL}/submitted`
    },
};

export const RES_NHS_MANAGEMENT_REJECTED = {
    GET: {
        request: Actions.REQUEST_NHS_MANAGEMENT_REJECTED,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_REJECTED,
        url: `${baseURL}/rejected`
    },
};

export const RES_NHS_MANAGEMENT_REMOVED = {
    GET: {
        request: Actions.REQUEST_NHS_MANAGEMENT_REMOVED,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_REMOVED,
        url: `${baseURL}/removed`
    },
};

export const RES_NHS_MANAGEMENT_SCHEDULED = {
    GET: {
        request: Actions.REQUEST_NHS_MANAGEMENT_SCHEDULED,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_SCHEDULED,
        url: `${baseURL}/scheduled`
    },
    GET_DET: {
        request: Actions.REQUEST_NHS_MANAGEMENT_SCHEDULED_DET,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_SCHEDULED_DET,
        url: `${baseURL}/scheduledDetails`
    },
};

export const RES_NHS_MANAGEMENT_CLAIM = {
    GET: {
        request: Actions.REQUEST_NHS_MANAGEMENT_CLAIM,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_CLAIM,
        url: `${baseURL}/claim`
    },
    PATIENT: {
        request: Actions.REQUEST_NHS_MANAGEMENT_CLAIM_PATIENT,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_CLAIM_PATIENT,
        url: `${baseURL}/claimPatient`
    },
    NHS_DETAILS: {
        request: Actions.REQUEST_NHS_MANAGEMENT_NHS_DETAILS,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_NHS_DETAILS,
        url: `${baseURL}/claimPatientNHSDetails`
    },
    SAVE: {action: Actions.SAVE_NHS_MANAGEMENT_CLAIM, url: `${baseURL}/saveClaim`},
    CANCEL: {action: Actions.CANCEL_NHS_MANAGEMENT_CLAIM, url: `${baseURL}/markClaimAsCancelled`},
    DUPLICATE: {action: Actions.DUPLICATE_NHS_MANAGEMENT_CLAIM, url: `${baseURL}/markClaimAsDuplicate`},
    DELETE: {action: Actions.DELETE_NHS_MANAGEMENT_CLAIM, url: `${baseURL}/deleteClaim`},
};

export const RES_NHS_REGISTRATION = {
    GET: {
        request: Actions.REQUEST_NHS_REGISTRATION,
        receive: Actions.RECEIVE_NHS_REGISTRATION,
        url: `${baseURL}/claimPatientNHSDetails`
    },
    SAVE: {action: Actions.SAVE_NHS_REGISTRATION, url: `/Patients/patientNHSDetails`},
    CLEAR: {action: Actions.CLEAR_NHS_REGISTRATION},
};

export const RES_NHS_CLAIM_HISTORY = {
    GET: {
        request: Actions.REQUEST_NHS_CLAIM_HISTORY,
        receive: Actions.RECEIVE_NHS_CLAIM_HISTORY,
        url: `${baseURL}/patientsClaims`
    },
};

export const RES_NHS_MANAGEMENT_CLAIMS = {
    STACK: {
        request: Actions.REQUEST_NHS_MANAGEMENT_STACK_CLAIM,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_STACK_CLAIM,
        url: `${baseURL}/stackClaim`
    },
    SUBMIT: {
        request: Actions.REQUEST_NHS_MANAGEMENT_SUBMIT_CLAIM,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_SUBMIT_CLAIM,
        url: `${baseURL}/submitClaims`
    },
    RESUBMIT: {
        request: Actions.REQUEST_NHS_MANAGEMENT_RESUBMIT_CLAIM,
        receive: Actions.RECEIVE_NHS_MANAGEMENT_RESUBMIT_CLAIM,
        url: `${baseURL}/resubmitClaim`
    },
};

export const getResource = ({url, request, receive}, details) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {params: {...details}})
            .then(res => {

                dispatch({type: receive, payload: res.data, ...details})
            });
        dispatch({type: request, payload: false, ...details});
    }
};

export const stackClaim = ({url, request, receive}, claim) => {

    const _claim = {...claim};
    _claim.patientNHSDetails = {id: claim.patientNHSDetails.id};
    _claim.treatmentPlan = {id: claim.treatmentPlan.id, claimForm: claim.treatmentPlan.claimForm};
    _claim.performer = {id: claim.performer.id};
    _claim.chxExmpAndRemissionCode = claim.chxExmpAndRemissionCode.ordinal;

    _claim.clinicalData = claim.clinicalData.map(charge => {
        return {id: charge.id}
    })
    _claim.otherServices = claim.otherServices.map(charge => {
        return {id: charge.id}
    })

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, _claim)
            .then(res => {

                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
}

export const markClaimAsCancelled = ({url, action}, details) => {

    return (dispatch) => {
        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, details)
            .then(res => {

                dispatch({type: action, payload: res.data})
            });
    }
};
