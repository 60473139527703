import * as Actions from './index';
import axios from "axios/index";
import {ac} from '../index'
import crypto from "crypto";

export const getPracticeLoginPageDetails = (mcId) => {

    return (dispatch) => {

        axios.get(`https://${ac.getBASERESTURL()}/practiceLoginPageDetails?mcId=${mcId}`)
            .then(res => {
                dispatch({
                    type: Actions.RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS,
                    payload: {practiceLoginPageDetails: res.data}
                });
            })
            .catch(() => {
            });
        dispatch({type: Actions.REQUEST_PRACTICE_LOGIN_PAGE_DETAILS, payload: {}});
    }
};

export const getLaboratoryDetails = (mcId) => {

    return (dispatch) => {

        axios.get(`https://${ac.getBASERESTURL()}/laboratoryDetails?mcId=${mcId}`)
            .then(res => {
                dispatch({type: Actions.RECEIVE_LABORATORY_DETAILS, payload: {laboratoryDetails: res.data}});
            })
            .catch(() => {
            });
        dispatch({type: Actions.REQUEST_LABORATORY_DETAILS, payload: {}});
    }
};

const validPassword = (password, salt, storedHash) => {
    const hash = crypto.pbkdf2Sync(password, salt, 1000, 64, `sha512`).toString(`hex`);
    return hash === storedHash;
};

export const doClientLogin = (loginDetails) => {

    return (dispatch) => {

        // dispatch({type: Actions.LOGIN});

        axios.get(`https://${ac.getBASERESTURL()}/authenticate/patient?mcId=${loginDetails.mcId}&portalReference=${loginDetails.portalReference}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
            .then(res => {

                if (res.data.patientValid && validPassword(loginDetails.password, res.data.salt, res.data.hash)) {

                    axios.post(`https://${ac.getBASERESTURL()}/authenticate/patient?mcId=${loginDetails.mcId}&portalReference=${loginDetails.portalReference}`, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => {

                            ac.setToken(res.data.token);
                            ac.setMcId(loginDetails.mcId);

                            dispatch({
                                type: Actions.LOGIN_SUCCESS_PATIENT,
                                payload: {
                                    token: res.data.token,
                                    patientId: parseInt(res.data.patientId),
                                    practice: loginDetails.mcId
                                }
                            });
                        }
                    )
                } else {
                    dispatch({
                        type: Actions.LOGIN_FAILURE,
                        payload: Actions.LOGIN_FAILURE
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: Actions.LOGIN_FAILURE,
                    payload: error
                });
            })
    }
};

export const doLogout = (action) => {

    return (dispatch) => {

        dispatch({type: action});
    }
};
