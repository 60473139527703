import React from 'react';
import "./wizardLogin.css";
import _ from 'lodash';
import {BaseComponent} from "../BaseComponent";
import * as Actions from "../../actions";
import {
    CC_UK,
    PAT_CREATE_STATUS_ALREADY_REGISTERED,
    PAT_CREATE_STATUS_AWAITING_CONFIRMATION,
    PAT_CREATE_STATUS_BLOCK_REGISTRATION,
    PAT_CREATE_STATUS_CONFIRMED,
    PAT_CREATE_STATUS_MATCHED_BOTH,
    PAT_CREATE_STATUS_MATCHED_EMAIL,
    PAT_CREATE_STATUS_MATCHED_MOBILE,
    PAT_CREATE_STATUS_MATCHED_NEITHER,
    PAT_CREATE_STATUS_REG_MATCHED_BOTH,
    PAT_CREATE_STATUS_REG_MATCHED_EMAIL,
    PAT_CREATE_STATUS_TRIES_EXCEEDED,
    PAT_CREATE_STATUS_UNCONFIRMED,
    PAT_CREATE_STATUS_UNMATCHED,
    PAT_CREATE_STATUS_UNSUBMITTED,
    PAT_CREATE_STATUS_USERNAME_EXISTS
} from "../../Constants";
import {
    RS_AVAILABILITY,
    RS_EXISTING_DETAILS,
    RS_EXISTING_DETAILS_RESEND,
    RS_FORGOT_PASSWORD,
    RS_LOGIN,
    RS_LOGIN_DIRECT,
    RS_LOGIN_FAILURE,
    RS_MATCH_RESULT,
    RS_NEW_DETAILS,
    RS_START
} from "./Constants";
import {ac} from "../../index";
import {Calendar} from "primereact/calendar";
import * as DefaultData from "./DefaultData";
import crypto from "crypto";
import {VALID_GROUPS} from "../../actions/fetchClient";
import {SM_404_CLOUD_PAGE_ERROR} from "../../actions/stateManagement";
import {ProgressBar} from "primereact/progressbar";
import {doClientLogin, getPracticeLoginPageDetails} from "../../actions/login";
import {
    getResource as getPatResource,
    patientRegister,
    RES_PATIENT_DETAILS,
    RES_PATIENT_DETAILS_SHORT,
    RES_PATIENT_PASSWORD,
    RES_PATIENT_REGISTRATION,
    resetPassword,
    savePatientDataWithMcId
} from "../../actions/personal";
import {connect} from "react-redux";
import {Checkbox} from "primereact/checkbox";
import "../../Registration.scss"
import ErrorBoundary from "./ErrorBoundary";
import {validateEmail} from "./Utils";
import {getPortalDiaryEvents, RES_diaryEvents} from "../../actions/diary";
import {getAllUsersWizard} from "../../actions/users";
import moment from "moment";
import {timeTemplate} from "./Utils";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/button";

class ConnectedWizard2 extends BaseComponent {
    constructor(props) {
        super(props);

        // Creating a unique salt for a particular user
        const salt = crypto.randomBytes(16).toString('hex');

        this.state = {

            portalReference: props.match.params.portalReference,
            newPatient: props.match.params.portalReference === 'newPatient',
            patientDataShortLoaded: props.match.params.portalReference === undefined || props.match.params.portalReference === 'newPatient',

            salt,

            width: 0,
            height: 0,

            currentDate: new Date(),
            onlines: [],
            onlinesLoaded: false,

            selectedAppointment: null,

            first: 0,
            rows: 5,

            registrationState: RS_LOGIN,

            registerDisabled: true,
            createResult: {result: PAT_CREATE_STATUS_UNSUBMITTED},

            inputForm: {
                title: {},
                firstName: '',
                lastName: '',
                userName: '',
                email: '',
                mobile: '',
                dob: new Date(),
                password: '',
                passwordConfirm: '',
                passwordRaw: '',
                passwordConfirmRaw: '',
                address1: '',
                address2: '',
                address3: '',
                county: '',
                postcode: '',
                registrationCode: '',
                contactByEmail: false,
                patientId: null,
                onlineRegistrationId: null,
                portalReference: props.match.params.portalReference
            }
        };
        const urlParts = window.location.pathname.split('/');
        ac.setGroupId(window.location);

        this.state.mcId = parseInt(urlParts[2], 10);

        const groupIndex = _.findIndex(VALID_GROUPS, group => group === urlParts[1]);
        const practiceId = parseInt(urlParts[2], 10);
        if (groupIndex === -1 || isNaN(practiceId) || practiceId < 1 || practiceId > 10) {
            window.location.hash = SM_404_CLOUD_PAGE_ERROR.route;
        }

        this.onSubmit = this.onSubmit = this.onSubmit.bind(this);
    }

    updateWindowDimensions = () => {
        const smallScreen = window.innerWidth < 667;
        this.setState({smallScreen, width: window.innerWidth, height: window.innerHeight});
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        const smallScreen = window.innerWidth < 667;
        this.setState({smallScreen}, () => {
            this.props.getPracticeLoginPageDetails(this.state.mcId);
            this.props.getAllUsersShort(this.state.mcId);

            if (this.state.portalReference !== undefined && !this.state.newPatient) {
                this.props.getPatientDetails(this.state.portalReference);
            } else {
                if (this.state.newPatient) {
                    this.setState({registrationState: RS_NEW_DETAILS, patientDataShortLoaded: true})
                } else {
                    this.setState({registrationState: RS_EXISTING_DETAILS_RESEND, patientDataShortLoaded: true})
                }
            }
        });
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.id !== this.props.message.id) {

            switch (this.props.message.type) {

                case Actions.RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS:

                    if (this.props.practiceLoginPageDetails === null) {
                        window.location.hash = SM_404_CLOUD_PAGE_ERROR.route;
                    } else if (this.props.practiceLoginPageDetails !== undefined) {
                        const practiceLoginPageDetails = {
                            practiceName: this.props.practiceLoginPageDetails[0],
                            telephone: this.props.practiceLoginPageDetails[1],
                            customBanner: this.props.practiceLoginPageDetails[2]
                        }
                        this.setState({
                            practiceLoginPageDetails: practiceLoginPageDetails,
                            practiceLoginPageDetailsLoaded: true
                        });
                    }
                    break;

                case Actions.RECEIVE_USER_SEARCH:
                    break;

                case RES_PATIENT_DETAILS_SHORT.GET.receive:

                    const {fullName, portalLoginRegistered} = this.props.patientDataShort === null ? {
                        fullName: null,
                        portalLoginRegistered: false
                    } : this.props.patientDataShort;

                    if (this.state.newPatient) {
                        this.setState({registrationState: RS_NEW_DETAILS, patientDataShortLoaded: true})
                    } else if (fullName !== null && portalLoginRegistered) {
                        this.setState({registrationState: RS_LOGIN, patientDataShortLoaded: true})
                    } else if (fullName !== null) {
                        this.setState({registrationState: RS_EXISTING_DETAILS, patientDataShortLoaded: true})
                    } else if (fullName === null) {
                        this.setState({registrationState: RS_EXISTING_DETAILS_RESEND, patientDataShortLoaded: true})
                    }
                    break;

                case Actions.PATIENT_REGISTER:

                    switch (this.props.createResult.result) {
                        case PAT_CREATE_STATUS_CONFIRMED:
                            this.setState({createResult: this.props.createResult, registrationState: RS_LOGIN_DIRECT});
                            break;
                        case PAT_CREATE_STATUS_MATCHED_BOTH:
                        case PAT_CREATE_STATUS_MATCHED_EMAIL:
                        case PAT_CREATE_STATUS_MATCHED_MOBILE:
                        case PAT_CREATE_STATUS_MATCHED_NEITHER:
                        case PAT_CREATE_STATUS_REG_MATCHED_BOTH:
                        case PAT_CREATE_STATUS_REG_MATCHED_EMAIL:

                            const inputForm = {...this.state.inputForm};
                            inputForm.patientId = this.props.createResult.patientId;
                            inputForm.onlineRegistrationId = this.props.createResult.onlineRegistrationId;

                            this.setState({
                                registrationState: RS_MATCH_RESULT,
                                createResult: this.props.createResult,
                                inputForm
                            });
                            break;
                        case PAT_CREATE_STATUS_ALREADY_REGISTERED:
                        case PAT_CREATE_STATUS_USERNAME_EXISTS:
                        case PAT_CREATE_STATUS_UNMATCHED:
                        case PAT_CREATE_STATUS_BLOCK_REGISTRATION:
                            this.setState({
                                registrationState: RS_MATCH_RESULT,
                                createResult: this.props.createResult
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                case Actions.PATIENT_SUBMIT:

                    switch (this.props.createResult.result) {
                        case PAT_CREATE_STATUS_AWAITING_CONFIRMATION:
                            this.setState({
                                registrationState: RS_MATCH_RESULT,
                                createResult: this.props.createResult
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                case Actions.PATIENT_CONFIRM:

                    switch (this.props.createResult.result) {
                        case PAT_CREATE_STATUS_UNCONFIRMED:
                        case PAT_CREATE_STATUS_TRIES_EXCEEDED:
                            this.setState({
                                registrationState: RS_MATCH_RESULT,
                                createResult: this.props.createResult
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                case Actions.LOGIN_SUCCESS_PATIENT:
                    window.location.hash = '/main/clientControlCenter';
                    break;
                case Actions.LOGIN_FAILURE:
                    this.setState({registrationState: RS_LOGIN_FAILURE, result: Actions.LOGIN_FAILURE})
                    break;
                case RES_diaryEvents.GET_PORTAL_UA.receive:

                    this.setState({onlines: this.props.onlines, onlinesLoaded: true});
                    break;
                default:
                    break;
            }
        }
    }

    forgotPassword = () => {

        this.setState({registrationState: RS_FORGOT_PASSWORD}, () => {
            this.props.resetPassword(this.state.inputForm.portalReference);
        })
    }

    loginDirect = () => {

        ac.setGroupId(window.location);

        const loginDetails = {
            mcId: this.state.mcId,
            portalReference: this.state.inputForm.portalReference,
            password: this.state.inputForm.passwordRaw,
        };

        this.props.doLogin(loginDetails);
    }

    onSubmit = (e) => {

        e.preventDefault();

        ac.setGroupId(window.location);

        const loginDetails = {
            mcId: this.state.mcId,
            portalReference: this.state.inputForm.portalReference,
            password: this.state.password,
        };

        this.props.doLogin(loginDetails);
    }

    onChange = ({owner, value}) => {

        const newState = {...this.state};

        switch (owner) {
            case 'inputForm' :
                _.set(newState, owner, value);
                this.setState(newState);
                break;
            case 'currentDate':
                _.set(newState, owner, value);
                this.setState(newState, () => {
                    const endDate = moment(this.state.currentDate).toDate();
                    this.props.getDiaryEvents(this.state.currentDate, endDate);
                });
                break;
            default :
                _.set(newState, owner, value);
                this.setState(newState);
                break;
        }
    }

    setPassword = (password) => {

        // Hashing user's salt and password with 1000 iterations, 64 length and sha512 digest
        const hash = crypto.pbkdf2Sync(password, this.state.salt, 1000, 64, `sha512`).toString(`hex`);
        return {hash, salt: this.state.salt};
    };

    createRegistration = () => {

        this.props.createRegistration(this.state.mcId, this.state.inputForm);
    }

    sendRegistration = () => {

        this.props.sendRegistration(this.state.mcId, this.state.inputForm);
    }

    confirmRegistration = () => {

        this.props.confirmRegistration(this.state.mcId, this.state.inputForm);
    }

    newRegistration = () => {

        if (validateEmail(this.state.inputForm.email)) {

            const patient = _.cloneDeep(DefaultData.PatientData(this.state.mcId));
            const NHSRegistration = _.cloneDeep(DefaultData.PatientNHSDetails);

            NHSRegistration.mc = this.state.mcId;

            patient.title = this.state.inputForm.title;
            patient.firstName = this.state.inputForm.firstName;
            patient.lastName = this.state.inputForm.lastName;
            patient.dateOfBirth = this.state.inputForm.dob;
            patient.mobile = this.state.inputForm.mobile;
            patient.email = this.state.inputForm.email;
            patient.password = this.state.inputForm.password;
            patient.salt = this.state.inputForm.salt;
            patient.username = this.state.inputForm.username;
            patient.registeredOn = new Date();

            patient.contactDetails = {
                id: null,
                mc: this.state.mcId,
                addressLine1: this.state.inputForm.address1,
                addressLine2: this.state.inputForm.address2,
                addressLine3: this.state.inputForm.address3,
                county: this.state.inputForm.county,
                postcode: this.state.inputForm.postcode,
                country: {id: CC_UK} // UK
            }
            this.props.addPatient(this.state.mcId, patient, NHSRegistration);
        } else {

        }
    }
    checkAvailability = (newPatient) => {

        const endDate = moment(this.state.currentDate).toDate();

        this.setState({registrationState: RS_AVAILABILITY, newPatient}, () => {
            this.props.getDiaryEvents(this.state.currentDate, endDate);
        })
    }

    login = (loginFailed) => {

        const message = loginFailed ? 'Password (Password Incorrect)' : 'Password';

        const disabled = !this.state.password || this.state.password.trim().length < 6;

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <label htmlFor="pass" className="label" style={{color: 'white'}}>{message}</label>
                    <input id="pass" type="password" className="input" data-type="password"
                           value={this.state.password}
                           onChange={(e) => {
                               this.setState({password: e.target.value})
                           }}
                           key={`123`}/>

                </div>
                <div className="group">
                    <input type="submit" className="button signin" value="Sign In" style={{background: '#348ffc'}}
                           onClick={this.onSubmit}
                           disabled={disabled}
                    />
                </div>
                <div className="group">
                    <label className='label' onClick={this.forgotPassword} style={{color: 'white'}}>Forgot Password?</label>
                </div>
            </div>
        )
    }

    existingPortalUserRedirection = () => {

        return (
            <div className="sign-in-htm">
                <div className="group"
                     style={{color: 'white'}}
                >
                    Due to increased system security you will need a new link to access the Patient Portal.
                    <br/>
                    <br/>
                    To obtain a new link please contact the practice on:
                    <br/>
                    <br/>
                    {this.state.practiceLoginPageDetails.telephone}.
                </div>
            </div>
        )
    }

    forgotPasswordRedirection = () => {

        return (
            <div className="sign-in-htm">
                <div className="group"
                     style={{color: 'white'}}
                >
                    To reset your password, please follow the new link to the Patient Portal that has been sent to your email and SMS contact.
                    This link will be your new link to the Patient Portal in future.
                </div>
            </div>
        )
    }

    addSubmitCheck = () => {

        if (this.state.inputForm.passwordRaw.trim().length < 6) {
            return (
                <div className="group">
                    <input type="submit" className="button" value="Password too short"
                           style={{background: '#348FFC'}}
                           onClick={() => {
                               this.createRegistration(ac.getMcId(), this.state.inputForm);
                           }}
                           disabled
                    />
                </div>
            )
        } else {
            if (this.state.inputForm.passwordRaw !== this.state.inputForm.passwordConfirmRaw) {
                return (
                    <div className="group">
                        <input type="submit" className="button" value="Passwords don't match"
                               style={{background: '#348FFC'}}
                               onClick={() => {
                                   this.createRegistration(ac.getMcId(), this.state.inputForm);
                               }}
                               disabled
                        />
                    </div>
                )
            } else {

                return (
                    <div className="group">
                        <input type="submit" className="button" value="Sign In" style={{background: '#348FFC'}}
                               onClick={() => {
                                   this.createRegistration(ac.getMcId(), this.state.inputForm);
                               }}
                        />
                    </div>
                )
            }
        }
    }

    existingUserDetailsSection = () => {

        return (
            <div className="sign-up1-htm">
                <div className="group">
                    <input type="submit" className="button" value="Check Availability" style={{background: '#348FFC'}}
                           onClick={() => {
                               this.checkAvailability(false);
                           }}
                    />
                </div>
                <div className="group">
                    <label htmlFor="pass" className="label" style={{color: 'white'}}>Create Password</label>
                    <input id="pass" type="password" className="input" data-type="password"
                           onChange={(e) => {
                               const {hash, salt} = this.setPassword(e.target.value);
                               const inputForm = {...this.state.inputForm};
                               inputForm.salt = salt;
                               inputForm.password = hash;
                               inputForm.passwordRaw = e.target.value;
                               this.setState({inputForm})
                           }}
                           key={`234`}/>

                </div>
                <div className="group">
                    <label htmlFor="passConfirm" className="label" style={{color: 'white'}}>Confirm Password</label>
                    <input id="passConfirm" type="password" className="input" data-type="password"
                           onChange={(e) => {
                               const {hashConfirm, saltConfirm} = this.setPassword(e.target.value);
                               const inputForm = {...this.state.inputForm};
                               inputForm.saltConfirm = saltConfirm;
                               inputForm.passwordConfirm = hashConfirm;
                               inputForm.passwordConfirmRaw = e.target.value;
                               this.setState({inputForm})
                           }}
                           key={`345`}/>

                </div>
                {this.addSubmitCheck()}
            </div>
        );
    }

    newUserDetailsSection = () => {

        return (
            <div className="sign-up1-htm">
                <div className="group">
                    <input type="submit" className="button" value="Check Availability" style={{background: '#348FFC'}}
                           onClick={() => {
                               this.checkAvailability(true);
                           }}
                    />
                </div>
                <div className="group p-grid">
                    <div className="p-col-6">
                        <label htmlFor="firstName" className="label" style={{color: 'white'}}>First Name</label>
                        <input id="firstName" type="text" className="input"
                               value={this.state.inputForm.firstName}
                               onChange={(e) => this.onChange({
                                   owner: 'inputForm.firstName',
                                   value: e.target.value
                               })}
                        />
                    </div>
                    <div className="p-col-6">
                        <label htmlFor="lastName" className="label" style={{color: 'white'}}>Last Name</label>
                        <input id="lastName" type="text" className="input"
                               value={this.state.inputForm.lastName}
                               onChange={(e) => this.onChange({
                                   owner: 'inputForm.lastName',
                                   value: e.target.value
                               })}
                        />
                    </div>
                </div>
                <div className="group p-grid">
                    <div className="p-col-6">
                        <label htmlFor="mobile" className="label" style={{color: 'white'}}>Mobile</label>
                        <input id="mobile" type="text" className="input"
                               value={this.state.inputForm.mobile}
                               onChange={(e) => this.onChange({
                                   owner: 'inputForm.mobile',
                                   value: e.target.value
                               })}
                        />
                    </div>
                    <div className="p-col-6">
                        <label htmlFor="email" className="label" style={{color: 'white'}}>Email</label>
                        <input id="email" type="text" className="input"
                               value={this.state.inputForm.email}
                               onChange={(e) =>
                                   this.onChange({
                                       owner: 'inputForm.email',
                                       value: e.target.value
                                   })}
                        />
                    </div>
                </div>
                <div className="group">
                    <label htmlFor="dateOfBirth" className="label" style={{color: 'white'}}>Date of Birth</label>
                    <Calendar id="popup"
                              dateFormat="dd/mm/yy"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange="1900:2020"
                              readOnlyInput={true}
                              placeholder="Birthdate" className="form-element"
                              value={this.state.inputForm.dob}
                              onChange={(e) => this.onChange({
                                  owner: 'inputForm.dob',
                                  value: e.value
                              })}
                    />
                </div>
                <div className="group">
                    <label htmlFor="pass" className="label" style={{color: 'white'}}>Create Password</label>
                    <input id="pass" type="password" className="input" data-type="password"
                           onChange={(e) => {
                               const {hash, salt} = this.setPassword(e.target.value);
                               const inputForm = {...this.state.inputForm};
                               if (e.target.value.trim() === '') {
                                   inputForm.password = '';
                               } else {
                                   inputForm.salt = salt;
                                   inputForm.password = hash;
                               }
                               this.setState({inputForm})
                           }}
                           key={`567`}/>

                </div>
                <div className="group">
                    <label htmlFor="passConfirm" className="label" style={{color: 'white'}}>Confirm Password</label>
                    <input id="passConfirm" type="password" className="input" data-type="password"
                           onChange={(e) => {
                               const {hashConfirm, saltConfirm} = this.setPassword(e.target.value);
                               const inputForm = {...this.state.inputForm};
                               if (e.target.value.trim() === '') {
                                   inputForm.passwordConfirm = '';
                               } else {
                                   inputForm.saltConfirm = saltConfirm;
                                   inputForm.passwordConfirm = hashConfirm;
                               }
                               this.setState({inputForm})
                           }}
                           key={`342`}/>

                </div>
                <div className="group">
                    <input type="submit" className="button" value="Sign In" style={{background: '#348FFC'}}
                           onClick={() => {
                               // this.newRegistration();
                           }}
                    />
                </div>
            </div>
        );
    }

    onPage = (e) => {
        this.setState({first: e.first, rows: e.rows});
    }

    insertOnlines() {

        const filteredOnlines = _.filter(this.state.onlines, online => moment(online.start).isSameOrAfter(moment(this.state.currentDate)))
        const sortedOnlines = _.orderBy(filteredOnlines, [(online) => {
            return moment(new Date(online.start)).format('YYYY-MM-DDTHH:mm');
        }, 'username'], ['asc', 'asc']);

        return (
            <DataTable value={sortedOnlines}
                       style={{fontSize: '12px'}}
                       selectionMode="single"
                       paginator={true}
                       rows={this.state.rows}
                       rowsPerPageOptions={[5]}
                       onPage={this.onPage}
                       first={this.state.first}
                       selection={this.state.selectedAppointment}
                       onSelectionChange={(e) => {
                           this.setState({selectedAppointment: e.value})
                       }}
            >
                <Column header='Time'
                        body={row => timeTemplate(new Date(row.start), new Date(row.end))}
                        style={{width: '25%'}}/>
                <Column header='With'
                        body={row => `${row.firstName} ${row.lastName}`.trim()}
                        style={{width: '30%'}}/>
                <Column header='Type'
                        body={row => row.title}
                        style={{width: '40%'}}/>
            </DataTable>
        )
    }

    onRegisterBooking = () => {

        if (this.state.newPatient) {
            this.setState({registrationState: RS_NEW_DETAILS, selectedAppointment: null})
        } else {
            this.setState({registrationState: RS_EXISTING_DETAILS, selectedAppointment: null})
        }
    }

    availabilitySection = () => {

        const yearStart = moment().year();
        const yearEnd = moment().add('years', 1).year();

        return (
            <div className="sign-up1-htm">
                <div className="group p-grid">
                    <div className='p-col-6'>
                        <label htmlFor="availability" className="label" style={{color: 'white'}}>Availability On</label>
                        <Calendar id="availability"
                                  style={{fontSize: '12px'}}
                                  dateFormat="dd/mm/yy"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange={`${yearStart}:${yearEnd}`}
                                  readOnlyInput={true}
                                  placeholder="Date" className="form-element"
                                  value={this.state.currentDate}
                                  onChange={(e) => this.onChange({
                                      owner: 'currentDate',
                                      value: e.value
                                  })}
                        />
                    </div>
                    <div className='p-col-6'>
                        <label htmlFor="book" className="label" style={{color: 'white'}}>Return to Register</label>
                        <Button label='Return'
                                onClick={this.onRegisterBooking}
                        />
                    </div>
                </div>
                <div className="group extended-pagination">
                    <label className="label" style={{color: 'white'}}>Availability</label>
                    {this.insertOnlines()}
                </div>
            </div>
        );
    }

    matchExistingBothResult = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            Your registration details matched the practices records. We will send your registration code to your registered email or mobile number.
                    </span>
                </div>
                <div className="group p-grid">
                    <div className="p-col-6">
                        <label htmlFor="byEmail"
                        >Send by Email</label>
                        <Checkbox checked={this.state.inputForm.contactByEmail}
                                  onChange={(e) => this.onChange({
                                      owner: 'inputForm.contactByEmail',
                                      value: e.checked
                                  })}
                                  style={{paddingLeft: "5px"}}
                        />
                    </div>
                    <div className="p-col-6">
                        <label htmlFor="byMobile"
                        >Send by Mobile</label>
                        <Checkbox checked={!this.state.inputForm.contactByEmail}
                                  onChange={(e) => this.onChange({
                                      owner: 'inputForm.contactByEmail',
                                      value: !e.checked
                                  })}
                                  style={{paddingLeft: "5px"}}
                        />
                    </div>
                </div>
                <div className="group">
                    <input type="submit" className="button" value="Send Registration Code"
                           style={{background: '#348FFC'}}
                           onClick={this.sendRegistration}/>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    matchExistingJustEmail = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            Your registration details matched the practices records We will send your registration code to your registered email address.
                    </span>
                </div>
                <div className="group">
                    <input type="submit" className="button" value="Send Registration Code"
                           style={{background: '#348FFC'}}
                           onClick={this.sendRegistration}/>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    matchExistingJustMobile = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            Your registration details matched the practices records We will send your registration code to your registered mobile number.
                    </span>
                </div>
                <div className="group">
                    <input type="submit" className="button" value="Send Registration Code"
                           style={{background: '#348FFC'}}
                           onClick={this.sendRegistration}/>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    matchNeither = () => {
        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            Unfortunately you don't have a registered email or mobile number.
                            To continue please contact your dental practice to register on {`${this.state.practiceLoginPageDetails.telephone}`}
                    </span>
                </div>
                <div className="hr"/>
            </div>
        );
    }

    usernameAlreadyExists = () => {
        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            Your chosen username is already registered. Please choose a different username and continue.
                    </span>
                </div>
                <div className="hr"/>
            </div>
        );
    }

    alreadyRegistered = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            Your registration details matched the practices records. Your details have already been registered for portal access.
                            please contact your dental practice for details on {`${this.state.practiceLoginPageDetails.telephone}`}
                    </span>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    blockRegistration = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            For some reason this account is not available for online portal registration.
                            Please contact your dental practice to become registered on {`${this.state.practiceLoginPageDetails.telephone}`}
                    </span>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    unmatched = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            No match was found for the details entered. If this is correct please continue otherwise return to the first registration page and correct the information.
                    </span>
                </div>
                <div className="group p-grid">
                    <div className="p-col-6">
                        <input type="submit" className="button" value="New Patient" style={{background: '#348FFC'}}
                               onClick={() => {
                                   this.setState({registrationState: RS_NEW_DETAILS})
                               }}
                        />
                    </div>
                    <div className="p-col-6">
                        <input type="submit" className="button" value="Existing Patient" style={{background: '#348FFC'}}
                               onClick={() => {
                                   this.setState({registrationState: RS_EXISTING_DETAILS})
                               }}
                        />
                    </div>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    newSuccess = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            Your details were successfully registered. We will send your registration code to your registered email or mobile number.
                    </span>
                </div>
                <div className="group p-grid">
                    <div className="p-col-6">
                        <label htmlFor="byEmail"
                        >Send by Email</label>
                        <Checkbox checked={this.state.inputForm.contactByEmail}
                                  onChange={(e) => this.onChange({
                                      owner: 'inputForm.contactByEmail',
                                      value: e.checked
                                  })}
                                  style={{paddingLeft: "5px"}}
                        />
                    </div>
                    <div className="p-col-6">
                        <label htmlFor="byMobile"
                        >Send by Mobile</label>
                        <Checkbox checked={!this.state.inputForm.contactByEmail}
                                  onChange={(e) => this.onChange({
                                      owner: 'inputForm.contactByEmail',
                                      value: !e.checked
                                  })}
                                  style={{paddingLeft: "5px"}}
                        />
                    </div>
                </div>
                <div className="group">
                    <input type="submit" className="button" value="Send Registration Code"
                           style={{background: '#348FFC'}}
                           onClick={this.sendRegistration}/>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    awaitingConfirmation = () => {

        const destination = this.state.contactByEmail ? `Enter the registration code that was sent to your registered email address.` : `Enter the registration code that was sent to your registered mobile number.`;

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                        {destination}
                    </span>
                </div>
                <div className="group">
                    <label htmlFor="regCode" className="label">Registration Code</label>
                    <input id="pass" type="text" className="input"
                           value={this.state.inputForm.registrationCode}
                           onChange={(e) => this.onChange({
                               owner: 'inputForm.registrationCode',
                               value: e.target.value
                           })}
                    />

                </div>
                <div className="group">
                    <input type="submit" className="button" value="Sign Up" style={{background: '#348FFC'}}
                           onClick={() => {
                               this.confirmRegistration();
                           }}
                    />
                </div>
                <div className="hr"/>
            </div>
        )
    }

    codeUnconfirmed = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            The entered registration code did not match the code contained in you email / SMS message. Please renter and resend.
                    </span>
                </div>
                <div className="group">
                    <label htmlFor="regCode" className="label">Registration Code</label>
                    <input id="pass" type="text" className="input"
                           value={this.state.registrationCode}
                           onChange={(e) => {
                               this.setState({registrationCode: e.target.value})
                           }}
                    />

                </div>
                <div className="group">
                    <input type="submit" className="button" value="Sign Up" style={{background: '#348FFC'}}
                           onClick={() => {
                               this.confirmRegistration();
                           }}
                    />
                </div>
                <div className="hr"/>
            </div>
        )
    }

    triesExceeded = () => {

        return (
            <div className="sign-in-htm">
                <div className="group">
                    <span style={{textAlign: 'center', color: 'white', paddingBottom: '20px'}}>
                            You have exceeded the maximum number of attempts at sending the correct registration code.
                            To continue please contact your dental practice to become registered on {`${this.state.practiceLoginPageDetails.telephone}`}
                    </span>
                </div>
                <div className="hr"/>
            </div>
        )
    }

    mainSection = () => {

        switch (this.state.registrationState) {
            case RS_LOGIN:
                return this.login(false);
            case RS_LOGIN_DIRECT:
                this.loginDirect()
                break;
            case RS_LOGIN_FAILURE:
                return this.login(true);
            case RS_EXISTING_DETAILS:
                return this.existingUserDetailsSection();
            case RS_EXISTING_DETAILS_RESEND:
                return this.existingPortalUserRedirection();
            case RS_NEW_DETAILS:
                return this.newUserDetailsSection();
            case RS_FORGOT_PASSWORD:
                return this.forgotPasswordRedirection();
            case RS_AVAILABILITY:
                return this.availabilitySection();
            case RS_MATCH_RESULT:

                switch (this.state.createResult.result) {
                    case PAT_CREATE_STATUS_ALREADY_REGISTERED:
                        return this.alreadyRegistered();
                    case PAT_CREATE_STATUS_AWAITING_CONFIRMATION:
                        return this.awaitingConfirmation();
                    case PAT_CREATE_STATUS_BLOCK_REGISTRATION:
                        return this.blockRegistration();
                    case PAT_CREATE_STATUS_MATCHED_BOTH:
                        return this.matchExistingBothResult();
                    case PAT_CREATE_STATUS_MATCHED_EMAIL:
                        return this.matchExistingJustEmail();
                    case PAT_CREATE_STATUS_MATCHED_MOBILE:
                        return this.matchExistingJustMobile();
                    case PAT_CREATE_STATUS_MATCHED_NEITHER:
                        return this.matchNeither();
                    case PAT_CREATE_STATUS_REG_MATCHED_BOTH:
                        return this.newSuccess();
                    case PAT_CREATE_STATUS_REG_MATCHED_EMAIL:
                        return this.matchExistingJustEmail();
                    case PAT_CREATE_STATUS_TRIES_EXCEEDED:
                        return this.triesExceeded();
                    case PAT_CREATE_STATUS_CONFIRMED:
                        return this.login();
                    case PAT_CREATE_STATUS_UNCONFIRMED:
                        return this.codeUnconfirmed();
                    case PAT_CREATE_STATUS_UNMATCHED:
                        return this.unmatched();
                    case PAT_CREATE_STATUS_UNSUBMITTED:
                        this.setState({registrationState: RS_START})
                        return;
                    case PAT_CREATE_STATUS_USERNAME_EXISTS:
                        return this.usernameAlreadyExists();

                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }

    showMainTab = (tabTextSize) => {

        const content = [];

        const tabStyle = {color: "white", fontSize: tabTextSize};

        const {fullName} = this.props.patientDataShort ? this.props.patientDataShort : {
            fullName: null,
            portalLoginRegistered: false
        };

        switch (this.state.registrationState) {
            case RS_LOGIN:
            case RS_LOGIN_FAILURE:
                content.push(<input id="tab-1" type="button" name="tab" className="sign-in"
                                    onClick={() => {
                                    }}
                />);
                content.push(<label htmlFor="tab-2" style={tabStyle} className="tab">{`Welcome ${fullName}`}</label>);
                break;
            case RS_EXISTING_DETAILS:
                content.push(<input id="tab-2" type="button" name="tab" className="sign-in"
                                    onClick={() => {
                                    }}
                />);
                content.push(<label htmlFor="tab-2" style={tabStyle} className="tab">{`Welcome ${fullName}`}</label>);
                break;
            case RS_NEW_DETAILS:
                content.push(<input id="tab-3" type="button" name="tab" className="sign-in"
                                    onClick={() => {
                                    }}
                />);
                content.push(<label htmlFor="tab-3" style={tabStyle} className="tab">New Patient Registration</label>);
                break;
            case RS_EXISTING_DETAILS_RESEND:
                content.push(<input id="tab-2" type="button" name="tab" className="sign-in"
                                    onClick={() => {
                                    }}
                />);
                content.push(<label htmlFor="tab-2" style={tabStyle} className="tab">{`Security Notice`}</label>);
                break;
            case RS_FORGOT_PASSWORD:
                content.push(<input id="tab-2" type="button" name="tab" className="sign-in"
                                    onClick={() => {
                                    }}
                />);
                content.push(<label htmlFor="tab-2" style={tabStyle} className="tab">{`Forgotten Password`}</label>);
                break;

            default:
                break;
        }
        return content;
    }

    render() {

        if (!this.state.practiceLoginPageDetailsLoaded || !this.state.patientDataShortLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        const practiceName = this.state.practiceLoginPageDetails.practiceName;

        let h1TextSize = this.state.smallScreen ? 16 : 25;
        let tabTextSize = 15;

        let minHeight = '100vh';

        if (this.state.height > 850) {
            minHeight = '70vh';
        } else if (this.state.height > 800 && this.state.height < 850) {
            minHeight = '80vh';
        } else if (this.state.height > 720 && this.state.height < 800) {
            minHeight = '90vh';
        } else if (this.state.height > 420 && this.state.height < 580) {
            minHeight = '130vh';
        } else if (this.state.height > 400 && this.state.height < 420) {
            minHeight = '160vh';
        } else if (this.state.height > 350 && this.state.height < 400) {
            minHeight = '180vh';
        } else if (this.state.height > 300 && this.state.height < 350) {
            minHeight = '200vh';
        }

        if (this.state.width > 420 && this.state.width < 580) {
            tabTextSize = 15;
        } else if (this.state.width > 400 && this.state.width < 420) {
            tabTextSize = 11;
        } else if (this.state.width > 350 && this.state.width < 400) {
            tabTextSize = 11;
        } else if (this.state.width > 300 && this.state.width < 350) {
            tabTextSize = 11;
        }
        return (

            <ErrorBoundary>
                <div className="body2 login-wrap"
                     style={{
                         minHeight,
                         background: `url(https://${ac.getBASERESTURL()}/Patients/openTemplateDocument/${ac.getMcId()}/${this.state.practiceLoginPageDetails.customBanner}) no-repeat center`
                     }}
                >
                    <div className="login-html" style={{paddingLeft: '28px', paddingRight: '28px', paddingTop: '20px'}}>
                        <h1 htmlFor="practiceName"
                            style={{
                                fontSize: h1TextSize,
                                textAlign: 'center',
                                color: 'white',
                                paddingBottom: '20px'
                            }}>{practiceName}</h1>

                        {this.showMainTab(tabTextSize)}

                        <div className="login-form">

                            {this.mainSection()}
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        message: state.stateManagement.message,

        practiceLoginPageDetailsLoaded: state.login.practiceDetailsLoginPageLoaded,
        practiceLoginPageDetails: state.login.practiceLoginPageDetails,

        usersLoaded: state.users.searchComplete,
        usersShort: state.users.results,

        onlinesLoaded: state.tablet.onlinesLoaded,
        onlines: state.tablet.onlines,

        patientDataShortLoaded: state.patients.short_loaded,
        patientDataShort: state.patients.short,

        createResult: state.patients.createResult,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPracticeLoginPageDetails: (mcId) => dispatch(getPracticeLoginPageDetails(mcId)),
        getPatientDetails: (portalReference) => dispatch(getPatResource(RES_PATIENT_DETAILS_SHORT.REF, {portalReference})),

        createRegistration: (mcId, inputForm) => dispatch(patientRegister(RES_PATIENT_REGISTRATION.CREATE, mcId, inputForm)),
        sendRegistration: (mcId, inputForm) => dispatch(patientRegister(RES_PATIENT_REGISTRATION.SEND, mcId, inputForm)),
        confirmRegistration: (mcId, inputForm) => dispatch(patientRegister(RES_PATIENT_REGISTRATION.CONFIRM, mcId, inputForm)),

        addPatient: (mcId, patient, NHSRegistration) => dispatch(savePatientDataWithMcId(RES_PATIENT_DETAILS.ADD_ONLINE, mcId, patient, NHSRegistration)),

        getAllUsersShort: (mcId) => dispatch(getAllUsersWizard(mcId)),
        getDiaryEvents: (start, end) => dispatch(getPortalDiaryEvents(RES_diaryEvents.GET_PORTAL_UA, start, end)),

        resetPassword: (reference) =>  dispatch(resetPassword(RES_PATIENT_PASSWORD.RESET, reference)),

        doLogin: (loginDetails) => dispatch(doClientLogin(loginDetails)),
    };
};

const Wizard2 = connect(mapStateToProps, mapDispatchToProps)(ConnectedWizard2);

export default Wizard2;
