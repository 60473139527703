import React from 'react';
import {
    setState,
    SM_NHSRegistration,
    SM_PATIENT_DATA,
    SM_PATIENT_NOK_DATA,
    stateRequest
} from "../../actions/stateManagement";
import {connect} from "react-redux";
import {Button} from 'primereact/components/button/Button';
import {Panel} from "primereact/panel";
import {ShowMessageDialog, suppressBrowserCM} from "./Utils";
import {getResource as getPatResource, RES_PATIENT_DETAILS} from "../../actions/personal";
import {PersonalSection} from "./Sections/Personal";
import * as Actions from "../../actions";
import {getDropDowns, RES_getDropDowns} from "../../actions/dropDowns";
import {getResource as getHKResource, RES_HOUSEKEEPING_OCCS} from "../../actions/housekeeping";
import {NHSDetails} from "./Sections/NHSDetails";
import _ from "lodash";
import * as DefaultData from "./DefaultData";
import {ProgressBar} from "primereact/progressbar";
import {HM_AddPassword, HM_notImplemented, SE_NONE, SM_CLIENT_PATIENT_DETAIL} from "./Constants";
import {ClientComponent} from "./ClientComponent";
import {getResource, RES_NHS_REGISTRATION} from "../../actions/nhsManagement";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Password} from "primereact/password";
import crypto from "crypto";
import {ac} from "../../index";

class ConnectedClientDetails extends ClientComponent {

    constructor(props) {
        super(props);

        this.state = {
            stateManagementId: `${SM_PATIENT_DATA.id}_${props.patientId}`,
            patientId: props.patientId,

            patientDataLoaded: false,
            patientData: null,

            patientNOKDataLoaded: false,
            patientNOKData: null,

            patientNHSDetails: _.cloneDeep(DefaultData.PatientNHSDetails),

            patientAppointmentSummaryLoaded: false,
            patientAppointmentSummary: null,

            [HM_AddPassword.id]: false,
        };

        this.sigCanvas = {};

        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);

        this.optionalNHSSection = this.optionalNHSSection.bind(this);
    }

    componentDidMount() {
        this.props.getDropDowns();
        this.props.getOccupations();
        this.props.getPatientDetails({patientId: this.state.patientId});
        this.props.getPatientNOKDetails({patientId: this.props.patientId});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_DETAILS:

                    if (this.props.patientData.NHSPatient) {
                        this.props.getPatientNHSRegistration({patientId: this.props.patientId});
                    } else {
                        this.setState({patientDataLoaded: true, patientData: this.props.patientData}, () => {
                            this.props.setState(this.state.stateManagementId, {...this.state});
                        });
                    }
                    break;
                case Actions.RECEIVE_PATIENT_NOK:

                    let patientNOKData = null;

                    if (this.props.patientNOKData) {
                        patientNOKData = {...this.props.patientNOKData};
                    } else {
                        patientNOKData = _.cloneDeep(DefaultData.NextOfKin(ac.getMcId()));
                        patientNOKData.kin = {id: this.state.patientId};
                    }
                    this.setState({patientNOKDataLoaded: true, patientNOKData}, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;
                case Actions.RECEIVE_PATIENT_APPOINTMENT_SUMMARY:

                    this.setState({
                        patientAppointmentSummaryLoaded: true,
                        patientAppointmentSummary: this.props.patientAppointmentSummary
                    });
                    break;
                case RES_NHS_REGISTRATION.GET.receive:

                    this.setState({
                        NHSRegistrationLoaded: true,
                        NHSRegistration: this.props.NHSRegistration,
                        patientDataLoaded: true,
                        patientData: this.props.patientData
                    }, () => {
                        this.props.setState(this.state.stateManagementId, {...this.state});
                    });
                    break;

                default:
                    break;
            }
        }
    }

    onPasswordChange = () => {

        this.setState({[HM_AddPassword.id]: true});
    }

    setPassword(password) {

        // Creating a unique salt for a particular user
        const salt = crypto.randomBytes(16).toString('hex');

        // Hashing user's salt and password with 1000 iterations, 64 length and sha512 digest
        const hash = crypto.pbkdf2Sync(password, salt, 1000, 64, `sha512`).toString(`hex`);
        return {hash, salt};
    };

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onClear() {

        this.sigCanvas.clear();
    }

    onSavePatientData = () => {

        this.props.stateRequest({action: Actions.SAVE_PATIENT_PORTAL_DETAILS, smRef: this.props.patientId})
        this.onSave(SE_NONE);
    }

    optionalNHSSection() {

        if (this.state.patientData.NHSPatient) {
            return (
                <NHSDetails onChange={this.onChange}
                            target='patientNHSDetails'
                            patientNHSDetails={this.state.patientNHSDetails}
                            {...this.state}
                            {...this.props}
                />
            )
        }
    }

    showDialogs = () => {

        if (this.state[HM_AddPassword.id]) {

            const footer = <div>
                <Button label="OK" icon="fa fa-check" onClick={() => {
                    this.setState({[HM_AddPassword.id]: false});
                }}/>
                <Button label="Cancel" icon="fas fa-times" onClick={() => {
                    this.setState({[HM_AddPassword.id]: false});
                }
                }/>
            </div>;

            return (
                <Dialog header={HM_AddPassword.header}
                        visible={true}
                        width="350px"
                        modal={true}
                        responsize={true}
                        resizable={true}
                        blockScroll={true}
                        minY={70}
                        footer={footer}
                        onHide={() => {
                            this.setState({[HM_AddPassword.id]: false})
                        }}
                >

                    <div className="p-grid p-fluid">
                        <div className="p-col-3">
                            <label>Password</label>
                        </div>
                        <div className="p-col-9">
                            <Password feedback={false}
                                      onChange={(e) => {
                                          const {hash, salt} = this.setPassword(e.target.value);
                                          this.onChange({owner: 'patientData.salt', value: salt});
                                          this.onChange({owner: 'patientData.password', value: hash});
                                      }}/>
                        </div>
                    </div>
                </Dialog>
            )
        } else {
            return (
                ShowMessageDialog(this, HM_notImplemented)
            )
        }
    }

    render() {

        if (!this.state.patientDataLoaded || !this.state.patientNOKDataLoaded || !this.props.dropDownsLoaded || !this.props.occupationsLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        suppressBrowserCM();

        const header = <div><label id='panel-header'>{SM_CLIENT_PATIENT_DETAIL.detail}</label>
            <div className="p-toolbar-group-right">
                <Button label={SM_CLIENT_PATIENT_DETAIL.exitLabel} icon={SM_CLIENT_PATIENT_DETAIL.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSavePatientData()}
                />
            </div>
        </div>;

        return (

            <div className="p-col-12 p-lg-12">
                <Panel header={header} className="no-pad">

                    {this.showDialogs()}

                    <div className="p-col-12">
                        <PersonalSection portalView={true} onChange={(e) => {
                            this.onChange({
                                ...e,
                                source: {
                                    id: this.state.stateManagementId,
                                    action: RES_PATIENT_DETAILS.SAVE.action,
                                    saveState: true,
                                    saveObjects: false
                                }
                            })
                        }}
                                         target='patientData'
                                         showProviders={false}
                                         onTablet={true}
                                         onPasswordChange={this.onPasswordChange}
                                         patientDataLoaded={this.state.patientDataLoaded}
                                         patientData={this.state.patientData}
                                         patientNOKDataLoaded={this.state.patientNOKDataLoaded}
                                         patientNOKData={this.state.patientNOKData}
                                         occupations={this.props.occupations}
                                         titles={this.props.titles}
                                         countries={this.props.countries}
                                         usersShort={this.props.usersShort}
                        />

                        {this.optionalNHSSection()}
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    const patientDataLoadedId = `${SM_PATIENT_DATA.loaded}_${ownProps.patientId}`;
    const patientDataId = `${SM_PATIENT_DATA.id}_${ownProps.patientId}`;

    const patientNOKDataLoadedId = `${SM_PATIENT_NOK_DATA.loaded}_${ownProps.patientId}`;
    const patientNOKDataId = `${SM_PATIENT_NOK_DATA.id}_${ownProps.patientId}`;

    const NHSRegistrationLoadedId = `${SM_NHSRegistration.loaded}_${ownProps.patientId}`;
    const NHSRegistrationId = `${SM_NHSRegistration.id}_${ownProps.patientId}`;

    let patientDataLoaded = state.patients[patientDataLoadedId];
    patientDataLoaded = patientDataLoaded === undefined ? false : patientDataLoaded;

    let patientNOKDataLoaded = state.patients[patientNOKDataLoadedId];
    patientNOKDataLoaded = patientNOKDataLoaded === undefined ? false : patientNOKDataLoaded;

    let NHSRegistrationLoaded = state.patients[NHSRegistrationLoadedId];
    NHSRegistrationLoaded = NHSRegistrationLoaded === undefined ? false : NHSRegistrationLoaded;

    const patientData = state.patients[patientDataId];
    const patientNOKData = state.patients[patientNOKDataId];

    return {

        dropDownsLoaded,
        titles: state.dropDowns.titles,
        providers: state.dropDowns.providers,
        sources: state.dropDowns.sources,
        countries: state.dropDowns.countries,
        origins: state.dropDowns.origins,
        genders: state.dropDowns.genders,

        occupationsLoaded: state.housekeeping.occupationsLoaded,
        occupations: state.housekeeping.occupations,

        patientDataLoaded,
        patientData,

        patientNOKDataLoaded,
        patientNOKData,

        NHSRegistrationLoaded,
        NHSRegistrationId,
        NHSRegistration: state.patients[NHSRegistrationId],

        loggedIn: state.login.loggedIn,
        message: state.stateManagement.message,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getOccupations: () => dispatch(getHKResource(RES_HOUSEKEEPING_OCCS.GET, {})),
        getPatientDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET, params)),
        getPatientNOKDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET_NOK, params)),
        getPatientNHSRegistration: (params) => dispatch(getResource(RES_NHS_REGISTRATION.GET, params)),

        stateRequest: (source) => dispatch(stateRequest(source)),
        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ClientDetails = connect(mapStateToProps, MapDispatchToProps)(ConnectedClientDetails);

export default ClientDetails;
