import {BaseComponent} from "../BaseComponent";
import {SE_NONE} from "./Constants";

export class ClientComponent extends BaseComponent {

    onSave = (command) => {

        this.props.onCommandChange(command)
    }

    onCancel = () => {

        this.props.onCommandChange(SE_NONE)
    }
}