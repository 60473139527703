import React from 'react';
import _ from 'lodash';

import {Panel} from 'primereact/components/panel/Panel';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';

import {calendar, checkBox, dropDown, inputText, selectGendersButton} from './OnChangeUtils';
import {PAT_STATUSES} from "../../../Constants";
import moment from "moment";
import {InputText} from "primereact/inputtext";
import {getFormattedDateDiffShort} from "../Utils";

const optionalInfo = (props) => {

    if (props.includeOptionalInfo) {
        return [
            <div className="p-col-4">
                <label htmlFor="name.journeyStage">Journey Stage</label>
            </div>,
            <div className="p-col-8">
                <Dropdown id="name.journeyStage"
                          placeHolder='Journey Stage'
                          value={props.value.stage}
                          options={props.stages}
                          onChange={props.onStageChange}
                          autoWidth={false}
                />
            </div>,
            <div className="p-col-4"><label>Changed On</label></div>,
            <div className="p-col-8"><label>{props.value.changedOn}</label></div>,
            <div className="p-col-4"><label>Patient ID</label></div>,
            <div className="p-col-8"><label>{props.value.patientId}</label></div>,
        ]
    }
};

const optionalStatus = (props) => {

    const contents = [];

    const patientStatus = _.find(PAT_STATUSES, status => status.name === props[props.target].status);

    if (!props.portalView && !props.onTablet) {
        contents.push(<div className="p-col-3">
                <label htmlFor="status">Status</label>
            </div>
        )
        contents.push(<div className="p-col-9">
                <Dropdown id="status"
                          value={patientStatus}
                          options={PAT_STATUSES}
                          optionLabel='text'
                          onChange={(e) => {
                              props.onChange({owner: `${props.target}.status`, value: e.value.name});
                          }}
                          autoWidth={false}
                />
            </div>
        )
    }
    return contents;
}

const showDateOfBirth = (props) => {

    const dateOfBirth = _.get(props, `${props.target}.dateOfBirth`);
    const age = getFormattedDateDiffShort(dateOfBirth, new moment());

    const content = [];

    content.push(
        <>
            <div className="p-col-3">
                <label htmlFor="dob">Date of Birth</label>
            </div>
            <div className={`${props.showAge ? "p-col-6" : "p-col-9"}`}>
                {calendar(props, 'dateOfBirth')}
            </div>
        </>
    )

    if (props.showAge) {
        content.push(
            <div className="p-col-3">
                <InputText value={age}
                           disabled={true}
                />
            </div>
        )
    }
    return content;
}

const optionalNHS = (props) => {

    if (!props.onTablet) {
        return (
            <>
                <div className="p-col-3">
                    <label htmlFor="nhsPatient">NHS</label>
                </div>
                <div className="p-col-9">
                    {checkBox(props, 'NHSPatient', '', false, false)}
                </div>
            </>
        )
    }
}

export const Name = (props) => {

    return (
        <Panel header='Name'>
            <div className="p-grid p-fluid form-group">
                {optionalInfo(props)}
                {optionalStatus(props)}
                <div className="p-col-3">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-9">
                    {dropDown(1, props, 'titles', 'title', 'abbreviation', true, false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="firstName">First Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'firstName', 'First Name', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="middleName">Middle Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'middleName', 'Middle Name', false, false)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="lastName">Last Name</label>
                </div>
                <div className="p-col-9">
                    {inputText(props, 'lastName', 'Last Name', false, true)}
                </div>
                <div className="p-col-3">
                    <label htmlFor="gender">Gender</label>
                </div>
                <div className="p-col-9">
                    {selectGendersButton(props, 'gender')}
                </div>

                {showDateOfBirth(props)}
                {optionalNHS(props)}
            </div>
        </Panel>
    )
};
