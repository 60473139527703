import classNames from "classnames";
import {ICON_GOTO, ICON_OK, ICON_PRINT, ICON_SHOW, ICON_USERS} from "../../Constants";
import React from "react";

export const clientLayoutClassName = classNames('layout-wrapper', {
    'menu-layout-static': true,
    'menu-layout-overlay': false,
    'layout-menu-overlay-active': false,
    'menu-layout-slim': false,
    'menu-layout-horizontal': false,
    'layout-menu-static-inactive': true,
    'layout-menu-static-active': false
});

// treatment plan statuses

export const TP_PROPOSED = {name: 'PROPOSED', text: 'Proposed', ord: 0};
export const TP_ACCEPTED = {name: 'ACCEPTED', text: 'Accepted', ord: 1};
export const TP_REJECTED = {name: 'REJECTED', text: 'Rejected', ord: 2};
export const TP_CLOSED = {name: 'CLOSED', text: 'Closed', ord: 3};

export const TP_STATUS = [TP_PROPOSED, TP_ACCEPTED, TP_REJECTED, TP_CLOSED];

export const tpStatusText = status => TP_STATUS.find(entry => status === entry.name).text;

export const SE_NONE = 'SE_NONE';

export const SE_ADD_PERSONAL_DETAILS = 'SE_ADD_PERSONAL_DETAILS';
export const SE_ADD_REFERRER_DETAILS = 'SE_ADD_REFERRER_DETAILS';

export const COLOR_ChiralBorder = '#5181CA';

export const SE_APPOINTMENT_HISTORY = 'SE_APPOINTMENT_HISTORY';
export const SE_BOOK_APPOINTMENT = 'SE_BOOK_APPOINTMENT';
export const SE_ADD_MEDICAL_HISTORY = 'SE_ADD_MEDICAL_HISTORY';
export const SE_ADD_QUESTIONNAIRE = 'SE_ADD_QUESTIONNAIRE';
export const SE_UPLOAD_XRAY_IMAGE = 'SE_UPLOAD_XRAY_IMAGE';
export const SE_SIGN_CONSENT = 'SE_SIGN_CONSENT';
export const SE_SIGN_GDPR_CONSENT = 'SE_SIGN_GDPR_CONSENT';
export const SE_SIGN_GDPR_CONSENT_RETRY = 'SE_SIGN_GDPR_CONSENT_RETRY';
export const SE_PAYMENT_PLANNING = 'SE_PAYMENT_PLANNING';
export const SE_PAYMENT_PLANNING_CONSENT = 'SE_PAYMENT_PLANNING_CONSENT';
export const SE_TREATMENT_PLANNING = 'SE_TREATMENT_PLANNING';
export const SE_TREATMENT_PLANNING_CONSENT = 'SE_TREATMENT_PLANNING_CONSENT';
export const SE_PRESCRIPTION_HISTORY = 'SE_PRESCRIPTION_HISTORY';
export const SE_DOCUMENTS = 'SE_DOCUMENTS';

export const SE_CHAT = 'SE_CHAT';

export const SE_ONLINE_BOOKED = 'OnlineBooked';
export const SE_ONLINE_VC_BOOKED = 'OnlineVCBooked';

export const SE_BOOK_VIDEO_CALL = 'SE_BOOK_VIDEO_CALL';
export const SE_VIDEO_CALL = 'SE_VIDEO_CALL';

export const VIDEO_OWNER_PRACTICE = 'VIDEO_OWNER_PRACTICE';
export const VIDEO_OWNER_PATIENT = 'VIDEO_OWNER_PATIENT';

export const PATIENT_CONNECTED = 'PATIENT_CONNECTED';
export const PATIENT_CONNECTED_RESULT = 'PATIENT_CONNECTED_RESULT';

export const PATIENT_ANSWER = 'PATIENT_ANSWER';
export const PATIENT_CANDIDATE = 'PATIENT_CANDIDATE';

export const PATIENT_DISCONNECTED = 'PATIENT_DISCONNECTED';
export const PATIENT_DISCONNECTED_RESULT = 'PATIENT_DISCONNECTED_RESULT';

export const PRACTICE_CONNECTED = 'PRACTICE_CONNECTED';
export const PRACTICE_CONNECTED_RESULT = 'PRACTICE_CONNECTED_RESULT';

export const PRACTICE_OFFER = 'PRACTICE_OFFER';
export const PRACTICE_CANDIDATE = 'PRACTICE_CANDIDATE';

export const PRACTICE_DISCONNECTED = 'PRACTICE_DISCONNECTED';
export const PRACTICE_DISCONNECTED_RESULT = 'PRACTICE_DISCONNECTED_RESULT';

export const VIDEO_ERROR = 'ERROR';
export const VIDEO_OK = 'OK';

//

const ICON_SAVE = 'pi pi-check';
const ICON_BACK = 'far fa-caret-square-up';

const ICON_CALL = 'fas fa-phone';
const ICON_SHARE = 'far fa-share-square';

const LABEL_SAVE = 'Save';
const LABEL_BACK = 'Return';

export const ICON_CANCEL = 'fas fa-ban';
export const ICON_EDIT = 'fas fa-edit';
export const ICON_DELETE = 'fa fa-times';
export const ICON_CLOSE = 'far fa-times-circle';
export const ICON_MINUS = 'fa fa-minus';
export const ICON_PLUS = 'fa fa-plus';
export const ICON_UPLOAD = 'fas fa-upload';

export const SM_CLIENT_PATIENT_DETAILS = {
    label: 'My Details',
    icon: 'far fa-user-circle',
};

export const SM_CLIENT_PATIENT_DETAIL = {
    loaded: 'SM_TAB_PATIENT_DETAILS_LOADED',
    id: 'SM_CLIENT_PATIENT_DETAILS',
    label: 'Personal Information',
    detail: 'Patient Details',
    icon: 'far fa-question-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE
};

export const SM_CLIENT_BOOK_APPOINTMENT = {
    loaded: 'SM_CLIENT_BOOK_APPOINTMENT_LOADED',
    id: 'SM_CLIENT_BOOK_APPOINTMENT',
    label: 'Book Appointment',
    detail: 'Book an appointment',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_APPOINTMENTS = {
    label: 'My Appointments',
    icon: 'far fa-calendar-alt',
};

export const SM_CLIENT_APPOINTMENT_HISTORY = {
    loaded: 'SM_CLIENT_APPOINTMENT_HISTORY_LOADED',
    id: 'SM_CLIENT_APPOINTMENT_HISTORY',
    label: 'My History',
    detail: 'Appointment History',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_DOCUMENTS = {
    loaded: 'SM_CLIENT_DOCUMENTS_LOADED',
    id: 'SM_CLIENT_DOCUMENTS_HISTORY',
    label: 'My Documents',
    detail: 'Here are your documents',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_BOOK_VIDEO_CALL = {
    loaded: 'SM_CLIENT_BOOK_VIDEO_CALL_LOADED',
    id: 'SM_CLIENT_BOOK_VIDEO_CALL',
    label: 'Book Video Call',
    detail: 'Book a video call',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_VIDEO_CALL = {
    loaded: 'SM_CLIENT_VIDEO_CALL_LOADED',
    id: 'SM_CLIENT_VIDEO_CALL',
    label: 'Video Call',
    detail: 'Join a video call',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
    callIcon: ICON_CALL,
};

export const SM_CLIENT_VIDEO_CALL_SHARE_SCREEN = {
    loaded: 'SM_CLIENT_VIDEO_CALL_SHARE_SCREEN_LOADED',
    id: 'SM_CLIENT_VIDEO_CALL_SHARE_SCREEN',
    label: 'Share Screen',
    detail: 'Share a part or all of your screen',
    icon: ICON_SHARE,
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_UPLOAD_IMAGE = {
    loaded: 'SM_CLIENT_UPLOAD_IMAGE_LOADED',
    id: 'SM_CLIENT_UPLOAD_IMAGE',
    label: 'Upload XRay / Images',
    detail: 'Upload XRay / Images',
    icon: 'far fa-image',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_PRACTICE_CHAT = {
    loaded: 'SM_CLIENT_PRACTICE_CHAT_LOADED',
    id: 'SM_CLIENT_PRACTICE_CHAT',
    label: 'Chat with Practice',
    detail: 'Chat with Practice',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK
};

export const SM_CLIENT_PATIENT_MED_CON = {
    loaded: 'SM_CLIENT_PATIENT_MED_CON_LOADED',
    id: 'SM_CLIENT_PATIENT_MED_CON',
    label: 'Medical History',
    detail: 'Medical History',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_SAVE,
    exitLabelSM: '',
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_PATIENT_QUESTIONNAIRE = {
    loaded: 'SM_CLIENT_PATIENT_QUESTIONNAIRE_LOADED',
    id: 'SM_CLIENT_PATIENT_QUESTIONNAIRE',
    label: 'Questionnaire',
    detail: 'Questionnaire',
    o2Saturation: 'Enter Patient Sp02 (mm Hg)',
    temperature: 'Enter Patient Temperature oC',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_PATIENT_MED_CON_SIG = {
    label: 'Patient Signature',
};

export const SM_CLIENT_TREATMENT_PLANS = {
    label: 'My Treatment Plans',
    icon: 'fas fa-list-ol',
}

export const SM_CLIENT_TREATMENT_PLAN_PDF = {
    loaded: 'SM_CLIENT_TREATMENT_PLAN_PDF_LOADED',
    id: 'SM_CLIENT_TREATMENT_PLAN_PDF',
    label: 'Treatment Plan',
    icon: 'fas fa-list-ol',
    filename: 'TreatmentPlan'
}

export const SM_CLIENT_TREATMENT_PLANNING = {
    loaded: 'SM_CLIENT_TREATMENT_PLANNING_LOADED',
    id: 'SM_CLIENT_TREATMENT_PLANNING',
    label: 'My History',
    detail: 'My Treatment Planning',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_TREATMENT_PLANNING_CONSENT = {
    label: 'My Consent',
    detail: 'Treatment Planning Consent',
    icon: 'far fa-check-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_PAYMENT_PLANS = {
    label: 'My Payment Plans',
    icon: 'fas fa-list-ol',
}

export const SM_CLIENT_PAYMENT_PLANNING = {
    loaded: 'SM_CLIENT_PAYMENT_PLANNING_LOADED',
    id: 'SM_CLIENT_PAYMENT_PLANNING',
    label: 'My History',
    detail: 'My Payment Planning',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_PAYMENT_PLANNING_CONSENT = {
    label: 'My Consent',
    detail: 'Payment Planning Consent',
    icon: 'far fa-check-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const SM_CLIENT_CONSENT_FORMS_EMPTY = {
    label: 'You don\'t currently have any consent forms to sign',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_PRESCRIPTIONS = {
    label: 'My Prescriptions',
    icon: 'far fa-list-alt',
};

export const SM_CLIENT_PRESCRIPTION_HISTORY = {
    loaded: 'SM_CLIENT_PRESCRIPTION_HISTORY_LOADED',
    id: 'SM_CLIENT_PRESCRIPTION_HISTORY',
    label: 'My History',
    detail: 'My Prescription History',
    icon: 'fa fa-align-justify',
    exitLabel: LABEL_BACK,
    exitIcon: ICON_BACK,
};

export const SM_CLIENT_REFERRER_DETAILS = {
    loaded: 'SM_CLIENT_REFERRER_DETAILS_LOADED',
    id: 'SM_CLIENT_REFERRER_DETAILS',
    label: 'Referrer Details',
    detail: 'Referrer Details',
    icon: 'far fa-question-circle',
    exitLabel: LABEL_SAVE,
    exitIcon: ICON_SAVE,
};

export const RS_LOGIN = 'RS_LOGIN';
export const RS_LOGIN_DIRECT = 'RS_LOGIN_DIRECT';
export const RS_LOGIN_FAILURE = 'RS_LOGIN_FAILURE';
export const RS_START = 'RS_START';
export const RS_EXISTING_DETAILS = 'RS_EXISTING_DETAILS';
export const RS_EXISTING_DETAILS_RESEND = 'RS_EXISTING_DETAILS_RESEND';
export const RS_NEW_DETAILS = 'RS_NEW_DETAILS';
export const RS_AVAILABILITY = 'RS_AVAILABILITY';
export const RS_MATCH_RESULT = 'RS_MATCH_RESULT';
export const RS_FORGOT_PASSWORD = 'RS_FORGOT_PASSWORD';

export const HM_notImplemented = {
    id: 'HM_notImplemented',
    header: 'Not Implemented',
    message: 'This feature is not yet implemented'
};

// Questionnaire related

export const HM_COMPLETE_QUES = {
    id: 'HM_COMPLETE_QUES',
    header: 'Complete Questionnaire',
    message: 'Please answer all the questions either Yes / No',
    icon: ''
};

export const HM_PaymentPlanChargeCodeNotSpecified = {
    id: 'HM_PaymentPlanChargeCodeNotSpecified',
    header: 'Payment Plan',
    message: 'The payment plan account group and charge code must be set before accepting payment plan'
};

// MHF related

export const HM_COMPLETE_MHF = {
    id: 'HM_COMPLETE_MHF',
    header: 'Complete Medical History Form',
    message: 'Please answer all the questions either Yes / No',
    icon: ''
};

export const HM_ABOUT_CHIRAL_CLIENT = {
    id: 'HM_ABOUT_CHIRAL_CLIENT',
    header: 'About Chiral Patient Portal',
    message: 'www.chiralsystems.com : support@chiralsystems.com',
    icon: ICON_USERS
};

export const HM_COMPLETE_GDPR = {
    id: 'HM_COMPLETE_GDPR',
    header: 'Complete GDPR consent',
    message: 'Please Complete the GDPR consent form before continuing',
    icon: ICON_USERS
};

export const HM_COMPLETE_OTHERS = {
    id: 'HM_COMPLETE_OTHERS',
    header: 'Complete Requested Actions',
    message: 'Please Complete the requested forms before continuing',
    icon: ICON_USERS
};

export const HM_HELP_CHIRAL_CLIENT = {
    id: 'HM_HELP_CHIRAL_CLIENT',
    header: 'Patient Help',
    message: '',
    icon: 'far fa-question-circle'
};

export const HM_LOGOUT = {
    id: 'HM_LOGOUT',
    header: 'Logout',
    message: '',
    icon: ICON_GOTO
};

export const TB_IMAGE_SELECT_IMAGE = {id: 'TB_IMAGE_SELECT_IMAGE', text: 'Select image', icon: 'fa fa-plus'};
export const TB_IMAGE_DESELECT_IMAGE = {id: 'TB_IMAGE_DESELECT_IMAGE', text: 'Deselect image', icon: 'fa fa-minus'};

export const TAB_PatientDetailsUpdate = 'PatientDetailsUpdate';
export const TAB_MedicalHistoryUpdate = 'MedicalHistoryUpdate';
export const TAB_PatientConsentForms = 'PatientConsentForms';
export const TAB_TreatmentPlanSigns = 'TreatmentPlanSigns';
export const TAB_PaymentPlanSigns = 'PaymentPlanSigns';
export const TAB_Questionnaire = 'Questionnaire';

export const QUESTIONNAIRE_CURRENT = 'Current';

// Medical history from related

export const MHF_ST_Current = 'Current';

export const TB_VIEW_PAYMENT_PLAN = {
    id: 'TB_VIEW_PAYMENT_PLAN',
    text: 'View plan',
    icon: 'far far-eye',
};

export const TB_ADD_PAYMENT_PLAN = {
    id: 'TB_ADD_PAYMENT_PLAN',
    text: 'Add a patient payment plan',
    header: 'Select A Patient Treatment plan',
    icon: 'fa paymentPlan24'
};

// payment plan related

export const PP_STATUS_ACCEPTED = {name: 'Accepted', text: 'Accepted', ord: 1};
export const PP_STATUS_FINISHED = {name: 'Finished', text: 'Finished', ord: 3};
export const PP_STATUS_SUSPENDED = {name: 'Suspended', text: 'Suspended', ord: 4};
export const PP_STATUS_WRITTEN_OFF = {name: 'WrittenOff', text: 'Written Off', ord: 5};
export const PP_STATUS_PROPOSED = {name: 'Proposed', text: 'Proposed', ord: 7};

export const PP_PRECENT0 = {value: 'percent0', label: '      None', percentage: 0};
export const PP_PRECENT5 = {value: 'percent5', label: ' 5 percent', percentage: 5};
export const PP_PRECENT10 = {value: 'percent10', label: '10 percent', percentage: 10};
export const PP_PRECENT15 = {value: 'percent15', label: '15 percent', percentage: 15};
export const PP_PRECENT20 = {value: 'percent20', label: '20 percent', percentage: 20};
export const PP_PRECENT25 = {value: 'percent25', label: '25 percent', percentage: 25};
export const PP_PRECENT30 = {value: 'percent30', label: '30 percent', percentage: 30};
export const PP_PRECENT35 = {value: 'percent35', label: '35 percent', percentage: 35};
export const PP_PRECENT40 = {value: 'percent40', label: '40 percent', percentage: 40};
export const PP_PRECENT50 = {value: 'percent55', label: '50 percent', percentage: 50};
export const PP_PRECENT45 = {value: 'percent45', label: '45 percent', percentage: 45};
export const PP_PRECENT_AM = {value: 'percent_AM', label: '    Amount', percentage: 0};

export const PP_PRECENT = [PP_PRECENT0, PP_PRECENT_AM, PP_PRECENT5, PP_PRECENT10, PP_PRECENT15, PP_PRECENT20, PP_PRECENT25, PP_PRECENT30, PP_PRECENT35, PP_PRECENT40, PP_PRECENT45, PP_PRECENT50];

export const PP_INTERVAL_WEEKLY = {value: 'Weekly', label: 'Weekly'};
export const PP_INTERVAL_MONTHLY = {value: 'Monthly', label: 'Monthly'};
export const PP_INTERVAL_QUARTERLY = {value: 'Quarterly', label: 'Quarterly'};
export const PP_INTERVAL_MONTHS2 = {value: 'Months2', label: 'Every 2 Months'};
export const PP_INTERVAL_MONTHS4 = {value: 'Months4', label: 'Every 4 Months'};
export const PP_INTERVAL_MONTHS6 = {value: 'Months6', label: 'Every 6 Months'};

export const PP_INTERVAL = [PP_INTERVAL_WEEKLY, PP_INTERVAL_MONTHLY, PP_INTERVAL_QUARTERLY, PP_INTERVAL_MONTHS2, PP_INTERVAL_MONTHS4, PP_INTERVAL_MONTHS6];

export const PP_SET_TYPE_OPTION1_DC = {value: 'Option1_DC', label: 'Option One Debit Card'};
export const PP_SET_TYPE_OPTION1_CC = {value: 'Option1_CC', label: 'Option One Credit Card'};
export const PP_SET_TYPE_OPTION2_DC = {value: 'Option2_DC', label: 'Option Two Debit Card'};
export const PP_SET_TYPE_OPTION2_CC = {value: 'Option2_CC', label: 'Option Two Credit Card'};
export const PP_SET_TYPE_DC = {value: 'Option_DC', label: 'Debit Card'};
export const PP_SET_TYPE_CC = {value: 'Option_CC', label: 'Credit Card'};
export const PP_SET_TYPE_SO = {value: 'Option_SO', label: 'Standing Order'};
export const PP_SET_TYPE_OPTION_CHEQUE = {value: 'Option_Cheque', label: 'Cheque'};
export const PP_SET_TYPE_OPTION_CASH = {value: 'Option_Cash', label: 'Cash'};

export const PP_SET_TYPE = [PP_SET_TYPE_OPTION1_DC, PP_SET_TYPE_OPTION1_CC, PP_SET_TYPE_OPTION2_DC, PP_SET_TYPE_OPTION2_CC, PP_SET_TYPE_DC, PP_SET_TYPE_CC, PP_SET_TYPE_SO, PP_SET_TYPE_OPTION_CHEQUE, PP_SET_TYPE_OPTION_CASH];

export const UP_DOCUMENT_ADDED = "DOCUMENT_ADDED";

export const DOC_PAYMENT_PLAN = {name: 'PAYMENT_PLAN', text: 'Standard Payment Plan'};

// consent form related

export const OUTCOME_ConsentGiven = 'ConsentGiven';
export const OUTCOME_ConsentNotGiven = 'ConsentNotGiven';

// Template / Document Types

export const DOC_WORD = {name: 'WORD', text: 'Standard Patient Letter'};
export const DOC_IMAGE = {name: 'IMAGE', text: ''};
export const DOC_UPLOAD_MSWORD = {name: 'UPLOAD_MSWORD', text: ''};
export const DOC_UPLOAD_WORD = {name: 'UPLOAD_WORD', text: ''};
export const DOC_UPLOAD_XRAY_TIF = {name: 'UPLOAD_XRAY_TIF', text: ''};
export const DOC_UPLOAD_XRAY_JPG = {name: 'UPLOAD_XRAY_JPG', text: ''};
export const DOC_UPLOAD_XRAY_GIF = {name: 'UPLOAD_XRAY_GIF', text: ''};
export const DOC_UPLOAD_SCAN_TIF = {name: 'UPLOAD_SCAN_TIF', text: ''};
export const DOC_UPLOAD_SCAN_JPG = {name: 'UPLOAD_SCAN_JPG', text: ''};
export const DOC_UPLOAD_SCAN_GIF = {name: 'UPLOAD_SCAN_GIF', text: ''};
export const DOC_REFERRAL = {name: 'REFERRAL', text: 'Standard Referrer / Patient'};
export const DOC_PRESCRIPTION = {name: 'PRESCRIPTION', text: 'Standard Prescription'};
export const DOC_PDF = {name: 'PDF', text: ''};
export const DOC_TREATMENT_PLAN = {name: 'TREATMENT_PLAN', text: 'Standard Treatment Plan'};
export const DOC_ANY = {name: 'ANY', text: ''};
export const DOC_UPLOAD_SCAN_PDF = {name: 'UPLOAD_SCAN_PDF', text: ''};
export const DOC_SMS = {name: 'SMS', text: 'Adhoc SMS'};
export const DOC_APPOINTMENT_LETTER = {name: 'APPOINTMENT_LETTER', text: 'Standard Appointment Letter'};

export const DOC_CONSENT = {
    name: 'CONSENT',
    text: 'Patient Consent Form'
};

export const DOC_CONSENT_TEMPLATE = {
    name: 'CONSENT_TEMPLATE',
    text: 'Patient Consent Form Template'
};

export const HM_IMAGES_UPLOAD = {
    id: 'HM_IMAGES_UPLOAD',
    header: 'Upload Image(s)',
    message: 'Upload Image(s)'
};

export const HM_IMAGES_SELECT_ALL = {
    id: 'HM_IMAGES_SELECT_ALL',
    header: 'Select All',
    message: 'Select All'
};

export const HM_IMAGES_DESELECT_ALL = {
    id: 'HM_IMAGES_DESELECT_ALL',
    header: 'Deselect All',
    message: 'Deselect All'
};

export const HM_DOWNLOAD_SELECTED = {
    id: 'HM_DOWNLOAD_SELECTED',
    header: 'Download selected images',
    message: 'Download selected images'
};

export const HM_PatientImages = {
    id: 'HM_PatientImages',
    header: 'Patient Images',
    message: 'Patient Images'
};

export const HM_uploadPatImage = {
    id: 'HM_uploadPatImage',
    header: 'Upload Patient Image / XRay / PDF',
    message: 'Select and upload a patient image / xray / PDF'
};

export const HM_PORTAL_CONFIRM_GDPR_CONSENT = {
    id: 'HM_PORTAL_CONFIRM_GDPR_CONSENT',
    header: 'Confirm GDPR Consent',
    message: 'Are you sure you want to give consent to GDPR',
    icon: ICON_OK,
};

export const HM_PreviewPatientDocument = {
    id: 'HM_PreviewPatientDocument',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: ICON_SHOW
};

export const HM_PrintConsentDocument = {
    id: 'HM_PrintConsentDocument',
    header: 'Print Consent Document',
    message: 'Print Consent Document',
    icon: ICON_PRINT,
};

export const HM_PrintPatientDocument = {
    id: 'HM_PrintPatientDocument',
    header: 'Print Patient Document',
    message: 'Print Patient Document',
    icon: ICON_PRINT,
};

export const HM_PrintPaymentPlanDocument = {
    id: 'HM_PrintPaymentPlanDocument',
    header: 'Print Payment Plan',
    message: '',
    icon: ICON_PRINT
};

export const HM_PrintPresDocument = {
    id: 'HM_PrintPresDocument',
    header: 'Print Prescription',
    message: 'Print Patient Prescription',
    icon: ICON_PRINT,
};

export const HM_PrintReferralDocument = {
    id: 'HM_PrintReferralDocument',
    header: 'Print Referral Document',
    message: 'Print Patient Referral Document',
    icon: ICON_PRINT,
};

export const HM_PrintTreatmentPlanDocument = {
    id: 'HM_PrintTreatmentPlanDocument',
    header: 'Print Treatment Plan Document',
    message: 'Print Patient Treatment Plan Document',
    icon: ICON_PRINT,
};

export const HM_TABLET_CONFIRM_CONSENT = {
    id: 'HM_TABLET_CONFIRM_CONSENT',
    header: 'Confirm Consent',
    message: 'Are you sure you want to save these consent form(s)',
    icon: ICON_OK,
};

export const docTypeTemplate = (row) => {

    switch (row.type) {
        case DOC_WORD.name :
        case DOC_ANY.name :
            return <i className="fa fa-circle"/>;
        case DOC_PRESCRIPTION.name:
            return <i className="fa fa-circle"/>;
        case DOC_REFERRAL.name :
            return <i className="fa fa-circle"/>;
        case DOC_UPLOAD_MSWORD.name :
            return <i className="fa fa-circle"/>;
        case DOC_UPLOAD_WORD.name :
            return <i className="fa fa-circle"/>;
        case DOC_UPLOAD_XRAY_TIF.name :
        case DOC_UPLOAD_XRAY_JPG.name :
        case DOC_UPLOAD_XRAY_GIF.name :
        case DOC_UPLOAD_SCAN_TIF.name :
        case DOC_UPLOAD_SCAN_JPG.name :
        case DOC_UPLOAD_SCAN_GIF.name :
        case DOC_UPLOAD_SCAN_PDF.name :
        case DOC_IMAGE.name :
            return <i className="fa fa-circle"/>;
        case DOC_PDF.name :
            return <i className="fa fa-circle"/>;
        case DOC_CONSENT.name :
            return <i className="fa fa-circle"/>;
        case DOC_TREATMENT_PLAN.name :
            return <i className="fa fa-circle"/>;
        case DOC_SMS.name :
            return <i className="fa fa-circle"/>;
        default:
            return null;
    }
};

export const CM_DOCUMENTS_PAT = 'cmPatientDocuments';
export const CM_DOCUMENTS_TP = 'cmTpDocuments';
export const CM_DOCUMENTS_CONSENT = 'cmConsentFormDocuments';

export const HM_AddPassword = {
    id: 'HM_AddPassword',
    header: 'Patient Password',
    message: 'Set the patient password'
};

// Appointment related

export const APP_CREATED = 'CREATED';

// event types

export const DE_TYPE_ONLINE_BOOKING = {
    name: 'OnlineBooking',
    text: 'Online Booking',
    ordinal: 2,
    iconName: 'fa onlineBooking16'
};
export const DE_TYPE_VIDEO_CONF = {
    name: 'VideoConference',
    text: 'Video Conference',
    ordinal: 3,
    iconName: 'fa webCam16'
};

// portal related

export const HM_PORTAL_BOOK_APPOINTMENT = {
    id: 'HM_PORTAL_BOOK_APPOINTMENT',
    header: 'Book Appointment',
    message: 'Are you sure you want to book this appointment',
    icon: ICON_OK,
};

export const HM_PORTAL_BOOK_APPOINTMENT_NOTE = {
    id: 'HM_PORTAL_BOOK_APPOINTMENT_NOTE',
    header: 'Appointment Booking Note',
    message: 'Appointment Booking Note',
    icon: ICON_OK,
};

export const UP_ADD_DIARYEVENT = "ADD_DIARYEVENT";
export const UP_DEL_DIARYEVENT = "DEL_DIARYEVENT";
export const UP_DIARYEVENT = "UP_DIARYEVENT";
export const UP_DIARYEVENT_ALREADY_BOOKED = "DIARYEVENT_ALREADY_BOOKED";

// charge statuses

export const CH_INVOICE = 'CH_INVOICE';

export const EDI_CR_AssessAndReview = 'AssessAndReview';

export const EDI_CR_AssessAndRefuse = 'AssessAndRefuse';

export const EDI_CR_AssessAndFittedStacked = 'AssessAndFittedStacked';

export const EDI_CR_AssessAndFittedSubmit = 'AssessAndFittedSubmit';

export const PAY_TYPE_CASH = {value: 'CASH', label: 'Cash', abbrev: 'CS', ordinal: 0};
