import React from "react";
import moment from "moment";

import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {FileUpload} from "primereact/fileupload";
import {outputCurrency} from "./Client/Sections/OnChangeUtils";
import {
    RecallRemType,
    RRT_RECALL_EMAIL,
    RRT_RECALL_MOBILE,
    RRT_RECALL_SMS,
    RRT_RECALL_SNAILMAIL,
    RRT_RECALL_WORKPHONE, RRT_REMINDER_EMAIL, RRT_REMINDER_MOBILE, RRT_REMINDER_SMS, RRT_REMINDER_SNAILMAIL
} from "../Constants";
import _ from "lodash";

export const suppressBrowserCM = () => {
    document.addEventListener('contextmenu', e => {
        e.preventDefault()
    })
};

export const tb_boilerPlate = (callback, icon, tooltip, key) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-left">
            <Button tooltipOptions={{position: 'top'}}
                    tooltip={tooltip}
                    icon={icon}
                    onClick={(e) => {
                        callback(false)
                    }}>
            </Button>
        </div>
    )
};

export const tb_boilerPlateFU = (callback, icon, tooltip, key) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-left" id='toolbar-icons'>

            <FileUpload tooltipOptions={{position: 'top'}}
                        tooltip={tooltip}
                        icon={icon}
                        mode='basic'
                        onUpload={(event) => {
                            callback(false)
                        }}/>
        </div>
    )
};

export const tb_boilerPlateRight = (callback, icon, tooltip, key, normal) => {

    const className = normal === undefined ? "p-button-danger" : null;

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right">
            <Button tooltipOptions={{position: 'top'}}
                    tooltip={tooltip}
                    className={className}
                    icon={icon}
                    onClick={() => {
                        callback(false)
                    }}/>
        </div>
    )
};

export const tb_boilerPlateRight2 = (callback, icon, tooltip, key, disabled) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-right">
            <Button tooltipOptions={{position: 'top'}}
                    tooltip={tooltip}
                    icon={icon}
                    disabled={disabled}
                    onClick={(e) => {
                        callback(false)
                    }}>
            </Button>
        </div>
    )
};

export const tb_boilerPlateRightCurrency = (key, value, disabled) => {

    let style = {textAlign: 'right'};
    let styledValue = value;
    if (value < 0.0) {
        style.color = 'red';
        styledValue = styledValue * -1.0;
    }
    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons' style={{textAlign: 'right', paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={outputCurrency(styledValue)}
                       style={style}
                       disabled={disabled}
            />
        </div>
    )
};

export const getDateDiff = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);
        out.push(diff);
    });
    return out;
};

export const getFormattedDateDiff = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);
        out.push(diff + ` ${interval}`);
    });
    return out.join(', ');
};

export const getFormattedDateDiffShort = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);

        if (interval === 'years') {
            out.push(diff + ` Ys`);
        } else {
            out.push(diff + ` Ms`);
        }
    });
    return out.join(', ');
};

export const tb_boilerPlateRightAge = (key, dob, disabled) => {

    const age = getFormattedDateDiff(dob, new moment());

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons' style={{paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={age}
                       disabled={disabled}
            />
        </div>
    )
};

export const tb_boilerPlateRightText = (key, text, disabled) => {

    return (
        <div key={`key__${key}`} className="p-toolbar-group-right" id='toolbar-icons' style={{paddingRight: '5px', paddingTop: '3px'}}>
            <InputText value={text}
                       disabled={disabled}
            />
        </div>
    )
};

export const tb_boilerPlate2 = (callback, icon, disabled, tooltip, key) => {
    return (
        <div key={`key__${key}`} className="p-toolbar-group-left">
            <Button tooltipOptions={{position: 'top'}}
                    tooltip={tooltip}
                    icon={icon}
                    disabled={disabled}
                    onClick={(e) => {
                        callback()
                    }}>
            </Button>
        </div>
    )
};

export const userTemplate = (user) => {

    if (user === undefined || user === null) {
        return '';
    } else {
        const title = user.title === null ? '' : user.title.abbreviation;
        return `${title} ${user.firstName} ${user.lastName}`.trim();
    }
};

export const roleTemplate = (user) => {

    if (user === undefined || user === null) {
        return '';
    } else {
        return user.myRole.description;
    }
}

export const contactReminderMethodTextTemplate = ({reminderMethod, contactMethod}) => {

    const source = reminderMethod === undefined ? contactMethod : reminderMethod;
    const ordinal = _.find(RecallRemType, (type) => type.name === source).ordinal;
    return contactMethodTemplate(ordinal);
};

export const contactMethodTemplate = (method) => {

    let imageName = '';

    switch (method) {
        case RRT_REMINDER_EMAIL.ordinal :
        case RRT_RECALL_EMAIL.ordinal :
            imageName = RRT_REMINDER_EMAIL.icon;
            break;
        case RRT_REMINDER_MOBILE.ordinal :
        case RRT_RECALL_MOBILE.ordinal :
            imageName = RRT_REMINDER_MOBILE.icon;
            break;
        case RRT_REMINDER_SMS.ordinal:
        case RRT_RECALL_SMS.ordinal:
            imageName = RRT_REMINDER_SMS.icon;
            break;
        case RRT_REMINDER_SNAILMAIL.ordinal:
        case RRT_RECALL_SNAILMAIL.ordinal:
            imageName = RRT_REMINDER_SNAILMAIL.icon;
            break;
        default :
            imageName = RRT_RECALL_WORKPHONE.icon;
            break;
    }
    return <div style={{fontSize: '17px'}} className={`${imageName}`}/>;
}

export const contactRecallMethodTextTemplate = (row) => {

    const method = row.prss === undefined ? row.method : row.prss.recallMethod;
    const ordinal = _.find(RecallRemType, type => type.name === method).ordinal;
    return contactRecallMethodTemplate(ordinal);
}

export const contactRecallMethodTemplate = (method) => {

    let imageName = '';

    switch (method) {
        case RRT_RECALL_EMAIL.ordinal :
            imageName = RRT_RECALL_EMAIL.icon;
            break;
        case RRT_RECALL_MOBILE.ordinal :
            imageName = RRT_RECALL_MOBILE.icon;
            break;
        case RRT_RECALL_SMS.ordinal:
            imageName = RRT_RECALL_SMS.icon;
            break;
        case RRT_RECALL_SNAILMAIL.ordinal:
            imageName = RRT_RECALL_SNAILMAIL.icon;
            break;
        default :
            imageName = RRT_RECALL_WORKPHONE.icon;
            break;
    }
    return <div style={{fontSize: '17px'}} className={`${imageName}`}/>;
}
