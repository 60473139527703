import React from 'react';

import {Panel} from 'primereact/components/panel/Panel';
import {dropDown, inputText} from './Sections/OnChangeUtils';

export const NextOfKin = (props) => {

    let key = Math.floor(Math.random() * 1000);

    return (
        <Panel header={props.header}>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-2">
                    <label htmlFor="title">Title</label>
                </div>
                <div className="p-col-4">
                    {dropDown(1, props, 'titles', 'title', 'abbreviation', false, false, true)}
                </div>
                <div className="p-col-2">
                    <label htmlFor="relationship">Relationship</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'relationship', 'Relationship', false, false)}
                </div>

                <div className="p-col-2">
                    <label htmlFor="firstName">First Name</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'firstName', 'First Name', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor="lastName">Last Name</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'lastName', 'Last Name', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='line1'>Line 1</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'contactDetails.addressLine1', 'Line 1', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='line2'>Line 2</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'contactDetails.addressLine2', 'Line 2', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='city'>City</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'contactDetails.addressLine3', 'City', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='county'>County</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'contactDetails.county', 'County', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='postcode'>Postcode</label>
                </div>
                <div className="p-col-10">
                    {inputText(props, 'contactDetails.postcode', 'Postcode', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor="mainCountry">Country</label>
                </div>
               <div className="p-col-4">
                    {dropDown(key+=1, props, 'countries', 'contactDetails.country', 'countryName', false, false)}
                </div>
                <div className="p-col-2">
                    <label htmlFor='number'>Contact Number</label>
                </div>
                <div className="p-col-4">
                    {inputText(props, 'mobile', 'Contact Number', false, false)}
                </div>
            </div>
        </Panel>
    );
};
