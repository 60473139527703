import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import 'nanoscroller';
import classNames from 'classnames';

import 'primereact/resources/primereact.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'nanoscroller/bin/css/nanoscroller.css';

import './App.scss';

import {
    SM_404_CLOUD_LAB_PAGE_ERROR,
    SM_404_CLOUD_PAGE_ERROR,
    SM_404_PAGE_ERROR,
    SM_500_CLOUD_PAGE_ERROR,
    SM_CLIENT_PW_FORGOT,
    SM_CLOUD_PORTAL_LOGGED_IN,
    SM_CLOUD_PORTAL_LOGIN
} from "./actions/stateManagement";

import ClientLoggedIn from "./components/Client/ClientLoggedIn";
import NotFound from "./components/NotFound";
import CloudNotFound from "./components/CloudNotFound";
import ClientResetPassword from "./components/Client/ClientResetPassword";
import Wizard2 from "./components/Client/Wizard2";
import CloudLabNotFound from "./components/CloudLabNotFound";
import NotActive from "./components/NotActive";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
    }

    render() {

        let layoutClassName = classNames('login-body');

        return (

            <div className={layoutClassName}>

                <div className="layout-main">
                    <Switch>
                        <Route path={SM_CLOUD_PORTAL_LOGIN.route} exact component={Wizard2}/>
                        <Route path={SM_CLIENT_PW_FORGOT.route} component={ClientResetPassword}/>

                        <Route path={SM_CLOUD_PORTAL_LOGGED_IN.route} exact component={ClientLoggedIn}/>

                        <Route path={SM_500_CLOUD_PAGE_ERROR.route} exact component={NotActive}/>

                        <Route path={SM_404_CLOUD_PAGE_ERROR.route} exact component={CloudNotFound}/>
                        <Route path={SM_404_CLOUD_LAB_PAGE_ERROR.route} exact component={CloudLabNotFound}/>
                        <Route path={SM_404_PAGE_ERROR.route} exact component={NotFound}/>

                        <Route path='*' exact component={NotFound}/>
                    </Switch>
                </div>
            </div>);
    }
}

export default App;
