import {SM_USER_CAPABILITIES} from "../stateManagement";
import {ac} from "../../index";
import {RES_getUsersAndCapabilities} from "../users";
import _ from "lodash";

export const saveUserCapabilities = (dispatch, sm, clearAfterSave) => {

    // if the capabilities are defined and need saving do so ....
    if (sm[`${SM_USER_CAPABILITIES.id}`] !== undefined) {

        const {users} = sm[`${SM_USER_CAPABILITIES.id}`].data;

        const updatedUsers = _.filter(users, user => user.updated);
        const returns = [];

        updatedUsers.forEach(user => {


            ac.fetchClient().post(`${RES_getUsersAndCapabilities.SAVE_USERS.url}/?mcId=${ac.getMcId()}`, {
                params: {
                    psr: user.psr, capabilities: user.capabilities
                }
            })
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedUsers.length) {
                        dispatch({type: RES_getUsersAndCapabilities.SAVE_USERS.action, payload: true})
                    }
                });

        });
    }
};

export const saveRoleCapabilities = (dispatch, sm, clearAfterSave) => {

    // if the capabilities are defined and need saving do so ....
    if (sm[`${SM_USER_CAPABILITIES.id}`] !== undefined) {

        const {roles} = sm[`${SM_USER_CAPABILITIES.id}`].data;

        const updatedRoles = _.filter(roles, role => role.updated);
        const returns = [];

        updatedRoles.forEach(role => {

            const _role = {role: role.role, capabilities: role.capabilities};

            ac.fetchClient().post(`${RES_getUsersAndCapabilities.SAVE_ROLES.url}/?mcId=${ac.getMcId()}`, _role)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedRoles.length) {

                        if (clearAfterSave) {
                            dispatch({type: RES_getUsersAndCapabilities.CLEAR.action})
                        } else {
                            dispatch({type: RES_getUsersAndCapabilities.SAVE_ROLES.action, payload: true})
                        }
                    }
                });

        });
    }
};
