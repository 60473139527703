import _ from 'lodash';
import * as Actions from '../actions/index';
import {SM_REFERRER_DATA, SM_REFERRER_PATIENT_DATA} from "../actions/stateManagement";
import {standardReturn} from "./users";

const initialState = {

    // Referrer data
};

export const referrers = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Referrer Related
            case Actions.RECEIVE_REFERRER_DETAILS:

                if (!action.loaded) {

                    const referrerDataLoadedId = `${SM_REFERRER_DATA.loaded}_${action.id}`;
                    const referrerDataId = `${SM_REFERRER_DATA.id}_${action.id}`;

                    return {
                        ...state,

                        [referrerDataLoadedId]: true,
                        [referrerDataId]: action.payload,
                    }
                } else return standardReturn(state, action);

            case Actions.RECEIVE_REFERRER_PATIENTS:

                const referrerPatientLoadedId = `${SM_REFERRER_PATIENT_DATA.loaded}_${action.id}`;
                const referrerPatientId = `${SM_REFERRER_PATIENT_DATA.id}_${action.id}`;

                return {
                    ...state,

                    [referrerPatientLoadedId]: true,
                    [referrerPatientId]: action.payload,
                };

            case Actions.RECEIVE_REFERRER_ADD:

                return {
                    ...state,
                    result: action.payload.result,
                    referrer: action.payload.referrer,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};