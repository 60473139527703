import moment from 'moment';
import _ from 'lodash';

import {
    ADD,
    ADDBACK,
    ADDBACK_MULTI,
    DELETE,
    DOC_IMAGE,
    fullColorHex,
    MOVING_IN_DIARY,
    SR_CLINIC,
    SR_NOTAVAILABLE,
    TASK_STATUS_ACTIVE,
    UP_ADD_DIARYEVENT,
    UP_DEL_DIARYEVENT,
    UP_DIARYEVENT,
    UP_DOCUMENT_USE_AS_PHOTO,
    UP_NEWTASK,
    UP_TASKUPDATE,
    UPDATE
} from "../Constants";
import * as Actions from "../actions";
import {RECEIVE_APP_PATIENT_SEARCH} from "../actions";
import {RES_CLINICIAN_DIARY, RES_PRACTICE_DIARY, RES_PRACTICE_WEEK_DIARY} from "../actions/diary";
import {ac} from "../index";

const initialState = {

    // Diary data

    currentDate: new Date(),
    typesLoaded: false,

    headerLoaded: false,
    movingEventsLoaded: false,
    resourcesLoaded: false,

    eventsLoaded: false,
    cliniciansEventsLoaded: false,
    weekEventsLoaded: false,

    diaryHeader: [],
    cliniciansHeader: [],
    weekHeader: [],

    diaryResources: [],
    clinicianResources: [],
    weekResources: [],

    events: [],
    cliniciansEvents: [],
    weekEvents: [],

    diaryEventTypes: [],
    movingEvents: [],
    diaryTasks: [],

    newDiaryEvent: null,

    matchedPatients: [],

    providerColourScheme: false,

    sortOrder: [],

    daysActivePopUpsLoaded: false,
    daysActivePopUps: [],

    videoAppointmentsLoaded: false,
    videoAppointments: [],
};

export const diary = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Diary Related

            case Actions.DIARY_SORT_ORDER:

                return {
                    ...state,
                    sortOrder: action.payload
                };

            case Actions.REQUEST_VIDEO_APPOINTMENTS:

                return {
                    ...state,
                    videoAppointmentsLoaded: false,
                    videoAppointments: [],
                };

            case Actions.RECEIVE_VIDEO_APPOINTMENTS:

                return {
                    ...state,
                    videoAppointmentsLoaded: true,
                    videoAppointments: action.payload,
                };

            case Actions.RECEIVE_DIARY_EVENT_TYPES:

                return {
                    ...state,
                    typesLoaded: true,
                    diaryEventTypes: action.payload,
                };

            case Actions.RECEIVE_DIARY_RESOURCES:

                return {
                    ...state,
                    resourcesLoaded: true,
                    currentDate: action.date,
                    diaryResources: action.payload,
                };

            case RES_PRACTICE_WEEK_DIARY.CLEAR:

                return {
                    ...state,
                    weekEvents: [],
                    weekEventsLoaded: false,
                    weekHeader: {},
                    weekResources: [],
                };

            case RES_PRACTICE_DIARY.POPUPS.request: {

                return {
                    ...state,
                    daysActivePopUpsLoaded: false,
                    daysActivePopUps: [],
                };
            }

            case RES_PRACTICE_DIARY.POPUPS.receive: {

                return {
                    ...state,
                    daysActivePopUpsLoaded: true,
                    daysActivePopUps: action.payload,
                };
            }

            case RES_CLINICIAN_DIARY.CLEAR.action:

                const clinicianState = {...state};

                delete clinicianState[`cliniciansEvents_${action.payload}`];
                delete clinicianState[`cliniciansHeader_${action.payload}`];
                delete clinicianState[`clinicianResources_${action.payload}`];
                delete clinicianState[`unscheduledResources_${action.payload}`];
                delete clinicianState[`providerColourScheme_${action.payload}`];

                return clinicianState;

            case Actions.REQUEST_CLINICIAN_DIARY_EVENTS:

                return {
                    ...state,
                    selectedClinician: action.clinicianId,
                };

            case Actions.RECEIVE_ADD_DIARYEVENT:

                return {
                    ...state,
                    newDiaryEvent: action.payload,
                };

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Diary Related

            case Actions.WSM_APPOINTMENTS: {

                switch (action.payload.function) {

                    case MOVING_IN_DIARY: {

                        let dao = action.payload.content;

                        // deal with clinician diary
                        const clinicianId = dao.providerId;
                        const clinicianLoaded = state[`cliniciansEventsLoaded_${clinicianId}`];
                        const cliniciansEvents = state[`cliniciansEvents_${clinicianId}`];

                        if (clinicianLoaded) {
                            const index = _.findIndex(cliniciansEvents, (target) => dao.id === target.id);

                            if (index !== -1) {
                                cliniciansEvents.splice(index, 1);
                            }
                        }

                        // deal with practice diary
                        const events = [...state.events];
                        const index = _.findIndex(events, (target) => dao.id === target.id);

                        // if this is the originating client we will already have the appointment. Otherwise add it in.
                        if (index !== -1) {
                            events.splice(index, 1);
                            return {
                                ...state,
                                eventsLoaded: true,
                                events,
                                [`cliniciansEventsLoaded_${clinicianId}`]: clinicianLoaded,
                                [`cliniciansEvents_${clinicianId}`]: cliniciansEvents,
                            };
                        } else {
                            return state;
                        }
                    }
                    case ADDBACK:
                    case ADD:

                        let dao = action.payload.content;

                        // deal with clinician diary
                        const clinicianId = dao.providerId;
                        const clinicianLoaded = state[`cliniciansEventsLoaded_${clinicianId}`];
                        const cliniciansEvents = state[`cliniciansEvents_${clinicianId}`];

                        if (clinicianLoaded) {
                            const index = _.findIndex(cliniciansEvents, (target) => dao.id === target.id);

                            // if this is the originating client we will already have the appointment. Otherwise add it in.
                            if (index === -1) {

                                const event = convertAppointmentDAOtoEvent(dao);
                                cliniciansEvents.push(event);
                            }
                        }

                        // deal with practice diary
                        const events = [...state.events];
                        const index = _.findIndex(events, (target) => dao.id === target.id);

                        // if this is the originating client we will already have the appointment. Otherwise add it in.
                        if (index === -1) {

                            const event = convertAppointmentDAOtoEvent(dao);
                            events.push(event);
                        }
                        return {
                            ...state,
                            eventsLoaded: true,
                            events,
                            [`cliniciansEventsLoaded_${clinicianId}`]: clinicianLoaded,
                            [`cliniciansEvents_${clinicianId}`]: cliniciansEvents,
                        };

                    case UPDATE: {

                        const events = [...state.events];

                        let dao = action.payload.content;

                        const appointment = convertAppointmentDAOtoEvent(dao);
                        const index = _.findIndex(events, (target) => dao.id === target.id);

                        events[index] = appointment;
                        return {
                            ...state,
                            eventsLoaded: true,
                            events
                        }
                    }

                    case DELETE: {

                        const events = [...state.events];

                        let dao = action.payload.content;

                        const index = _.findIndex(events, (target) => dao === parseInt(target.id, 10));
                        events.splice(index, 1);
                        return {
                            ...state,
                            eventsLoaded: true,
                            events
                        }
                    }

                    case ADDBACK_MULTI:
                        return {
                            ...state,
                        };

                    default:
                        break;
                }
                break;
            }

            case Actions.WSM_UPDATES: {

                const updateState = {...state};

                switch (action.payload.function) {

                    case UP_ADD_DIARYEVENT: {

                        const event = action.payload.content; // extract diary event
                        const diaryEvent = convertDiaryEventDAOtoEvent(event);
                        updateState.events.push(diaryEvent);

                        const cliniciansEventsLoaded = updateState[`cliniciansEventsLoaded_${event.eventForId}`];

                        if (cliniciansEventsLoaded) {
                            updateState[`cliniciansEvents_${event.eventForId}`].push(diaryEvent);
                        }
                        break;
                    }

                    case UP_DIARYEVENT: {

                        const diaryEvent = convertDiaryEventDAOtoEvent(action.payload.content);
                        const index = _.findIndex(updateState.events, (target) => diaryEvent.id === target.id);
                        updateState.events[index] = diaryEvent;

                        const {content} = action.payload;
                        const targetId = content.eventForId !== undefined ? content.eventForId : content.userId;

                        const cliniciansEventsLoaded = updateState[`cliniciansEventsLoaded_${targetId}`];

                        if (cliniciansEventsLoaded) {
                            const index = _.findIndex(updateState[`cliniciansEvents_${targetId}`], (target) => action.payload.content.id === target.id);
                            updateState[`cliniciansEvents_${targetId}`][index] = diaryEvent;
                        }

                        break;
                    }

                    case UP_DEL_DIARYEVENT: {

                        const index = _.findIndex(updateState.events, (target) => action.payload.content.id === target.id);
                        updateState.events.splice(index, 1);

                        const cliniciansEventsLoaded = updateState[`cliniciansEventsLoaded_${action.payload.content.eventFor.id}`];
                        if (cliniciansEventsLoaded) {
                            const index = _.findIndex(updateState[`cliniciansEvents_${action.payload.content.eventFor.id}`], (target) => action.payload.content.id === target.id);
                            updateState[`cliniciansEvents_${action.payload.content.eventFor.id}`].splice(index, 1);
                        }
                        break;
                    }
                    case UP_NEWTASK:

                        updateState.diaryTasks.push(action.payload.content);
                        break;
                    case UP_TASKUPDATE:

                        const index = _.findIndex(updateState.diaryTasks, (target) => action.payload.content.id === target.id);

                        if (action.payload.content.status !== TASK_STATUS_ACTIVE) {
                            updateState.diaryTasks.splice(index, 1);
                        }
                        break;
                    default:
                        break;

                    case UP_DOCUMENT_USE_AS_PHOTO: {
                        const patientId = parseInt(action.payload.content[0], 10);
                        const patientImageFileName = action.payload.content[1];

                        const events = [...state.events];

                        const index = _.findIndex(events, (target) => patientId === target.patientId);
                        if (index > -1) {
                            events[index].patientImageFileName = `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${patientImageFileName}/${DOC_IMAGE.name}`;
                            events[index].patientThumbFileName = `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${patientImageFileName}`;
                        }
                        return {
                            ...state,
                            events
                        };
                    }
                }

                return {
                    ...updateState,
                }
            }

            case RECEIVE_APP_PATIENT_SEARCH:

                return {
                    ...state,
                    matchedPatients: action.payload
                };

            case Actions.RECEIVE_ADD_APPOINTMENT: {

                const events = [...state.events];
                let event = convertAppointmentDAOtoEvent(action.payload);

                const index = _.findIndex(events, (target) => event.id === target.id);

                // if this is the originating client we will already have the appointment. Otherwise add it in.
                if (index === -1) {
                    events.push(event);
                }

                return {
                    ...state,
                    eventsLoaded: true,
                    events
                };
            }

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return {
                    ...state,
                };
        }
    } catch (error) {
        return state;
    }
};

export const convertAppointmentDAOtoEvent = (dao) => {

    return {
        id: dao.id,
        resourceId: dao.providerId,
        userId: dao.providerId,
        patientId: dao.patientId,
        bookedById: dao.bookedById,
        title: dao.shortDescription,
        start: moment(dao.start).toDate(),
        end: moment(dao.end).toDate(),
        bookedOn: new Date(dao.bookedOn),
        comment: dao.description,
        patientFullname: `${dao.title} ${dao.patientFirstName} ${dao.patientLastName}`.trim(),
        patientImageFileName: `${ac.getIMAGE_SERVER_API()}/openPatientDocument/${ac.getMcId()}/${dao.patientImageFileName}/${DOC_IMAGE.name}`,
        patientThumbFileName: `${ac.getIMAGE_SERVER_API()}/openThumbnail/${ac.getMcId()}/${dao.patientImageFileName}`,
        firstName: dao.patientFirstName,
        lastName: dao.patientLastName,
        color: fullColorHex(dao.red, dao.green, dao.blue).toUpperCase(),
        gender: dao.gender,
        female: dao.gender === 1,
        type: {id: dao.type},
        attended: dao.attended,
        confirmed: dao.confirmed,
        workRequired: dao.workRequired,
        discriminator: SR_CLINIC,
        source: dao,
    };
};

export const convertAppointmentWeekViewDAOtoEvent = (usersShort, dao) => {

    if (usersShort === null)
        return;

    const user = _.find(usersShort, user => user.username === dao.username);

    if (user === undefined)
        return;

    return {
        id: dao.id,
        resourceId: user.id,
        userId: user.id,
        patientId: dao.patientId,
        start: new Date(dao.start),
        end: new Date(dao.end),
        comment: dao.summary,
        patientFullname: `${dao.abbreviation} ${dao.firstName} ${dao.lastName}`.trim(),
        firstName: dao.firstName,
        lastName: dao.lastName,
        color: fullColorHex(user.red, user.green, user.blue).toUpperCase(),
        type: {id: dao.type},
        discriminator: SR_CLINIC,
        source: dao,
    };
};

const convertDiaryEventDAOtoEvent = (dao) => {

    return {
        id: dao.id,
        resourceId: dao.eventForId,
        userId: dao.eventForId,
        title: dao.type.title,
        start: new Date(dao.start),
        end: new Date(dao.end),
        comment: dao.note,
        color: fullColorHex(dao.type.red, dao.type.green, dao.type.blue).toUpperCase(),
        type: dao.type,
        discriminator: SR_NOTAVAILABLE,
        source: dao,
    };
};
