import * as Actions from './index';
import {ac} from '../index'

const baseURL = '/Utils';

export const RES_getDropDowns = {url: `dropDowns`, request: Actions.REQUEST_DROPDOWNS, receive: Actions.RECEIVE_DROPDOWNS};

export const getDropDowns = ({url, request, receive}) => {

    return (dispatch) => {

            ac.fetchClient().get(`${baseURL}/${url}?mcId=${ac.getMcId()}`)
                .then(res => {
                    dispatch({type: receive, payload: res.data})
                });
            dispatch({type: request, payload: false});
    }
};
