import _ from 'lodash';
import {standardReturn} from "./users";
import {
    RES_PREFERENCES_ACS,
    RES_PREFERENCES_EMAIL,
    RES_PREFERENCES_HOURS,
    RES_PREFERENCES_NHS,
    RES_PREFERENCES_PDS,
    RES_PREFERENCES_RAR,
} from "../actions/preferences";
import * as Actions from "../actions";

const initialState = {

    practiceDetailsLoaded: false,
    practiceDetails: [],

    reportTextsLoaded: false,
    reportTexts: [],

    patientConsentFormsLoaded: false,
    patientConsentForms: [],

    contractsLoaded: false,
    contracts: [],

    locationsLoaded: false,
    locations: [],
};

export const preferences = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case RES_PREFERENCES_PDS.GET.request:
            case RES_PREFERENCES_ACS.GET.request:
            case RES_PREFERENCES_EMAIL.GET.request:
            case RES_PREFERENCES_HOURS.GET.request:
            case RES_PREFERENCES_RAR.GET.request:

                return {
                    ...state,
                    practiceDetailsLoaded: false,
                };

            case RES_PREFERENCES_PDS.GET.receive:
            case RES_PREFERENCES_ACS.GET.receive:
            case RES_PREFERENCES_EMAIL.GET.receive:
            case RES_PREFERENCES_HOURS.GET.receive:
            case RES_PREFERENCES_RAR.GET.receive:

                if (!action.loaded) {

                    return {
                        ...state,
                        practiceDetailsLoaded: true,
                        practiceDetails: action.payload,
                    }
                } else return standardReturn(state, action);

            case RES_PREFERENCES_PDS.SAVE.action:
            case RES_PREFERENCES_ACS.SAVE.action:
            case RES_PREFERENCES_EMAIL.SAVE.action:
            case RES_PREFERENCES_HOURS.SAVE.action:
            case RES_PREFERENCES_RAR.SAVE.action:

                return {
                    ...state,
                };

            case RES_PREFERENCES_NHS.GET.request:

                return {
                    ...state,
                    contractsLoaded: false,
                };

            case RES_PREFERENCES_NHS.GET.receive:

                if (!action.loaded) {

                    return {
                        ...state,
                        contractsLoaded: true,
                        contracts: action.payload,
                    }
                } else return standardReturn(state, action);

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};

