import React, {Component} from 'react';
import _ from "lodash";
import {ac} from '../../../index'

import {Button} from 'primereact/components/button/Button';
import {Dialog} from 'primereact/components/dialog/Dialog';
import {FileUpload} from 'primereact/components/fileupload/FileUpload';
import {baseURL} from "../../../actions/personal";
import {ICON_CANCEL} from "../../../Constants";

export default class UploadPatientDocument extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onBeforeSend = this.onBeforeSend.bind(this);
        this.onBeforeUpload = this.onBeforeUpload.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props !== prevProps) {
        }
    }

    onChange(event) {

        const state = {...this.state};
        _.set(state, event.owner, event.value);

        this.setState(state);
    }

    onBeforeUpload(event) {

        const data = {
            mcId: ac.getMcId(),
            patientId: this.props.patientId,
            userId: this.props.loginId,
            notes: this.state.notes,
            uploadedByPatient: this.props.uploadedByPatient
        };
        const dataStr = JSON.stringify(data);

        event.formData.append('patientDetails', new Blob(), dataStr);
    }

    onBeforeSend(event) {

        event.xhr.setRequestHeader('Authorization', ac.getAuthToken());
    }

    onHide() {
        this.props.onHideDialog(this.props.dialogId);
    }

    render() {

        const footer = (
            <div>
                <Button label="Close"
                        icon={ICON_CANCEL}
                        onClick={this.onHide}/>
            </div>
        );

        return (
            <Dialog header={this.props.header}
                    footer={footer}
                    visible={true}
                    modal={true}
                    responsize={true}
                    resizable={true}
                    blockScroll={true}
                    onHide={this.onHide}>

                <div id="detailPanel">

                    <FileUpload tooltipOptions={{position: 'top'}}
                                mode='advanced'
                                multiple={true}
                                name={'files'}
                                url={`https://${ac.getBASERESTURL()}${baseURL}/uploadFiles`}
                                maxFileSize={20000000}
                                accept={this.props.access}
                                onBeforeUpload={this.onBeforeUpload}
                                onBeforeSend={this.onBeforeSend}
                                onUpload={this.props.onFileUploaded}
                                onError={this.props.onFileUploadError}/>
                </div>
            </Dialog>
        )
    }
};
