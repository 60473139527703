import * as Actions from '../actions/index';
import _ from 'lodash';
import {
    RES_getProviderPerformers, RES_NHS_MANAGEMENT_CLAIM, RES_NHS_MANAGEMENT_CLAIMS,
    RES_NHS_MANAGEMENT_REJECTED, RES_NHS_MANAGEMENT_REMOVED, RES_NHS_MANAGEMENT_SCHEDULED,
    RES_NHS_MANAGEMENT_SUBMITTED
} from "../actions/nhsManagement";
import {RES_NHS_MANAGEMENT_STACKED} from "../actions/nhsManagement";

const initialState = {

    providerPerformersLoaded: false,
    providerPerformers: [],

    stackedLoaded: false,
    stacked: [],

    submittedLoaded: false,
    submitted: [],

    rejectedLoaded: false,
    rejected: [],

    removedLoaded: false,
    removed: [],

    scheduledLoaded: false,
    scheduled: [],

    patientLoaded: false,
    patient: null,

    nhsDetailsLoaded: false,
    nhsDetails: null,

    claimLoaded: false,
    claim: null,
};

export const nhsManagement = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case RES_getProviderPerformers.GET.receive:

                return {
                    ...state,
                    providerPerformersLoaded: true,
                    providerPerformers: action.payload,
                };

            case RES_NHS_MANAGEMENT_STACKED.GET.request:
                return {
                    ...state,
                    stackedLoaded: false,
                    stacked: [],
                };

            case RES_NHS_MANAGEMENT_SUBMITTED.GET.request:
                return {
                    ...state,
                    submittedLoaded: false,
                    submitted: [],
                };

            case RES_NHS_MANAGEMENT_REJECTED.GET.request:
                return {
                    ...state,
                    rejectedLoaded: false,
                    rejected: [],
                };

            case RES_NHS_MANAGEMENT_REMOVED.GET.request:
                return {
                    ...state,
                    removedLoaded: false,
                    removed: [],
                };

            case RES_NHS_MANAGEMENT_SCHEDULED.GET.request:
                return {
                    ...state,
                    scheduledLoaded: false,
                    scheduled: [],
                };

            case RES_NHS_MANAGEMENT_STACKED.GET.receive:

                return {
                    ...state,
                    stackedLoaded: true,
                    stacked: action.payload,
                };

            case RES_NHS_MANAGEMENT_SUBMITTED.GET.receive:

                return {
                    ...state,
                    submittedLoaded: true,
                    submitted: action.payload,
                };

            case RES_NHS_MANAGEMENT_REJECTED.GET.receive:

                return {
                    ...state,
                    rejectedLoaded: true,
                    rejected: action.payload,
                };

            case RES_NHS_MANAGEMENT_REMOVED.GET.receive:

                return {
                    ...state,
                    removedLoaded: true,
                    removed: action.payload,
                };

            case RES_NHS_MANAGEMENT_SCHEDULED.GET.receive:

                return {
                    ...state,
                    scheduledLoaded: true,
                    scheduled: action.payload,
                };

            case RES_NHS_MANAGEMENT_CLAIM.PATIENT.request:

                return {
                    ...state,
                    patientLoaded: false,
                    patient: null,
                };

            case RES_NHS_MANAGEMENT_CLAIM.PATIENT.receive:

                return {
                    ...state,
                    patientLoaded: true,
                    patient: action.payload,
                };

            case RES_NHS_MANAGEMENT_CLAIM.NHS_DETAILS.request:

                return {
                    ...state,
                    nhsDetailsLoaded: false,
                    nhsDetails: null,
                };

            case RES_NHS_MANAGEMENT_CLAIM.NHS_DETAILS.receive:

                return {
                    ...state,
                    nhsDetailsLoaded: true,
                    nhsDetails: action.payload,
                };

            case RES_NHS_MANAGEMENT_CLAIM.GET.request:

                return {
                    ...state,
                    claimLoaded: false,
                    claim: null,
                };

            case RES_NHS_MANAGEMENT_CLAIM.GET.receive:

                return {
                    ...state,
                    claimLoaded: true,
                    claim: action.payload,
                };

            case RES_NHS_MANAGEMENT_CLAIMS.SUBMIT.request:

                return {
                    ...state,
                    token: action.payload,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};