import React from 'react';
import {PDFBaseComponent} from "../../PDFBaseComponent";
import {getPDFDocument, RES_PATIENT_DOCUMENTS} from "../../../actions/personal";
import {setState, SM_PATIENT_DOCUMENT_PDF} from "../../../actions/stateManagement";
import {ProgressBar} from "primereact/progressbar";
import {TextAccountsInCredit} from "../Messages";
import {connect} from "react-redux";

class ConnectedPatientUploadedPDF extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                stateManagementId: props.patientDataId,
                pdfType: SM_PATIENT_DOCUMENT_PDF.id,
                numPages: null,
                PDFLoaded: false,
                pdfBuffer: [],
            }
        }
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (this.props.message !== prevProps.message) {

            switch (this.props.message.type) {

                case RES_PATIENT_DOCUMENTS.PDF.receive:
                    this.setState({
                        pdfBuffer: this.props.pdfBuffer,
                        PDFLoaded: true
                    }, () => {
                        this.props.setState(this.state.stateManagementId, this.state);
                    });
                    break;
                default:
                    break;
            }
        }
    }

    render() {

        if (!this.state.PDFLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (
            <div className="p-col-12 p-lg-12">

                {this.createPDFToolBar(TextAccountsInCredit, this.props.options.filename)}
                {this.createPDFContainer(this.props.options.visibleName)}
            </div>
        )
    }

    componentWillUnmount() {
        this._componentWillUnmount(RES_PATIENT_DOCUMENTS.CLEAR);
    }
}

const mapStateToProps = (state, ownProps) => {

    const loadedIndex = `${SM_PATIENT_DOCUMENT_PDF.loaded}_${ownProps.options.docId}`;
    const index = `${SM_PATIENT_DOCUMENT_PDF.id}_${ownProps.options.docId}`;

    return {
        message: state.stateManagement.message,

        PDFLoaded: state.patients[loadedIndex],
        pdfBuffer: state.patients[index],

        patientDataId: index,

        currentState: state.stateManagement[index],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getReport: (params) => dispatch(getPDFDocument(RES_PATIENT_DOCUMENTS.PDF, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    };
};

const PatientUploadedPDF = connect(mapStateToProps, mapDispatchToProps)(ConnectedPatientUploadedPDF);

export default PatientUploadedPDF;
