import _ from 'lodash';
import {SM_patientChart} from "../actions/stateManagement";
import {RES_CHART} from "../actions/personal";
import {RES_TREATMENT_PLAN_BY_APP_ID} from "../actions/treatmentPlanning";

const initialState = {

    message: null,

    // Chart resource data

    resources: {resources: {}},
    resourcesLoaded: false,

};

export const chartResources = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //// Chart Related

            case RES_CHART.GET.receive :

                return {
                    ...state,
                    [`${SM_patientChart.id}_${action.payload.id}`]: action.payload.charting,
                    [`${SM_patientChart.loaded}_${action.payload.id}`]: true
                };

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action:

                let deleteChartState = {...state};
                delete deleteChartState[`${SM_patientChart.loaded}_${action.id}`];
                delete deleteChartState[`${SM_patientChart.id}_${action.id}`];

                return deleteChartState;

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};