import React, {Component} from 'react';
import _ from "lodash";

import {Toolbar} from 'primereact/components/toolbar/Toolbar';
import {Button} from 'primereact/components/button/Button';

import {HM_notImplemented} from "../Constants";

export class BaseComponent extends Component {

    constructor(props) {
        super(props);

        this.contextMenuTarget = null;

        this.onPage = this.onPage.bind(this);
        this.onPageFlex = this.onPageFlex.bind(this);

        this.onShowMenuEntry = this.onShowMenuEntry.bind(this);
        this.onHideMenuEntry = this.onHideMenuEntry.bind(this);

        this.onShowMenuEvent = this.onShowMenuEvent.bind(this);
        this.onHideMenuEvent = this.onHideMenuEvent.bind(this);

        this.onNotImplemented = this.onNotImplemented.bind(this);
        this.loadingToolbar = this.loadingToolbar.bind(this);
        this.setValue = this.setValue.bind(this);
    }

    setFocus(componentName) {

        let focusSet = false;

        let inputText = document.getElementsByClassName("p-inputtext p-component");

        if (inputText[componentName] !== undefined) {
            inputText[componentName].focus();
            focusSet = true;
        }

        if (!focusSet) {
            inputText = document.getElementsByClassName("p-inputtext p-component p-error");

            if (inputText[componentName] !== undefined) {
                inputText[componentName].focus();
            }
        }
    }

    onNotImplemented() {
        this.onShowMenuEntry({item: {target: HM_notImplemented.id}});
    }

    loadingToolbar() {
        return (
            <Toolbar>
                <div className="p-toolbar-group-right">
                    <Button className="p-button-danger" icon="fa fa-times"
                            onClick={() => {
                                this.props.onCloseClick()
                            }}/>
                </div>
            </Toolbar>
        )
    }

    onPage(e) {
        this.setState({first: e.first, rows: e.rows}, () => {
            if (this.props.setState !== undefined) {
                this.props.setState(this.state.stateManagementId, this.state);
            }
        });
    }

    onPageFlex(e, target, rows) {
        this.setState({[target]: e.first, [rows]: e.rows}, () => {
            if (this.props.setState !== undefined) {
                this.props.setState(this.state.stateManagementId, this.state);
            }
        });
    }

    setValue(target, value) {
        this.setState({[target]: value});
    }

    onShowMenuEntry(event) {

        const state = {...this.state};
        _.set(state, event.item.target, true);

        this.setState(state);
    }

    onShowMenuEvent(event) {

        const state = {...this.state};
        _.set(state, event.item.target, true);
        _.set(state, 'eventTarget', event.item.target);

        this.setState(state);
    }

    onHideMenuEntry(target) {

        const state = {...this.state};
        _.set(state, target, false);

        this.setState(state);
    }

    onHideMenuEvent(event) {

        const state = {...this.state};
        _.set(state, event, false);

        this.setState(state);
    }

    colourTemplate(rowData) {

        const item = rowData;

        const color = `rgb(${item.red},${item.green},${item.blue})`;
        return <div style={{backgroundColor: color}}>&nbsp;</div>

    }

    psrColourTemplate({red, green, blue}) {

        const color = `rgb(${red},${green},${blue})`;
        return <div style={{backgroundColor: color}}>&nbsp;</div>

    }

    psrUsernameTemplate({username}) {

        return `${username}`.trim();
    }

    psrNameTemplate({titleAbbreviation, firstName, lastName}) {

        return `${titleAbbreviation} ${firstName} ${lastName}`.trim();
    }

    colourTemplateSplit(rowData) {

        const tc = rowData;

        if (tc.colour === null) {
            return null;
        }

        if (tc.colour === undefined) {
            return null;
        }

        const color = `rgb(${tc.colour.split(':').join(',')})`;
        return <div style={{backgroundColor: color}}>&nbsp;</div>
    }

    recallTemplate(rowData) {

        return rowData.recallType === undefined || rowData.recallType === null ? '' : rowData.recallType.title;
    }

    pjsActionTemplate(rowData) {

        return (typeof rowData.action) === 'string' ? rowData.action : '';
    }

    render() {
        return null;
    }
}
