import _ from 'lodash';
import * as Actions from '../actions/index';
import {RES_HOUSEKEEPING, RES_TEMPLATE_NOTES} from "../actions/housekeeping";
import {
    SM_CLINICIAN_DIARY,
    SM_HOUSEKEEPING,
    SM_HOUSEKEEPING_ADETS,
    SM_HOUSEKEEPING_ASSCATS,
    SM_HOUSEKEEPING_CCS,
    SM_HOUSEKEEPING_CRS,
    SM_HOUSEKEEPING_JSTAGES,
    SM_HOUSEKEEPING_MCS,
    SM_HOUSEKEEPING_OCCS,
    SM_HOUSEKEEPING_PAT_CONSENT_FORMS,
    SM_HOUSEKEEPING_RECTYPES,
    SM_HOUSEKEEPING_TCODES,
    SM_HOUSEKEEPING_TITLES,
    SM_PATIENT_ACCESS,
    SM_PATIENT_ACCOUNT_HISTORY,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS,
    SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_APPOINTMENT_NOTES,
    SM_PATIENT_APPOINTMENT_SUMMARY,
    SM_PATIENT_ASSESSMENTS,
    SM_PATIENT_ASSESSMENTS_ORTHO,
    SM_PATIENT_DATA,
    SM_PATIENT_DOCUMENTS,
    SM_PATIENT_MHF,
    SM_PATIENT_NOTES,
    SM_PATIENT_QUES,
    SM_PATIENT_SEARCH,
    SM_PATIENT_TREATMENT_HISTORY,
    SM_PATIENT_XRAY_RECORDS,
    SM_PAYMENT_PLAN,
    SM_PAYMENT_PLAN_HISTORY,
    SM_PaymentPlaning,
    SM_PERIO_CHART,
    SM_PRACTICE_DIARY,
    SM_REFERRER_DATA,
    SM_templateNotes,
    SM_TreatmentPlan,
    SM_TreatmentPlanHistory,
    SM_TreatmentPlaning,
    SM_USER_DATA,
    SM_USER_SEARCH
} from "../actions/stateManagement";
import {RES_CLINICIAN_DIARY, RES_PRACTICE_DIARY} from "../actions/diary";
import {RES_TREATMENT_PLAN_BY_APP_ID, RES_TREATMENT_PLANNING} from "../actions/treatmentPlanning";
import {
    RES_FIND_PATIENTS,
    RES_PATIENT_ACCESS,
    RES_PATIENT_DETAILS,
    RES_PATIENT_QUESTIONNAIRE
} from "../actions/personal";
import {RES_FIND_USERS, RES_USER_DETAILS} from "../actions/users";
import {RES_PAYMENT_PLANNING} from "../actions/paymentPlanning";

const initialState = {

    readyToLogIn: true,
    messageId: 0,
    message: {type: null, function: null, id: 0},
};

export const stateManagement = (state = _.cloneDeep(initialState), action) => {

    try {
        // this will block
        if (!state.readyToLogIn) {

            if (action.type !== Actions.RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS) {
                return {
                    ...state,
                    readyToLogIn: true,
                }
            }
        }

        switch (action.type) {

            case Actions.MESSAGE_BUS: {

                const messageId = state.messageId + 1;

                return {
                    ...state,
                    messageId,
                    message: {type: action.type, function: action.payload.type, id: messageId, payload: action.payload},
                };
            }
            case Actions.SET_STATE: {

                let data = null;

                if (action.payload.data) {
                    data = {
                        ...action.payload,
                    };
                } else {
                    data = null;
                }
                let newState = {...state, [`${action.payload.id}`]: data, readyToLogIn: true};

                // if we have a parent specified it must be present for the state to be saved
                // otherwise we return old state
                if (Boolean(action.payload.optionalParent && !Boolean(state[action.payload.optionalParent]))) {
                    return state;
                }
                return newState;
            }

            case RES_USER_DETAILS.NHS_SAVE.action:

                const userData = state[action.payload.userDataId];

                userData.data.user.underUDAManagement = action.payload.underUDAManagement;
                userData.data.user.contractId = action.payload.contractId;
                userData.data.user.performerNumber = action.payload.performerNumber;
                userData.data.user.providerPIN = action.payload.providerPIN;

                return {...state, [`${action.payload.userDataId}`]: userData};

            case RES_HOUSEKEEPING.CLEAR.action:

                const hskState = {...state};

                // now remove all defined state setting for housekeeping sections
                delete hskState[SM_HOUSEKEEPING.id];
                delete hskState[SM_HOUSEKEEPING_TITLES.id];
                delete hskState[SM_HOUSEKEEPING_TCODES.id];
                delete hskState[SM_HOUSEKEEPING_CCS.id];
                delete hskState[SM_HOUSEKEEPING_ADETS.id];
                delete hskState[SM_HOUSEKEEPING_RECTYPES.id];
                delete hskState[SM_HOUSEKEEPING_JSTAGES.id];
                delete hskState[SM_HOUSEKEEPING_MCS.id];
                delete hskState[SM_HOUSEKEEPING_CRS.id];
                delete hskState[SM_HOUSEKEEPING_OCCS.id];
                delete hskState[SM_HOUSEKEEPING_ASSCATS.id];
                delete hskState[SM_HOUSEKEEPING_PAT_CONSENT_FORMS.id];

                return hskState;

            case RES_PRACTICE_DIARY.CLEAR.action:

                const dayDiaryState = {...state};

                delete dayDiaryState[SM_PRACTICE_DIARY.id];
                return dayDiaryState;

            case RES_CLINICIAN_DIARY.CLEAR.action:

                const clinicianDiaryState = {...state};

                delete clinicianDiaryState[`${SM_CLINICIAN_DIARY.id}_${action.payload}`];
                return clinicianDiaryState;

            case Actions.CLEAR_USER_DETAILS:

                let userDetailsState = {...state};

                delete userDetailsState[`${SM_USER_DATA.loaded}_${action.payload}`];
                delete userDetailsState[`${SM_USER_DATA.id}_${action.payload}`];

                return userDetailsState;

            case Actions.CLEAR_REFERRER_DETAILS:

                let referrerDetailsState = {...state};

                delete referrerDetailsState[`${SM_REFERRER_DATA.loaded}_${action.smRef}`];
                delete referrerDetailsState[`${SM_REFERRER_DATA.id}_${action.smRef}`];

                return referrerDetailsState;

            case RES_PATIENT_DETAILS.CLEAR.action: {

                const patientDetailsState = {...state};

                delete patientDetailsState[`${SM_PATIENT_DATA.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DATA.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DOCUMENTS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_DOCUMENTS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ACCOUNT_HISTORY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ACCOUNT_HISTORY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_TREATMENT_HISTORY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_TREATMENT_HISTORY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_APPOINTMENT_SUMMARY.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_APPOINTMENT_SUMMARY.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOTES.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_NOTES.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS_ORTHO.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${action.smRef}`];
                delete patientDetailsState[`${SM_PERIO_CHART.loaded}_${action.smRef}`];
                delete patientDetailsState[`${SM_PERIO_CHART.id}_${action.smRef}`];

                const messageId = state.messageId + 1;
                return {
                    ...patientDetailsState,
                    messageId,
                    message: {type: action.type, function: null, id: messageId},
                };
            }

            case RES_TREATMENT_PLAN_BY_APP_ID.CLEAR.action:

                let deleteAppTPState = {...state};

                delete deleteAppTPState[action.smRef];
                delete deleteAppTPState[`${action.smRef}_CH`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_NOTES.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_NOTES.id}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS.id}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_APPOINTMENT_ASSESSMENTS_ORTHO.id}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_XRAY_RECORDS.loaded}_${action.id}`];
                delete deleteAppTPState[`${SM_PATIENT_XRAY_RECORDS.id}_${action.id}`];

                return deleteAppTPState;

            case RES_TREATMENT_PLANNING.CLEAR.action:

                // this is just for treatment planing pages
                let clearTPState = {...state};

                // get the loaded treatment plan list from the relevant planning entry
                let tpHistory = _.get(clearTPState, `${SM_TreatmentPlanHistory.id}_${action.smRef}.data.treatmentPlanHistory.plans`);

                if (tpHistory !== undefined) {
                    // clear all plan entries based on the loaded history
                    tpHistory.forEach(plan => {
                        delete clearTPState[`${SM_TreatmentPlan.id}_${plan.id}`];
                        delete clearTPState[`${SM_TreatmentPlan.id}_${plan.id}_CH`];
                        delete clearTPState[`${SM_PATIENT_NOTES.id}_${plan.id}`];
                        delete clearTPState[`${SM_PATIENT_ASSESSMENTS.id}_${plan.id}`];
                        delete clearTPState[`${SM_PATIENT_ASSESSMENTS_ORTHO.id}_${plan.id}`];
                    });
                }
                delete clearTPState[`${SM_TreatmentPlanHistory.id}_${action.smRef}`];
                delete clearTPState[`${SM_TreatmentPlaning.id}_${action.smRef}`];

                return clearTPState;

            case RES_PAYMENT_PLANNING.CLEAR.action:

                // this is just for payment planing pages
                let clearPPState = {...state};

                const selectedPPs = `${SM_PaymentPlaning.id}_${action.smRef}.data.selectedPPs`;

                // get the loaded payment plan list from the relevant planning entry
                let ppHistory = _.get(clearPPState, selectedPPs);

                if (ppHistory !== undefined) {
                    // clear all plan entries based on the loaded history
                    ppHistory.forEach(plan => {
                        delete clearPPState[`${SM_PAYMENT_PLAN.id}_${plan.id}`];
                    });
                }
                delete clearPPState[`${SM_PAYMENT_PLAN_HISTORY.id}_${action.smRef}`];
                delete clearPPState[`${SM_PaymentPlaning.id}_${action.smRef}`];

                return clearPPState;

            case RES_PATIENT_ACCESS.CLEAR.action:

                const patientAccessState = {...state};
                delete patientAccessState[SM_PATIENT_ACCESS.id];

                return patientAccessState;

            case RES_FIND_USERS.CLEAR.action:

                const findUsersState = {...state};
                delete findUsersState[SM_USER_SEARCH.id];

                return findUsersState;

            case RES_FIND_PATIENTS.CLEAR.action:

                const findPatientsState = {...state};
                delete findPatientsState[SM_PATIENT_SEARCH.id];

                return findPatientsState;

            case RES_TEMPLATE_NOTES.CLEAR.action:

                const templateNoteState = {...state};
                delete templateNoteState[SM_templateNotes.id];

                return templateNoteState;

            case Actions.CLEAR_REFERRER_ADD:

                const clearReferrerState = {...state};
                delete clearReferrerState[action.payload];

                return clearReferrerState;

            case Actions.CLEAR_PATIENT_ADD:

                const clearPatientState = {...state};
                delete clearPatientState[action.payload];

                return clearPatientState;

            case Actions.CLEAR_USER_ADD:

                const clearUserState = {...state};
                delete clearUserState[action.payload];

                return clearUserState;

            case Actions.CLEAR_TREATMENT_PLAN_PDF_BY_TP_ID:

                const clearTPPDF = {...state};
                delete clearTPPDF[action.payload.id];

                return clearTPPDF;

            case RES_PATIENT_QUESTIONNAIRE.CLEAR.action:

                const clearQuestionnaire = {...state};
                delete clearQuestionnaire[`${SM_PATIENT_QUES.id}_${action.smRef}`];

                return sendMessage(clearQuestionnaire, action);

            case Actions.CLEAR_PATIENT_MHF:

                const clearMHFS = {...state};
                delete clearMHFS[`${SM_PATIENT_MHF.id}_${action.smRef}`];

                return sendMessage(clearMHFS, action);

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                const loggedOutState = _.cloneDeep(initialState);
                return {
                    ...loggedOutState,
                    readyToLogIn: false,
                }
            default :
                return sendMessage(state, action);
        }
    } catch (error) {
        return state;
    }
};

const sendMessage = (state, action) => {

    const messageId = state.messageId + 1;

    return {
        ...state,
        messageId,
        message: {type: action.type, function: null, id: messageId},
    };

}