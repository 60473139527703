import React from 'react';

import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Button} from 'primereact/components/button/Button';
import {Panel} from 'primereact/components/panel/Panel';
import {connect} from "react-redux";
import _ from "lodash";
import {getResource, RES_TREATMENT_PLAN_BY_ID, RES_TREATMENT_PLAN_HISTORY} from "../../actions/treatmentPlanning";
import {HM_notImplemented, SE_NONE, SM_CLIENT_TREATMENT_PLANNING, TP_ACCEPTED} from "./Constants";
import {dateTemplate, getTreatmentPlanIds, ShowMessageDialog} from "./Utils";
import moment from "moment";
import {DELETE_TREATMENT_PLAN} from "../../actions";
import {suppressBrowserCM} from "../Utils";
import {ClientComponent} from "./ClientComponent";

export class ConnectedClientTreatmentPlanHistory extends ClientComponent {

    constructor(props) {
        super(props);

        this.state = {

            first: 0,
            rows: 5,
            canSave: {status: false},
            treatmentPlanHistoryLoaded: false,
            treatmentPlanHistory: [],
            selectedTreatmentPlan: null,

            showAddPlan: false,

            fakeTreatmentPlanId: -100,
        }

        this.onSelection = this.onSelection.bind(this);
        this.onRowSelection = this.onRowSelection.bind(this);

        this.showDialogs = this.showDialogs.bind(this);

        this.toolbarCallbacks = {

            [HM_notImplemented.id]: this.onNotImplemented,
        }
    }

    componentDidMount() {

        this.props.getTPHistory(this.props.patientId);
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message !== this.props.message) {

            switch (this.props.message.type) {

                case RES_TREATMENT_PLAN_HISTORY.GET.receive:
                case DELETE_TREATMENT_PLAN:

                    this.setState({
                        treatmentPlanHistory: this.props.treatmentPlanHistory,
                        treatmentPlanHistoryLoaded: this.props.treatmentPlanHistoryLoaded
                    });
                    break;

                case RES_TREATMENT_PLAN_BY_ID.ADD.action:

                    this.props.getTPHistory(this.props.patientId);
                    break;

                default:
                    break;
            }
        }
    }

    onRowSelection(e) {
        this.props.onAddTPTab(this.state.selectedTreatmentPlan);
    }

    onSelection(e) {
        this.setState({selectedTreatmentPlan: e.value});
    }

    showDialogs() {

        return (
            ShowMessageDialog(this, HM_notImplemented)
        )
    }

    render() {

        if (!this.state.treatmentPlanHistoryLoaded) {
            return null;
        }
        suppressBrowserCM();

        const panelHeader = <div><label id='panel-header'>{SM_CLIENT_TREATMENT_PLANNING.detail}</label>
            <div className="p-toolbar-group-right">
                <Button label={SM_CLIENT_TREATMENT_PLANNING.exitLabel} icon={SM_CLIENT_TREATMENT_PLANNING.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSave(SE_NONE)}
                />
            </div>
        </div>;

        const sortedTreatmentPlans = _.orderBy(this.state.treatmentPlanHistory.plans, 'start', (o) => {
            return moment(o.created).format('YYYYMMDD');
        }, ['desc']);

        return (
            <div
                style={{fontSize: 'small'}}
            >

                <Panel header={panelHeader}>

                    {this.showDialogs()}

                    <div className="p-grid extended-pagination">
                        <DataTable value={sortedTreatmentPlans}
                                   editable={true}
                                   paginator={true}
                                   rows={this.state.rows}
                                   rowsPerPageOptions={[5, 10, 20]}
                                   onPage={this.onPage}
                                   first={this.state.first}
                                   selectionMode="single"
                                   selection={this.state.selectedTreatmentPlan}
                                   onSelectionChange={this.onSelection}
                        >
                            <Column body={row => dateTemplate(row.created)} header="Created On"
                                    style={{width: '15%', textAlign: 'right'}}/>
                            <Column field="title" header="Title" style={{width: '25%'}}/>
                            <Column field="description" header="Description" style={{width: '50%'}}/>
                            <Column body={row => row.status === TP_ACCEPTED.name ? 'Accepted' : 'Proposed'}
                                    field="status" header="Status" style={{width: '10%', textAlign: 'right'}}/>
                        </DataTable>
                    </div>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const {
        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,
    } = getTreatmentPlanIds(state, ownProps);

    return {

        message: state.stateManagement.message,

        loginIdentity: state.login.user,

        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTPHistory: (patientId) => dispatch(getResource(RES_TREATMENT_PLAN_HISTORY.GET, patientId)),
    }
};

const ClientTreatmentPlanHistory = connect(mapStateToProps, mapDispatchToProps)(ConnectedClientTreatmentPlanHistory);

export default ClientTreatmentPlanHistory;