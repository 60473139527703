import {MHF_ST_Current} from "./Constants";
import {CC_UK, OUTCOME_ConsentNotGiven, PAT_STATUS_ACTIVE} from "../../Constants";
import {ExemptionAndRemissionCodes} from "./Sections/NHSDetails";

export const ConsentForm = (mcId) => {

    return {
        id: null,
        mc: mcId,
        patient: null,
        appointment: null,
        treatmentPlan: null,
        paymentPlan: null,
        consentDate: new Date(),
        outcome: OUTCOME_ConsentNotGiven,
        signatureDocument: null,
        consentDocument: null,
        templateId: null,
        saved: false,
    }
};

export const Questionnaire = (mcId) => {

    return {
        id: null,
        mc: mcId,
        preAppointmentDate: new Date(),
        uponArrivalDate: new Date(),
        patient: null,
        entries: [],
        signatureId: null,
        state: MHF_ST_Current,
        o2Saturation: 0.0,
        temparature: 0.0,
    }
};

export const MedicalHistoryForm = (mcId) => {

    return {
        id: null,
        mc: mcId,
        createdOn: new Date(),
        expiresOn: new Date(),
        patient: null,
        entries: [],
        extras: '',
        signatureId: null,
        state: MHF_ST_Current,
    }
};

export const NextOfKin = (mcId) => {

    return {
        id: null,
        mc: null,
        patient: null,
        title: null,
        firstName: '',
        lastName: '',
        mobile: '',
        relationship: '',
        contactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK} // UK
        },
    }
}

export const PatientData = (mcId) => {

    return {
        id: null,
        mc: mcId,

        provider: null,
        registeredBy: null,
        registeredOn: null,
        title: null,
        firstName: '',
        middleName: '',
        lastName: '',
        gender: 2,
        status: PAT_STATUS_ACTIVE.name,
        dateOfBirth: new Date(),
        addressedAs: '',
        salutation: '',
        knownAs: '',
        contactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK} // UK
        },
        alternContactDetails: {
            id: null,
            mc: mcId,
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            county: '',
            postcode: '',
            country: {id: CC_UK} // UK
        },
        homeTelephone: '',
        workTelephone: '',
        otherTelephone: '',
        fax: '',
        mobile: '',
        email: '',
        homeTelephoneComment: '',
        workTelephoneComment: '',
        otherTelephoneComment: '',
        mobileComment: '',
        emailComment: '',
        referrer: null,
        otherSource: '',
        appointments: [],
        reminders: [],
        charges: [],
        treatmentPlans: [],
        generalNotes: [],
        accountBalance: 0.0,
        pendingBalance: 0.0,
        lastBillSent: null,
        lastUpdated: '',
        conditions: [],
        billingGroup: null,
        groupPayee: null,
        hasConditions: false,
        hasNotes: false,
        lateArriver: false,
        latePayer: false,
        medicalHistoryFormNeeded: false,
        alternateRef: '',
        studyModelBox: '',
        sendToPrinciple: false,
        importReference: '',
        occupation: null,
        xrayConnectionReference: '',
        useAlternativeAddress: false,
        discountCharge: null,
        lastTransaction: null,
        pricingPlanMembership: null,
        patientJourney: null,
        adultCharting: false,
        claimId: null,
        NHSPatient: false,
        iotnDhc: 0,
        iotnAc: 0,
        blockPortalLogin: false,
        portalLoginRegistered: false,
        portalLoginGDPRRequired: true,
    }
};

export const PatientNHSDetails = (mcId) => {
    const patientNHSDetails = {
        id: -1,
        mc: mcId,
        patient: null,
        nhsNumber: '',
        previousSurname: '',
        ethnicGroup: {code: 99},
        exemptionsAndRemissions: [],
        exemptionShown: [],
        collegeOrUniversity: '',
        marternityExemptionNo: '',
        nameOfPersonReceivingBenefit: '',
        NINo: '',
        hc2CertificateNo: '',
        hc3CertificateNo: '',
        taxCreditNo: '',
        hc3PaymentLimit: 0.0,
        babyDueOrBorn: null,
        dateOfBirth: null,
    };
    ExemptionAndRemissionCodes.forEach(code => {
        patientNHSDetails.exemptionsAndRemissions.push(false);
        patientNHSDetails.exemptionShown.push(false);
    })
    return patientNHSDetails;
};
