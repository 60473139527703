import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {HM_ABOUT_CHIRAL_CLIENT, HM_HELP_CHIRAL_CLIENT, HM_LOGOUT} from "./Constants";
import {connect} from "react-redux";

class ConnectedClientAppTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        profileMode: null,
        horizontal: false,
        topbarMenuActive: false,
        activeTopbarItem: null
    };

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        profileMode: PropTypes.string.isRequired,
        horizontal: PropTypes.bool.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string
    };

    constructor() {
        super();
        this.state = {};
    }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    render() {

        let topbarItemsClassName = classNames('topbar-items fadeInDown', {'topbar-items-visible': this.props.topbarMenuActive});

        const patientData = this.props.patientData;
        const loginName = `${patientData.fullName}`.trim();

        return <div className="topbar clearfix">
            <div className="topbar-left navbar-header-title-container">
                <h1 className="navbar-header-title">Patient Portal</h1>
            </div>

            <div className="topbar-right">
                <a id="menu-button" onClick={this.props.onMenuButtonClick} href={"/"}>
                    <i className="fa fa-angle-left"/>
                </a>

                <a id="topbar-menu-button" onClick={this.props.onTopbarMenuButtonClick} href={"/"}>
                    <i className="fa fa-bars"/>
                </a>
                <ul className={topbarItemsClassName}>
                    <li className={classNames({'active-top-menu': this.props.activeTopbarItem === 'settings'})}
                        onClick={(e) => this.onTopbarItemClick(e, 'settings')}>
                        <a href={"/"}>
                            <i className="topbar-icon fa fa-fw fa-cog"/>
                        </a>
                        <ul className="layout-menu fadeInDown">
                            <li role="menuitem">
                                <a href='/' onClick={(e) => this.onTopbarItemClick(e, HM_HELP_CHIRAL_CLIENT.id)}>
                                    <i className={HM_HELP_CHIRAL_CLIENT.icon}/>
                                    <span>{HM_HELP_CHIRAL_CLIENT.header}</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href='/' onClick={(e) => this.onTopbarItemClick(e, HM_ABOUT_CHIRAL_CLIENT.id)}>
                                    <i className={HM_ABOUT_CHIRAL_CLIENT.icon}/>
                                    <span>{HM_ABOUT_CHIRAL_CLIENT.header}</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a id={`VVBB`} href='/'
                                   onClick={(e) => this.onTopbarItemClick(e, HM_LOGOUT.id)}>
                                    <i className={HM_LOGOUT.icon}/>
                                    <span>{`${HM_LOGOUT.header}`}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h1 className="navbar-header-title">{loginName}</h1>
                    </li>
                </ul>
            </div>
        </div>;
    }
}

const mapStateToProps = (state) => {

    const practiceDetailsLoaded = Boolean(state.preferences.practiceDetailsLoaded);
    const practiceDetails = practiceDetailsLoaded ? state.preferences.practiceDetails : [];

    return {
        loginIdentity: state.login.user,

        practiceDetailsLoaded,
        practiceDetails,
    }
};

export const ClientAppTopbar = connect(mapStateToProps, null)(ConnectedClientAppTopbar);
