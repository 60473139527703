import React from 'react';
import {SM_PATIENT_MHF, SM_PATIENT_NOK_DATA} from "../../actions/stateManagement";
import {suppressBrowserCM} from "../Utils";
import _ from "lodash";
import {MedicalHistoryForm} from "./DefaultData";
import {ProgressBar} from "primereact/progressbar";
import {Card} from "primereact/card";
import {ac} from "../../index";
import {InputTextarea} from "primereact/inputtextarea";
import {
    getMedicalHistory,
    getResource as getPatResource,
    RES_PATIENT_DETAILS,
    RES_PATIENT_MHFS,
    saveMedicalHistory,
    savePatientNOK
} from "../../actions/personal";
import {connect} from "react-redux";
import {Panel} from "primereact/panel";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "primereact/components/button/Button";
import * as Actions from "../../actions";
import {
    HM_COMPLETE_MHF,
    ICON_CANCEL,
    ICON_DELETE,
    SE_NONE,
    SM_CLIENT_PATIENT_MED_CON,
    SM_CLIENT_PATIENT_MED_CON_SIG
} from "./Constants";
import {ClientComponent} from "./ClientComponent";
import {SelectButton} from "primereact/selectbutton";
import {ShowMessageDialog} from "./Utils";
import {NextOfKin} from "./NextOfKin";
import * as DefaultData from "./DefaultData";

import {getDropDowns, RES_getDropDowns} from "../../actions/dropDowns";

class ConnectedClientMedCons extends ClientComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                medicalHistoryForm: _.cloneDeep(MedicalHistoryForm(ac.getMcId())),
                patientId: props.patientId,
                mhfContents: [],
                completed: false,
                width: 0,
                height: 0,
                showColor: false,

                patientNOKDataLoaded: false,
                patientNOKData: null,
            }
        }
        this.sigCanvas = {};
    }

    componentDidMount() {

        this.props.getDropDowns();
        this.props.getMedicalHistory(this.props.patientId);
        this.props.getPatientNOKDetails({patientId: this.props.patientId});

        window.addEventListener('resize', this.updateWindowDimensions);
        const smallScreen = window.innerWidth < 667;
        this.setState({smallScreen});
    }

    updateWindowDimensions = () => {
        const smallScreen = window.innerWidth < 667;
        this.setState({smallScreen, width: window.innerWidth, height: window.innerHeight});
    }

    componentDidUpdate(prevProps, ps, ss) {


        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case Actions.RECEIVE_PATIENT_MHF:

                    const medicalHistoryForm = {...this.props.medicalHistoryForm};

                    medicalHistoryForm.entries.forEach(entry => entry.yes = null);

                    this.setState({patientDataLoaded: true, medicalHistoryForm});
                    break;
                case Actions.RECEIVE_PATIENT_NOK:

                    let patientNOKData = null;

                    if (this.props.patientNOKData) {
                        patientNOKData = {...this.props.patientNOKData};
                    } else {
                        patientNOKData = _.cloneDeep(DefaultData.NextOfKin(ac.getMcId()));
                        patientNOKData.kin = {id: this.state.patientId};
                    }
                    this.setState({patientNOKDataLoaded: true, patientNOKData});
                    break;
                default:
                    break;
            }
        }
    }

    onChange = (event) => {

        if (event.owner.startsWith('patientNOKData')) {

            const state = {...this.state};

            _.set(state, event.owner, event.value);
            this.setState({state});
        } else {

            const medicalHistoryForm = {...this.state.medicalHistoryForm};
            const entry = _.find(medicalHistoryForm.entries, entry => entry.id === event.id);

            _.set(entry, event.owner, event.value);

            if (event.owner === 'yes') {

                let completed = true;

                medicalHistoryForm.entries.forEach(entry => {
                    if (!entry.condition.formHeader)
                        if (entry.yes === null) {
                            completed = false;
                        }
                });
                this.setState({medicalHistoryForm, completed});
            } else {
                this.setState({medicalHistoryForm});
            }
        }
    }

    onSaveForm = () => {

        if (this.state.completed) {

            this.props.saveMedicalHistory({
                form: this.state.medicalHistoryForm,
                image: this.sigCanvas.toDataURL('image/png')
            });
            this.props.savePatientNOKDetails(this.state.patientNOKData);

            this.onSave(SE_NONE);
        } else {
            this.setState({[HM_COMPLETE_MHF.id]: true, showColor: true});
        }
    }

    onClear = () => {

        this.sigCanvas.clear();
    }

    insertConditions = () => {

        const conditions = [];
        const formEntries = _.sortBy(this.state.medicalHistoryForm.entries, ['condition.medicalHistoryFormIndex', 'name'], ['asc', 'asc']);

        const selectItems = this.state.smallScreen ? [
            {label: 'Y', value: true},
            {label: 'N', value: false}
        ] : [
            {label: 'Yes', value: true},
            {label: 'No', value: false}
        ];

        formEntries.forEach((entry) => {

            if (entry.condition.formHeader) {
                conditions.push(
                    <div style={{
                        backgroundColor: 'rgb(151,188,215)',
                        marginTop: '5px',
                        marginBottom: '5px',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        display: 'flex', flexDirection: 'column'
                    }}
                         key={`header_${entry.condition.id}`}
                    >
                        <span>{entry.condition.formEntry}</span>
                    </div>
                )
            } else {

                const backgroundColor = entry.yes === null && this.state.showColor ? 'pink' : '';

                conditions.push(
                    <Card header={entry.condition.formEntry}
                          key={`question_${entry.condition.id}`}
                    >
                        <div className="p-grid p-fluid form-group p-col-12" style={{fontSize: 'small'}}>
                            <div className="p-col-3" style={{backgroundColor}}>
                                <SelectButton value={entry.yes}
                                              options={selectItems}
                                              onChange={(e) =>
                                                  this.onChange({id: entry.id, owner: 'yes', value: e.value})
                                              }/>
                            </div>
                            <div className="p-col-9">
                                <InputTextarea value={entry.comment}
                                               autoResize={true}
                                               rows={3}
                                               onChange={(e) => {
                                                   this.onChange({
                                                       id: entry.id,
                                                       owner: 'comment',
                                                       value: e.target.value
                                                   })
                                               }}
                                />
                            </div>
                        </div>
                    </Card>
                )
            }
        });
        return conditions;
    }

    showDialogs() {

        const content = [];
        if (this.state[HM_COMPLETE_MHF.id]) {
            content.push(ShowMessageDialog(this, HM_COMPLETE_MHF));
        }
        return content;
    }

    render() {

        if (!this.state.patientDataLoaded || !this.state.patientNOKDataLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        suppressBrowserCM();

        const header = <div><label id='panel-header'>{SM_CLIENT_PATIENT_MED_CON.detail}</label>
            <div className="p-toolbar-group-right">
                <Button label={this.state.smallScreen ? '' : SM_CLIENT_PATIENT_MED_CON.exitLabel}
                        icon={SM_CLIENT_PATIENT_MED_CON.exitIcon}
                        className="p-button-success"
                        onClick={() => this.onSaveForm(SE_NONE)}
                />
                <Button label={this.state.smallScreen ? '' : 'Cancel'} icon={ICON_CANCEL}
                        className="p-button-success"
                        onClick={() => this.onCancel()}
                />
            </div>
        </div>;

        const sigHeader = <div><label id='panel-header'>{SM_CLIENT_PATIENT_MED_CON_SIG.label}</label>
            <Button label={this.state.smallScreen ? '' : SM_CLIENT_PATIENT_MED_CON.exitLabel}
                    icon={SM_CLIENT_PATIENT_MED_CON.exitIcon}
                    className="p-button-success"
                    onClick={() => this.onSaveForm(SE_NONE)}
            />
            <Button label={this.state.smallScreen ? '' : 'Cancel'} icon={ICON_CANCEL}
                    className="p-button-success"
                    onClick={() => this.onCancel()}
            />
            <Button label={this.state.smallScreen ? '' : 'Clear'} icon={ICON_DELETE}
                    style={{marginLeft: '0.25em', marginBottom: '0.6em'}}
                    onClick={this.onClear}/>
        </div>;

        return (

            <div className="p-col-12 p-lg-12">

                {this.showDialogs()}

                <Panel header={header} className="no-pad">
                    <div className="p-col-12">

                        {this.insertConditions()}
                    </div>
                </Panel>

                <div style={{marginTop: '10px'}}>
                    <NextOfKin onChange={this.onChange}
                               target='patientNOKData'
                               header='Next of Kin'
                               patientNOKData={this.state.patientNOKData}
                               titles={this.props.titles}
                               countries={this.props.countries}
                    />
                </div>

                <Panel header={sigHeader} style={{marginTop: '10px'}}>
                    <SignatureCanvas penColor='darkBlue'
                                     ref={(ref) => {
                                         this.sigCanvas = ref
                                     }}
                                     canvasProps={{
                                         width: 500,
                                         height: 200,
                                         className: 'sigCanvas'
                                     }}
                                     className="no-pad"
                    />
                </Panel>
            </div>
        )
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
}

const mapStateToProps = (state, ownProps) => {

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    const loadedId = `${SM_PATIENT_MHF.loaded}_${ownProps.patientId}`;
    const dataId = `${SM_PATIENT_MHF.id}_${ownProps.patientId}`;

    const patientNOKDataLoadedId = `${SM_PATIENT_NOK_DATA.loaded}_${ownProps.patientId}`;
    const patientNOKDataId = `${SM_PATIENT_NOK_DATA.id}_${ownProps.patientId}`;

    let patientNOKDataLoaded = state.patients[patientNOKDataLoadedId];
    patientNOKDataLoaded = patientNOKDataLoaded === undefined ? false : patientNOKDataLoaded;

    const patientNOKData = state.patients[patientNOKDataId];

    return {

        loaded: state.patients[loadedId],
        medicalHistoryForm: state.patients[dataId],

        patientNOKDataLoaded,
        patientNOKData,

        dropDownsLoaded,
        titles: state.dropDowns.titles,
        countries: state.dropDowns.countries,

        message: state.stateManagement.message,

        currentState: state.stateManagement[dataId],
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getDropDowns: () => dispatch(getDropDowns(RES_getDropDowns)),
        getMedicalHistory: (patientId) => dispatch(getMedicalHistory(RES_PATIENT_MHFS.GET_TABLET, patientId)),
        getPatientNOKDetails: (params) => dispatch(getPatResource(RES_PATIENT_DETAILS.GET_NOK, params)),

        saveMedicalHistory: (medicalHistoryForm) => dispatch(saveMedicalHistory(RES_PATIENT_MHFS.SAVE, medicalHistoryForm)),
        savePatientNOKDetails: (patientNOKData) => dispatch(savePatientNOK(RES_PATIENT_DETAILS.SAVE_NOK, patientNOKData)),
    }
};

const ClientMedCons = connect(mapStateToProps, MapDispatchToProps)(ConnectedClientMedCons);

export default ClientMedCons;
