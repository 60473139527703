export const REQUEST_PRACTICE_LOGIN_PAGE_DETAILS = 'REQUEST_PRACTICE_LOGIN_PAGE_DETAILS';
export const RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS = 'RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS';

export const REQUEST_PRACTICE_DETAILS = 'REQUEST_PRACTICE_DETAILS';
export const RECEIVE_PRACTICE_DETAILS = 'RECEIVE_PRACTICE_DETAILS';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SUCCESS_PATIENT = 'LOGIN_SUCCESS_PATIENT';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';

export const UPDATE_TABSTACK = 'UPDATE_TABSTACK';
export const SAVE_STACK_VECTORS = 'SAVE_STACK_VECTORS';
export const OPEN_MESSAGING = 'OPEN_MESSAGING';

export const CLIENT_LOGOUT = 'CLIENT_LOGOUT';

// laboratory list

export const REQUEST_LABORATORY_LIST = 'REQUEST_LABORATORY_LIST';
export const RECEIVE_LABORATORY_LIST = 'RECEIVE_LABORATORY_LIST';

export const REQUEST_LABORATORY_DETAILS = 'REQUEST_LABORATORY_DETAILS';
export const RECEIVE_LABORATORY_DETAILS = 'RECEIVE_LABORATORY_DETAILS';

// admin user login identity

export const REQUEST_LOGIN_ADMIN_USER_DETAILS = 'REQUEST_LOGIN_ADMIN_USER_DETAILS';
export const RECEIVE_LOGIN_ADMIN_USER_DETAILS = 'RECEIVE_LOGIN_ADMIN_USER_DETAILS';

// Laboratory user login identity

export const REQUEST_LOGIN_LAB_USER_DETAILS = 'REQUEST_LOGIN_LAB_USER_DETAILS';
export const RECEIVE_LOGIN_LAB_USER_DETAILS = 'RECEIVE_LOGIN_LAB_USER_DETAILS';

// user login identity

export const RECEIVE_LOGIN_USER_DETAILS = 'RECEIVE_LOGIN_USER_DETAILS';

// patient login identity

export const RECEIVE_LOGIN_PATIENT_DETAILS = 'RECEIVE_LOGIN_PATIENT_DETAILS';

// system resources

export const REQUEST_DROPDOWNS = 'REQUEST_DROPDOWNS';
export const RECEIVE_DROPDOWNS = 'RECEIVE_DROPDOWNS';

export const RECEIVE_DIARY_EVENT_TYPES = 'RECEIVE_DIARY_EVENT_TYPES';


export const REQUEST_DIARY_EVENTS = 'REQUEST_DIARY_EVENTS';
export const RECEIVE_DIARY_EVENTS = 'RECEIVE_DIARY_EVENTS';

export const REQUEST_DIARY_EVENTS_PORTAL = 'REQUEST_DIARY_EVENTS_PORTAL';
export const RECEIVE_DIARY_EVENTS_PORTAL = 'RECEIVE_DIARY_EVENTS_PORTAL';

export const REQUEST_DIARY_EVENTS_PORTAL_UA = 'REQUEST_DIARY_EVENTS_PORTAL_UA';
export const RECEIVE_DIARY_EVENTS_PORTAL_UA = 'RECEIVE_DIARY_EVENTS_PORTAL_UA';

export const REQUEST_DIARY_EVENTS_PORTAL_VC = 'REQUEST_DIARY_EVENTS_PORTAL_VC';
export const RECEIVE_DIARY_EVENTS_PORTAL_VC = 'RECEIVE_DIARY_EVENTS_PORTAL_VC';

export const CLEAR_PRACTICE_DIARY = 'CLEAR_PRACTICE_DIARY';
export const CLEAR_PRACTICE_WEEK_DIARY = 'CLEAR_PRACTICE_WEEK_DIARY';

export const REQUEST_CLINICIAN_DIARY_EVENTS = 'REQUEST_CLINICIAN_DIARY_EVENTS';
export const RECEIVE_CLINICIAN_DIARY_EVENTS = 'RECEIVE_CLINICIAN_DIARY_EVENTS';

export const CLEAR_CLINICIAN_DIARY = 'CLEAR_CLINICIAN_DIARY';

export const REQUEST_DIARY_PALLETE_ENTRIES = 'REQUEST_DIARY_PALLETE_ENTRIES';
export const RECEIVE_DIARY_PALLETE_ENTRIES = 'RECEIVE_DIARY_PALLETE_ENTRIES';

export const RECEIVE_DIARY_RESOURCES = 'RECEIVE_DIARY_RESOURCES';

export const REQUEST_PATIENT_SEARCH = 'REQUEST_PATIENT_SEARCH';
export const RECEIVE_PATIENT_SEARCH = 'RECEIVE_PATIENT_SEARCH';

export const PATIENT_SEARCH_STATE = 'PATIENT_SEARCH_STATE';
export const RECEIVE_APP_PATIENT_SEARCH = 'RECEIVE_APP_PATIENT_SEARCH';

export const RECEIVE_ADD_APPOINTMENT = 'RECEIVE_ADD_APPOINTMENT';
export const RECEIVE_ADD_DIARYEVENT = 'RECEIVE_ADD_DIARYEVENT';

export const RECEIVE_ADDBACK_APPOINTMENT = 'RECEIVE_ADDBACK_APPOINTMENT';

export const DIARY_PRINT_DAY_LIST = 'DIARY_PRINT_DAY_LIST';

export const REQUEST_ADD_PORTAL_BOOKING = 'REQUEST_ADD_PORTAL_BOOKING';
export const RECEIVE_ADD_PORTAL_BOOKING = 'RECEIVE_ADD_PORTAL_BOOKING';

export const REQUEST_ADD_PORTAL_VIDEO_BOOKING = 'REQUEST_ADD_PORTAL_VIDEO_BOOKING';
export const RECEIVE_ADD_PORTAL_VIDEO_BOOKING = 'RECEIVE_ADD_PORTAL_VIDEO_BOOKING';

export const REQUEST_VIDEO_APPOINTMENTS = 'REQUEST_VIDEO_APPOINTMENTS';
export const RECEIVE_VIDEO_APPOINTMENTS = 'RECEIVE_VIDEO_APPOINTMENTS';

// Report related

export const REQUEST_REPORT_ACC_ITEM = 'REQUEST_REPORT_ACC_ITEM';
export const RECEIVE_REPORT_ACC_ITEM = 'RECEIVE_REPORT_ACC_ITEM';

// Appointment related

export const REQUEST_TREATMENT_PLAN_BY_APP_ID = 'REQUEST_TREATMENT_PLAN_BY_APP_ID';
export const RECEIVE_TREATMENT_PLAN_BY_APP_ID = 'RECEIVE_TREATMENT_PLAN_BY_APP_ID';
export const SAVE_TREATMENT_PLAN_BY_APP_ID = 'SAVE_APP_TREATMENT_PLAN_BY_APP_ID';
export const SAVE_CLEAR_TREATMENT_PLAN_BY_APP_ID = 'SAVE_CLEAR_TREATMENT_PLAN_BY_APP_ID';
export const CLEAR_TREATMENT_PLAN_BY_APP_ID = 'CLEAR_TREATMENT_PLAN_BY_APP_ID';

// Treatment Plan related
export const REQUEST_TREATMENT_PLAN_BY_TP_ID = 'REQUEST_TREATMENT_PLAN_BY_TP_ID';
export const RECEIVE_TREATMENT_PLAN_BY_TP_ID = 'RECEIVE_TREATMENT_PLAN_BY_TP_ID';
export const SAVE_TREATMENT_PLAN_BY_TP_ID = 'SAVE_APP_TREATMENT_PLAN_BY_TP_ID';
export const DELETE_TREATMENT_PLAN = 'DELETE_TREATMENT_PLAN';
export const ADD_TREATMENT_PLAN = 'ADD_TREATMENT_PLAN'; // this is only available from treatment planning so no need to add _BY_TP_ID
export const SAVE_CLEAR_TREATMENT_PLAN_BY_TP_ID = 'SAVE_CLEAR_TREATMENT_PLAN_BY_TP_ID';
export const CLEAR_TREATMENT_PLAN_BY_TP_ID = 'CLEAR_TREATMENT_PLAN_BY_TP_ID';
export const CLEAR_TREATMENT_PLAN_PDF_BY_TP_ID = 'CLEAR_TREATMENT_PLAN_PDF_BY_TP_ID';

export const REQUEST_TREATMENT_PLAN_FOR_TASK = 'REQUEST_TREATMENT_PLAN_FOR_TASK';
export const RECEIVE_TREATMENT_PLAN_FOR_TASK = 'RECEIVE_TREATMENT_PLAN_FOR_TASK';

export const REQUEST_TREATMENT_PLAN_PDF = 'REQUEST_TREATMENT_PLAN_PDF';
export const RECEIVE_TREATMENT_PLAN_PDF = 'RECEIVE_TREATMENT_PLAN_PDF';

export const SAVE_TPLANNING = 'SAVE_TPLANNING';
export const SAVE_CLEAR_TPLANNING = 'SAVE_CLEAR_TPLANNING';
export const CLEAR_TPLANNING = 'CLEAR_TPLANNING';

export const CLEAR_TP_HISTORY = 'CLEAR_TP_HISTORY';
export const REQUEST_TP_HISTORY = 'REQUEST_TP_HISTORY';
export const RECEIVE_TP_HISTORY = 'RECEIVE_TP_HISTORY';

// payment plan related

export const CLEAR_PP_HISTORY = 'CLEAR_PP_HISTORY';
export const REQUEST_PP_HISTORY = 'REQUEST_PP_HISTORY';
export const RECEIVE_PP_HISTORY = 'RECEIVE_PP_HISTORY';

export const REQUEST_PAYMENT_PLAN_BY_TP_ID = 'REQUEST_PAYMENT_PLAN_BY_TP_ID';
export const RECEIVE_PAYMENT_PLAN_BY_TP_ID = 'RECEIVE_PAYMENT_PLAN_BY_TP_ID';
export const SAVE_PAYMENT_PLAN = 'SAVE_PAYMENT_PLAN';
export const DELETE_PAYMENT_PLAN = 'DELETE_PAYMENT_PLAN';
export const ADD_PAYMENT_PLAN = 'ADD_PAYMENT_PLAN';
export const SAVE_CLEAR_PAYMENT_PLAN = 'SAVE_CLEAR_PAYMENT_PLAN';
export const CLEAR_PAYMENT_PLAN = 'CLEAR_PAYMENT_PLAN';
export const SUSPEND_PAYMENT_PLAN = 'SUSPEND_PAYMENT_PLAN';
export const RESUME_PAYMENT_PLAN = 'RESUME_PAYMENT_PLAN';
export const WRITE_OFF_PAYMENT_PLAN = 'WRITE_OFF_PAYMENT_PLAN';
export const SUSPEND_PAYMENT_PLAN_PAT = 'SUSPEND_PAYMENT_PLAN_PAT';
export const RESUME_PAYMENT_PLAN_PAT = 'RESUME_PAYMENT_PLAN_PAT';
export const WRITE_OFF_PAYMENT_PLAN_PAT = 'WRITE_OFF_PAYMENT_PLAN_PAT';
export const REQUEST_PAYMENT_PLAN_PDF = 'REQUEST_PAYMENT_PLAN_PDF';
export const RECEIVE_PAYMENT_PLAN_PDF = 'RECEIVE_PAYMENT_PLAN_PDF';

export const REQUEST_PAYMENT_PLAN_INSTALLMENTS = 'REQUEST_PAYMENT_PLAN_INSTALLMENTS';
export const RECEIVE_PAYMENT_PLAN_INSTALLMENTS = 'RECEIVE_PAYMENT_PLAN_INSTALLMENTS';

export const REQUEST_PP_ISSUE_INVOICES = 'REQUEST_PP_ISSUE_INVOICES';
export const RECEIVE_PP_ISSUE_INVOICES = 'RECEIVE_PP_ISSUE_INVOICES';

// User related

export const REQUEST_ACCOUNTS_GROUPS = 'REQUEST_ACCOUNTS_GROUPS';
export const RECEIVE_ACCOUNTS_GROUPS = 'RECEIVE_ACCOUNTS_GROUPS';

export const REQUEST_USER_SEARCH = 'REQUEST_USER_SEARCH';
export const RECEIVE_USER_SEARCH = 'RECEIVE_USER_SEARCH';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';

export const REQUEST_MESSENGER_RECIPIENTS = 'REQUEST_MESSENGER_RECIPIENTS';
export const RECEIVE_MESSENGER_RECIPIENTS = 'RECEIVE_MESSENGER_RECIPIENTS';

export const DIARY_SORT_ORDER = 'DIARY_SORT_ORDER';

export const REQUEST_ACTIVE_POPUPS = 'REQUEST_ACTIVE_POPUPS';
export const RECEIVE_ACTIVE_POPUPS = 'RECEIVE_ACTIVE_POPUPS';

export const PATIENT_USER_STATE = 'PATIENT_USER_STATE';

export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS';
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';

export const UPDATE_USER_NHS_DETAILS = 'UPDATE_USER_NHS_DETAILS';

export const REQUEST_USER_PATIENTS = 'REQUEST_USER_PATIENTS';
export const RECEIVE_USER_PATIENTS = 'RECEIVE_USER_PATIENTS';

export const REQUEST_USER_ADD = 'REQUEST_USER_ADD';
export const RECEIVE_USER_ADD = 'RECEIVE_USER_ADD';
export const CLEAR_USER_ADD = 'CLEAR_USER_ADD';

export const REQUEST_ALL_USERS_AND_CAPABILITIES = 'REQUEST_ALL_USERS_AND_CAPABILITIES';
export const RECEIVE_ALL_USERS_AND_CAPABILITIES = 'RECEIVE_ALL_USERS_AND_CAPABILITIES';

export const SAVE_USERS_CAPABILITIES = 'SAVE_USERS_CAPABILITIES';
export const SAVE_ROLES_CAPABILITIES = 'SAVE_ROLES_CAPABILITIES';
export const CLEAR_ROLES_CAPABILITIES = 'CLEAR_ROLES_CAPABILITIES';

export const REQUEST_SAVE_USER_DETAILS = 'REQUEST_SAVE_USER_DETAILS';
export const COMPLETED_SAVE_USER_DETAILS = 'COMPLETED_SAVE_USER_DETAILS';

export const REQUEST_SET_USER_STATUS = 'REQUEST_SET_USER_STATUS';
export const COMPLETED_SET_USER_STATUS = 'COMPLETED_SET_USER_STATUS';

export const REQUEST_PROVIDER_PERFORMERS = 'REQUEST_PROVIDER_PERFORMERS';
export const RECEIVE_PROVIDER_PERFORMERS = 'RECEIVE_PROVIDER_PERFORMERS';

// Referrer related

export const REQUEST_REFERRER_SEARCH = 'REQUEST_REFERRER_SEARCH';
export const RECEIVE_REFERRER_SEARCH = 'RECEIVE_REFERRER_SEARCH';

export const RECEIVE_REFERRER_ADD = 'RECEIVE_REFERRER_ADD';
export const CLEAR_REFERRER_ADD = 'CLEAR_REFERRER_ADD';

export const RECEIVE_REFERRER_DETAILS = 'RECEIVE_REFERRER_DETAILS';

export const RECEIVE_REFERRER_PATIENTS = 'RECEIVE_REFERRER_PATIENTS';

export const CLEAR_REFERRER_DETAILS = 'CLEAR_REFERRER_DETAILS';

// Patient related

export const PATIENT_REGISTER = 'PATIENT_REGISTER';
export const PATIENT_SUBMIT = 'PATIENT_SUBMIT';
export const PATIENT_CONFIRM = 'PATIENT_CONFIRM';

export const REQUEST_PATIENT_ADD = 'REQUEST_PATIENT_ADD';
export const RECEIVE_PATIENT_ADD = 'RECEIVE_PATIENT_ADD';
export const CLEAR_PATIENT_ADD = 'CLEAR_PATIENT_ADD';
export const PATIENT_ADD_ERROR = 'PATIENT_ADD_ERROR';

export const REQUEST_PATIENT_ADD_ONLINE = 'REQUEST_PATIENT_ADD_ONLINE';

export const CLEAR_PATIENT_SEARCH = 'CLEAR_PATIENT_SEARCH';
export const PATIENT_WRITE_OFF = 'PATIENT_WRITE_OFF';
export const REQUEST_PATIENT_REACTIVATE = 'REQUEST_PATIENT_REACTIVATE';
export const RECEIVE_PATIENT_REACTIVATE = 'RECEIVE_PATIENT_REACTIVATE';

export const REQUEST_PATIENT_DETAILS = 'REQUEST_PATIENT_DETAILS';
export const RECEIVE_PATIENT_DETAILS = 'RECEIVE_PATIENT_DETAILS';

export const REQUEST_PATIENT_NOK = 'REQUEST_PATIENT_NOK';
export const RECEIVE_PATIENT_NOK = 'RECEIVE_PATIENT_NOK';

export const REQUEST_PATIENT_NHS_DETAILS = 'REQUEST_PATIENT_NHS_DETAILS';
export const RECEIVE_PATIENT_NHS_DETAILS = 'RECEIVE_PATIENT_NHS_DETAILS';

export const REQUEST_PATIENT_PORTAL_DATA = 'REQUEST_PATIENT_PORTAL_DATA';
export const RECEIVE_PATIENT_PORTAL_DATA = 'RECEIVE_PATIENT_PORTAL_DATA';

export const SAVE_PATIENT_DETAILS = 'SAVE_PATIENT_DETAILS';
export const SAVE_PATIENT_PORTAL_DETAILS = 'SAVE_PATIENT_PORTAL_DETAILS';
export const CLEAR_PATIENT_DETAILS = 'CLEAR_PATIENT_DETAILS';
export const SAVE_CLEAR_PATIENT_DETAILS = 'SAVE_CLEAR_PATIENT_DETAILS';

export const SAVE_PATIENT_NOK_DETAILS = 'SAVE_PATIENT_NOK_DETAILS';
export const CLEAR_PATIENT_NOK_DETAILS = 'CLEAR_PATIENT_NOK_DETAILS';
export const SAVE_CLEAR_PATIENT_NOK_DETAILS = 'SAVE_CLEAR_PATIENT_NOK_DETAILS';
export const SAVE_PATIENT_MED_COND = 'SAVE_PATIENT_MED_COND';

export const REQUEST_PATIENT_PASS = 'REQUEST_PATIENT_PASS';
export const RECEIVE_PATIENT_PASS = 'RECEIVE_PATIENT_PASS';

export const REQUEST_PATIENT_MHFS = 'REQUEST_PATIENT_MHFS';
export const RECEIVE_PATIENT_MHFS = 'RECEIVE_PATIENT_MHFS';

export const REQUEST_PATIENT_MHF = 'REQUEST_PATIENT_MHF';
export const RECEIVE_PATIENT_MHF = 'RECEIVE_PATIENT_MHF';

export const SAVE_PATIENT_MHF = 'SAVE_PATIENT_MHF';
export const CLEAR_PATIENT_MHF = 'CLEAR_PATIENT_MHF';

export const REQUEST_PATIENT_QUESTIONNAIRE = 'REQUEST_PATIENT_QUESTIONNAIRE';
export const RECEIVE_PATIENT_QUESTIONNAIRE = 'RECEIVE_PATIENT_QUESTIONNAIRE';

export const REQUEST_PATIENT_QUESTIONNAIRE_BY_ID = 'REQUEST_PATIENT_QUESTIONNAIRE_BY_ID';
export const RECEIVE_PATIENT_QUESTIONNAIRE_BY_ID = 'RECEIVE_PATIENT_QUESTIONNAIRE_BY_ID';

export const REQUEST_QUERY_PATIENT_QUESTIONNAIRE = 'REQUEST_QUERY_PATIENT_QUESTIONNAIRE';
export const RECEIVE_QUERY_PATIENT_QUESTIONNAIRE = 'RECEIVE_QUERY_PATIENT_QUESTIONNAIRE';

export const REQUEST_COMPLETE_UNCHANGED= 'REQUEST_COMPLETE_UNCHANGED';
export const RECEIVE_COMPLETE_UNCHANGED = 'RECEIVE_COMPLETE_UNCHANGED';

export const REQUEST_PRECOMPLETED_QUESTIONNAIRES = 'REQUEST_PRECOMPLETED_QUESTIONNAIRES';
export const RECEIVE_PRECOMPLETED_QUESTIONNAIRES = 'RECEIVE_PRECOMPLETED_QUESTIONNAIRES';

export const REQUEST_COMPLETED_QUESTIONNAIRES = 'REQUEST_COMPLETED_QUESTIONNAIRES';
export const RECEIVE_COMPLETED_QUESTIONNAIRES = 'RECEIVE_COMPLETED_QUESTIONNAIRES';

export const SAVE_PATIENT_QUESTIONNAIRE = 'SAVE_PATIENT_QUESTIONNAIRE';
export const CLEAR_PATIENT_QUESTIONNAIRE = 'CLEAR_PATIENT_QUESTIONNAIRE';

export const REQUEST_PATIENT_MCONS = 'REQUEST_PATIENT_MCONS';
export const RECEIVE_PATIENT_MCONS = 'RECEIVE_PATIENT_MCONS';

export const REQUEST_PATIENT_TREATMENT_HISTORY = 'REQUEST_PATIENT_TREATMENT_HISTORY';
export const RECEIVE_PATIENT_TREATMENT_HISTORY = 'RECEIVE_PATIENT_TREATMENT_HISTORY';

export const SAVE_PATIENT_TREATMENT_HISTORY = 'SAVE_PATIENT_TREATMENT_HISTORY';
export const CLEAR_PATIENT_TREATMENT_HISTORY = 'CLEAR_PATIENT_TREATMENT_HISTORY';

export const REQUEST_PATIENT_APPOINTMENT_TREATMENT_HISTORY = 'REQUEST_PATIENT_APPOINTMENT_TREATMENT_HISTORY';
export const RECEIVE_PATIENT_APPOINTMENT_TREATMENT_HISTORY = 'RECEIVE_PATIENT_APPOINTMENT_TREATMENT_HISTORY';

export const REQUEST_PATIENT_ACCOUNT_HISTORY_OVERVIEW = 'REQUEST_PATIENT_ACCOUNT_HISTORY_OVERVIEW';
export const RECEIVE_PATIENT_ACCOUNT_HISTORY_OVERVIEW = 'RECEIVE_PATIENT_ACCOUNT_HISTORY_OVERVIEW';

export const REQUEST_PATIENT_ACCOUNT_HISTORY = 'REQUEST_PATIENT_ACCOUNT_HISTORY';
export const RECEIVE_PATIENT_ACCOUNT_HISTORY = 'RECEIVE_PATIENT_ACCOUNT_HISTORY';
export const REQUEST_PATIENT_ACCOUNT_HISTORY_PDF = 'REQUEST_PATIENT_ACCOUNT_HISTORY_PDF';
export const RECEIVE_PATIENT_ACCOUNT_HISTORY_PDF = 'RECEIVE_PATIENT_ACCOUNT_HISTORY_PDF';
export const CLEAR_PATIENT_ACCOUNT_HISTORY_PDF = 'CLEAR_PATIENT_ACCOUNT_HISTORY_PDF';

export const REQUEST_PATIENT_PERIO_HISTORY = 'REQUEST_PATIENT_PERIO_HISTORY';
export const RECEIVE_PATIENT_PERIO_HISTORY = 'RECEIVE_PATIENT_PERIO_HISTORY';

export const SAVE_PATIENT_PERIO_CHART = 'SAVE_PATIENT_PERIO_CHART';
export const DELETE_PATIENT_PERIO_CHART = 'DELETE_PATIENT_PERIO_CHART';
export const CLEAR_PATIENT_PERIO_HISTORY = 'CLEAR_PATIENT_PERIO_HISTORY';

export const REQUEST_ACCOUNT_PAYMENT = 'REQUEST_ACCOUNT_PAYMENT';
export const RECEIVE_ACCOUNT_PAYMENT = 'RECEIVE_ACCOUNT_PAYMENT';

export const REQUEST_ACCOUNT_RECEIPT = 'REQUEST_ACCOUNT_RECEIPT';
export const RECEIVE_ACCOUNT_RECEIPT = 'RECEIVE_ACCOUNT_RECEIPT';

export const REQUEST_ACCOUNT_ITEM_PDF = 'REQUEST_ACCOUNT_ITEM_PDF';
export const RECEIVE_ACCOUNT_ITEM_PDF = 'RECEIVE_ACCOUNT_ITEM_PDF';

export const REQUEST_CHANGE_PAY_TYPE = 'REQUEST_CHANGE_PAY_TYPE';
export const RECEIVE_CHANGE_PAY_TYPE = 'RECEIVE_CHANGE_PAY_TYPE';

export const REQUEST_PAYMENT_PLAN_HISTORY = 'REQUEST_PAYMENT_PLAN_HISTORY';
export const RECEIVE_PAYMENT_PLAN_HISTORY = 'RECEIVE_PAYMENT_PLAN_HISTORY';

export const PATIENT_ACCESS_ADD = 'PATIENT_ACCESS_ADD';

export const REQUEST_PATIENT_APPOINTMENT_SUMMARY = 'REQUEST_PATIENT_APPOINTMENT_SUMMARY';
export const RECEIVE_PATIENT_APPOINTMENT_SUMMARY = 'RECEIVE_PATIENT_APPOINTMENT_SUMMARY';
export const CLEAR_PATIENT_APPOINTMENT_SUMMARY = 'CLEAR_PATIENT_APPOINTMENT_SUMMARY';
export const REQUEST_PATIENT_APPOINTMENT_HISTORY_PDF = 'REQUEST_PATIENT_APPOINTMENT_HISTORY_PDF';
export const RECEIVE_PATIENT_APPOINTMENT_HISTORY_PDF = 'RECEIVE_PATIENT_APPOINTMENT_HISTORY_PDF';

export const REQUEST_PATIENT_DETAILS_SHORT = 'REQUEST_PATIENT_DETAILS_SHORT';
export const RECEIVE_PATIENT_DETAILS_SHORT = 'RECEIVE_PATIENT_DETAILS_SHORT';

export const REQUEST_PATIENT_REMINDERS = 'REQUEST_PATIENT_REMINDERS';
export const RECEIVE_PATIENT_REMINDERS = 'RECEIVE_PATIENT_REMINDERS';

export const REQUEST_PATIENT_RECALLS = 'REQUEST_PATIENT_RECALLS';
export const RECEIVE_PATIENT_RECALLS = 'RECEIVE_PATIENT_RECALLS';

export const REQUEST_PATIENT_NOTES = 'REQUEST_PATIENT_NOTES';
export const RECEIVE_PATIENT_NOTES = 'RECEIVE_PATIENT_NOTES';
export const REQUEST_PATIENT_NOTE_HISTORY_PDF = 'REQUEST_PATIENT_NOTE_HISTORY_PDF';
export const RECEIVE_PATIENT_NOTE_HISTORY_PDF = 'RECEIVE_PATIENT_NOTE_HISTORY_PDF';
export const SAVE_PATIENT_NOTES = 'SAVE_PATIENT_NOTES';
export const CLEAR_PATIENT_NOTES = 'CLEAR_PATIENT_NOTES';
export const DELETE_PATIENT_NOTE = 'DELETE_PATIENT_NOTE';

export const REQUEST_PATIENT_APPOINTMENT_NOTES = 'REQUEST_PATIENT_APPOINTMENT_NOTES';
export const RECEIVE_PATIENT_APPOINTMENT_NOTES = 'RECEIVE_PATIENT_APPOINTMENT_NOTES';
export const SAVE_PATIENT_APPOINTMENT_NOTES = 'SAVE_PATIENT_APPOINTMENT_NOTES';
export const CLEAR_PATIENT_APPOINTMENT_NOTES = 'CLEAR_PATIENT_APPOINTMENT_NOTES';
export const DELETE_PATIENT_APPOINTMENT_NOTE = 'DELETE_PATIENT_APPOINTMENT_NOTE';

export const REQUEST_PATIENT_XRAY_RECORDS = 'REQUEST_PATIENT_XRAY_RECORDS';
export const RECEIVE_PATIENT_XRAY_RECORDS = 'RECEIVE_PATIENT_XRAY_RECORDS';
export const SAVE_PATIENT_XRAY_RECORDS = 'SAVE_PATIENT_XRAY_RECORDS';
export const CLEAR_PATIENT_XRAY_RECORDS = 'CLEAR_PATIENT_XRAY_RECORDS';
export const DELETE_PATIENT_XRAY_RECORD = 'DELETE_PATIENT_XRAY_RECORD';

export const REQUEST_PATIENT_DOCUMENTS = 'REQUEST_PATIENT_DOCUMENTS';
export const RECEIVE_PATIENT_DOCUMENTS = 'RECEIVE_PATIENT_DOCUMENTS';
export const CREATE_PATIENT_DOCUMENTS = 'CREATE_PATIENT_DOCUMENTS';
export const SAVE_PATIENT_DOCUMENTS = 'SAVE_PATIENT_DOCUMENTS';
export const OPEN_PATIENT_DOCUMENTS = 'OPEN_PATIENT_DOCUMENTS';
export const DELETE_PATIENT_DOCUMENTS = 'DELETE_PATIENT_DOCUMENTS';
export const USE_AS_PHOTO = 'USE_AS_PHOTO';
export const REQUEST_PATIENT_DOCUMENT_PDF = 'REQUEST_PATIENT_DOCUMENT_PDF';
export const RECEIVE_PATIENT_DOCUMENT_PDF = 'RECEIVE_PATIENT_DOCUMENT_PDF';

export const REQUEST_PATIENT_BILLING_GROUP = 'REQUEST_PATIENT_BILLING_GROUP';
export const RECEIVE_PATIENT_BILLING_GROUP = 'RECEIVE_PATIENT_BILLING_GROUP';

export const ADD_PATIENT_BILLING_GROUP = 'ADD_PATIENT_BILLING_GROUP';
export const UPDATE_PATIENT_BILLING_GROUP = 'UPDATE_PATIENT_BILLING_GROUP';
export const REMOVE_PATIENT_BILLING_GROUP = 'REMOVE_PATIENT_BILLING_GROUP';
export const DELETE_PATIENT_BILLING_GROUP = 'DELETE_PATIENT_BILLING_GROUP';

export const REQUEST_PATIENT_BG_IN_FIND = 'REQUEST_PATIENT_BG_IN_FIND';
export const RECEIVE_PATIENT_BG_IN_FIND = 'RECEIVE_PATIENT_BG_IN_FIND';

export const REQUEST_PATIENT_ASSESSMENTS = 'REQUEST_PATIENT_ASSESSMENTS';
export const RECEIVE_PATIENT_ASSESSMENTS = 'RECEIVE_PATIENT_ASSESSMENTS';
export const SAVE_PAT_ASSESSMENT = 'SAVE_PAT_ASSESSMENT';
export const CLEAR_PAT_ASSESSMENT = 'CLEAR_PAT_ASSESSMENT';

export const REQUEST_PATIENT_ASSESSMENTS_APPOINTMENT = 'REQUEST_PATIENT_ASSESSMENTS_APPOINTMENT';
export const RECEIVE_PATIENT_ASSESSMENTS_APPOINTMENT = 'RECEIVE_PATIENT_ASSESSMENTS_APPOINTMENT';
export const SAVE_PAT_ASSESSMENT_APPOINTMENT = 'SAVE_PAT_ASSESSMENT_APPOINTMENT';
export const CLEAR_PAT_ASSESSMENT_APPOINTMENT = 'CLEAR_PAT_ASSESSMENT_APPOINTMENT';

export const REQUEST_ORTHO_ASSESSMENTS = 'REQUEST_ORTHO_ASSESSMENTS';
export const RECEIVE_ORTHO_ASSESSMENTS = 'RECEIVE_ORTHO_ASSESSMENTS';
export const SAVE_ORTHO_ASSESSMENT = 'SAVE_ORTHO_ASSESSMENT';
export const CLEAR_ORTHO_ASSESSMENT = 'CLEAR_ORTHO_ASSESSMENT';

export const REQUEST_ORTHO_ASSESSMENTS_APPOINTMENT = 'REQUEST_ORTHO_ASSESSMENTS_APPOINTMENT';
export const RECEIVE_ORTHO_ASSESSMENTS_APPOINTMENT = 'RECEIVE_ORTHO_ASSESSMENTS_APPOINTMENT';
export const SAVE_ORTHO_ASSESSMENT_APPOINTMENT = 'SAVE_ORTHO_ASSESSMENT_APPOINTMENT';
export const CLEAR_ORTHO_ASSESSMENT_APPOINTMENT = 'CLEAR_ORTHO_ASSESSMENT_APPOINTMENT';

export const REQUEST_NHS_REGISTRATION = 'REQUEST_NHS_REGISTRATION';
export const RECEIVE_NHS_REGISTRATION = 'RECEIVE_NHS_REGISTRATION';
export const SAVE_NHS_REGISTRATION = 'SAVE_NHS_REGISTRATION';
export const CLEAR_NHS_REGISTRATION = 'CLEAR_NHS_REGISTRATION';

export const REQUEST_NHS_CLAIM_HISTORY = 'REQUEST_NHS_CLAIM_HISTORY';
export const RECEIVE_NHS_CLAIM_HISTORY = 'RECEIVE_NHS_CLAIM_HISTORY';

export const REQUEST_PATIENT_ISSUE_INVOICE = 'REQUEST_PATIENT_ISSUE_INVOICE';
export const RECEIVE_PATIENT_ISSUE_INVOICE = 'RECEIVE_PATIENT_ISSUE_INVOICE';

export const REQUEST_PATIENT_ISSUE_RECEIPT = 'REQUEST_PATIENT_ISSUE_RECEIPT';
export const RECEIVE_PATIENT_ISSUE_RECEIPT = 'RECEIVE_PATIENT_ISSUE_RECEIPT';

export const REQUEST_PATIENT_MAKE_PAYMENT = 'REQUEST_PATIENT_MAKE_PAYMENT';
export const RECEIVE_PATIENT_MAKE_PAYMENT = 'RECEIVE_PATIENT_MAKE_PAYMENT';

export const REQUEST_PATIENT_MAKE_REFUND = 'REQUEST_PATIENT_MAKE_REFUND';
export const RECEIVE_PATIENT_MAKE_REFUND = 'RECEIVE_PATIENT_MAKE_REFUND';

export const REQUEST_PATIENT_ISSUE_CREDIT = 'REQUEST_PATIENT_ISSUE_CREDIT';
export const RECEIVE_PATIENT_ISSUE_CREDIT = 'RECEIVE_PATIENT_ISSUE_CREDIT';

export const REQUEST_PATIENT_ACCOUNT_RECALC = 'REQUEST_PATIENT_ACCOUNT_RECALC';
export const RECEIVE_PATIENT_ACCOUNT_RECALC = 'RECEIVE_PATIENT_ACCOUNT_RECALC';

export const REQUEST_PATIENT_ACCESS = 'REQUEST_PATIENT_ACCESS';
export const RECEIVE_PATIENT_ACCESS = 'RECEIVE_PATIENT_ACCESS';
export const CLEAR_PATIENT_ACCESS = 'CLEAR_PATIENT_ACCESS';

export const REQUEST_PLAN_PROVIDERS = 'REQUEST_PLAN_PROVIDERS';
export const RECEIVE_PLAN_PROVIDERS = 'RECEIVE_PLAN_PROVIDERS';

export const SEND_SMS = 'SEND_SMS';
export const REQUEST_SMS_CREDIT_LIMIT = 'REQUEST_SMS_CREDIT_LIMIT';
export const RECEIVE_SMS_CREDIT_LIMIT = 'RECEIVE_SMS_CREDIT_LIMIT';

export const RESEND_PORTAL_REFERENCE = 'RESEND_PORTAL_REFERENCE';
export const RESET_PORTAL_PASSWORD = 'RESET_PORTAL_PASSWORD';

export const PREVIEW_PATIENT_DOCUMENT = 'PREVIEW_PATIENT_DOCUMENT';
export const SEND_PATIENT_DOCUMENT = 'SEND_PATIENT_DOCUMENT';

export const PREVIEW_ACCOUNT_ITEM = 'PREVIEW_ACCOUNT_ITEM';
export const SEND_ACCOUNT_ITEM = 'SEND_ACCOUNT_ITEM';

// Housekeeping related
export const SAVE_HOUSEKEEPING = 'SAVE_HOUSEKEEPING';
export const CLEAR_HOUSEKEEPING = 'CLEAR_HOUSEKEEPING';
export const SAVE_CLEAR_HOUSEKEEPING = 'SAVE_CLEAR_HOUSEKEEPING';

export const REQUEST_HOUSEKEEPING_TITLES = 'REQUEST_HOUSEKEEPING_TITLES';
export const RECEIVE_HOUSEKEEPING_TITLES = 'RECEIVE_HOUSEKEEPING_TITLES';
export const SAVE_HOUSEKEEPING_TITLES = 'SAVE_HOUSEKEEPING_TITLES';
export const DELETE_HOUSEKEEPING_TITLES = 'DELETE_HOUSEKEEPING_TITLES';

export const REQUEST_HOUSEKEEPING_CSCHED = 'REQUEST_HOUSEKEEPING_CSCHED';
export const RECEIVE_HOUSEKEEPING_CSCHED = 'RECEIVE_HOUSEKEEPING_CSCHED';
export const SAVE_HOUSEKEEPING_CSCHED = 'SAVE_HOUSEKEEPING_CSCHED';
export const DELETE_HOUSEKEEPING_CSCHED = 'DELETE_HOUSEKEEPING_CSCHED';
export const REMOVE_HOUSEKEEPING_CSCHED = 'REMOVE_HOUSEKEEPING_CSCHED';

export const REQUEST_HOUSEKEEPING_TCODES = 'REQUEST_HOUSEKEEPING_TCODES';
export const RECEIVE_HOUSEKEEPING_TCODES = 'RECEIVE_HOUSEKEEPING_TCODES';
export const SAVE_HOUSEKEEPING_TCODES = 'SAVE_HOUSEKEEPING_TCODES';
export const DELETE_HOUSEKEEPING_TCODES = 'DELETE_HOUSEKEEPING_TCODES';

export const REQUEST_HOUSEKEEPING_JSTAGES = 'REQUEST_HOUSEKEEPING_JSTAGES';
export const RECEIVE_HOUSEKEEPING_JSTAGES = 'RECEIVE_HOUSEKEEPING_JSTAGES';
export const SAVE_HOUSEKEEPING_JSTAGES = 'SAVE_HOUSEKEEPING_JSTAGES';
export const DELETE_HOUSEKEEPING_JSTAGES = 'DELETE_HOUSEKEEPING_JSTAGES';

export const REQUEST_HOUSEKEEPING_CCS = 'REQUEST_HOUSEKEEPING_CCS';
export const RECEIVE_HOUSEKEEPING_CCS = 'RECEIVE_HOUSEKEEPING_CCS';
export const SAVE_HOUSEKEEPING_CCS = 'SAVE_HOUSEKEEPING_CCS';
export const DELETE_HOUSEKEEPING_CCS = 'DELETE_HOUSEKEEPING_CCS';

export const REQUEST_HOUSEKEEPING_FAVS = 'REQUEST_HOUSEKEEPING_FAVS';
export const RECEIVE_HOUSEKEEPING_FAVS = 'RECEIVE_HOUSEKEEPING_FAVS';
export const SAVE_HOUSEKEEPING_FAVS = 'SAVE_HOUSEKEEPING_FAVS';
export const DELETE_HOUSEKEEPING_FAVS = 'DELETE_HOUSEKEEPING_FAVS';

export const REQUEST_HOUSEKEEPING_ADETS = 'REQUEST_HOUSEKEEPING_ADETS';
export const RECEIVE_HOUSEKEEPING_ADETS = 'RECEIVE_HOUSEKEEPING_ADETS';
export const SAVE_HOUSEKEEPING_AT_ADETS = 'SAVE_HOUSEKEEPING_AT_ADETS';
export const SAVE_HOUSEKEEPING_DE_ADETS = 'SAVE_HOUSEKEEPING_DE_ADETS';
export const DELETE_HOUSEKEEPING_AT_ADETS = 'DELETE_HOUSEKEEPING_AT_ADETS';
export const DELETE_HOUSEKEEPING_DE_ADETS = 'DELETE_HOUSEKEEPING_DE_ADETS';

export const REQUEST_HOUSEKEEPING_RECTYPES = 'REQUEST_HOUSEKEEPING_RECTYPES';
export const RECEIVE_HOUSEKEEPING_RECTYPES = 'RECEIVE_HOUSEKEEPING_RECTYPES';
export const SAVE_HOUSEKEEPING_RECTYPES = 'SAVE_HOUSEKEEPING_RECTYPES';
export const DELETE_HOUSEKEEPING_RECTYPES = 'DELETE_HOUSEKEEPING_RECTYPES';

export const REQUEST_HOUSEKEEPING_MCS = 'REQUEST_HOUSEKEEPING_MCS';
export const RECEIVE_HOUSEKEEPING_MCS = 'RECEIVE_HOUSEKEEPING_MCS';
export const SAVE_HOUSEKEEPING_MCS = 'SAVE_HOUSEKEEPING_MCS';
export const DELETE_HOUSEKEEPING_MCS = 'DELETE_HOUSEKEEPING_MCS';

export const REQUEST_HOUSEKEEPING_QUES = 'REQUEST_HOUSEKEEPING_QUES';
export const RECEIVE_HOUSEKEEPING_QUES = 'RECEIVE_HOUSEKEEPING_QUES';
export const SAVE_HOUSEKEEPING_QUES = 'SAVE_HOUSEKEEPING_QUES';
export const DELETE_HOUSEKEEPING_QUES = 'DELETE_HOUSEKEEPING_QUES';

export const REQUEST_HOUSEKEEPING_CRS = 'REQUEST_HOUSEKEEPING_CRS';
export const RECEIVE_HOUSEKEEPING_CRS = 'RECEIVE_HOUSEKEEPING_CRS';
export const SAVE_HOUSEKEEPING_CRS = 'SAVE_HOUSEKEEPING_CRS';
export const DELETE_HOUSEKEEPING_CRS = 'DELETE_HOUSEKEEPING_CRS';

export const REQUEST_HOUSEKEEPING_OCCS = 'REQUEST_HOUSEKEEPING_OCCS';
export const RECEIVE_HOUSEKEEPING_OCCS = 'RECEIVE_HOUSEKEEPING_OCCS';
export const SAVE_HOUSEKEEPING_OCCS = 'SAVE_HOUSEKEEPING_OCCS';
export const DELETE_HOUSEKEEPING_OCCS = 'DELETE_HOUSEKEEPING_OCCS';

export const REQUEST_HOUSEKEEPING_ASSCATS = 'REQUEST_HOUSEKEEPING_ASSCATS';
export const RECEIVE_HOUSEKEEPING_ASSCATS = 'RECEIVE_HOUSEKEEPING_ASSCATS';
export const SAVE_HOUSEKEEPING_ASSCATS = 'SAVE_HOUSEKEEPING_ASSCATS';
export const DELETE_HOUSEKEEPING_ASSCATS = 'DELETE_HOUSEKEEPING_ASSCATS';

export const RECEIVE_CREDIT_LIMIT = 'RECEIVE_CREDIT_LIMIT';

// User Capabilities

export const REQUEST_CAPABILITIES = 'REQUEST_CAPABILITIES';
export const RECEIVE_CAPABILITIES = 'RECEIVE_CAPABILITIES';

// templates

export const REQUEST_TEMPLATES_SYSTEM = 'REQUEST_TEMPLATES_SYSTEM';
export const RECEIVE_TEMPLATES_SYSTEM = 'RECEIVE_TEMPLATES_SYSTEM';
export const SAVE_TEMPLATES_SYSTEM = 'SAVE_TEMPLATES_SYSTEM';
export const COPY_TEMPLATES_SYSTEM = 'COPY_TEMPLATES_SYSTEM';
export const DELETE_TEMPLATES_SYSTEM = 'DELETE_TEMPLATES_SYSTEM';

export const REQUEST_TEMPLATES_PATIENT = 'REQUEST_TEMPLATES_PATIENT';
export const RECEIVE_TEMPLATES_PATIENT = 'RECEIVE_TEMPLATES_PATIENT';
export const SAVE_TEMPLATES_PATIENT = 'SAVE_TEMPLATES_PATIENT';
export const COPY_TEMPLATES_PATIENT = 'COPY_TEMPLATES_PATIENT';
export const DELETE_TEMPLATES_PATIENT = 'DELETE_TEMPLATES_PATIENT';

export const REQUEST_PATIENT_DOC_TEMPLATES = 'REQUEST_PATIENT_DOC_TEMPLATES';
export const RECEIVE_PATIENT_DOC_TEMPLATES = 'RECEIVE_PATIENT_DOC_TEMPLATES';

export const CREATE_TP_DOCUMENT = 'CREATE_TP_DOCUMENT';
export const REQUEST_TEMPLATES_TREATMENT_PLANS = 'REQUEST_TEMPLATES_TREATMENT_PLANS';
export const RECEIVE_TEMPLATES_TREATMENT_PLANS = 'RECEIVE_TEMPLATES_TREATMENT_PLANS';

export const REQUEST_TEMPLATES_PAYMENT_PLANS = 'REQUEST_TEMPLATES_PAYMENT_PLANS';
export const RECEIVE_TEMPLATES_PAYMENT_PLANS = 'RECEIVE_TEMPLATES_PAYMENT_PLANS';

export const REQUEST_TEMPLATES_REFERRAL = 'REQUEST_TEMPLATES_REFERRAL';
export const RECEIVE_TEMPLATES_REFERRAL = 'RECEIVE_TEMPLATES_REFERRAL';

export const REQUEST_TEMPLATES_PRESCRIPTIONS = 'REQUEST_TEMPLATES_PRESCRIPTIONS';
export const RECEIVE_TEMPLATES_PRESCRIPTIONS = 'RECEIVE_TEMPLATES_PRESCRIPTIONS';

export const REQUEST_TEMPLATES_APPOINTMENT_LETTER = 'REQUEST_TEMPLATES_APPOINTMENT_LETTER';
export const RECEIVE_TEMPLATES_APPOINTMENT_LETTER = 'RECEIVE_TEMPLATES_APPOINTMENT_LETTER';

export const REQUEST_TEMPLATES_CONSENT_FORMS = 'REQUEST_TEMPLATES_CONSENT_FORMS';
export const RECEIVE_TEMPLATES_CONSENT_FORMS = 'RECEIVE_TEMPLATES_CONSENT_FORMS';

export const RECEIVE_REPORT_TYPES = 'RECEIVE_REPORT_TYPES';

// template notes

export const SAVE_TEMPLATE_NOTES = 'SAVE_TEMPLATE_NOTES';
export const CLEAR_TEMPLATE_NOTES = 'CLEAR_TEMPLATE_NOTES';
export const SAVE_CLEAR_TEMPLATE_NOTES = 'SAVE_CLEAR_TEMPLATE_NOTES';
export const DELETE_TEMPLATE_NOTES = 'DELETE_TEMPLATE_NOTES';

export const REQUEST_TEMPLATE_NOTES = 'REQUEST_TEMPLATE_NOTES';
export const RECEIVE_TEMPLATE_NOTES = 'RECEIVE_TEMPLATE_NOTES';

// Charting Resources

export const REQUEST_CHART_RESOURCES = 'REQUEST_CHART_RESOURCES';
export const RECEIVE_CHART_RESOURCES = 'RECEIVE_CHART_RESOURCES';

export const REQUEST_CHART = 'REQUEST_CHART';
export const RECEIVE_CHART = 'RECEIVE_CHART';
export const SAVE_CHART = 'SAVE_CHART';
export const CLEAR_CHART = 'CLEAR_CHART';
export const SAVE_CLEAR_CHART = 'SAVE_CLEAR_CHART';

// Web socket messaging

export const WSM_OPEN = 'WSM_OPEN';
export const WSM_CLOSED = 'WSM_CLOSED';

export const WSM_SET_STATE = 'WSM_SET_STATE';
export const WSM_SEND = 'WSM_SEND';
export const WSM_SEND_USER = 'WSM_SEND_USER';
export const WSM_RECEIVE = 'WSM_RECEIVE';

export const WSM_UPDATES = 'WSM_UPDATES';
export const WSM_APPOINTMENTS = 'WSM_APPOINTMENTS';
export const WSM_INVOICES = 'WSM_INVOICES';
export const WSM_USERS = 'WSM_USERS';

// State management

export const MESSAGE_BUS = 'MESSAGE_BUS';

export const SET_STATE = 'SET_STATE';

// Recalls

export const RECALL_CLOSE = 'RECALL_CLOSE';

// Tasks

export const RECEIVE_TASKS_OUTSTANDING = 'RECEIVE_TASKS_OUTSTANDING';

export const REQUEST_TASK_DIARY = 'REQUEST_TASK_DIARY';
export const RECEIVE_TASK_DIARY = 'RECEIVE_TASK_DIARY';

// Tablet

export const RECEIVE_TAB_PATIENT_DETAILS = 'RECEIVE_TAB_PATIENT_DETAILS';

export const RECEIVE_TAB_PATIENT_MED_CONS = 'RECEIVE_TAB_PATIENT_MED_CONS';

export const REQUEST_TAB_PATIENT_QUESTIONNAIRES = 'REQUEST_TAB_PATIENT_QUESTIONNAIRES';
export const RECEIVE_TAB_PATIENT_QUESTIONNAIRES = 'RECEIVE_TAB_PATIENT_QUESTIONNAIRES';

export const REQUEST_TAB_PATIENT_CONSENTS = 'REQUEST_TAB_PATIENT_CONSENTS';
export const RECEIVE_TAB_PATIENT_CONSENTS = 'RECEIVE_TAB_PATIENT_CONSENTS';
export const REQUEST_SAVE_TAB_PATIENT_CONSENTS = 'REQUEST_SAVE_TAB_PATIENT_CONSENTS';
export const RECEIVE_SAVE_TAB_PATIENT_CONSENTS = 'RECEIVE_SAVE_TAB_PATIENT_CONSENTS';

export const REQUEST_TAB_PATIENT_CONSENT_FORM_PDF = 'REQUEST_TAB_PATIENT_CONSENT_FORM_PDF';
export const RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF = 'RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF';

export const REQUEST_GDPR_CONSENT_FORM_PDF = 'REQUEST_GDPR_CONSENT_FORM_PDF';
export const RECEIVE_GDPR_CONSENT_FORM_PDF = 'RECEIVE_GDPR_CONSENT_FORM_PDF';

export const RECEIVE_TAB_PATIENT_TP_SIGNS = 'RECEIVE_TAB_PATIENT_TP_SIGNS';

export const RECEIVE_TAB_PATIENT_PP_SIGNS = 'RECEIVE_TAB_PATIENT_PP_SIGNS';

export const REQUEST_TAB_ADD_TO_LIST = 'REQUEST_TAB_ADD_TO_LIST';
export const RECEIVE_TAB_ADD_TO_LIST = 'RECEIVE_TAB_ADD_TO_LIST';

export const REQUEST_TAB_REMOVE_FROM_LIST = 'REQUEST_TAB_REMOVE_FROM_LIST';
export const RECEIVE_TAB_REMOVE_FROM_LIST = 'RECEIVE_TAB_REMOVE_FROM_LIST';
export const REQUEST_TAB_REMOVE_FROM_LIST_BY_ID = 'REQUEST_TAB_REMOVE_FROM_LIST_BY_ID';
export const RECEIVE_TAB_REMOVE_FROM_LIST_BY_ID = 'RECEIVE_TAB_REMOVE_FROM_LIST_BY_ID';

export const CLEAR_TAB_LIST = 'CLEAR_TAB_LIST';

export const REQUEST_TAB_POSSIBLES = 'REQUEST_TAB_POSSIBLES';
export const RECEIVE_TAB_POSSIBLES = 'RECEIVE_TAB_POSSIBLES';

// NHS Management

export const REQUEST_NHS_MANAGEMENT_STACKED = 'REQUEST_NHS_MANAGEMENT_STACKED';
export const RECEIVE_NHS_MANAGEMENT_STACKED = 'RECEIVE_NHS_MANAGEMENT_STACKED';

export const REQUEST_NHS_MANAGEMENT_SUBMITTED = 'REQUEST_NHS_MANAGEMENT_SUBMITTED';
export const RECEIVE_NHS_MANAGEMENT_SUBMITTED = 'RECEIVE_NHS_MANAGEMENT_SUBMITTED';

export const REQUEST_NHS_MANAGEMENT_REJECTED = 'REQUEST_NHS_MANAGEMENT_REJECTED';
export const RECEIVE_NHS_MANAGEMENT_REJECTED = 'RECEIVE_NHS_MANAGEMENT_REJECTED';

export const REQUEST_NHS_MANAGEMENT_REMOVED = 'REQUEST_NHS_MANAGEMENT_REMOVED';
export const RECEIVE_NHS_MANAGEMENT_REMOVED = 'RECEIVE_NHS_MANAGEMENT_REMOVED';

export const REQUEST_NHS_MANAGEMENT_SCHEDULED = 'REQUEST_NHS_MANAGEMENT_SCHEDULED';
export const RECEIVE_NHS_MANAGEMENT_SCHEDULED = 'RECEIVE_NHS_MANAGEMENT_SCHEDULED';
export const REQUEST_NHS_MANAGEMENT_SCHEDULED_DET = 'REQUEST_NHS_MANAGEMENT_SCHEDULED_DET';
export const RECEIVE_NHS_MANAGEMENT_SCHEDULED_DET = 'RECEIVE_NHS_MANAGEMENT_SCHEDULED_DET';

export const REQUEST_NHS_MANAGEMENT_CLAIM = 'REQUEST_NHS_MANAGEMENT_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_CLAIM = 'RECEIVE_NHS_MANAGEMENT_CLAIM';
export const REQUEST_NHS_MANAGEMENT_CLAIM_PATIENT = 'REQUEST_NHS_MANAGEMENT_CLAIM_PATIENT';
export const RECEIVE_NHS_MANAGEMENT_CLAIM_PATIENT = 'RECEIVE_NHS_MANAGEMENT_CLAIM_PATIENT';
export const REQUEST_NHS_MANAGEMENT_NHS_DETAILS = 'REQUEST_NHS_MANAGEMENT_NHS_DETAILS';
export const RECEIVE_NHS_MANAGEMENT_NHS_DETAILS = 'RECEIVE_NHS_MANAGEMENT_NHS_DETAILS';
export const SAVE_NHS_MANAGEMENT_CLAIM = 'SAVE_NHS_MANAGEMENT_CLAIM';
export const DELETE_NHS_MANAGEMENT_CLAIM = 'DELETE_NHS_MANAGEMENT_CLAIM';
export const CANCEL_NHS_MANAGEMENT_CLAIM = 'CANCEL_NHS_MANAGEMENT_CLAIM';
export const DUPLICATE_NHS_MANAGEMENT_CLAIM = 'DUPLICATE_NHS_MANAGEMENT_CLAIM';

export const REQUEST_NHS_MANAGEMENT_STACK_CLAIM = 'REQUEST_NHS_MANAGEMENT_STACK_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_STACK_CLAIM = 'RECEIVE_NHS_MANAGEMENT_SUBMIT_CLAIM';

export const REQUEST_NHS_MANAGEMENT_SUBMIT_CLAIM = 'REQUEST_NHS_MANAGEMENT_SUBMIT_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_SUBMIT_CLAIM = 'RECEIVE_NHS_MANAGEMENT_SUBMIT_CLAIM';

export const REQUEST_NHS_MANAGEMENT_RESUBMIT_CLAIM = 'REQUEST_NHS_MANAGEMENT_RESUBMIT_CLAIM';
export const RECEIVE_NHS_MANAGEMENT_RESUBMIT_CLAIM = 'RECEIVE_NHS_MANAGEMENT_RESUBMIT_CLAIM';
