import React from 'react';
import moment from 'moment';
import axios from 'axios';
import {AudioContext} from 'standardized-audio-context';
import {Panel} from "primereact/components/panel/Panel";
import {
    COLOR_ChiralBorder,
    PATIENT_ANSWER,
    PATIENT_CANDIDATE,
    PATIENT_CONNECTED,
    PATIENT_CONNECTED_RESULT,
    PATIENT_DISCONNECTED,
    PATIENT_DISCONNECTED_RESULT,
    PRACTICE_CANDIDATE,
    PRACTICE_CONNECTED,
    PRACTICE_CONNECTED_RESULT,
    PRACTICE_DISCONNECTED,
    PRACTICE_DISCONNECTED_RESULT,
    PRACTICE_OFFER,
    SE_NONE,
    SM_CLIENT_VIDEO_CALL,
    SM_CLIENT_VIDEO_CALL_SHARE_SCREEN,
    VIDEO_ERROR,
    VIDEO_OK,
    VIDEO_OWNER_PATIENT,
    VIDEO_OWNER_PRACTICE,
} from "./Constants";
import {Button} from "primereact/components/button/Button";
import {ClientComponent} from "./ClientComponent";
import {ac} from "../../index";
import {dispatchMessage} from "../../actions/websockets";
import {WSM_SEND} from "../../actions";
import {connect} from "react-redux";
import {RTCConfiguration} from "../../actions/fetchClient";
import {InputText} from "primereact/inputtext";
import PatientErrorBoundary, {dateTimeTemplate} from "./Utils";
import {Card} from "primereact/card";

class ConnectedVideoCall extends ClientComponent {

    constructor(props) {
        super(props);
        this.state = {

            groupId: ac.getGroupId(),

            videoCallInProgress: false,

            patientStream: null,

            appointmentStart: null,
            appointmentEnd: null,

            practiceAvailable: false,
            patientAvailable: false,
            practiceCalled: false,
            patientAnswered: false,

            audioStarted: false,
        };

        this.localVideoref = React.createRef();
        this.remoteVideoref = React.createRef();

        if (this.props.callOwner === VIDEO_OWNER_PATIENT) {
            const {nextVCAppointmentStart, nextVCAppointmentEnd} = props.patientData;
            this.state.appointmentStart = new Date(nextVCAppointmentStart);
            this.state.appointmentEnd = new Date(nextVCAppointmentEnd);

            const isAfter = moment().subtract(10, 'minute').isAfter(this.state.appointmentStart);
            const isBefore = moment().isBefore(moment(this.state.appointmentEnd));
            this.state.videoCallInProgress = isAfter && isBefore;
        } else {

        }
        this.state.videoCallInProgress = true;

        this.patientURL = "/assets/audio/108802__jordanielmills__07-up3.mp3";
        this.practiceURL = "/assets/audio/0766.mp3";

        this.audioContext = null;
    }

    componentDidMount() {

        this.props.dispatchMessage(JSON.stringify({
            groupId: this.state.groupId,
            type: this.props.callOwner === VIDEO_OWNER_PRACTICE ? PRACTICE_CONNECTED : PATIENT_CONNECTED,
            id: this.props.patientId,
        }))

        this.audioContext = new AudioContext();

        if (this.props.callOwner === VIDEO_OWNER_PATIENT) {
            axios.get(this.patientURL, {responseType: 'arraybuffer',})
                .then((res) => {

                    this.audioContext.decodeAudioData(res.data)
                        .then((audioBuffer) => {
                            this.patientSource = this.audioContext.createBufferSource();
                            this.patientSource.buffer = audioBuffer;
                            this.patientSource.connect(this.audioContext.destination)
                        })
                })
        } else {
            axios.get(this.practiceURL, {responseType: 'arraybuffer',})
                .then((res) => {

                    this.audioContext.decodeAudioData(res.data)
                        .then((audioBuffer) => {
                            this.practiceSource = this.audioContext.createBufferSource();
                            this.practiceSource.buffer = audioBuffer;
                            this.practiceSource.connect(this.audioContext.destination)
                        })
                })
        }
    }

    addTracks = (stream) => {

        if (stream === null) return;

        for (const track of stream.getTracks()) {
            ac.getRTCPeerConnection().addTrack(track, stream);
        }
    }

    onScreenSuccess = (stream) => {
        window.localStream = stream;
        this.localVideoref.current.srcObject = stream;

        if (this.props.callOwner === VIDEO_OWNER_PRACTICE) {
            this.addTracks(stream);
        } else {
            this.setState({patientStream: stream});
        }
    }

    onScreenError = (error) => {
        console.log(`Error ${error}`);
    }

    onSuccess = (stream) => {
        window.localStream = stream;
        this.localVideoref.current.srcObject = stream;

        if (this.props.callOwner === VIDEO_OWNER_PRACTICE) {
            this.addTracks(stream);
        } else {
            this.setState({patientStream: stream});
        }
    }

    onError = (error) => {
        console.log(error);
    }

    createMediaStream = () => {

        const constraints = {video: true, audio: true};

        navigator.mediaDevices.getUserMedia(constraints)
            .then(this.onSuccess)
            .catch(this.onError);

    }

    createScreenMediaStream = () => {

        const constraints = {video: true, audio: true};

        navigator.mediaDevices.getDisplayMedia(constraints)
            .then(this.onScreenSuccess)
            .catch(this.onScreenError);

    }

    connectMediaStream = () => {
        this.addTracks(this.state.patientStream);
    }

    createRTCPeerConnection = () => {

        ac.setRTCPeerConnection(new RTCPeerConnection(RTCConfiguration));

        ac.getRTCPeerConnection().onicecandidate = (e) => {
            if (e.candidate) {

                this.props.dispatchMessage(JSON.stringify({
                    groupId: this.state.groupId,
                    type: this.props.callOwner === VIDEO_OWNER_PRACTICE ? PRACTICE_CANDIDATE : PATIENT_CANDIDATE,
                    id: this.props.patientId,
                    iceCandidate: e.candidate,
                }))
            }
        }

        ac.getRTCPeerConnection().oniceconnectionstatechange = (e) => {
            switch (ac.getRTCPeerConnection().iceConnectionState) {
                case "connected":
                    this.setState({patientAnswered: true});

                    this.props.callOwner === VIDEO_OWNER_PRACTICE ? this.practiceSource.stop() : this.patientSource.stop();
                    break;
                case "checking":
                case "closed":
                case "completed":
                case "disconnected":
                case "failed":
                case "new":
                    break;
                default:
                    break;
            }
        }

        ac.getRTCPeerConnection().onicecandidateerror = (e) => {
        }

        ac.getRTCPeerConnection().ontrack = (e) => {
            this.remoteVideoref.current.srcObject = e.streams[0];
        }
    }

    shareScreen = () => {
        this.createScreenMediaStream();
    }

    playCallSounds = () => {
        this.practiceSource.start();
    }

    makeCall = () => {

        try {
            ac.getRTCPeerConnection().createOffer({offerToReceiveVideo: 1})
                .then((sdp) => {
                    ac.getRTCPeerConnection().setLocalDescription(sdp)
                        .then(() => {
                        });

                    this.playCallSounds();

                    this.props.dispatchMessage(JSON.stringify({
                        groupId: this.state.groupId,
                        type: PRACTICE_OFFER,
                        id: this.props.patientId,
                        offer: {type: sdp.type, sdp: sdp.sdp}
                    }))
                })
            this.setState({audioStarted: true})
        } catch (error) {
            console.log(`Error in setting caller local SDP offer`);
        }
    }

    answerCall = () => {

        try {
            ac.getRTCPeerConnection().createAnswer({offerToReceiveVideo: 1})
                .then((sdp) => {
                    ac.getRTCPeerConnection().setLocalDescription(sdp)
                        .then(() => {
                        });

                    this.props.dispatchMessage(JSON.stringify({
                        groupId: this.state.groupId,
                        type: PATIENT_ANSWER,
                        id: this.props.patientId,
                        answer: {type: sdp.type, sdp: sdp.sdp}
                    }))
                })

        } catch (error) {
            console.log(`Error in setting caller local SDP offer`);
        }
    }

    endCall = () => {

        this.props.dispatchMessage(JSON.stringify({
            groupId: this.state.groupId,
            type: this.props.callOwner === VIDEO_OWNER_PRACTICE ? PRACTICE_DISCONNECTED : PATIENT_DISCONNECTED,
            id: this.props.patientId,
        }))
        this.onSave(SE_NONE)

        if (this.state.audioStarted) {
            this.setState({audioStarted: false}, () => {
                if (this.patientSource) {
                    this.patientSource.stop();
                }

                if (this.practiceSource) {
                    this.practiceSource.stop()
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if ((this.props.messageToRead !== undefined && this.props.messageToRead.type !== undefined) && prevProps.messageToRead !== this.props.messageToRead) {

            switch (this.props.messageToRead.type) {
                case PRACTICE_CONNECTED_RESULT:
                    this.createRTCPeerConnection();
                    this.createMediaStream();
                    this.setState({practiceAvailable: true, patientAvailable: this.props.messageToRead.result})
                    break;
                case PRACTICE_CONNECTED:
                    this.setState({practiceAvailable: this.props.messageToRead.result === VIDEO_OK})
                    break;
                case PATIENT_CONNECTED_RESULT:
                    this.createMediaStream();
                    this.setState({patientResult: true, practiceAvailable: this.props.messageToRead.result})
                    break;
                case PATIENT_CONNECTED:
                    this.setState({patientAvailable: this.props.messageToRead.result === VIDEO_OK})
                    break;
                case PRACTICE_DISCONNECTED_RESULT:
                case PRACTICE_DISCONNECTED:
                case PATIENT_DISCONNECTED_RESULT:
                case PATIENT_DISCONNECTED:
                    this.setState({
                        practiceCalled: false,
                        patientAnswered: false,
                        practiceAvailable: false,
                        patientAvailable: false
                    }, () => {
                        if (ac.getRTCPeerConnection() !== null) ac.getRTCPeerConnection().close();
                    })
                    break;
                case PRACTICE_OFFER:
                    this.createRTCPeerConnection();
                    this.connectMediaStream();
                    this.setState({practiceCalled: true, audioStarted: true})
                    try {
                        const offer = JSON.parse(this.props.messageToRead.result);
                        ac.getRTCPeerConnection().setRemoteDescription(new RTCSessionDescription(offer));

                        this.patientSource.start()
                    } catch (error) {
                        console.log(`Error setting remote Answer Description`);
                    }
                    break;
                case PATIENT_ANSWER:
                    try {
                        const answer = JSON.parse(this.props.messageToRead.result);
                        ac.getRTCPeerConnection().setRemoteDescription(new RTCSessionDescription(answer));
                    } catch (error) {
                        console.log(`Error setting remote Answer Description`);
                    }
                    break;
                case PRACTICE_CANDIDATE:
                    try {
                        const ICE = JSON.parse(this.props.messageToRead.result)
                        ac.getRTCPeerConnection().addIceCandidate(new RTCIceCandidate(ICE));
                    } catch (error) {
                        console.log(`Error setting practice remote ICE`);
                    }
                    break;
                case PATIENT_CANDIDATE:
                    try {
                        const ICE = JSON.parse(this.props.messageToRead.result)
                        ac.getRTCPeerConnection().addIceCandidate(new RTCIceCandidate(ICE));
                    } catch (error) {
                        console.log(`Error setting patient remote ICE`);
                    }
                    break;
                case VIDEO_ERROR:
                    console.log(` >>>>> ${this.props.messageToRead.result} <<<<<`);
                    break;
                default:
                    break;
            }
        }
    }

    getAppointmentProvider = () => {
        const {title, firstName, lastName} = this.props.appointment.appointmentWith;
        return `${title.abbreviation} ${firstName} ${lastName}`.trim();
    }

    render() {

        if (this.state.videoCallInProgress) {

            if (this.props.callOwner === VIDEO_OWNER_PATIENT) {

                const appointmentWith = this.state.videoCallInProgress ? `${SM_CLIENT_VIDEO_CALL.detail} with ${this.props.patientData.nextVCAppointmentWith} @ ${dateTimeTemplate(this.props.patientData.nextVCAppointmentStart, this.props.patientData.nextVCAppointmentEnd)}` : SM_CLIENT_VIDEO_CALL.detail;
                const name = this.props.patientData.nextVCAppointmentWith;
                const {title, firstName, lastName} = this.props.patientData;
                const myName = `${title.abbreviation} ${firstName} ${lastName}`.trim();

                const endCallDisabled = !this.state.patientAnswered;
                const answerCallDisabled = !(this.state.practiceCalled && !this.state.patientAnswered);

                const header = <div><label id='panel-header'>{appointmentWith}</label>
                    <div className="p-toolbar-group-right">
                        <Button label='Answer Call' icon={SM_CLIENT_VIDEO_CALL.callIcon}
                                className="p-button-success"
                                onClick={() => this.answerCall()}
                                disabled={answerCallDisabled}
                        />
                        <Button label='End Call' icon={SM_CLIENT_VIDEO_CALL.callIcon}
                                className="p-button-danger"
                                onClick={() => this.endCall()}
                                disabled={endCallDisabled}
                        />
                        <Button label={SM_CLIENT_VIDEO_CALL.exitLabel} icon={SM_CLIENT_VIDEO_CALL.exitIcon}
                                className="p-button-success"
                                onClick={() => this.endCall()}
                        />
                    </div>
                </div>

                return (
                    <PatientErrorBoundary>
                        <div className="p-col-12 p-lg-12">
                            <Panel header={header} className="no-pad">

                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <Panel header={name}>
                                            <video ref={this.remoteVideoref}
                                                   style={{
                                                       backgroundColor: COLOR_ChiralBorder,
                                                       width: "100%",
                                                       height: "auto"
                                                   }}
                                                   autoPlay
                                            />
                                        </Panel>
                                    </div>
                                    <div className="p-col-6">
                                        <Panel header={myName} className='p-fluid'>
                                            <video ref={this.localVideoref}
                                                   style={{
                                                       backgroundColor: COLOR_ChiralBorder,
                                                       width: "100%",
                                                       height: "auto"
                                                   }}
                                                   autoPlay
                                                   muted={"muted"}
                                            />
                                        </Panel>
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    </PatientErrorBoundary>
                )
            } else {

                const {title, firstName, lastName} = this.props.patientData;
                const name = `${title.abbreviation} ${firstName} ${lastName}`.trim();
                const appointmentWith = this.state.videoCallInProgress ? `${SM_CLIENT_VIDEO_CALL.detail} with ${name}` : '-';

                const endCallDisabled = !(this.state.practiceCalled || this.state.patientAnswered);
                const makeCallDisabled = !(this.state.practiceAvailable && this.state.patientAvailable && !this.state.practiceCalled && !this.state.patientAnswered);

                let callStatus = '';

                if (!this.state.patientAvailable) {
                    callStatus = 'Patient Unavailable';
                } else {
                    callStatus = 'Patient Called'
                }

                const myName = this.getAppointmentProvider();

                const header = <div><label id='panel-header'>{appointmentWith}</label></div>

                return (
                    <PatientErrorBoundary>
                        <div className="p-col-12 p-lg-12">
                            <Panel header={header} className="no-pad">

                                <div className="p-grid">
                                    <Card
                                        className="p-col-12">
                                        <div className="p-toolbar-group-right">
                                            <Button label={SM_CLIENT_VIDEO_CALL_SHARE_SCREEN.label}
                                                    icon={SM_CLIENT_VIDEO_CALL_SHARE_SCREEN.icon}
                                                    className="p-button-success"
                                                    onClick={() => this.shareScreen()}
                                                    disabled={endCallDisabled}
                                            />
                                            <InputText disabled={true} value={callStatus}/>
                                            <Button label='Make Call' icon={SM_CLIENT_VIDEO_CALL.callIcon}
                                                    className="p-button-success"
                                                    onClick={() => this.makeCall()}
                                                    disabled={makeCallDisabled}
                                            />
                                            <Button label='End Call' icon={SM_CLIENT_VIDEO_CALL.callIcon}
                                                    className="p-button-danger"
                                                    onClick={() => this.endCall()}
                                                    disabled={endCallDisabled}
                                            />
                                        </div>
                                    </Card>
                                    <Panel header={name}
                                           className="p-col-6">
                                        <video ref={this.remoteVideoref}
                                               style={{
                                                   backgroundColor: COLOR_ChiralBorder,
                                                   width: "100%",
                                                   height: "auto"
                                               }}
                                               autoPlay
                                        />
                                    </Panel>
                                    <Panel header={myName}
                                           className="p-col-6 no-pad">
                                        <video ref={this.localVideoref}
                                               style={{
                                                   backgroundColor: COLOR_ChiralBorder,
                                                   width: "100%",
                                                   height: "auto"
                                               }}
                                               autoPlay
                                               muted={"muted"}
                                        />
                                    </Panel>
                                </div>
                            </Panel>
                        </div>
                    </PatientErrorBoundary>
                )
            }
        } else {

            const header = <div><label id='panel-header'>Video Call</label></div>

            return (

                <div className="p-grid p-col-12 p-fluid p-lg-12">
                    <Panel header={header} className="p-col-12">
                        <h1>No Video Call is Currently Available</h1>
                    </Panel>
                </div>
            )
        }
    }

    componentWillUnmount() {

        if (this.state.audioStarted) {
            if (this.patientSource) {
                this.patientSource.stop()
                    .error(
                        console.log(`stop with out start`)
                    );
            }

            if (this.practiceSource) {
                this.practiceSource.stop()
                    .error(
                        console.log(`stop with out start`)
                    );
            }
        }
    }
}

const mapStateToProps = (state) => {

    return {
        messageToRead: state.websockets.messageToRead,
        websocketState: state.websockets.websocketState,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchMessage: (data) => dispatch(dispatchMessage(WSM_SEND, data)),
    };
};

const ClientVideoCall = connect(mapStateToProps, mapDispatchToProps)(ConnectedVideoCall);

export default ClientVideoCall;
