import React, {Component} from 'react';
import {Button} from "primereact/button";
import {
    SE_ADD_MEDICAL_HISTORY,
    SE_ADD_PERSONAL_DETAILS,
    SE_ADD_QUESTIONNAIRE,
    SE_ADD_REFERRER_DETAILS,
    SE_APPOINTMENT_HISTORY,
    SE_BOOK_APPOINTMENT,
    SE_BOOK_VIDEO_CALL,
    SE_CHAT,
    SE_DOCUMENTS,
    SE_NONE,
    SE_PAYMENT_PLANNING,
    SE_PAYMENT_PLANNING_CONSENT,
    SE_PRESCRIPTION_HISTORY,
    SE_SIGN_CONSENT,
    SE_SIGN_GDPR_CONSENT,
    SE_SIGN_GDPR_CONSENT_RETRY,
    SE_TREATMENT_PLANNING,
    SE_TREATMENT_PLANNING_CONSENT,
    SE_UPLOAD_XRAY_IMAGE,
    SE_VIDEO_CALL,
    VIDEO_OWNER_PATIENT
} from "./Constants";
import BookVideoCall from "./BookVideoCall";
import BookAppointment from "./BookAppointment";
import VideoCall from "./VideoCall";
import {ClientChat} from "./ClientChat";
import {Panel} from "primereact/panel";
import ClientDetails from "./ClientDetails";
import ClientMedCons from "./ClientMedCons";
import ClientImageUploader from "./ClientImageUploader";
import {ClientPrescriptionHistory} from "./ClientPrescriptionHistory";
import ClientAppointmentHistory from "./ClientAppointmentHistory";
import {ClientReferrerDetails} from "./ClientReferrerDetails";
import ClientConsentForms from "./ClientConsentForms";
import ClientPaymentPlanHistory from "./ClientPaymentPlanHistory";
import ClientTreatmentPlanHistory from "./ClientTreatmentPlanHistory";
import ClientDocuments from "./ClientDocuments";
import ClientGDPRConsentForm from "./ClientGDPRConsentForm";
import {dateTemplate, dateTimeTemplateClient} from "./Utils";
import * as Actions from "../../actions";
import {connect} from "react-redux";
import {ADD, ADDBACK, DELETE, MOVING_IN_DIARY, UPDATE} from "../../Constants";
import {getResource, RES_PATIENT_DETAILS} from "../../actions/personal";
import ClientQuestionnaire from "./ClientQuestionnaire";

export class ConnectedDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {}

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.message !== this.props.message || prevProps.wsmessage !== this.props.wsmessage) {

            switch (this.props.message.type) {
                case Actions.WSM_UPDATES:

                    switch (this.props.wsmessage.function) {

                        default:
                            break;
                    }
                    break;
                case Actions.WSM_APPOINTMENTS:

                    switch (this.props.wsmessage.function) {
                        case ADD:
                        case ADDBACK:
                        case MOVING_IN_DIARY:
                        case UPDATE:
                            if (this.props.wsmessage.content.patientId === this.props.patientData.id) {
                                this.props.getPatientDetails({id: this.props.patientData.id});
                            }
                            break;
                        case DELETE:
                            if (this.props.wsmessage.content === this.props.patientData.nextAppointmentId || this.props.wsmessage.content === this.props.patientData.nextVCAppointmentId) {
                                this.props.getPatientDetails({id: this.props.patientData.id});
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    addOptionalOnlineBooking = () => {

        if (this.props.patientData.onlineBooking) {
            return (
                <Button icon="fa fa-calendar" label="Book An Appointment"
                        style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_BOOK_APPOINTMENT)}/>
            )
        }
    };

    addOptionalVideoConferencingBox = () => {

        const vcAppointmentWith = this.props.patientData.nextVCAppointmentWith === '' ? '-' : this.props.patientData.nextVCAppointmentWith;

        const content = [];

        if (this.props.patientData.videoConferencing) {

            content.push(
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="p-grid overview-box overview-box-3">
                        <div className="overview-box-title">
                            <i className="fa fa-video"/>
                            <span>Next Video Call</span>
                        </div>
                        <div
                            className="overview-box-count">{`${dateTimeTemplateClient(this.props.patientData.nextVCAppointmentStart, this.props.patientData.nextVCAppointmentEnd)}`}</div>
                        <div className="overview-box-stats">{`${vcAppointmentWith}`}</div>
                    </div>
                </div>
            )
        }
        return content;
    }
    addOptionalVideoConferencing = () => {

        const content = [];

        if (this.props.patientData.videoConferencing) {

            content.push(<Button icon="fa fa-video" label="Book A Video Call" style={{marginBottom: '10px'}}
                                 onClick={() => this.props.menuCommand(SE_BOOK_VIDEO_CALL)}
                                 key={SE_BOOK_VIDEO_CALL}
            />);
            content.push(<Button icon="fa fa-video" label="Join Video Call" style={{marginBottom: '10px'}}
                                 onClick={() => this.props.menuCommand(SE_VIDEO_CALL)}
                                 disabled={this.props.websocketState === WebSocket.CLOSED}
                                 key={SE_VIDEO_CALL}
            />);
        }
        return content;
    };

    addOptionalMedicalHistory = () => {

        if (this.props.patientData.medicalHistory) {
            return (
                <Button icon="fa fa-align-justify" label="Add Medical History"
                        style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_ADD_MEDICAL_HISTORY)}/>
            )
        }
    };

    addOptionalQuestionnaire = () => {

        if (this.props.patientData.questionnaire) {
            return (
                <Button icon="fa fa-align-justify" label="Complete Questionnaire"
                        style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_ADD_QUESTIONNAIRE)}/>
            )
        }
    };

    addOptionalConsentForms = () => {

        if (this.props.patientData.consentForms) {
            return (
                <Button icon="fa fa-align-justify" label="Sign Consent Forms"
                        style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_SIGN_CONSENT)}/>
            )
        }
    };

    addRequestedMedicalHistory = () => {

        if (this.props.medicalHistory) {
            return (
                <Button icon="fa fa-align-justify" label="Complete Medical History"
                        style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_ADD_MEDICAL_HISTORY)}/>
            )
        }
    };

    addRequestedQuestionnaire = () => {

        if (this.props.questionnaire) {
            return (
                <Button icon="fa fa-align-justify" label="Complete Questionnaire"
                        style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_ADD_QUESTIONNAIRE)}/>
            )
        }
    };

    addRequestedConsentForms = () => {

        if (this.props.consentForms) {
            return (
                <Button icon="fa fa-align-justify" label="Sign Consent Forms"
                        style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_SIGN_CONSENT)}/>
            )
        }
    };

    addOptionalImageUpload = () => {

        if (this.props.patientData.imageUpload) {
            return (
                <Button icon="fa fa-image" label="Upload an Image(s)" style={{marginBottom: '10px'}}
                        onClick={() => this.props.menuCommand(SE_UPLOAD_XRAY_IMAGE)}/>
            )
        }
    }

    defaultSection = (selectedAction) => {

        switch (selectedAction) {

            case SE_NONE :

                if (this.props.consentForms || this.props.medicalHistory || this.props.questionnaire) {
                    return (

                        <div className="p-col-12 p-lg-12">
                            <Panel header="What we would like you to fill in first" className="no-pad">

                                <div className="p-grid">
                                    <div className="p-col-12" style={{textAlign: 'justify'}}>
                                        {this.addRequestedMedicalHistory()}
                                        {this.addRequestedConsentForms()}
                                        {this.addRequestedQuestionnaire()}
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    )
                } else {
                    return (

                        <div className="p-col-12 p-lg-12">
                            <Panel header="What do you want to do?" className="no-pad">

                                <div className="p-grid">
                                    <div className="p-col-12" style={{textAlign: 'justify'}}>
                                        {this.addOptionalOnlineBooking()}
                                        {this.addOptionalVideoConferencing()}
                                        {this.addOptionalImageUpload()}
                                    </div>
                                </div>
                            </Panel>
                        </div>
                    )
                }
            case SE_SIGN_GDPR_CONSENT_RETRY :
                return (

                    <div className="p-col-12 p-lg-12">
                        <Panel header="What do you want to do?" className="no-pad">

                            <div className="p-grid">
                                <div className="p-col-12" style={{textAlign: 'justify'}}>
                                    <Button icon="fas fa-signature" label="Sign GDPR Consent Form"
                                            style={{marginBottom: '10px'}}
                                            onClick={() => this.props.menuCommand(SE_SIGN_GDPR_CONSENT)}/>
                                </div>
                            </div>
                        </Panel>
                    </div>
                )
            default:
                break;
        }
    }

    showSelectedAction = () => {

        switch (this.props.command) {
            case SE_NONE:
                return this.defaultSection(SE_NONE);
            case SE_ADD_PERSONAL_DETAILS:
                return <ClientDetails patientId={this.props.patientData.id}
                                      onCommandChange={this.props.menuCommand}
                />;
            case SE_ADD_REFERRER_DETAILS:
                return <ClientReferrerDetails patientId={this.props.patientData.id}
                                              onCommandChange={this.props.menuCommand}
                />;
            case SE_APPOINTMENT_HISTORY:
                return <ClientAppointmentHistory patientId={this.props.patientData.id}
                                                 onCommandChange={this.props.menuCommand}
                />;
            case SE_BOOK_APPOINTMENT:
                return <BookAppointment patientId={this.props.patientData.id}
                                        onCommandChange={this.props.menuCommand}
                />;
            case SE_BOOK_VIDEO_CALL:
                return <BookVideoCall patientId={this.props.patientData.id}
                                      onCommandChange={this.props.menuCommand}
                />;
            case SE_VIDEO_CALL:
                return <VideoCall patientId={this.props.patientData.id}
                                  onCommandChange={this.props.menuCommand}
                                  callOwner={VIDEO_OWNER_PATIENT}
                                  patientData={this.props.patientData}
                />;
            case SE_CHAT:
                return <ClientChat patientId={this.props.patientData.id}
                                   onCommandChange={this.props.menuCommand}
                />;
            case SE_ADD_MEDICAL_HISTORY:
                return <ClientMedCons patientId={this.props.patientData.id}
                                      onCommandChange={this.props.menuCommand}
                />;
            case SE_ADD_QUESTIONNAIRE:
                return <ClientQuestionnaire patientId={this.props.patientData.id}
                                            onCommandChange={this.props.menuCommand}
                />;
            case SE_UPLOAD_XRAY_IMAGE:
                return <ClientImageUploader patientId={this.props.patientData.id}
                                            onCommandChange={this.props.menuCommand}
                />;
            case SE_TREATMENT_PLANNING:
                return <ClientTreatmentPlanHistory patientId={this.props.patientData.id}
                                                   onCommandChange={this.props.menuCommand}
                />;
            case SE_TREATMENT_PLANNING_CONSENT:
                return <ClientConsentForms patientId={this.props.patientData.id}
                                           onCommandChange={this.props.menuCommand}
                                           type={SE_TREATMENT_PLANNING_CONSENT}
                />;
            case SE_PAYMENT_PLANNING:
                return <ClientPaymentPlanHistory patientId={this.props.patientData.id}
                                                 onCommandChange={this.props.menuCommand}
                />;
            case SE_PAYMENT_PLANNING_CONSENT:
                return <ClientConsentForms patientId={this.props.patientData.id}
                                           onCommandChange={this.props.menuCommand}
                                           type={SE_PAYMENT_PLANNING_CONSENT}
                />;
            case SE_PRESCRIPTION_HISTORY:
                return <ClientPrescriptionHistory patientId={this.props.patientData.id}
                                                  onCommandChange={this.props.menuCommand}
                />;
            case SE_SIGN_GDPR_CONSENT:
                return <ClientGDPRConsentForm patientId={this.props.patientData.id}
                                              onCommandChange={this.props.menuCommand}
                                              consentControlCallBack={this.props.consentControlCallBack}
                                              type={SE_SIGN_GDPR_CONSENT}
                />;
            case SE_SIGN_GDPR_CONSENT_RETRY:
                return this.defaultSection(this.props.command);
            case SE_SIGN_CONSENT:
                return <ClientConsentForms patientId={this.props.patientData.id}
                                           onCommandChange={this.props.menuCommand}
                                           type={SE_SIGN_CONSENT}
                />;
            case SE_DOCUMENTS:
                return <ClientDocuments patientId={this.props.patientData.id}
                                        onCommandChange={this.props.menuCommand}
                                        type={SE_DOCUMENTS}
                />;
            default:
                return this.defaultSection();
        }
    }

    render() {

        const appointmentWith = this.props.patientData.nextAppointmentWith === '' ? '-' : this.props.patientData.nextAppointmentWith;

        return <div className="p-grid dashboard">

            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="fa fa-calendar"></i>
                        <span>Next Appointment</span>
                    </div>
                    <div
                        className="overview-box-count">{`${dateTimeTemplateClient(this.props.patientData.nextAppointmentStart, this.props.patientData.nextAppointmentEnd)}`}</div>
                    <div className="overview-box-stats">{`${appointmentWith}`}</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-2">
                    <div className="overview-box-title">
                        <i className="fa fa-align-justify"></i>
                        <span>Last Medical History Form Completed</span>
                    </div>
                    <div className="overview-box-count">{`${dateTemplate(this.props.patientData.mhDate)}`}</div>
                    <div
                        className="overview-box-stats">{`Expires ${dateTemplate(this.props.patientData.mhExpiryDate)}`}</div>
                </div>
            </div>

            {this.addOptionalVideoConferencingBox()}

            {this.showSelectedAction()}
        </div>
    }
}

const mapStateToProps = (state) => {

    return {
        message: state.stateManagement.message,
        wsmessage: state.websockets.message,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPatientDetails: (params) => dispatch(getResource(RES_PATIENT_DETAILS.GET_PORTAL_DATA, params)),
    };
};

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(ConnectedDashboard);

export default Dashboard;


