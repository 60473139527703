import * as Actions from "./index";
import {ac} from '../index'
import {TAB_PatientConsentForms, TAB_Questionnaire} from "../components/Client/Constants";

const baseURL = '/Tablet';

export const RES_TAB_PATIENT_QUESTIONNAIRES = {
    GET: {
        request: Actions.REQUEST_TAB_PATIENT_QUESTIONNAIRES,
        receive: Actions.RECEIVE_TAB_PATIENT_QUESTIONNAIRES,
        type: TAB_Questionnaire,
        label: 'Questionnaires',
        index: 'questionnairesMembers',
        url: `${baseURL}/PatientList`
    },
};

export const RES_TAB_PATIENT_CONSENTS = {
    GET_CLOUD: {
        request: Actions.REQUEST_TAB_PATIENT_CONSENTS,
        receive: Actions.RECEIVE_TAB_PATIENT_CONSENTS,
        type: TAB_PatientConsentForms,
        label: 'Consent Forms',
        index: 'patientConsentMembers',
        url: `${baseURL}/PatientList`
    },
    GET_TABLET: {
        request: Actions.REQUEST_TAB_PATIENT_CONSENTS,
        receive: Actions.RECEIVE_TAB_PATIENT_CONSENTS,
        type: TAB_PatientConsentForms,
        label: 'Consent Forms',
        index: 'patientConsentMembers',
        url: `${baseURL}/PatientList`
    },
    GET_PORTAL: {
        request: Actions.REQUEST_TAB_PATIENT_CONSENTS,
        receive: Actions.RECEIVE_TAB_PATIENT_CONSENTS,
        type: TAB_PatientConsentForms,
        label: 'Consent Forms',
        index: 'patientConsentMembers',
        url: `${baseURL}/PatientsList`
    },
    TABLET: {
        request: Actions.REQUEST_TAB_PATIENT_CONSENT_FORM_PDF,
        receive: Actions.RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF,
        type: TAB_PatientConsentForms,
        label: 'Consent Forms Required',
        url: `${baseURL}/PatientConsentList.pdf`
    },
    PORTAL_GDPR: {
        request: Actions.REQUEST_GDPR_CONSENT_FORM_PDF,
        receive: Actions.RECEIVE_GDPR_CONSENT_FORM_PDF,
        type: TAB_PatientConsentForms,
        label: 'GDPR Consent Required - Please read and sign the form below before using the patient portal',
        url: `${baseURL}/GDPRConsent.pdf`
    },
    PORTAL: {
        request: Actions.REQUEST_TAB_PATIENT_CONSENT_FORM_PDF,
        receive: Actions.RECEIVE_TAB_PATIENT_CONSENT_FORM_PDF,
        type: TAB_PatientConsentForms,
        label: 'Consent Forms Required',
        url: `${baseURL}/PatientConsentList.pdf`
    },
    SAVE: {
        request: Actions.REQUEST_SAVE_TAB_PATIENT_CONSENTS,
        receive: Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS,
        url: `${baseURL}/PatientConsent`
    },
    SAVE_GDPR: {
        request: Actions.REQUEST_SAVE_TAB_PATIENT_CONSENTS,
        receive: Actions.RECEIVE_SAVE_TAB_PATIENT_CONSENTS,
        url: `${baseURL}/GDPRConsent`
    }
};

export const RES_TABLET_LISTS = {
    ADD_TO: {
        request: Actions.REQUEST_TAB_ADD_TO_LIST,
        receive: Actions.RECEIVE_TAB_ADD_TO_LIST,
        url: `${baseURL}/addToList`
    },
    REMOVE_FROM: {
        request: Actions.REQUEST_TAB_REMOVE_FROM_LIST,
        receive: Actions.RECEIVE_TAB_REMOVE_FROM_LIST,
        url: `${baseURL}/removeFromList`
    },
    REMOVE_FROM_BY_ID: {
        request: Actions.REQUEST_TAB_REMOVE_FROM_LIST_BY_ID,
        receive: Actions.RECEIVE_TAB_REMOVE_FROM_LIST_BY_ID,
        url: `${baseURL}/removeFromListById`
    },
    POSSIBLES: {
        request: Actions.REQUEST_TAB_POSSIBLES,
        receive: Actions.RECEIVE_TAB_POSSIBLES,
        url: `${baseURL}/possibleOthers`
    },
    CLEAR: {
        action: Actions.CLEAR_TAB_LIST,
    }
};

export const getLists = ({url, request, receive, type}) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&type=${type}`)
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const getList = ({url, request, receive, type}, patientId) => {

    return (dispatch) => {

        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}&patientId=${patientId}&type=${type}`)
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const removeFromLists = ({url, request, receive}, tabletMember) => {

    return (dispatch) => {

        ac.fetchClient().delete(`${url}?mcId=${ac.getMcId()}`, {params: {...tabletMember}})
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const removeFromListsById = ({url, request, receive}, tabletMemberId) => {

    return (dispatch) => {

        ac.fetchClient().delete(`${url}?mcId=${ac.getMcId()}&memberId=${tabletMemberId}`)
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: false});
    }
};

export const getPortalPDF = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            },
            params: {...params}
        })
            .then(res => {
                dispatch({type: receive, payload: res.data, id: params.patientId})
            });
        dispatch({type: request, payload: {}, id: params.id});
    }
};

export const saveConsent = ({url, request, receive}, params) => {

    return (dispatch) => {

        ac.fetchClient().post(`${url}?mcId=${ac.getMcId()}`, params)
            .then(res => {
                dispatch({type: receive, payload: res.data})
            });
        dispatch({type: request, payload: {}});
    }
};
