import React from 'react';
import {setState, SM_TAB_GDPR_CONSENT_SIG} from "../../actions/stateManagement";
import {PDFConsentSection, ShowQuestionDialog, suppressBrowserCM} from "./Utils";
import _ from "lodash";
import {
    clientLayoutClassName,
    HM_PORTAL_CONFIRM_GDPR_CONSENT,
    ICON_CANCEL,
    OUTCOME_ConsentGiven,
    OUTCOME_ConsentNotGiven,
    SE_NONE,
    SE_SIGN_GDPR_CONSENT
} from "./Constants";
import {connect} from "react-redux";
import {Panel} from "primereact/panel";
import * as Actions from "../../actions";
import {REQUEST_SAVE_TAB_PATIENT_CONSENTS} from "../../actions";
import {getPortalPDF, RES_TAB_PATIENT_CONSENTS, saveConsent} from "../../actions/tablet";
import {PDFBaseComponent} from "../PDFBaseComponent";
import {RES_TEMPLATES_CONSENT_FORMS} from "../../actions/housekeeping";
import {ProgressBar} from "primereact/progressbar";
import {Checkbox} from "primereact/checkbox";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import * as DefaultData from "./DefaultData";
import {ac} from "../../index";

class ConnectedClientGDPRConsentForm extends PDFBaseComponent {

    constructor(props) {
        super(props);

        if (props.currentState) {
            this.state = props.currentState.data;
        } else {
            this.state = {
                consentFormLoaded: false,
                consentForm: null,
                pdf: null,
                image: null,

                [HM_PORTAL_CONFIRM_GDPR_CONSENT.id]: false,
            }
        }
        this.sigCanvas = null;
    }

    componentDidMount() {

        this.props.getGDPRForm({id: this.props.patientId});
    }

    componentDidUpdate(prevProps, ps, ss) {

        if (prevProps.message.type !== this.props.message.type) {

            switch (this.props.message.type) {
                case RES_TEMPLATES_CONSENT_FORMS.GET.receive:
                    break;

                case Actions.RECEIVE_GDPR_CONSENT_FORM_PDF:

                    const consentForm = _.cloneDeep(DefaultData.ConsentForm(ac.getMcId()));

                    consentForm.patient = {id: this.props.patientId};
                    consentForm.outcome = OUTCOME_ConsentNotGiven;

                    this.setState({pdf: this.props.pdf, pdfLoaded: true, consentForm});
                    break;

                case REQUEST_SAVE_TAB_PATIENT_CONSENTS:
                    break;

                default:
                    break;
            }
        }
    }

    onGive = (event) => {

        const consentForm = {...this.state.consentForm};
        consentForm.outcome = event.value ? OUTCOME_ConsentGiven : OUTCOME_ConsentNotGiven;
        this.setState({consentForm});
    }

    onDeny = (event) => {
        const consentForm = {...this.state.consentForm};
        this.outcome = event.value ? OUTCOME_ConsentNotGiven : OUTCOME_ConsentGiven;
        this.setState({consentForm});
    }

    onExit = () => {

        this.props.saveConsent({
            form: this.state.consentForm,
            image: this.state.image,
            patientId: this.props.patientId,
        });

        this.props.onCommandChange(SE_NONE);
        this.props.consentControlCallBack(this.state.consentForm.outcome);
    }

    onSave = () => {

        const image = this.sigCanvas.toDataURL('image/png');
        this.sigCanvas.off();

        this.setState({image, [HM_PORTAL_CONFIRM_GDPR_CONSENT.id]: true});
    }

    onClear = () => {

        this.sigCanvas.clear();
        this.sigCanvas.on();

        this.setState({image: null});
    }

    onCancel = () => {

        this.props.onCommandChange({owner: 'selectedAction', value: SE_SIGN_GDPR_CONSENT})
    }

    showDialogs = () => {

        return (
            ShowQuestionDialog(this, HM_PORTAL_CONFIRM_GDPR_CONSENT, this.onExit)
        )
    }

    insertReportText = () => {

        const header = <div><label
            id='panel-header'>{RES_TAB_PATIENT_CONSENTS.PORTAL_GDPR.label}</label></div>;

        const sigHeader =
            <div>
                <label id='panel-header'>{SM_TAB_GDPR_CONSENT_SIG.label}</label>
                <div className="p-toolbar-group-right">
                    <Button label="Clear" icon={ICON_CANCEL}
                            style={{marginLeft: '0.25em', marginBottom: '0.6em'}}
                            onClick={() => this.onClear()}/>
                </div>
            </div>;

        const isGiven = this.state.consentForm.outcome === OUTCOME_ConsentGiven;
        const isSaved = this.state.image !== null;

        return (
            <div className="p-col-12">
                <Panel header={header} style={{fontSize: '1.5em', padding: '10px'}}>

                    <PDFConsentSection key='123'
                                       numPages={this.state.numPages}
                                       pdf={this.state.pdf}
                                       onClear={this.onClear}
                                       onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                                       onLoadError={this.onLoadError}
                                       GDPRConsent={true}
                    />

                    <Panel header='Patients Consent'
                           className="p-col-12"
                           style={{paddingTop: '5px'}}
                    >
                        <Checkbox className="p-col-6"
                                  checked={isGiven}
                                  onChange={(e) => {
                                      this.onGive({
                                          value: e.checked,
                                      })
                                  }}
                                  inputId={`ConsentGiven`}
                        />
                        <label className="p-col-6 p-checkbox-label"
                               htmlFor={`ConsentGiven`}
                        >{`Patient Gives Consent`}
                        </label>
                        <Checkbox className="p-col-6"
                                  checked={!isGiven}
                                  onChange={(e) => {
                                      this.onDeny({
                                          value: e.checked,
                                      })
                                  }}
                                  inputId={`ConsentNotGiven`}
                        />
                        <label className="p-col-6 p-checkbox-label"
                               htmlFor={`ConsentNotGiven`}
                        >
                            {`Patient Does Not Give Consent`}
                        </label>
                    </Panel>

                    <Panel className="p-col-12"
                           style={{paddingTop: '5px'}}
                           header={sigHeader}>
                        <SignatureCanvas penColor='darkBlue'
                                         ref={(ref) => this.sigCanvas = ref}
                                         canvasProps={{
                                             width: 500,
                                             height: 200,
                                             className: 'sigCanvas'
                                         }}/>
                    </Panel>

                    <Toolbar>
                        <div className="p-toolbar-group-left">
                            <Button label="Register Your Consent" icon="pi pi-check"
                                    className="p-button-success"
                                    onClick={() => this.onSave()}
                                    disabled={isSaved}
                            />
                        </div>
                    </Toolbar>

                </Panel>
            </div>
        )
    }

    render() {

        suppressBrowserCM();

        if (!this.state.pdfLoaded) {
            return <ProgressBar mode="indeterminate" style={{height: '6px'}}/>;
        }

        return (

            <div className={clientLayoutClassName} id='mainPCC'>

                {this.showDialogs()}

                <div>
                    <div className="p-grid">

                        {this.insertReportText()}

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    const loadedId = `${Actions.RECEIVE_GDPR_CONSENT_FORM_PDF}Loaded`;

    return {

        pdfLoaded: state.tablet[loadedId],
        pdf: state.tablet[Actions.RECEIVE_GDPR_CONSENT_FORM_PDF],

        message: state.stateManagement.message,

        loginIdentity: state.login.user,
        userId: state.login.userId,
    }
};

const MapDispatchToProps = dispatch => {
    return {
        getGDPRForm: (params) => dispatch(getPortalPDF(RES_TAB_PATIENT_CONSENTS.PORTAL_GDPR, params)),
        saveConsent: (params) => dispatch(saveConsent(RES_TAB_PATIENT_CONSENTS.SAVE_GDPR, params)),

        setState: (id, data) => dispatch(setState(id, data)),
    }
};

const ClientGDPRConsentForm = connect(mapStateToProps, MapDispatchToProps)(ConnectedClientGDPRConsentForm);

export default ClientGDPRConsentForm;
