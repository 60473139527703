import moment from 'moment';
import {ac} from "../../index";
import {RES_PAYMENT_PLAN_BY_ID, RES_PAYMENT_PLANNING} from "../paymentPlanning";
import {
    PP_INTERVAL_MONTHLY,
    PP_INTERVAL_MONTHS2,
    PP_INTERVAL_MONTHS4,
    PP_INTERVAL_MONTHS6,
    PP_INTERVAL_QUARTERLY,
    PP_INTERVAL_WEEKLY,
    PP_STATUS_ACCEPTED,
    PP_STATUS_PROPOSED
} from "../../Constants";
import {PAY_TYPE_CASH} from "../../components/Client/Constants";

const _savePaymentPlan = (dispatch, _paymentPlan, installments, printAfterCreate, noOfCopies) => {

    const paymentPlan = {..._paymentPlan};
    paymentPlan.patient = {id: paymentPlan.patient.id};
    paymentPlan.arrangedBy = {id: paymentPlan.arrangedBy.id};
    paymentPlan.treatmentPlan = {id: paymentPlan.treatmentPlan.id};
    paymentPlan.chargeableItem = paymentPlan.chargeableItem === null ? null : {id: paymentPlan.chargeableItem.id};

    ac.fetchClient().post(`${RES_PAYMENT_PLAN_BY_ID.SAVE.url}?mcId=${ac.getMcId()}`, {
        params: {
            paymentPlan,
            installments,
            printAfterCreate,
            noOfCopies
        }
    })
        .then(res => {

            dispatch({type: RES_PAYMENT_PLAN_BY_ID.SAVE.action, payload: res.data})
        });
};

export const savePaymentPlan = (dispatch, sm, source, paymentPlan, installments, clearAfterSavePP) => {

    switch (paymentPlan.status) {

        case PP_STATUS_PROPOSED.name :
            _savePaymentPlan(dispatch, paymentPlan, [], false, 0);
            break;

        case PP_STATUS_ACCEPTED.name :

            const installments = [];

            if (paymentPlan.numberOfinstallments === 1) {
                const instalment = {
                    id: null,
                    mc: ac.getMcId(),
                    amount: paymentPlan.installmentAmount,
                    dueOn: paymentPlan.firstInstallment,
                    notes: '',
                    payType: PAY_TYPE_CASH.value,
                };
                installments.push(instalment);
            } else {

                let installDate = moment(paymentPlan.firstInstallment);

                for (let index = 0; index < paymentPlan.numberOfinstallments; index++) {

                    const instalment = {
                        id: null,
                        mc: ac.getMcId(),
                    };

                    if (index === paymentPlan.numberOfinstallments - 1)
                        instalment.amount = paymentPlan.finalInstallmentAmount;
                    else
                        instalment.amount = paymentPlan.installmentAmount;

                    if (index > 0) {
                        switch (paymentPlan.instalInterval) {
                            case PP_INTERVAL_MONTHS2.value :
                                installDate = installDate.add(2, 'months');
                                break;
                            case PP_INTERVAL_MONTHS4.value :
                                installDate = installDate.add(4, 'months');
                                break;
                            case PP_INTERVAL_MONTHS6.value :
                                installDate = installDate.add(6, 'months');
                                break;
                            case PP_INTERVAL_MONTHLY.value :
                                installDate = installDate.add(1, 'months');
                                break;
                            case PP_INTERVAL_QUARTERLY.value :
                                installDate = installDate.add(3, 'months');
                                break;
                            case PP_INTERVAL_WEEKLY.value :
                                installDate = installDate.add(1, 'weeks');
                                break;
                            default:
                                break;
                        }
                    }
                    instalment.dueOn = installDate.toDate();
                    instalment.notes = '';
                    instalment.payType = PAY_TYPE_CASH.value;

                    installments.push(instalment);
                }
            }
            _savePaymentPlan(dispatch, paymentPlan, installments, false, 0);
            break;

        default:
    }
    if (Boolean(clearAfterSavePP)) {
        dispatch({type: RES_PAYMENT_PLANNING.CLEAR.action, id: source.id, smRef: source.smRef});
    } else {
        dispatch({type: RES_PAYMENT_PLANNING.SAVE.action, id: source.id, smRef: source.smRef});
    }
};

