import _ from 'lodash';

export const __CHIRAL_CONTACT_DETAILS__ = 'Please contact Chiral System Ltd via support@chiralsystems.com';

export const __CHIRAL_SUPPORT_NUMBER__ = '+44 (0) 207 099 9126';

export const HTTP_RESPONSE_Unauthorized = 401;

export const ACC_ST_ACTIVE = {name: 'Active', text: 'Active'};

// Mail Shot related

export const RegisteredBefore = {value: 'RegisteredBefore', label: 'Registered Before', ordinal: 7};
export const RegisteredAfter = {value: 'RegisteredAfter', label: 'Registered After', ordinal: 9};
export const Male = {value: 'Male', label: 'Male', ordinal: 10};
export const Female = {value: 'Female', label: 'Female', ordinal: 11};


// icon related

export const SideBarButtonStyle = {color: "#1a92d0", fontSize: "24px", width: 28};
export const DashBoardButtonStyle = {color: "#70d0f6", fontSize: "48px", marginRight: "20px", width: 48};

export const ICON_PLUS = 'fa fa-plus';
export const ICON_DOWNLOAD = 'fas fa-download';
export const ICON_EDIT = 'fas fa-edit';
export const ICON_GOTO = 'fas fa-sign-out-alt';
export const ICON_DELETE = 'fa fa-times';
export const ICON_CANCEL = 'fas fa-ban';
export const ICON_OK = 'fa fa-check';
export const ICON_SHOW = 'fas fa-file-pdf';
export const ICON_PRINT = 'fas fa-print';
export const ICON_EMAIL = 'fas fa-at';
export const ICON_SMAIL = 'fas fa-envelope';
export const ICON_PHONE = 'fas fa-phone';
export const ICON_MOBILE = 'fas fa-mobile-alt';
export const ICON_CAMERA = 'fas fa-camera';
export const ICON_PREFS = 'fas fa-cog';
export const ICON_USERS = 'fas fa-users';
export const ICON_SMS = 'fas fa-sms';

// message related constants

export const ALL = "ALL";
export const PRINT24 = ICON_PRINT;

export const ADD = "ADD";
export const ADDBACK = "ADDBACK";
export const ADDBACK_MULTI = "ADDBACK_MULTI";

export const PREVIEW = "PREVIEW";
export const SEND = "SEND";

export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

export const MOVING_IN_DIARY = "MOVING_IN_DIARY";

export const RESERVE = "RESERVE";

export const DE_TYPE_VIDEO_CONF = {
    name: 'VideoConference',
    text: 'Video Conference',
    ordinal: 3,
    iconName: 'fa webCam16'
};

// patient related

export const PAT_CREATE_STATUS_USERNAME_EXISTS = 'USERNAME_EXISTS';
export const PAT_CREATE_STATUS_BLOCK_REGISTRATION = 'BLOCK_REGISTRATION';
export const PAT_CREATE_STATUS_ALREADY_REGISTERED = 'ALREADY_REGISTERED';
export const PAT_CREATE_STATUS_UNSUBMITTED = 'UNSUBMITTED';
export const PAT_CREATE_STATUS_MATCHED_BOTH = 'MATCHED_BOTH';
export const PAT_CREATE_STATUS_MATCHED_EMAIL = 'MATCHED_EMAIL';
export const PAT_CREATE_STATUS_MATCHED_MOBILE = 'MATCHED_MOBILE';
export const PAT_CREATE_STATUS_MATCHED_NEITHER = 'MATCHED_NEITHER';
export const PAT_CREATE_STATUS_UNMATCHED = 'UNMATCHED';
export const PAT_CREATE_STATUS_AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION';
export const PAT_CREATE_STATUS_CONFIRMED = 'CONFIRMED';
export const PAT_CREATE_STATUS_UNCONFIRMED = 'UNCONFIRMED';
export const PAT_CREATE_STATUS_TRIES_EXCEEDED = 'TRIES_EXCEEDED';
export const PAT_CREATE_STATUS_REG_MATCHED_BOTH = 'REG_MATCHED_BOTH';
export const PAT_CREATE_STATUS_REG_MATCHED_EMAIL = 'REG_MATCHED_EMAIL';

export const PAT_STATUS_INACTIVE = {name: 'INACTIVE', text: 'Inactive'};
export const PAT_STATUS_ACTIVE = {name: 'ACTIVE', text: 'Active'};
export const PAT_STATUS_BILLSONLY = {name: 'BILLSONLY', text: 'Bills Only'};
export const PAT_STATUS_CASUALPATIENT = {name: 'CASUALPATIENT', text: 'Casual'};
export const PAT_STATUS_DECEASED = {name: 'DECEASED', text: 'Deceased'};
export const PAT_STATUS_HOLD = {name: 'HOLD', text: 'Hold'};
export const PAT_STATUS_INTERIM = {name: 'INTERIM', text: 'Interim'};
export const PAT_STATUS_RECALLSONLY = {name: 'RECALLSONLY', text: 'Recalls Only'};
export const PAT_STATUS_WRITTENOFF = {name: 'WRITTENOFF', text: 'Written Off'};
export const PAT_STATUS_SLOWPAYER = {name: 'SLOWPAYER', text: 'Slow Payer'};
export const PAT_STATUS_NOCOMMUNICATION = {name: 'NOCOMMUNICATION', text: 'Non Communication'};

export const PAT_STATUSES = [PAT_STATUS_INACTIVE, PAT_STATUS_ACTIVE, PAT_STATUS_BILLSONLY, PAT_STATUS_CASUALPATIENT, PAT_STATUS_DECEASED, PAT_STATUS_HOLD, PAT_STATUS_INTERIM, PAT_STATUS_RECALLSONLY, PAT_STATUS_WRITTENOFF, PAT_STATUS_SLOWPAYER, PAT_STATUS_NOCOMMUNICATION];

export const ADD_PATIENT_OK = 'OK';

// App tool bar related

export const HM_MESSAGING = {
    id: 'HM_MESSAGING',
    header: 'Messaging',
    message: '',
    icon: 'far fa-comments'
};

export const HM_HELP_CHIRAL = {
    id: 'HM_HELP_CHIRAL',
    header: 'Help',
    message: '',
    icon: 'far fa-question-circle'
};

export const HM_ABOUT_CHIRAL = {
    id: 'HM_ABOUT_CHIRAL',
    header: 'About Chiral Cloud',
    message: '',
    icon: ICON_USERS
};

export const HM_LOGOUT = {
    id: 'HM_LOGOUT',
    header: 'Logout',
    message: '',
    icon: ICON_GOTO
};

export const HM_DeletePatientDocument = {
    id: 'HM_DeletePatientDocument',
    header: 'Delete Patient Document',
    message: 'Are you sure you want to delete this document?',
    icon: ICON_DELETE,
};

export const HM_EditPatientDocument = {
    id: 'HM_EditPatientDocument',
    header: 'Edit Document Information',
    message: 'Edit Patient Document',
    icon: ICON_EDIT,
};

export const HM_PrintPatientDocument = {
    id: 'HM_PrintPatientDocument',
    header: 'Print Patient Document',
    message: 'Print Patient Document',
    icon: ICON_PRINT,
};

export const HM_EmailPatientDocument = {
    id: 'HM_EmailPatientDocument',
    header: 'Email Patient Document',
    message: 'Email Patient Document',
    icon: ICON_EMAIL,
};

export const HM_PrintPresDocument = {
    id: 'HM_PrintPresDocument',
    header: 'Print Prescription',
    message: 'Print Patient Prescription',
    icon: ICON_PRINT,
};

export const HM_PrintReferralDocument = {
    id: 'HM_PrintReferralDocument',
    header: 'Print Referral Document',
    message: 'Print Patient Referral Document',
    icon: ICON_PRINT,
};

export const HM_DeleteTreatmentPlanDocument = {
    id: 'HM_DeleteTreatmentPlanDocument',
    header: 'Delete Treatment Plan Document',
    message: 'Are you sure you want to delete this document?',
    icon: ICON_DELETE,
};

export const HM_EditTreatmentPlanDocument = {
    id: 'HM_EditTreatmentPlanDocument',
    header: 'Edit Treatment Plan Document',
    message: 'Edit Patient Treatment Plan Document',
    icon: ICON_EDIT,
};

export const HM_PrintTreatmentPlanDocument = {
    id: 'HM_PrintTreatmentPlanDocument',
    header: 'Print Treatment Plan Document',
    message: 'Print Patient Treatment Plan Document',
    icon: ICON_PRINT,
};

export const HM_EmailTreatmentPlanDocument = {
    id: 'HM_EmailTreatmentPlanDocument',
    header: 'Email Treatment Plan Document',
    message: 'Email Patient Treatment Plan Document',
    icon: ICON_EMAIL,
};

export const HM_DeletePaymentPlanDocument = {
    id: 'HM_DeletePaymentPlanDocument',
    header: 'Delete Payment Plan Document',
    message: 'Are you sure you want to delete this document?',
    icon: ICON_DELETE,
};

export const HM_DeleteConsentDocument = {
    id: 'HM_DeleteConsentDocument',
    header: 'Delete Consent Document',
    message: 'Are you sure you want to delete this document?',
    icon: ICON_DELETE,
};

export const HM_EditConsentDocument = {
    id: 'HM_EditConsentDocument',
    header: 'Edit Consent Document',
    message: 'Edit Consent Document',
    icon: ICON_EDIT,
};

export const HM_PrintConsentDocument = {
    id: 'HM_PrintConsentDocument',
    header: 'Print Consent Document',
    message: 'Print Consent Document',
    icon: ICON_PRINT,
};

export const HM_EmailConsentDocument = {
    id: 'HM_EmailConsentDocument',
    header: 'Email Consent Document',
    message: 'Email Consent Document',
    icon: ICON_EMAIL,
};

export const HM_EditPaymentPlanDocument = {
    id: 'HM_PrintPaymentPlanDocument',
    header: 'Edit Payment Plan',
    message: '',
    icon: ICON_EDIT
};

export const HM_PrintPaymentPlanDocument = {
    id: 'HM_PrintPaymentPlanDocument',
    header: 'Print Payment Plan',
    message: '',
    icon: ICON_PRINT
};

export const HM_EmailPaymentPlanDocument = {
    id: 'HM_EmailPaymentPlanDocument',
    header: 'Email Payment Plan Document',
    message: 'Email Payment Plan Document',
    icon: ICON_EMAIL,
};

export const HM_AddFollowOnRecall = {
    id: 'HM_AddFollowOnRecall',
    id2: 'HM_AddFollowOnRecall2',
    header: 'Add Recall Follow On',
    message: 'Add a patient recall follow on',
    tpOwner: 'treatmentPlan.patient.patientJourney.stage',
    ppOwner: 'patientData.patientJourney.stage',
    icon: ICON_PLUS,
};

// User Roles

export const ADMINISTRATOR = 'ADMINISTRATOR';
export const CLINICIAN = 'CLINICIAN';
export const NURSE = 'NURSE';
export const TABLET = 'TABLET';

// account types

export const ACC_TYPE_INVOICE = 'ACC_TYPE_INVOICE';
export const ACC_TYPE_RECEIPT = 'ACC_TYPE_RECEIPT';
export const ACC_TYPE_PAYMENT = 'ACC_TYPE_PAYMENT';

// treatment plan statuses

export const TP_PROPOSED = {name: 'PROPOSED', text: 'Proposed', ord: 0};
export const TP_ACCEPTED = {name: 'ACCEPTED', text: 'Accepted', ord: 1};
export const TP_REJECTED = {name: 'REJECTED', text: 'Rejected', ord: 2};
export const TP_CLOSED = {name: 'CLOSED', text: 'Closed', ord: 3};

export const TP_STATUS = [TP_PROPOSED, TP_ACCEPTED, TP_REJECTED, TP_CLOSED];

export const tpStatusText = status => TP_STATUS.find(entry => status === entry.name).text;

// patient journey stage actions

export const PJ_None = {value: 'None', label: 'No Action', months: 0, ord: 0};
export const PJ_RET3 = {value: 'RET3', label: 'Retention Check 3 Months', months: 3, ord: 1};
export const PJ_RET6 = {value: 'RET6', label: 'Retention Check 6 Months', months: 6, ord: 2};
export const PJ_RET9 = {value: 'RET9', label: 'Retention Check 9 Months', months: 9, ord: 3};
export const PJ_RET12 = {value: 'RET12', label: 'Retention Check 12 Months', months: 12, ord: 4};
export const PJ_RV3 = {value: 'RV3', label: 'Review in 3 Months', months: 3, ord: 5};
export const PJ_RV6 = {value: 'RV6', label: 'Review in 6 Months', months: 6, ord: 6};
export const PJ_RV9 = {value: 'RV9', label: 'Review in 9 Months', months: 9, ord: 7};
export const PJ_RV12 = {value: 'RV12', label: 'Review in 12 Months', months: 12, ord: 8};
export const PJ_RV18 = {value: 'RV18', label: 'Review in 18 Months', months: 18, ord: 9};
export const PJ_RV24 = {value: 'RV24', label: 'Review in 24 Months', months: 24, ord: 10};
export const PJ_RV36 = {value: 'RV36', label: 'Review in 36 Months', months: 36, ord: 11};
export const PJ_RV48 = {value: 'RV48', label: 'Review in 48 Months', months: 48, ord: 12};
export const PJ_RET4 = {value: 'RET4', label: 'Retention Check 4 Months', months: 4, ord: 13};
export const PJ_DEACT = {value: 'DEACT', label: 'Deactivate Patient', months: 0, ord: 14};
export const PJ_Recall = {value: 'Recall', label: 'Set Recall', months: 0, ord: 15};

export const PJ_Action = [PJ_None, PJ_RET3, PJ_RET6, PJ_RET9, PJ_RET12, PJ_RV3, PJ_RV6, PJ_RV9, PJ_RV12, PJ_RV18, PJ_RV24, PJ_RV36, PJ_RV48, PJ_RET4, PJ_DEACT, PJ_Recall];

export const pjActionOrdinal = action => PJ_Action.find(entry => action === entry.name).ord;
export const pjActionText = action => PJ_Action.find(entry => action === entry.name).text;

// Template / Document Types

export const DOC_IMAGE = {name: 'IMAGE', text: ''};
export const DOC_SYSTEM_TEMPLATE = {name: 'SYSTEM_TEMPLATE', text: ''};
export const DOC_PDF = {name: 'PDF', text: ''};
export const DOC_TREATMENT_PLAN = {name: 'TREATMENT_PLAN', text: 'Standard Treatment Plan'};

// consent form related

export const OUTCOME_ConsentNotGiven = 'ConsentNotGiven';

// Report types

export const REP_AccRendered = {value: 'AccRendered', label: "Accounts Rendered"};
export const REP_BackupHistory = {value: 'BackupHistory', label: "Backup History"};
export const REP_BGOverdueList = {value: 'BGOverdueList', label: "Billing Group Overdue List"};
export const REP_CreditNote = {value: 'CreditNote', label: "Credit Note"};
export const REP_DayList = {value: 'DayList', label: "Day List"};
export const REP_DaysInvoicesReceipts = {value: 'DaysInvoicesReceipts', label: "Days Transactions"};
export const REP_Invoice = {value: 'Invoice', label: "Invoice"};
export const REP_InvoiceDueList = {value: 'InvoiceDueList', label: "Invoices Due"};
export const REP_IssuableInvoices = {value: 'IssuableInvoices', label: "Issuable Invoices"};
export const REP_NegativePatientAccount = {value: 'NegativePatientAccount', label: "Negative Patient Accounts"};
export const REP_NonAttendence1 = {value: 'NonAttendence1', label: "Non Attendence 1"};
export const REP_OverdueList = {value: 'OverdueList', label: "Overdue List"};
export const REP_PatientAccount = {value: 'PatientAccount', label: "Patient Account"};
export const REP_PatientAdjustment = {value: 'PatientAdjustment', label: "Account Adjustement"};
export const REP_PatientAppointmentHistory = {value: 'PatientAppointmentHistory', label: "Patient Appointment History"};
export const REP_PatientTreatmentHistory = {value: 'PatientTreatmentHistory', label: "Patient Treatment History"};
export const REP_Payment = {value: 'Payment', label: "Payment"};
export const REP_PositivePatientAccount = {value: 'PositivePatientAccount', label: "Patient Accounts in Credit"};
export const REP_Recalls = {value: 'Recalls', label: "Recall"};
export const REP_RecallsDue = {value: 'RecallsDue', label: "Recalls Due"};
export const REP_Reminder = {value: 'Reminder', label: "Reminder"};
export const REP_RemindersDue = {value: 'RemindersDue', label: "Reminders Due"};
export const REP_RevenueShare = {value: 'RevenueShare', label: "Years Revenue Share"};
export const REP_ScheduledCharges = {value: 'ScheduledCharges', label: "Scheduled Charges"};
export const REP_StandardPatient = {value: 'StandardPatient', label: "Standard Patient"};
export const REP_TreatmentHistory = {value: 'TreatmentHistory', label: "Treatment History"};
export const REP_TreatmentPlan = {value: 'TreatmentPlan', label: "Treatment Plan"};
export const REP_UninvoicedTreatments = {value: 'UninvoicedTreatments', label: "Uninvoiced Treatments"};
export const REP_WorkRequired1 = {value: 'WorkRequired1', label: "WorkRequired1"};
export const REP_WorkRequired2 = {value: 'WorkRequired2', label: "WorkRequired2"};
export const REP_YearlyRevenue = {value: 'YearlyRevenue', label: "Years Revenue"};
export const REP_NonAttendence2 = {value: 'NonAttendence2', label: "Non Attendence 2"};
export const REP_WeeksInvoicesReceipts = {value: 'WeeksInvoicesReceipts', label: "Weeks Transactions"};
export const REP_Prescription = {value: 'Prescription', label: "Standard Prescription"};
export const REP_NonAttendence3 = {value: 'NonAttendence3', label: "Non Attendence 3"};
export const REP_CostCentre1 = {value: 'CostCentre1', label: "Cost Centre 1"};
export const REP_StandardRefrrerPatient = {value: 'StandardRefrrerPatient', label: "Standard Referrer / Patient"};
export const REP_NALetter1 = {value: 'NALetter1', label: "Non Attendence Letter 1"};
export const REP_NALetter2 = {value: 'NALetter2', label: "Non Attendence Letter 2"};
export const REP_NALetter3 = {value: 'NALetter3', label: "Non Attendence Letter 3"};
export const REP_DaysCash = {value: 'DaysCash', label: "Days Cash"};
export const REP_PersonalDetails = {value: 'PersonalDetails', label: "Personal Details"};
export const REP_DaysCanccelations = {value: 'DaysCanccelations', label: "Days Cancellation"};
export const REP_WeeksCanccelations = {value: 'WeeksCanccelations', label: "Weeks Cancellations"};
export const REP_PatientNote = {value: 'PatientNote', label: "Patient Note"};
export const REP_PatientNoteHistory = {value: 'PatientNoteHistory', label: "Patient Note History"};
export const REP_MedicalCondition = {value: 'MedicalCondition', label: "Patient Medical Condition"};
export const REP_MedicalConditionHistory = {
    value: 'MedicalConditionHistory',
    label: "Patient Medical Condition History"
};
export const REP_ImageView = {value: 'ImageView', label: "Image Viewer"};
export const REP_FutureAppointments = {value: 'FutureAppointments', label: "Patient Future Appointment Summary"};
export const REP_Receipt = {value: 'Receipt', label: "Receipt"};
export const REP_GroupRecalls = {value: 'GroupRecalls', label: "Group Recall"};
export const REP_JointRecalls = {value: 'JointRecalls', label: "Joint Recall"};
export const REP_FailedToAttend = {value: 'FailedToAttend', label: "Failed To Attend"};
export const REP_ContactList = {value: 'ContactList', label: "Contact List"};
export const REP_ContactListDetails = {value: 'ContactListDetails', label: "Contact List Details"};
export const REP_PatientChart = {value: 'PatientChart', label: "Patient Chart"};
export const REP_PatientPerioChart = {value: 'PatientPerioChart', label: "Patient Perio Chart"};
export const REP_RecallsSent = {value: 'RecallsSent', label: "Recalls Sent"};
export const REP_RecallsSentToday = {value: 'RecallsSentToday', label: "Recalls Sent Today"};
export const REP_RecallSuccesses = {value: 'RecallSuccesses', label: "Recall Successes"};
export const REP_IncompletedTreatments = {value: 'IncompletedTreatments', label: "Incompleted Treatments"};
export const REP_MonthlyRevenue = {value: 'MonthlyRevenue', label: "Months Revenue"};
export const REP_BGAccRendered = {value: 'BGAccRendered', label: "Billing Group Accounts Rendered"};
export const REP_MonthlyCash = {value: 'MonthlyCash', label: "Months Cash"};
export const REP_YearlyCash = {value: 'YearlyCash', label: "Years Cash"};
export const REP_VoidedTransactions = {value: 'VoidedTransactions', label: "Voided Transactions"};
export const REP_TreatmentCodes = {value: 'TreatmentCodes', label: "Treatment Codes"};
export const REP_Refund = {value: 'Refund', label: "Refund"};
export const REP_AccountWriteOffs = {value: 'AccountWriteOffs', label: "Account Write Offs"};
export const REP_NewPatients = {value: 'NewPatients', label: "New Patients"};
export const REP_PotHygiene = {value: 'PotHygiene', label: "Potential Hygiene Patients"};
export const REP_ReferredPatients = {value: 'ReferredPatients', label: "Referrers Patients"};
export const REP_DayList2 = {value: 'DayList2', label: "Performer Day List"};
export const REP_OrthoPaymentPlan4 = {value: 'OrthoPaymentPlan4', label: "Orthodontic Payment Plan"};
export const REP_OrthoRefLetter = {value: 'OrthoRefLetter', label: "Orthodontic Referrer Letter"};
export const REP_PerformerUDAs = {value: 'PerformerUDAs', label: "Performer UDA/UOAs"};
export const REP_PerformerStacked = {value: 'PerformerStacked', label: "Performer Stacked Claims"};
export const REP_PatientJourney = {value: 'PatientJourney', label: "Patient Journey"};
export const REP_PerformerSubmitted = {value: 'PerformerSubmitted', label: "Performer Submitted Claims"};
export const REP_PerformerRejected = {value: 'PerformerRejected', label: "Performer Rejected Claims"};
export const REP_PerformerRemoved = {value: 'PerformerRemoved', label: "Performer Removed Claims"};
export const REP_PerformerScheduled = {value: 'PerformerScheduled', label: "Performer Scheduled Claims"};
export const REP_PatientJourneySS = {value: 'PatientJourneySS', label: "Patient Journey Snapshot"};
export const REP_ConsentForm = {value: 'ConsentForm', label: "Patient Consent Form"};
export const REP_KPITreatmentPlans = {value: 'KPITreatmentPlans', label: "Treatment Plans"};
export const REP_KPIReferrealSource = {value: 'KPIReferrealSource', label: "Referral Sources"};
export const REP_ActivePatients = {value: 'ActivePatients', label: "Active Patients"};
export const REP_TreatmentCodeUsage = {value: 'TreatmentCodeUsage', label: "Treatment Code Usage"};
export const REP_PaymentPlan = {value: 'PaymentPlan', label: "Payment Plan"};
export const REP_PatientAssessment = {value: 'PatientAssessment', label: "Patient Assessment"};
export const REP_RecallManagement = {value: 'RecallManagement', label: "Recall Management"};
export const REP_ActivePaymentPlans = {value: 'ActivePaymentPlans', label: "Active Payment Plans"};
export const REP_Referrers = {value: 'Referrers', label: "Referrers"};
export const REP_PaymentPlanProposal = {value: 'PaymentPlanProposal', label: "Payment Plan Proposal"};
export const REP_StandardAppointmentLetter = {value: 'StandardAppointmentLetter', label: "Standard Appointment Letter"};

export const REPORTS = [
    REP_AccRendered,
    REP_BackupHistory,
    REP_BGOverdueList,
    REP_CreditNote,
    REP_DayList,
    REP_DaysInvoicesReceipts,
    REP_Invoice,
    REP_InvoiceDueList,
    REP_IssuableInvoices,
    REP_NegativePatientAccount,
    REP_NonAttendence1,
    REP_OverdueList,
    REP_PatientAccount,
    REP_PatientAdjustment,
    REP_PatientAppointmentHistory,
    REP_PatientTreatmentHistory,
    REP_Payment,
    REP_PositivePatientAccount,
    REP_Recalls,
    REP_RecallsDue,
    REP_Reminder,
    REP_RemindersDue,
    REP_RevenueShare,
    REP_ScheduledCharges,
    REP_StandardPatient,
    REP_TreatmentHistory,
    REP_TreatmentPlan,
    REP_UninvoicedTreatments,
    REP_WorkRequired1,
    REP_WorkRequired2,
    REP_YearlyRevenue,
    REP_NonAttendence2,
    REP_WeeksInvoicesReceipts,
    REP_Prescription,
    REP_NonAttendence3,
    REP_CostCentre1,
    REP_StandardRefrrerPatient,
    REP_NALetter1,
    REP_NALetter2,
    REP_NALetter3,
    REP_DaysCash,
    REP_PersonalDetails,
    REP_DaysCanccelations,
    REP_WeeksCanccelations,
    REP_PatientNote,
    REP_PatientNoteHistory,
    REP_MedicalCondition,
    REP_MedicalConditionHistory,
    REP_ImageView,
    REP_FutureAppointments,
    REP_Receipt,
    REP_GroupRecalls,
    REP_JointRecalls,
    REP_FailedToAttend,
    REP_ContactList,
    REP_ContactListDetails,
    REP_PatientChart,
    REP_PatientPerioChart,
    REP_RecallsSent,
    REP_RecallsSentToday,
    REP_RecallSuccesses,
    REP_IncompletedTreatments,
    REP_MonthlyRevenue,
    REP_BGAccRendered,
    REP_MonthlyCash,
    REP_YearlyCash,
    REP_VoidedTransactions,
    REP_TreatmentCodes,
    REP_Refund,
    REP_AccountWriteOffs,
    REP_NewPatients,
    REP_PotHygiene,
    REP_ReferredPatients,
    REP_DayList2,
    REP_OrthoPaymentPlan4,
    REP_OrthoRefLetter,
    REP_PerformerUDAs,
    REP_PerformerStacked,
    REP_PatientJourney,
    REP_PerformerSubmitted,
    REP_PerformerRejected,
    REP_PerformerRemoved,
    REP_PerformerScheduled,
    REP_PatientJourneySS,
    REP_ConsentForm,
    REP_KPITreatmentPlans,
    REP_KPIReferrealSource,
    REP_ActivePatients,
    REP_TreatmentCodeUsage,
    REP_PaymentPlan,
    REP_PatientAssessment,
    REP_RecallManagement,
    REP_ActivePaymentPlans,
    REP_Referrers,
    REP_PaymentPlanProposal,
    REP_StandardAppointmentLetter,
];

//

export const reportOrdinal = report => _.findIndex(REPORTS, (entry => report === entry.value));

export const RRT_REMINDER_HOMEPHONE = {name: 'REMINDER_HOMEPHONE', text: 'Home Phone', icon: 'phoneIcon16', ordinal: 0};
export const RRT_REMINDER_WORKPHONE = {name: 'REMINDER_WORKPHONE', text: 'Work Phone', icon: 'phoneIcon16', ordinal: 1};
export const RRT_REMINDER_OTHERPHONE = {
    name: 'REMINDER_OTHERPHONE',
    text: 'Other Phone',
    icon: 'phoneIcon16',
    ordinal: 2
};
export const RRT_REMINDER_MOBILE = {name: 'REMINDER_MOBILE', text: 'Mobile Phone', icon: 'mobileIcon16', ordinal: 3};
export const RRT_REMINDER_EMAIL = {name: 'REMINDER_EMAIL', text: 'Email', icon: ICON_EMAIL, ordinal: 4};
export const RRT_REMINDER_SMS = {name: 'REMINDER_SMS', text: 'SMS', icon: ICON_SMS, ordinal: 5};
export const RRT_REMINDER_SNAILMAIL = {name: 'REMINDER_SNAILMAIL', text: 'Letter', icon: ICON_SMAIL, ordinal: 6};

export const RRT_RECALL_EMAIL = {name: 'RECALL_EMAIL', text: 'Email', icon: ICON_EMAIL, ordinal: 7};
export const RRT_RECALL_SMS = {name: 'RECALL_SMS', text: 'SMS', icon: ICON_SMS, ordinal: 8};
export const RRT_RECALL_SNAILMAIL = {name: 'RECALL_SNAILMAIL', text: 'Letter', icon: ICON_SMAIL, ordinal: 9};
export const RRT_RECALL_HOMEPHONE = {name: 'RECALL_HOMEPHONE', text: 'Home Phone', icon: ICON_PHONE, ordinal: 10};
export const RRT_RECALL_WORKPHONE = {name: 'RECALL_WORKPHONE', text: 'Work Phone', icon: ICON_PHONE, ordinal: 11};
export const RRT_RECALL_OTHERPHONE = {name: 'RECALL_OTHERPHONE', text: 'Other Phone', icon: ICON_PHONE, ordinal: 12};
export const RRT_RECALL_MOBILE = {name: 'RECALL_MOBILE', text: 'Mobile Phone', icon: ICON_MOBILE, ordinal: 13};
export const RRT_None = {name: 'None', text: 'None', icon: '', ordinal: 14};

export const RecallRemType = [RRT_REMINDER_HOMEPHONE, RRT_REMINDER_WORKPHONE, RRT_REMINDER_OTHERPHONE, RRT_REMINDER_MOBILE, RRT_REMINDER_EMAIL,
    RRT_REMINDER_SMS, RRT_REMINDER_SNAILMAIL, RRT_RECALL_EMAIL, RRT_RECALL_SMS, RRT_RECALL_SNAILMAIL, RRT_RECALL_HOMEPHONE, RRT_RECALL_WORKPHONE,
    RRT_RECALL_OTHERPHONE, RRT_RECALL_MOBILE, RRT_None];

export const recallRemTypeText = recallRemType => RecallRemType[_.findIndex(RecallRemType, (entry => recallRemType === entry.name))].text;

// Date formats

export const JSON_DATE_FORMAT = 'MM/DD/YYYY';

// Cancellation Reason related

export const SystemReason = 'SystemReason';
export const UserReason = 'UserReason';

export const SystemReasonText = 'Practice Reason';

export const UserReasonText = 'Patient Reason';

export const cancellationReasonTypes = [{value: SystemReason, label: SystemReasonText}, {
    value: UserReason,
    label: UserReasonText
}];

// Diary related

export const HM_PreviewPatientTreatmentPlan = {
    id: 'HM_PreviewPatientTreatmentPlan',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: ICON_SHOW
};

export const HM_PreviewPatientPaymentPlan = {
    id: 'HM_PreviewPatientPaymentPlan',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: ICON_SHOW
};

export const HM_PreviewPatientConsent = {
    id: 'HM_PreviewPatientConsent',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: ICON_SHOW
};

// Account Section Related

export const REPORT_SHOW = 'SHOW';

export const HM_ShowInvoice = {id: 'ShowInvoice', label: 'Show Invoice', header: '', message: '', icon: ICON_SHOW};
export const HM_PrintInvoice = {id: 'PrintInvoice', label: 'Print Invoice', header: '', message: '', icon: ICON_PRINT};

export const HM_ShowReceipt = {id: 'ShowReceipt', label: 'Show Receipt', header: '', message: '', icon: ICON_SHOW};
export const HM_PrintReceipt = {id: 'PrintReceipt', label: 'Print Receipt', header: '', message: '', icon: ICON_PRINT};

export const HM_ShowPayment = {id: 'ShowPayment', label: 'Show Payment', header: '', message: '', icon: ICON_SHOW};
export const HM_PrintPayment = {id: 'PrintPayment', label: 'Print Payment', header: '', message: '', icon: ICON_PRINT};

export const HM_ShowCreditNote = {
    id: 'ShowCreditNote',
    label: 'Show Credit Note',
    header: '',
    message: '',
    icon: ICON_SHOW
};
export const HM_PrintCreditNote = {
    id: 'PrintCreditNote',
    label: 'Print Credit Note',
    header: '',
    message: '',
    icon: ICON_PRINT
};

export const HM_ShowRefund = {id: 'ShowRefund', label: 'Show Refund', header: '', message: '', icon: ICON_SHOW};
export const HM_PrintRefund = {id: 'PrintRefund', label: 'Print Refund', header: '', message: '', icon: ICON_PRINT};

export const HM_ShowAdjustment = {
    id: 'ShowAdjustment',
    label: 'Show Adjustment',
    header: '',
    message: '',
    icon: ICON_SHOW
};
export const HM_PrintAdjustment = {
    id: 'PrintAdjustment',
    label: 'Print Adjustment',
    header: '',
    message: '',
    icon: ICON_PRINT
};

// Update targets

export const UP_PATIENTBALANCE = "PATIENTBALANCE";
export const UP_PAYMENT = "PAYMENT";
export const UP_REFUND_ISSUE = "REFUND_ISSUE";
export const UP_ADD_DIARYEVENT = "ADD_DIARYEVENT";
export const UP_DEL_DIARYEVENT = "DEL_DIARYEVENT";
export const UP_DIARYEVENT = "UP_DIARYEVENT";
export const UP_RECALL = "RECALL";
export const UP_RECALL_CLOSE = "RECALL_CLOSE";

export const UP_NEWTASK = "UP_NEWTASK";
export const UP_TASKUPDATE = "UP_TASKUPDATE";

export const UP_RULEADD = "RULEADD";
export const UP_RULEUPDATE = "RULEUPDATE";
export const UP_RULEREMOVE = "RULEREMOVE";

export const UP_INVOICEISSUE = "INVOICEISSUE";

export const UP_DOCUMENT_USE_AS_PHOTO = "UP_DOCUMENT_USE_AS_PHOTO";

export const UP_TEMPLATE_ADDED = "TEMPLATE_ADDED";

export const UP_RECEIPTISSUED = "UP_RECEIPTISSUED";

// void related values 
export const UP_INVOICEVOIDED = "INVOICEVOIDED";
export const UP_CREDITNOTEVOIDED = "CREDITNOTEVOIDED";
export const UP_PAYMENTVOIDED = "PAYMENTVOIDED";
export const UP_ADJUSTMENTVOIDED = "ADJUSTMENTVOIDED";
export const UP_RECEIPTVOIDED = "RECEIPTVOIDED";
export const UP_REFUNDVOIDED = "REFUNDVOIDED";

export const UP_PAYMENTPAYTYPE = "PAYMENTPAYTYPE";
export const UP_RECEIPTPAYTYPE = "RECEIPTPAYTYPE";

// schedule rules related

export const SR_CLINIC = 0;
export const SR_NOTAVAILABLE = 2;

// referrer related

export const REF_INDEPENDENT = {label: 'Independent', value: 'INDEPENDENT'};
export const REF_PATIENT = {label: 'Patient', value: 'PATIENT'};
export const REF_PROVIDER = {label: 'Provider', value: 'PROVIDER'};

export const REFERRER_TYPES = [REF_INDEPENDENT, REF_PATIENT, REF_PROVIDER];

export const HM_EMAIL_IMAGE_QR = {
    id: 'HM_EMAIL_IMAGE_QR',
    label: 'Patient Image Email QR Code',
    header: 'Patient Image Email QR Code',
    message: '',
    icon: '',
};

// Country related

export const CC_UK = 230;

const rgbToHex = (rgb) => {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
};

export const fullColorHex = (r, g, b) => {
    const red = rgbToHex(r);
    const green = rgbToHex(g);
    const blue = rgbToHex(b);
    return `#${red}${green}${blue}`;
};

export const fullColorHexColonSep = (colours) => {

    const parts = colours.split(':');
    const red = rgbToHex(parts[0]);
    const green = rgbToHex(parts[1]);
    const blue = rgbToHex(parts[2]);
    return `#${red}${green}${blue}`;
};

export const TASK_STATUS_ACTIVE = 'Active';

// payment plan related

export const PP_STATUS_ACCEPTED = {name: 'Accepted', text: 'Accepted', ord: 1};
export const PP_STATUS_STATED = {name: 'Started', text: 'Started', ord: 2};
export const PP_STATUS_FINISHED = {name: 'Finished', text: 'Finished', ord: 3};
export const PP_STATUS_SUSPENDED = {name: 'Suspended', text: 'Suspended', ord: 4};
export const PP_STATUS_WRITTEN_OFF = {name: 'WrittenOff', text: 'Written Off', ord: 5};
export const PP_STATUS_PROPOSED = {name: 'Proposed', text: 'Proposed', ord: 7};

export const PP_STATUS_VISIBLE = [PP_STATUS_PROPOSED, PP_STATUS_ACCEPTED, PP_STATUS_STATED, PP_STATUS_FINISHED, PP_STATUS_SUSPENDED, PP_STATUS_WRITTEN_OFF];

export const PP_INTERVAL_WEEKLY = {value: 'Weekly', label: 'Weekly'};
export const PP_INTERVAL_MONTHLY = {value: 'Monthly', label: 'Monthly'};
export const PP_INTERVAL_QUARTERLY = {value: 'Quarterly', label: 'Quarterly'};
export const PP_INTERVAL_MONTHS2 = {value: 'Months2', label: 'Every 2 Months'};
export const PP_INTERVAL_MONTHS4 = {value: 'Months4', label: 'Every 4 Months'};
export const PP_INTERVAL_MONTHS6 = {value: 'Months6', label: 'Every 6 Months'};

export const HM_PORTAL_BOOK_VIDEO_CALL = {
    id: 'HM_PORTAL_BOOK_VIDEO_CALL',
    header: 'Book Video Call',
    message: 'Are you sure you want to book this video call',
    icon: ICON_OK,
};

export const HM_PORTAL_BOOK_VIDEO_CALL_NOTE = {
    id: 'HM_PORTAL_BOOK_VIDEO_CALL_NOTE',
    header: 'Video Call Booking Note',
    message: 'Video Call Booking Note',
    icon: ICON_OK,
};

export const HM_PRINT_MED_HISTORY = {
    id: 'PRINT_MED_HISTORY',
    header: 'Print Medical History',
    message: 'Print current medical history Form'
};

export const HM_PRINT_QUESTIONNAIRE = {
    id: 'PRINT_QUESTIONNAIRE',
    header: 'Print Questionnaire',
    message: 'Print current medical history Form'
};

export const HM_PreviewPatientDocument = {
    id: 'HM_PreviewPatientDocument',
    header: 'Email Preview',
    message: 'Preview the email content',
    icon: ICON_SHOW
};

export const HM_PrintProviderDayList = {
    id: 'HM_PrintProviderDayList',
    header: 'Print Day List',
    message: '',
    icon: ICON_PRINT
};

export const HM_notImplemented = {
    id: 'HM_notImplemented',
    header: 'Not Implemented',
    message: 'This feature is not yet implemented'
};
