import * as Actions from '../actions/index';
import _ from 'lodash';

const initialState = {
    wsmessageid: 0,
    wsSendMessageid: 0,

    message:{},

    messageToSend: null,
    userMessageToSend: null,

    websocketState: WebSocket.CLOSED,
};

export const websockets = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.WSM_SET_STATE:
                return {
                    ...state,
                    websocketState: action.payload,
                };

            case Actions.WSM_SEND:
                return {
                    ...state,
                    receiver: Actions.WSM_SEND,
                    messageToSend: {message: action.payload, id: state.wsSendMessageid},
                    wsSendMessageid: state.wsSendMessageid++,
                };

            case Actions.WSM_SEND_USER:
                console.log(`${Actions.WSM_SEND_USER} : ${action.payload.type}`);
                return {
                    ...state,
                    receiver: Actions.WSM_SEND_USER,
                    userMessageToSend: {message: action.payload, id: state.wsSendMessageid},
                    wsSendMessageid: state.wsSendMessageid++,
                };

            case Actions.WSM_RECEIVE:
                return {
                    ...state,
                    messageToRead: action.payload,
                };

            case Actions.WSM_APPOINTMENTS:
                return {
                    ...state,
                    sender: Actions.WSM_APPOINTMENTS,
                    message: action.payload,
                    wsmessageid: state.wsmessageid++,
                };

            case Actions.WSM_UPDATES:
                return {
                    ...state,
                    sender: Actions.WSM_UPDATES,
                    message: action.payload,
                    wsmessageid: state.wsmessageid++,
                };

            case Actions.WSM_INVOICES:
                return {
                    ...state,
                    sender: Actions.WSM_INVOICES,
                    message: action.payload,
                    wsmessageid: state.wsmessageid++,
                };

            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                console.log(`${Actions.LOGOUT} : -`);

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};