import _ from 'lodash';
import moment from 'moment';

import {
    RES_HOUSEKEEPING,
    RES_HOUSEKEEPING_ADETS,
    RES_HOUSEKEEPING_ASSCATS,
    RES_HOUSEKEEPING_CCS,
    RES_HOUSEKEEPING_CRS,
    RES_HOUSEKEEPING_FAVS,
    RES_HOUSEKEEPING_JSTAGES,
    RES_HOUSEKEEPING_MCS,
    RES_HOUSEKEEPING_OCCS,
    RES_HOUSEKEEPING_QUES,
    RES_HOUSEKEEPING_RECTYPES,
    RES_HOUSEKEEPING_TCODES,
    RES_HOUSEKEEPING_TITLES
} from "../housekeeping";
import {ac} from "../../index";
import {
    SM_HOUSEKEEPING_ADETS,
    SM_HOUSEKEEPING_ASSCATS,
    SM_HOUSEKEEPING_CCS,
    SM_HOUSEKEEPING_CRS,
    SM_HOUSEKEEPING_FAVS,
    SM_HOUSEKEEPING_JSTAGES,
    SM_HOUSEKEEPING_MCS,
    SM_HOUSEKEEPING_OCCS,
    SM_HOUSEKEEPING_QUES,
    SM_HOUSEKEEPING_RECTYPES,
    SM_HOUSEKEEPING_TCODES,
    SM_HOUSEKEEPING_TITLES
} from "../stateManagement";

const saveTitles = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_TITLES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_TITLES.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveCostCenters = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_CCS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_CCS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveTreatmentFavourites = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_FAVS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_FAVS.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveTreatmentCodes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_TCODES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_TCODES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveJourneyStages = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited) || item.id < 0) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_JSTAGES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_JSTAGES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveAppointmentTypes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(_item => {

        if (Boolean(_item.edited)) {
            _item.edited = false;
            const item = {..._item};
            item.defaultProvider = null;
            item.id = item.id < 0 ? null : item.id;

            if (moment.isMoment(item.duration)) {
                item.duration = item.duration.toDate().toUTCString();
            }
            ac.fetchClient().post(`${RES_HOUSEKEEPING_ADETS.AT_SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_ADETS.AT_SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    })
};

const saveDiaryEventTypes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {

            item.edited = false;

            const _item = {...item};
            _item.id = _item.id < 0 ? null : _item.id;
            _item.type = _item.type.name;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_ADETS.DE_SAVE.url}?mcId=${ac.getMcId()}`, _item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_ADETS.DE_SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveRecallTypes = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_RECTYPES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_RECTYPES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveMedicalConditions = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_MCS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_MCS.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveQuestionnaires = (dispatch, items) => {

    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;

            ac.fetchClient().post(`${RES_HOUSEKEEPING_QUES.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        dispatch({type: RES_HOUSEKEEPING_QUES.SAVE.action, payload: returns});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveCancellationReasons = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_CRS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_CRS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveOccupations = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_OCCS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_OCCS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const saveAssCats = (dispatch, items) => {

    const staticItems = _.filter(items, item => !(item.id < 0 || item.edited));
    const updatedNewItems = _.filter(items, item => item.id < 0 || item.edited);
    const returns = [];

    updatedNewItems.forEach(item => {

        if (Boolean(item.edited)) {
            item.edited = false;
            item.id = item.id < 0 ? null : item.id;
            ac.fetchClient().post(`${RES_HOUSEKEEPING_ASSCATS.SAVE.url}?mcId=${ac.getMcId()}`, item)
                .then(res => {

                    returns.push(res.data);

                    if (returns.length === updatedNewItems.length) {
                        const newHistory = [...staticItems, ...returns];
                        dispatch({type: RES_HOUSEKEEPING_ASSCATS.SAVE.action, payload: newHistory});
                        dispatch({type: RES_HOUSEKEEPING.SAVE.action, payload: returns}) // needed to clear save icon and button
                    }
                });
        }
    });
};

const getData = (sm, item, name) => {
    const base = _.get(sm, `${item.id}`);
    return base === undefined ? [] : base.data[name];
};

export const saveHouskeeping = (dispatch, sm) => {

    const titles = getData(sm, SM_HOUSEKEEPING_TITLES, 'titles');
    const costCenters = getData(sm, SM_HOUSEKEEPING_CCS, 'costCenters');
    const treatmentFavourites = getData(sm, SM_HOUSEKEEPING_FAVS, 'treatmentFavourites');
    const treatmentCodes = getData(sm, SM_HOUSEKEEPING_TCODES, 'treatmentCodes');
    const journeyStages = getData(sm, SM_HOUSEKEEPING_JSTAGES, 'journeyStages');
    const appointmentTypes = getData(sm, SM_HOUSEKEEPING_ADETS, 'appointmentTypes');
    const diaryEventTypes = getData(sm, SM_HOUSEKEEPING_ADETS, 'diaryEventTypes');
    const recallTypes = getData(sm, SM_HOUSEKEEPING_RECTYPES, 'recallTypes');
    const medicalConditions = getData(sm, SM_HOUSEKEEPING_MCS, 'medicalConditions');
    const questions = getData(sm, SM_HOUSEKEEPING_QUES, 'questions');
    const cancellationReasons = getData(sm, SM_HOUSEKEEPING_CRS, 'cancellationReasons');
    const occupations = getData(sm, SM_HOUSEKEEPING_OCCS, 'occupations');
    const assCats = getData(sm, SM_HOUSEKEEPING_ASSCATS, 'assCats');

    saveTitles(dispatch, titles);
    saveCostCenters(dispatch, costCenters);
    saveTreatmentFavourites(dispatch, treatmentFavourites);
    saveTreatmentCodes(dispatch, treatmentCodes);
    saveJourneyStages(dispatch, journeyStages);
    saveAppointmentTypes(dispatch, appointmentTypes);
    saveDiaryEventTypes(dispatch, diaryEventTypes);
    saveRecallTypes(dispatch, recallTypes);
    saveMedicalConditions(dispatch, medicalConditions);
    saveQuestionnaires(dispatch, questions);
    saveCancellationReasons(dispatch, cancellationReasons);
    saveOccupations(dispatch, occupations);
    saveAssCats(dispatch, assCats);
};
