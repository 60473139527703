import * as Actions from '../actions/index';
import _ from 'lodash';
import jwt from "jsonwebtoken";

const initialState = {

    practiceListLoaded: false,

    practiceDetailsLoaded: false,
    practiceDetails: {name: '', address: '', telephone: '', email: ''},

    practiceDetailsLoginPageLoaded: false,
    practiceLoginPageDetails: {name: '', address: '', telephone: '', email: ''},

    loggedIn: false,
    userId: null,
    user: null,
    capabilities: [],
    pcc: {
        tabStack: [],
        index: 0
    },
    token: null,
    patientLoggedIn: false,

};

export const login = (state = _.cloneDeep(initialState), action) => {

    try {
        switch (action.type) {

            case Actions.REQUEST_PRACTICE_LOGIN_PAGE_DETAILS:

                return state;

            case Actions.RECEIVE_PRACTICE_LOGIN_PAGE_DETAILS:

                return {
                    ...state,
                    practiceLoginPageDetails: action.payload.practiceLoginPageDetails,
                    practiceDetailsLoginPageLoaded: true
                };

            case Actions.REQUEST_PRACTICE_DETAILS:

                return state;

            case Actions.RECEIVE_PRACTICE_DETAILS:

                return {
                    ...state,
                    practiceDetails: action.payload.practiceDetails,
                    practiceDetailsLoaded: true
                };

            case Actions.LOGIN:

                return state;

            case Actions.LOGIN_SUCCESS: {

                let decoded = jwt.decode(action.payload.token);
                const userId = action.payload.userId;

                const capabilities = decoded.capabilities ? decoded.capabilities.split(' ') : [];

                return {
                    ...state,
                    userId,
                    capabilities,
                    token: action.payload.token,
                };
            }
            case Actions.RECEIVE_LOGIN_ADMIN_USER_DETAILS:
            case Actions.RECEIVE_LOGIN_USER_DETAILS:

                return {
                    ...state,
                    loggedIn: true,
                    user: action.payload,
                };

            case Actions.RECEIVE_LOGIN_PATIENT_DETAILS:

                return {
                    ...state,
                    patientLoggedIn: true,
                    patient: action.payload,
                };

            case Actions.LOGIN_FAILURE: {

                return {...state, loggedIn: false};
            }

            case Actions.LOGIN_SUCCESS_PATIENT: {

                const patientId = action.payload.patientId;

                return {
                    ...state,
                    patientId,
                    token: action.payload.token,
                };
            }

            case Actions.UPDATE_TABSTACK: {

                return {...state, pcc: action.payload};
            }

            case Actions.SAVE_STACK_VECTORS: {

                return {
                    ...state,
                    onPCButtonClick: action.payload.onPCButtonClick,
                    onTabCloseClick: action.payload.onTabCloseClick,
                    onTabUpdate: action.payload.onTabUpdate
                };
            }

            case Actions.OPEN_MESSAGING:
                return {
                    ...state,
                    openMessaging: true,
                }
            case Actions.LOGOUT:
            case Actions.CLIENT_LOGOUT:

                return _.cloneDeep(initialState);

            default :
                return state;
        }
    } catch (error) {
        return state;
    }
};