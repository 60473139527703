import {
    SM_HOUSEKEEPING_MCS,
    SM_NHSClaimHistory,
    SM_NHSRegistration,
    SM_PATIENT_ACCOUNT_HISTORY,
    SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW,
    SM_PATIENT_APPOINTMENT_SUMMARY, SM_PATIENT_ASSESSMENTS, SM_PATIENT_ASSESSMENTS_ORTHO, SM_PATIENT_BILLING_GROUP,
    SM_PATIENT_DATA, SM_PATIENT_DOCUMENTS, SM_PATIENT_IMAGES, SM_PATIENT_MCONS, SM_PATIENT_MHFS,
    SM_PATIENT_NOK_DATA,
    SM_PATIENT_PASS,
    SM_PATIENT_PRSS,
    SM_PATIENT_RECALLS, SM_PATIENT_REMINDERS,
    SM_PATIENT_TREATMENT_HISTORY,
    SM_patientChart,
    SM_patientDataShort,
    SM_PAYMENT_PLAN,
    SM_PAYMENT_PLAN_HISTORY,
    SM_PaymentPlaning, SM_PLAN_PROVIDERS, SM_TAB_GDPR_CONSENT,
    SM_TreatmentPlan,
    SM_TreatmentPlanHistory,
    SM_TreatmentPlaning
} from "../../actions/stateManagement";
import {Button} from "primereact/components/button/Button";
import {Dialog} from "primereact/components/dialog/Dialog";
import React from "react";
import moment from "moment";
import {
    __CHIRAL_CONTACT_DETAILS__,
    DOC_PDF,
    DOC_TREATMENT_PLAN,
    HM_DeleteConsentDocument,
    HM_DeletePatientDocument, HM_DeletePaymentPlanDocument,
    HM_DeleteTreatmentPlanDocument,
    HM_EditConsentDocument,
    HM_EditPatientDocument, HM_EditPaymentPlanDocument,
    HM_EditTreatmentPlanDocument,
    HM_EmailConsentDocument,
    HM_EmailPatientDocument, HM_EmailPaymentPlanDocument, HM_EmailTreatmentPlanDocument,
    HM_notImplemented,
    HM_PreviewPatientConsent,
    HM_PreviewPatientDocument, HM_PreviewPatientPaymentPlan, HM_PreviewPatientTreatmentPlan,
    HM_PRINT_MED_HISTORY,
    HM_PRINT_QUESTIONNAIRE,
    HM_PrintAdjustment,
    HM_PrintConsentDocument,
    HM_PrintCreditNote,
    HM_PrintInvoice,
    HM_PrintPatientDocument,
    HM_PrintPayment,
    HM_PrintPaymentPlanDocument,
    HM_PrintPresDocument,
    HM_PrintProviderDayList,
    HM_PrintReceipt,
    HM_PrintReferralDocument,
    HM_PrintRefund,
    HM_PrintTreatmentPlanDocument,
    PP_STATUS_VISIBLE,
    REP_ConsentForm, REP_PaymentPlan,
    REP_TreatmentPlan,
    REPORT_SHOW
} from "../../Constants";
import _ from "lodash";
import {Document, Page} from "react-pdf";
import {Panel} from "primereact/panel";
import {ac} from "../../index";
import {TabPanel} from "primereact/components/tabview/TabView";

export const converter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
});

export const getReportPDF = ({url, request, receive}, params) => {

    return (dispatch) => {
        ac.fetchClient().get(`${url}?mcId=${ac.getMcId()}`, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf'
            },
            params: {...params}
        })
            .then(res => {
                dispatch({type: receive, payload: res.data, ...params})
            });
        dispatch({type: request, payload: {}, ...params});
    }
};

export const getTreatmentPlanIds = (state, ownProps) => {

    const resourcesLoaded = state.chartResources.resourcesLoaded;
    const resources = resourcesLoaded ? state.chartResources.resources.resources : null;

    const treatmentCodesLoaded = Boolean(state.housekeeping.treatmentCodesLoaded);
    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const treatmentPlaningId = `${SM_TreatmentPlaning.id}_${ownProps.patientId}`;

    const treatmentPlanHistoryLoadedId = `${SM_TreatmentPlanHistory.loaded}_${ownProps.patientId}`;
    const treatmentPlanHistoryId = `${SM_TreatmentPlanHistory.id}_${ownProps.patientId}`;

    const treatmentPlanLoadedId = Boolean(ownProps.plan) ? `${SM_TreatmentPlan.loaded}_${ownProps.plan.id}` : null;
    const treatmentPlanId = Boolean(ownProps.plan) ? `${SM_TreatmentPlan.id}_${ownProps.plan.id}` : null;

    const paymentPlanLoadedId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.loaded}_${ownProps.plan.id}` : null;
    const paymentPlanId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.id}_${ownProps.plan.id}` : null;

    const treatmentPlanHistoryLoaded = Boolean(state.treatmentPlanning[treatmentPlanHistoryLoadedId]) ? state.treatmentPlanning[treatmentPlanHistoryLoadedId] : false;
    const treatmentPlanHistory = treatmentPlanHistoryLoaded ? state.treatmentPlanning[treatmentPlanHistoryId] : [];

    const treatmentPlanLoaded = Boolean(state.treatmentPlanning[treatmentPlanLoadedId]) ? state.treatmentPlanning[treatmentPlanLoadedId] : false;
    const treatmentPlan = treatmentPlanLoaded ? state.treatmentPlanning[treatmentPlanId] : null;

    const paymentPlanLoaded = Boolean(state.treatmentPlanning[paymentPlanLoadedId]) ? state.treatmentPlanning[paymentPlanLoadedId] : false;
    const paymentPlan = paymentPlanLoaded ? state.treatmentPlanning[paymentPlanId] : null;

    const chartLoadedId = treatmentPlanLoaded ? `${SM_patientChart.loaded}_${treatmentPlan.patient.id}` : null;
    const chartId = treatmentPlanLoaded ? `${SM_patientChart.id}_${treatmentPlan.patient.id}` : null;

    const baseCategoryGroups = resourcesLoaded ? state.chartResources.resources.baseCategoryGroups : [];
    const favCategoryGroups = resourcesLoaded ? state.chartResources.resources.favCategoryGroups : [];

    const chartLoaded = Boolean(state.chartResources[chartLoadedId]) ? state.chartResources[chartLoadedId] : false;
    const charting = chartLoaded ? state.chartResources[chartId] : {};

    const journeyStagesLoaded = state.housekeeping.journeyStagesLoaded;
    const journeyStages = state.housekeeping.journeyStages;

    return {

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        treatmentPlaningId,

        treatmentPlanHistoryLoaded,
        treatmentPlanHistoryId,
        treatmentPlanHistory,

        treatmentPlanLoaded,
        treatmentPlanId,
        treatmentPlan,

        paymentPlanLoaded,
        paymentPlanId,
        paymentPlan,

        baseCategoryGroups,
        favCategoryGroups,

        chartLoaded,
        charting,

        journeyStagesLoaded,
        journeyStages,
    }
};

export const getPaymentPlanIds = (state, ownProps) => {

    const resourcesLoaded = state.chartResources.resourcesLoaded;
    const resources = resourcesLoaded ? state.chartResources.resources.resources : null;

    const treatmentCodesLoaded = Boolean(state.housekeeping.treatmentCodesLoaded);
    const treatmentCodes = treatmentCodesLoaded ? state.housekeeping.treatmentCodes : [];

    const paymentPlaningId = `${SM_PaymentPlaning.id}_${ownProps.patientId}`;

    const paymentPlanHistoryLoadedId = `${SM_PAYMENT_PLAN_HISTORY.loaded}_${ownProps.patientId}`;
    const paymentPlanHistoryId = `${SM_PAYMENT_PLAN_HISTORY.id}_${ownProps.patientId}`;

    const paymentPlanLoadedId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.loaded}_${ownProps.plan.id}` : null;
    const paymentPlanId = Boolean(ownProps.plan) ? `${SM_PAYMENT_PLAN.id}_${ownProps.plan.id}` : null;

    const paymentPlanHistoryLoaded = Boolean(state.paymentPlanning[paymentPlanHistoryLoadedId]) ? state.paymentPlanning[paymentPlanHistoryLoadedId] : false;
    const paymentPlanHistory = paymentPlanHistoryLoaded ? state.paymentPlanning[paymentPlanHistoryId] : [];

    const paymentPlanLoaded = Boolean(state.paymentPlanning[paymentPlanLoadedId]) ? state.paymentPlanning[paymentPlanLoadedId] : false;
    const paymentPlan = paymentPlanLoaded ? state.paymentPlanning[paymentPlanId] : null;

    return {

        resourcesLoaded,
        resources,

        treatmentCodesLoaded,
        treatmentCodes,

        paymentPlaningId,

        paymentPlanHistoryLoaded,
        paymentPlanHistoryId,
        paymentPlanHistory,

        paymentPlanLoaded,
        paymentPlanId,
        paymentPlan,
    }
};

export const getPatientIds = (state, ownProps) => {

    const patId = `_${ownProps.patientId}`;

    const dropDownsLoaded = Boolean(state.dropDowns.dropDownsLoaded);

    const patientDataLoadedId = `${SM_PATIENT_DATA.loaded}${patId}`;
    const patientDataId = `${SM_PATIENT_DATA.id}${patId}`;

    const patientNOKDataLoadedId = `${SM_PATIENT_NOK_DATA.loaded}${patId}`;
    const patientNOKDataId = `${SM_PATIENT_NOK_DATA.id}${patId}`;

    const patientDataShortLoadedId = `${SM_patientDataShort.loaded}${patId}`;
    const patientDataShortId = `${SM_patientDataShort.id}${patId}`;

    const patientAppointmentSummaryLoadedId = `${SM_PATIENT_APPOINTMENT_SUMMARY.loaded}${patId}`;
    const patientAppointmentSummaryId = `${SM_PATIENT_APPOINTMENT_SUMMARY.id}${patId}`;

    const patientTreatmentHistoryLoadedId = `${SM_PATIENT_TREATMENT_HISTORY.loaded}${patId}`;
    const patientTreatmentHistoryId = `${SM_PATIENT_TREATMENT_HISTORY.id}${patId}`;

    const paymentPlanHistoryLoadedId = `${SM_PAYMENT_PLAN_HISTORY.loaded}${patId}`;
    const paymentPlanHistoryId = `${SM_PAYMENT_PLAN_HISTORY.id}${patId}`;

    const accountHistoryOverviewLoaded = `${SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW.loaded}${patId}`;
    const accountHistoryOverviewId = `${SM_PATIENT_ACCOUNT_HISTORY_OVERVIEW.id}${patId}`;

    const patientAccountHistoryLoadedId = ownProps.group !== undefined ? `${SM_PATIENT_ACCOUNT_HISTORY.loaded}${patId}_${ownProps.group.id}` : '';
    const patientAccountHistoryId = ownProps.group !== undefined ? `${SM_PATIENT_ACCOUNT_HISTORY.id}${patId}_${ownProps.group.id}` : '';

    const recallsLoadedId = `${SM_PATIENT_RECALLS.loaded}${patId}`;
    const recallsId = `${SM_PATIENT_RECALLS.id}${patId}`;
    const prssId = `${SM_PATIENT_PRSS.id}${patId}`;

    const passLoadedId = `${SM_PATIENT_PASS.loaded}${patId}`;
    const passId = `${SM_PATIENT_PASS.id}${patId}`;

    const patientMHFSLoadedId = `${SM_PATIENT_MHFS.loaded}${patId}`;
    const patientMHFSId = `${SM_PATIENT_MHFS.id}${patId}`;

    const patientMCONSLoadedId = `${SM_PATIENT_MCONS.loaded}${patId}`;
    const patientMCONSId = `${SM_PATIENT_MCONS.id}${patId}`;

    const remindersLoadedId = `${SM_PATIENT_REMINDERS.loaded}${patId}`;
    const remindersId = `${SM_PATIENT_REMINDERS.id}${patId}`;

    const patientDocumentsLoadedId = `${SM_PATIENT_DOCUMENTS.loaded}${patId}`;
    const patientDocumentsId = `${SM_PATIENT_DOCUMENTS.id}${patId}`;

    const patientImagesLoadedId = `${SM_PATIENT_IMAGES.loaded}${patId}`;
    const patientImagesId = `${SM_PATIENT_IMAGES.id}${patId}`;

    const patientBillingGroupLoadedId = `${SM_PATIENT_BILLING_GROUP.loaded}${patId}`;
    const patientBillingGroupId = `${SM_PATIENT_BILLING_GROUP.id}${patId}`;

    const NHSRegistrationLoadedId = `${SM_NHSRegistration.loaded}${patId}`;
    const NHSRegistrationId = `${SM_NHSRegistration.id}${patId}`;

    const NHSClaimHistoryLoadedId = `${SM_NHSClaimHistory.loaded}${patId}`;
    const NHSClaimHistoryId = `${SM_NHSClaimHistory.id}${patId}`;

    const planProvidersLoadedId = SM_PLAN_PROVIDERS.loaded;
    const planProvidersId = SM_PLAN_PROVIDERS.id;

    const HOUSEKEEPING_MCSLoadedId = `${SM_HOUSEKEEPING_MCS.loaded}${patId}`;
    const HOUSEKEEPING_MCSId = `${SM_HOUSEKEEPING_MCS.id}${patId}`;

    const patientOrthoHistoryLoadedId = `${SM_PATIENT_ASSESSMENTS_ORTHO.loaded}${patId}`;
    const patientOrthoHistoryId = `${SM_PATIENT_ASSESSMENTS_ORTHO.id}${patId}`;

    const patientAssessmentHistoryLoadedId = `${SM_PATIENT_ASSESSMENTS.loaded}${patId}`;
    const patientAssessmentHistoryId = `${SM_PATIENT_ASSESSMENTS.id}${patId}`;

    const templateNotesLoaded = Boolean(state.housekeeping.templateNotesLoaded) ? state.housekeeping.templateNotesLoaded : false;
    const templateNotes = templateNotesLoaded ? state.housekeeping.templateNotes : [];
    const defaultPaymentNote = state.housekeeping.defaultPaymentNote;

    let patientDataLoaded = state.patients[patientDataLoadedId];
    patientDataLoaded = patientDataLoaded === undefined ? false : patientDataLoaded;

    let patientNOKDataLoaded = state.patients[patientNOKDataLoadedId];
    patientNOKDataLoaded = patientNOKDataLoaded === undefined ? false : patientNOKDataLoaded;

    let patientDataShortLoaded = state.patients[patientDataShortLoadedId];
    patientDataShortLoaded = patientDataShortLoaded === undefined ? false : patientDataShortLoaded;

    let patientAppointmentSummaryLoaded = state.patients[patientAppointmentSummaryLoadedId];
    patientAppointmentSummaryLoaded = patientAppointmentSummaryLoaded === undefined ? false : patientAppointmentSummaryLoaded;

    let patientTreatmentHistoryLoaded = state.patients[patientTreatmentHistoryLoadedId];
    patientTreatmentHistoryLoaded = patientTreatmentHistoryLoaded === undefined ? false : patientTreatmentHistoryLoaded;

    let patientAccountHistoryLoaded = state.patients[patientAccountHistoryLoadedId];
    patientAccountHistoryLoaded = patientAccountHistoryLoaded === undefined ? false : patientAccountHistoryLoaded;

    let paymentPlanHistoryLoaded = state.patients[paymentPlanHistoryLoadedId];
    paymentPlanHistoryLoaded = paymentPlanHistoryLoaded === undefined ? false : paymentPlanHistoryLoaded;

    let recallsLoaded = state.patients[recallsLoadedId];
    recallsLoaded = recallsLoaded === undefined ? false : recallsLoaded;

    let passLoaded = state.patients[passLoadedId];
    passLoaded = passLoaded === undefined ? false : passLoaded;

    let patientMHFSLoaded = state.patients[patientMHFSLoadedId];
    patientMHFSLoaded = patientMHFSLoaded === undefined ? false : patientMHFSLoaded;

    let patientMCONSLoaded = state.patients[patientMCONSLoadedId];
    patientMCONSLoaded = patientMCONSLoaded === undefined ? false : patientMCONSLoaded;

    let remindersLoaded = state.patients[remindersLoadedId];
    remindersLoaded = remindersLoaded === undefined ? false : remindersLoaded;

    let patientDocumentsLoaded = state.patients[patientDocumentsLoadedId];
    patientDocumentsLoaded = patientDocumentsLoaded === undefined ? false : patientDocumentsLoaded;

    let patientImagesLoaded = state.patients[patientImagesLoadedId];
    patientImagesLoaded = patientImagesLoaded === undefined ? false : patientImagesLoaded;

    let patientBillingGroupLoaded = state.patients[patientBillingGroupLoadedId];
    patientBillingGroupLoaded = patientBillingGroupLoaded === undefined ? false : patientBillingGroupLoaded;

    let HOUSEKEEPING_MCSLoaded = state.patients[HOUSEKEEPING_MCSLoadedId];
    HOUSEKEEPING_MCSLoaded = HOUSEKEEPING_MCSLoaded === undefined ? false : HOUSEKEEPING_MCSLoaded;

    let NHSRegistrationLoaded = state.patients[NHSRegistrationLoadedId];
    NHSRegistrationLoaded = NHSRegistrationLoaded === undefined ? false : NHSRegistrationLoaded;

    let NHSClaimHistoryLoaded = state.patients[NHSClaimHistoryLoadedId];
    NHSClaimHistoryLoaded = NHSClaimHistoryLoaded === undefined ? false : NHSClaimHistoryLoaded;

    let planProvidersLoaded = state.patients[planProvidersLoadedId];
    planProvidersLoaded = planProvidersLoaded === undefined ? false : planProvidersLoaded;

    let patientOrthoHistoryLoaded = state.patients[patientOrthoHistoryLoadedId];
    patientOrthoHistoryLoaded = patientOrthoHistoryLoaded === undefined ? false : patientOrthoHistoryLoaded;

    let patientAssessmentHistoryLoaded = state.patients[patientAssessmentHistoryLoadedId];
    patientAssessmentHistoryLoaded = patientAssessmentHistoryLoaded === undefined ? false : patientAssessmentHistoryLoaded;

    return {
        dropDownsLoaded,

        templateNotesLoaded,
        templateNotes,
        defaultPaymentNote,

        patientDataId,
        patientDataLoaded,

        patientNOKDataId,
        patientNOKDataLoaded,

        patientDataShortId,
        patientDataShortLoaded,

        patientAppointmentSummaryId,
        patientAppointmentSummaryLoaded,

        patientTreatmentHistoryId,
        patientTreatmentHistoryLoaded,

        accountHistoryOverviewLoaded,
        accountHistoryOverviewId,

        patientAccountHistoryId,
        patientAccountHistoryLoaded,

        paymentPlanHistoryId,
        paymentPlanHistoryLoaded,

        remindersId,
        remindersLoaded,

        recallsId,
        recallsLoaded,
        prssId,

        passLoaded,
        passId,

        patientMHFSLoaded,
        patientMHFSId,

        patientMCONSLoaded,
        patientMCONSId,

        patientDocumentsId,
        patientDocumentsLoaded,

        patientImagesId,
        patientImagesLoaded,

        patientBillingGroupId,
        patientBillingGroupLoaded,

        NHSRegistrationId,
        NHSRegistrationLoaded,

        NHSClaimHistoryId,
        NHSClaimHistoryLoaded,

        planProvidersId,
        planProvidersLoaded,

        HOUSEKEEPING_MCSId,
        HOUSEKEEPING_MCSLoaded,

        patientOrthoHistoryId,
        patientOrthoHistoryLoaded,

        patientAssessmentHistoryId,
        patientAssessmentHistoryLoaded,
    }
};

export const ShowMessageDialog = (parent, hm, callback, _message) => {

    const visible = parent.state[hm.id];

    if (!visible) {
        return null;
    }

    const footer = <div>
        <Button label="Close" icon="fas fa-times" onClick={() => {
            if (callback !== undefined) {
                callback(hm.id);
            } else {
                parent.onHideMenuEntry(hm.id);
            }
        }}/>
    </div>;

    const message = _message === undefined ? '' : _message;
    return (

        <Dialog header={hm.header}
                visible={true}
                modal={true}
                responsize={true}
                resizable={false}
                blockScroll={true}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {`${hm.message} ${message}`.trim()}
        </Dialog>
    )
};

export const dateTemplate = (date) => {

    try {
        const _date = moment(date);
        return _date.isValid() ? _date.format('dddd, MMMM Do YYYY') : '-';
    } catch (ex) {
        return '-';
    }
};

export const timeTemplate = (start, end) => {
    return start === null ? '-' :
        <span style={{fontSize: "12px"}}>{moment(start).format('HH:mm')} - {moment(end).format('HH:mm')}</span>;
};

export const durationTemplate = (start, end) => {

    if (start === null || end === null) {
        return '-';
    } else {
        const t1 = moment.utc(start);
        const t2 = moment.utc(end);
        const mDuration = moment.duration(t2.diff(t1));

        return `${mDuration.hours()}:${mDuration.minutes()}`
    }
};

export const startTemplate = (date) => {
    return moment(date).format('HH:mm');
};

export const dateTimeTemplate = (start, end) => {
    return start === null ? '-' : `${moment(start).format('ddd, DD MMM HH:mm')} - ${moment(end).format('HH:mm')}`;
};

export const dateTimeTemplateClient = (start, end) => {
    return start === null ? 'No future appointment booked yet' : `${moment(start).format('ddd, DD MMM HH:mm')} - ${moment(end).format('HH:mm')}`;
};

export const dateTemplateShort = (date) => {

    try {
        const _date = moment(date);
        return _date.isValid() ? _date.format('Do MMMM YYYY') : '-';
    } catch (ex) {
        return '-';
    }
};

export const getFormattedDateDiffShort = (date1, date2) => {
    let b = moment(date1),
        a = moment(date2),
        intervals = ['years', 'months'],
        out = [];

    intervals.forEach(interval => {
        let diff = a.diff(b, interval);
        b.add(diff, interval);

        if (interval === 'years') {
            out.push(diff + ` Ys`);
        } else {
            out.push(diff + ` Ms`);
        }
    });
    return out.join(', ');
};

export const referrerTemplate = (row) => {

    const {firstName, lastName, companyName} = row;
    const _companyName = companyName === null ? '' : companyName.trim();

    const fullname = `${firstName} ${lastName}`.trim();

    return fullname === '' ? _companyName : _companyName.trim() === '' ? fullname : `${fullname} (${_companyName})`;
};

export const psrUsernameTemplate = (item, props) => {

    const user = _.find(props.usersShort, user => user.id === item.providerId);
    return user ? `${user.username}`.trim() : '';
};

export const currencyTemplate = (row, field) => {

    const value = _.get(row, field);

    return converter.format(value);
};

export const colourTemplate = (item, props) => {

    const appointmentType = _.find(props.appointmentTypes, type => type.id === item.type);

    const color = `rgb(${appointmentType.red},${appointmentType.green},${appointmentType.blue})`;
    return <div style={{backgroundColor: color}}>&nbsp;</div>

};

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default class PatientErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <h1>Something went wrong in the Patient Details section.</h1>
                    <h2>{__CHIRAL_CONTACT_DETAILS__}</h2>
                </div>
            );
        }
        return this.props.children;
    }
}

export const ppStatusDropDownList = () => {
    const statuses = [];

    PP_STATUS_VISIBLE.forEach(status => {
        statuses.push({label: status.text, value: status.name, ord: status.ord});
    });
    return statuses;
};

export const suppressBrowserCM = () => {
    document.addEventListener('contextmenu', e => {
        e.preventDefault()
    })
};

// PDF Consent functions

export const PDFHeader = (numPages, index, header) => {

    return <div><label id='panel-header'>{header}</label>
        <div className="p-toolbar-group-right">
            <label id='panel-header'>{`Page ${index + 1} of ${numPages}`}</label>
        </div>
    </div>;

}

export const PDFConsentSection = (props) => {

    let visibleName = ''

    if (props.GDPRConsent) {
        visibleName = SM_TAB_GDPR_CONSENT.label;
    } else {
        const template = _.find(props.cfTemplates, template => template.docId === props.consentForm.templateId);
        visibleName = template.visibleName;
    }
    const parameterObject = {
        data: props.pdf,
    };

    return (
        <div className="p-grid p-fluid">

            <div className="p-col-12">

                <Document
                    file={parameterObject}
                    onLoadSuccess={props.onDocumentLoadSuccess}
                    onLoadError={props.onLoadError}
                    onItemClick={() => {
                    }}
                >
                    {
                        Array.from(
                            new Array(props.numPages),
                            (el, index) => (
                                <Panel header={PDFHeader(props.numPages, index, visibleName)}
                                       key={`panel_${index + 1}`}
                                       style={{paddingTop: '10px'}}>
                                    <Page key={`page_${index + 1}`}
                                          pageNumber={index + 1}
                                          width={650}
                                    />
                                </Panel>
                            ),
                        )
                    }
                </Document>
            </div>
        </div>
    )
}

export const ShowQuestionDialog = (parent, hm, onOK) => {

    if (!parent.state[hm.id]) {
        return null;
    }

    const footer = <div key='sqd_0'>
        <Button label="Yes" icon="fa fa-check" onClick={() => {
            onOK();
        }}/>
        <Button label="No" icon="fas fa-times" onClick={() => {
            parent.onHideMenuEntry(hm.id);
        }}/>
    </div>;

    return (

        <Dialog header={hm.header}
                visible={parent.state[hm.id]}
                modal={true}
                responsize={true}
                resizable={true}
                blockScroll={true}
                minY={70}
                footer={footer}
                onHide={() => {
                    parent.onHideMenuEntry(hm.id);
                }}>
            {hm.message}
        </Dialog>
    )
};

export const printDocumentItem = (props, growl) => {

    let urlString = null;

    const {report, reportFunction, itemId, number, period, target, type, filename} = props.options;
    const key = Math.random().toString(36).slice(2);

    if (type === DOC_PDF.name) {
        urlString = `https://${ac.getBASERESTURL()}/Patients/openPatientDocument/${ac.getMcId()}/${filename}/${type}/${key}`
    } else {

        switch (target) {
            case HM_PrintInvoice.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.invoice.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}&period=${period}`;
                break;
            case HM_PrintReceipt.id:
            case HM_PrintRefund.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}`;
                break;
            case HM_PrintPayment.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.payment.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}`;
                break;
            case HM_PrintCreditNote.id:
            case HM_PrintAdjustment.id:
                urlString = `https://${ac.getBASERESTURL()}/Reports/accountingItem.cnpa.pdf?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&id=${itemId}`;
                break;
            case HM_PrintPatientDocument.id:
            case HM_PrintPresDocument.id:
            case HM_PrintReferralDocument.id:
            case HM_PrintTreatmentPlanDocument.id:
            case HM_PrintPaymentPlanDocument.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/createIt?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&itemId=${itemId}`;
                break;
            case HM_PrintProviderDayList.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/createIt?mcId=${ac.getMcId()}&report=${report}&reportFunction=${reportFunction}&date=${itemId}`;
                break;
            case HM_PRINT_MED_HISTORY.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/medicalHistoryForm.pdf?mcId=${ac.getMcId()}&patientId=${itemId}`;
                break;
            case HM_PRINT_QUESTIONNAIRE.id:
                urlString = `https://${ac.getBASERESTURL()}/Patient/PDFs/questionnaire.pdf?mcId=${ac.getMcId()}&patientId=${itemId}`;
                break;
            case HM_PrintConsentDocument.id:
                urlString = `https://${ac.getBASERESTURL()}/Patients/openPatientDocument/${ac.getMcId()}/${filename}/${type}/${key}`
                break;
            default:
                break;
        }
    }
    getReportPDF(urlString, number, growl);
};

export const menuButtonTemplate = (props, Component, id, tabIcon, tabLabel, section, options) => {

    const componentId = id;

    return (
        {
            edited: false,
            selectedSection: section,
            content:
                <TabPanel key={componentId}
                          header={tabLabel} leftIcon={`fa ${tabIcon}`}>
                    <Component onCloseClick={props.onTabCloseClick} id={componentId} options={options}
                               onAdditionalClick={() => {
                               }} onTabUpdate={props.onTabUpdate} selectedSection={section} {...props} />
                </TabPanel>,
            editedContent:
                <TabPanel key={componentId}
                          header={`${tabLabel}  *`} leftIcon={`fa ${tabIcon}`}>
                    <Component onCloseClick={props.onTabCloseClick} id={componentId} options={options}
                               onAdditionalClick={() => {
                               }} onTabUpdate={props.onTabUpdate} selectedSection={section} {...props}/>
                </TabPanel>,
        }
    );
};

export const buildPatientConsentItemsMenu = (toolbarCallbacks, onShowMenuEvent, portalView) => {

    if (portalView) {
        return ([
            {
                label: HM_PrintConsentDocument.header,
                icon: HM_PrintConsentDocument.icon,
                command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                    {
                        report: REP_ConsentForm.value,
                        reportFunction: REPORT_SHOW,
                        target: HM_PrintConsentDocument.id
                    }
                ),
            },
        ])
    } else {
        return ([
            {
                label: HM_DeleteConsentDocument.header,
                icon: HM_DeleteConsentDocument.icon,
                command: onShowMenuEvent,
                target: HM_notImplemented.id
            },
            {
                label: HM_EditConsentDocument.header,
                icon: HM_EditConsentDocument.icon,
                command: onShowMenuEvent,
                target: HM_notImplemented.id
            },
            {
                label: HM_PrintConsentDocument.header,
                icon: HM_PrintConsentDocument.icon,
                command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                    {
                        report: REP_ConsentForm.value,
                        reportFunction: REPORT_SHOW,
                        target: HM_PrintConsentDocument.id
                    }
                ),
            },
            {
                label: HM_EmailConsentDocument.header,
                icon: HM_EmailConsentDocument.icon,
                command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientConsent),
            },
        ])
    }
};

export const buildPatientDocumentsItemsMenu = (toolbarCallbacks, onShowMenuEvent, portalView) => {

    if (portalView) {
        // {
        //     label: HM_PrintPatientDocument.header,
        //     icon: HM_PrintPatientDocument.icon,
        //     command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
        //         {
        //             report: REP_StandardPatient.value,
        //             reportFunction: REPORT_SHOW,
        //             target: HM_PrintPatientDocument.id
        //         }
        //     ),
        // },
    } else {
        return ([
            {
                label: HM_DeletePatientDocument.header,
                icon: HM_DeletePatientDocument.icon,
                command: onShowMenuEvent,
                target: HM_DeletePatientDocument.id
            },
            {
                label: HM_EditPatientDocument.header,
                icon: HM_EditPatientDocument.icon,
                command: onShowMenuEvent,
                target: HM_EditPatientDocument.id
            },
            // {
            //     label: HM_PrintPatientDocument.header,
            //     icon: HM_PrintPatientDocument.icon,
            //     command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
            //         {
            //             report: REP_StandardPatient.value,
            //             reportFunction: REPORT_SHOW,
            //             target: HM_PrintPatientDocument.id
            //         }
            //     ),
            // },
            {
                label: HM_EmailPatientDocument.header,
                icon: HM_EmailPatientDocument.icon,
                command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientDocument),
            },
        ])
    }
};

export const buildTPPPDocumentsItemsMenu = (toolbarCallbacks, onShowMenuEvent, selectedItem, portalView) => {

    if (selectedItem === null)
        return null;

    if (selectedItem.type === DOC_TREATMENT_PLAN.name) {

        if (portalView) {
            return ([
                {
                    label: HM_PrintTreatmentPlanDocument.header,
                    icon: HM_PrintTreatmentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_TreatmentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintTreatmentPlanDocument.id
                        }
                    ),
                },
            ])
        } else {
            return ([
                {
                    label: HM_DeleteTreatmentPlanDocument.header,
                    icon: HM_DeleteTreatmentPlanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_DeleteTreatmentPlanDocument.id
                },
                {
                    label: HM_EditTreatmentPlanDocument.header,
                    icon: HM_EditTreatmentPlanDocument.icon,
                    command: onShowMenuEvent,
                },
                {
                    label: HM_PrintTreatmentPlanDocument.header,
                    icon: HM_PrintTreatmentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_TreatmentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintTreatmentPlanDocument.id
                        }
                    ),
                },
                {
                    label: HM_EmailTreatmentPlanDocument.header,
                    icon: HM_EmailTreatmentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientTreatmentPlan),
                },
            ])
        }
    } else {

        if (portalView) {
            return ([
                {
                    label: HM_PrintPaymentPlanDocument.header,
                    icon: HM_PrintPaymentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_PaymentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintPaymentPlanDocument.id
                        }
                    ),
                },
            ])
        } else {
            return ([
                {
                    label: HM_DeletePaymentPlanDocument.header,
                    icon: HM_DeletePaymentPlanDocument.icon,
                    command: onShowMenuEvent,
                    target: HM_DeletePaymentPlanDocument.id
                },
                {
                    label: HM_EditPaymentPlanDocument.header,
                    icon: HM_EditPaymentPlanDocument.icon,
                    command: onShowMenuEvent,
                },
                {
                    label: HM_PrintPaymentPlanDocument.header,
                    icon: HM_PrintPaymentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PrintPatientDocument.id](
                        {
                            report: REP_PaymentPlan.value,
                            reportFunction: REPORT_SHOW,
                            target: HM_PrintPaymentPlanDocument.id
                        }
                    ),
                },
                {
                    label: HM_EmailPaymentPlanDocument.header,
                    icon: HM_EmailPaymentPlanDocument.icon,
                    command: (e) => toolbarCallbacks[HM_PreviewPatientDocument.id](HM_PreviewPatientPaymentPlan),
                },
            ])
        }
    }
};
