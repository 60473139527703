import React, {Component} from 'react';
import {connect} from "react-redux";
import {dispatchMessage, handleSDPMessage} from "../actions/websockets";
import {WSM_CLOSED, WSM_OPEN, WSM_SET_STATE} from "../actions";
import Websocket from "react-websocket";
import {ac} from "../index";

class ConnectedClientWebsocketHandler extends Component {

    constructor(props) {
        super(props);

        this.sdpws = null;
    }

    onError = (error) => {
        throw new Error(error)
    }

    onOpenSDP = () => {
        console.log('onOpen :: SDP');
        this.props.dispatchMessage(WSM_OPEN);
    }

    onCloseSDP = () => {
        this.props.dispatchMessage(WSM_CLOSED);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.messageToSend !== null && this.props.messageToSend !== prevProps.messageToSend) {
            try {
                console.log('onSend :: SDP');
                this.sdpws.sendMessage(this.props.messageToSend.message);
            } catch (error) {
                console.log(error);
            }
        }
    }

    render() {
        try {

            return (
                <div>
                    <Websocket url={ac.getBASEWSURL() + '/SDP'}
                               onOpen={this.onOpenSDP}
                               onError={this.onError}
                               onMessage={this.props.handleSDPMessage.bind(this)}
                               onClose={this.onCloseSDP}
                               ref={(sdpws) => this.sdpws = sdpws}
                    />
                </div>
            );
        } catch (error) {
            return <h1>{`Websocket connect error : ${error}`}</h1>;
        }
    }
}

const mapStateToProps = (state) => {

    return {
        messageToSend: state.websockets.messageToSend,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchMessage: (data) => dispatch(dispatchMessage(WSM_SET_STATE, data)),

        handleSDPMessage: (data) => dispatch(handleSDPMessage(JSON.parse(data))),
    };
};

const ClientWebsocketHandler = connect(mapStateToProps, mapDispatchToProps)(ConnectedClientWebsocketHandler);

export default ClientWebsocketHandler;
